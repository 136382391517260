import { put, fork, takeLatest } from "redux-saga/effects";
import {
	EDIT_ACTIVITY_DESCRIPTION,
	EDIT_TOPIC_DESCRIPTION,
} from "../../constants";
import { editTopicRequest } from "../../sagas/editProgramDescriptions/handleEditTopicDescription";
import { editActivityRequest } from "../../sagas/editProgramDescriptions/handleEditActivityDescription";
import { generalApiErrorHandler } from "../../sagas/helpers/generalApiErrorHandler";
import { showNotification } from "../../actions";
import { getNotificationMessage } from "../../../helpers/notifications";

function* watchEditTopicDescription() {
	yield takeLatest(EDIT_TOPIC_DESCRIPTION, handleEditTopicDescription);
}

function* watchEditActivityDescription() {
	yield takeLatest(EDIT_ACTIVITY_DESCRIPTION, handleEditActivityDescription);
}

function* handleEditTopicDescription(requestData) {
	try {
		yield editTopicRequest(requestData);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* handleEditActivityDescription(requestData) {
	try {
		yield editActivityRequest(requestData);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* editDescriptions() {
	yield fork(watchEditTopicDescription);
	yield fork(watchEditActivityDescription);
}
