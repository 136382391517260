import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import CustomReportsFilterSelect from "components/CustomFormSelect/CustomReportsFilterSelect";
import { customSelectStyles as selectStyles } from "helpers/customSelectStyles";
import { addSelectedFilterOptions } from "../../../../redux/actions";
import { removeEmptyValues } from "helpers/removeEmptyValues";
import "../ReportsFilter/ReportsFilter.scss";

const EngagementReportsFilter = ({
	setOffsetReports,
	activeTab,
	isAllFiltersVisible,
	setAllFiltersVisible,
	filterOptions,
	setClearFilters,
	clearFilters,
	isMobile = false,
}) => {
	const dispatch = useDispatch();
	const { userRole } = useSelector((state) => state.auth);

	const { searchQuery } = useSelector((state) => state.reports);

	const _handleSubmit = (values) => {
		let filtersValues = {};

		for (const property in removeEmptyValues(values)) {
			if (
				property === "average_evaluation" ||
				property === "average_feedback" ||
				property === "progress"
			) {
				filtersValues[property] = values[property];
			} else {
				filtersValues[property] = [];
				filtersValues[property].push(values[property]);
			}
		}

		setOffsetReports(0);

		dispatch(addSelectedFilterOptions(filtersValues));

		filtersValues.search = searchQuery ? searchQuery : "";
	};

	return (
		<Formik
			initialValues={{
				schools: "",
				school_programs: "",
				average_evaluation: "",
				average_feedback: "",
				progress: "",
			}}
			onSubmit={(values) => _handleSubmit(values)}
		>
			{({ values }) => (
				<Form className="reports-filter">
					{userRole === "coach" && (
						<Field
							name="schools"
							options={filterOptions?.schools}
							placeholder="All institutions..."
							component={CustomReportsFilterSelect}
							styles={selectStyles}
							className="reports-filter__filter"
							clearFilters={clearFilters}
							setClearFilters={setClearFilters}
							isMobile={isMobile}
							activeTab={activeTab}
						/>
					)}
					<Field
						name="school_programs"
						options={filterOptions?.school_programs}
						placeholder="All programmes..."
						component={CustomReportsFilterSelect}
						styles={selectStyles}
						className="reports-filter__filter"
						clearFilters={clearFilters}
						setClearFilters={setClearFilters}
						isMobile={isMobile}
						activeTab={activeTab}
					/>
					<Field
						name="average_evaluation"
						options={filterOptions?.average_evaluation}
						placeholder="Evaluation rates..."
						component={CustomReportsFilterSelect}
						styles={selectStyles}
						className="reports-filter__filter"
						clearFilters={clearFilters}
						setClearFilters={setClearFilters}
						isMobile={isMobile}
						activeTab={activeTab}
					/>
					{userRole === "school_admin" && (
						<Field
							name="average_feedback"
							options={filterOptions?.average_feedback}
							placeholder="Feedback rates..."
							component={CustomReportsFilterSelect}
							styles={selectStyles}
							className="reports-filter__filter"
							clearFilters={clearFilters}
							setClearFilters={setClearFilters}
							isMobile={isMobile}
							activeTab={activeTab}
						/>
					)}
					{isAllFiltersVisible && (
						<Field
							name="progress"
							options={filterOptions?.progress}
							placeholder="Progress % ranges..."
							component={CustomReportsFilterSelect}
							styles={selectStyles}
							className="reports-filter__filter"
							clearFilters={clearFilters}
							setClearFilters={setClearFilters}
							isMobile={isMobile}
							activeTab={activeTab}
						/>
					)}
					{isMobile && (
						<button
							type="button"
							className="mobile__submit"
							onClick={() => {
								setAllFiltersVisible(false);
							}}
						>
							Apply filters
						</button>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default React.memo(EngagementReportsFilter);
