import React from 'react';

const FormIcon = ({color = '#486EA0', width = 32, height = 32, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8662 28.546L19.2719 29.291C19.0698 29.3587 18.8339 29.3926 18.5981 29.3926C17.9242 29.3926 17.2841 29.0878 16.8461 28.5799C16.4081 28.0381 16.2059 27.3608 16.307 26.6836L16.5091 25.3291H7.00796C6.40151 25.3291 5.92982 24.855 5.92982 24.2455C5.92982 23.6698 6.40151 23.1619 7.00796 23.1619H17.0145C17.1156 23.0603 17.183 22.9587 17.2841 22.8233L24.8311 14.1545V4.74074C24.8311 3.75873 24.0225 2.94603 23.0117 2.94603H20.2827V1.72698C20.2827 0.744974 19.5077 0 18.5644 0C17.621 0 16.8798 0.744974 16.8798 1.72698V2.94603H14.1507V1.72698C14.1507 0.744974 13.3758 0 12.4324 0C11.489 0 10.7141 0.744974 10.7141 1.72698V2.94603H7.98504V1.72698C7.98504 0.744974 7.24381 0 6.26674 0C5.32336 0 4.54844 0.744974 4.54844 1.72698V2.94603H1.81938C0.842304 2.94603 0 3.75873 0 4.74074V30.1714C0 31.1534 0.808611 32 1.81938 32H23.0454C24.0225 32 24.8648 31.1873 24.8648 30.1714V25.6677L22.978 27.8349C22.6748 28.1735 22.2705 28.4106 21.8662 28.546ZM7.00796 9.31217H17.8231C18.4296 9.31217 18.9013 9.78624 18.9013 10.3958C18.9013 10.9714 18.4296 11.4794 17.8231 11.4794H7.00796C6.40151 11.4794 5.92982 10.9714 5.92982 10.3958C5.92982 9.78624 6.4352 9.31217 7.00796 9.31217ZM7.00796 16.254H17.8231C18.4296 16.254 18.9013 16.728 18.9013 17.3376C18.9013 17.9132 18.4296 18.4212 17.8231 18.4212H7.00796C6.40151 18.4212 5.92982 17.9132 5.92982 17.3376C5.92982 16.728 6.4352 16.254 7.00796 16.254Z" fill={color}/>
            <path d="M18.0254 26.9541C17.9581 27.3943 18.3624 27.7668 18.8341 27.6313L21.3947 26.8525C21.4957 26.8186 21.5968 26.7509 21.6642 26.6832L22.4054 25.8366L19.3394 23.0938L18.5982 23.9403C18.4971 24.0419 18.4634 24.1435 18.4634 24.2789L18.0254 26.9541Z" fill={color}/>
            <path d="M20.249 22.0786L23.315 24.8215L29.6491 17.541L26.5831 14.832L20.249 22.0786Z" fill={color}/>
            <path d="M31.8053 14.1879L29.649 12.2916C29.3794 12.0546 29.0088 12.0885 28.773 12.3255L27.4927 13.7816L30.5587 16.4906L31.839 15.0345C32.0748 14.7975 32.0411 14.425 31.8053 14.1879Z" fill={color}/>
        </svg>
    );
};

export default FormIcon;