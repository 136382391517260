export const settings = {
    PUT_SETTINGS: "PUT_SETTINGS",
    PUT_SCHOOL_ADMIN_SETTINGS: "PUT_SCHOOL_ADMIN_SETTINGS",
    PUT_SETTINGS_SUCCESS: "PUT_SETTINGS_SUCCESS",
    PUT_SETTINGS_ERROR: "PUT_SETTINGS_ERROR",
    SEND_STATUS_TOGGLE_NOTIFICATION: "SEND_STATUS_TOGGLE_NOTIFICATION",
    GET_GOOGLE_AUTH_LINK: "GET_GOOGLE_AUTH_LINK",
    GET_GOOGLE_AUTH_LINK_SUCCESS: "GET_GOOGLE_AUTH_LINK_SUCCESS",
    GET_GOOGLE_AUTH_LINK_ERROR: "GET_GOOGLE_AUTH_LINK_ERROR",
    GET_GOOGLE_AUTH_STATUS: 'GET_GOOGLE_AUTH_STATUS',
    CANCEL_GOOGLE_AUTH: "CANCEL_GOOGLE_AUTH",
    CANCEL_GOOGLE_AUTH_ERROR: "CANCEL_GOOGLE_AUTH_ERROR",
    CANCEL_GOOGLE_AUTH_CLEAR_ERROR: 'CANCEL_GOOGLE_AUTH_CLEAR_ERROR'
};