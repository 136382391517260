import { platformSettings } from "../constants";

export function getPlatformSettings(settings) {
	return {
		type: platformSettings.GET_PLATFORM_SETTINGS,
		payload: settings,
	};
}

export function getPlatformSettingsSuccess(platformSettingsSuccess) {
	return {
		type: platformSettings.GET_PLATFORM_SETTINGS_SUCCESS,
		payload: platformSettingsSuccess,
	};
}

export function getPlatformSettingsError(error) {
	return {
		type: platformSettings.GET_PLATFORM_SETTINGS_ERRORS,
		payload: error,
	};
}
