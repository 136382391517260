import React from 'react';
import './initLoader.scss';
import {ReactComponent as LogoWhiteSVG} from "assets/vector/auth-page/logo-switch.svg";

const InitLoader = () => {
    return (
        <div className='init-loader'>
            <LogoWhiteSVG className="init-loader__logo"/>
        </div>
    );
};

export default InitLoader;