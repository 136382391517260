import React from "react";
import Button from "components/button/Button";
import "./RequestSession.scss";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../redux/actions/modal";
import { SESSION_REQUEST_MODAL } from "../../../../redux/constants/modal";
import { sessionRequest } from "../../../../redux/actions";

const RequestSession = ({
	text = `Request session`,
	programId,
	isDisabled,
}) => {
	const dispatch = useDispatch();

	const handleRequest = () => {
		dispatch(
			openModal(SESSION_REQUEST_MODAL, {
				title: "Are you sure you want to request a new session?",
				description: "Please specify the reason and your wishes.",
				confirmAction: (description) =>
					dispatch(sessionRequest(programId, description)),
			})
		);
	};

	return (
		<Button
			className="button button--orange-invert request-session"
			onClick={handleRequest}
			disabled={isDisabled}
		>
			{text}
		</Button>
	);
};

export default RequestSession;
