import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import "./EditScheduleForm.scss";
import BreakDays from "./EditScheduleFormComponents/BreakDays";
import TimePickersGroup from "./EditScheduleFormComponents/TimePickersGroup";
import DailyBreak from "./EditScheduleFormComponents/DailyBreak";
import TimeOff from "./EditScheduleFormComponents/TimeOff";
import { useSelector, useDispatch } from "react-redux";
import editScheduleValidationSchema from "./helpers/validationSchema";
import { transformValuesBeforeSending } from "./helpers/dateTransform";
import axios from "axios";
import { REACT_APP_API_BASE_URL } from "constants/api";
import { getInfoAboutCurrentUser, closeModal } from "../../../redux/actions";
import CustomErrorMessage from "./EditScheduleFormComponents/CustomErrorMessage";
import FormButtons from "./EditScheduleFormComponents/FormButtons";
import { setInitialValues } from "./helpers/setInitialValues";

const days = [
	{
		label: "Mon",
		value: 1,
	},
	{
		label: "Tue",
		value: 2,
	},
	{
		label: "Wed",
		value: 3,
	},
	{
		label: "Thu",
		value: 4,
	},
	{
		label: "Fri",
		value: 5,
	},
	{
		label: "Sat",
		value: 6,
	},
	{
		label: "Sun",
		value: 0,
	},
];

const EditScheduleForm = () => {
	const dispatch = useDispatch();

	const { authToken } = useSelector((state) => state.auth);
	const { absence_schedule } = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);

	const [dailyBreak, setDailyBreak] = useState(false);

	const handleSubmit = (values, actions, dailyBreak) => {
		const newValues = transformValuesBeforeSending(values, dailyBreak);

		axios({
			method: "post",
			url: `${REACT_APP_API_BASE_URL}/user/absence-schedule`,
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
			accept: "application/json",
			data: newValues,
		})
			.then((response) => {
				if (response.data.status === "Success") {
					dispatch(getInfoAboutCurrentUser(null, () => dispatch(closeModal())));
				}
			})
			.catch((error) => {
				const responseErrors = error.response.data;

				if (responseErrors.errors.length > 0) {
					responseErrors.errors.map((item) => {
						if (item.field.includes("time_off")) {
							const arr = item.field.split(".");

							if (arr.length === 3) {
								return actions.setFieldError(item.field, item.message);
							} else {
								return actions.setFieldError(
									item.field + ".api__error",
									item.message
								);
							}
						} else {
							const itemArray = item.field.split(".");
							return actions.setFieldError(itemArray[0], item.message);
						}
					});
				}
			});

		actions.setSubmitting(false);
	};

	useEffect(() => {
		if (absence_schedule?.daily_break_time) {
			setDailyBreak(absence_schedule.daily_break_time.length > 0);
		}
	}, []);

	return (
		<Formik
			initialValues={setInitialValues(absence_schedule)}
			validationSchema={editScheduleValidationSchema()}
			onSubmit={(values, actions) => handleSubmit(values, actions, dailyBreak)}
		>
			{({ values, errors, isSubmitting, dirty, isValid }) => {
				return (
					<Form className="edit-schedule__form">
						<div className="edit-schedule__break-days">
							<div className="title">Recurring Unavailable Time</div>
							<div className="fields">
								<Field
									values={values}
									name="break_days"
									options={days}
									component={BreakDays}
								/>
								<TimePickersGroup
									values={values}
									firstName="break_days_start"
									secondName="break_days_end"
									disabled={values?.break_days.length === 0}
								/>
							</div>
							{errors?.break_days_end && (
								<CustomErrorMessage message={errors.break_days_end} />
							)}
							{/* used to handle API errors */}
							{errors?.break_days_start && (
								<CustomErrorMessage message={errors.break_days_start} />
							)}
						</div>
						<DailyBreak
							switchActive={dailyBreak}
							toggleActive={setDailyBreak}
							values={values}
							firstName="daily_break_start"
							secondName="daily_break_end"
							errors={errors}
						/>
						<FieldArray name="time_off">
							{({ remove, push }) => (
								<>
									{values["time_off"].length > 0 &&
										values["time_off"].map((item, index) => (
											<div className="array-item__wrapper" key={index}>
												<TimeOff
													values={values}
													groupName="time_off"
													index={index}
													dateFirstName="start_day"
													dateSecondName="end_day"
													timeFirsName="start_time"
													timeSecondName="end_time"
													remove={remove}
												/>
												{errors &&
													errors["time_off"] &&
													errors["time_off"][index] &&
													errors["time_off"][index]["end_time"] && (
														<CustomErrorMessage
															message={errors["time_off"][index]["end_time"]}
														/>
													)}
												{errors &&
													errors["time_off"] &&
													errors["time_off"][index] &&
													errors["time_off"][index]["end_day"] && (
														<CustomErrorMessage
															message={errors["time_off"][index]["end_day"]}
														/>
													)}
												{/* used to handle API errors */}
												{errors &&
													errors["time_off"] &&
													errors["time_off"][index] &&
													errors["time_off"][index]["start"] && (
														<CustomErrorMessage
															message={errors["time_off"][index]["start"]}
														/>
													)}
												{errors &&
													errors["time_off"] &&
													errors["time_off"][index] &&
													errors["time_off"][index]["end"] && (
														<CustomErrorMessage
															message={errors["time_off"][index]["end"]}
														/>
													)}
												{errors &&
													errors["time_off"] &&
													errors["time_off"][index] &&
													errors["time_off"][index]["api__error"] && (
														<CustomErrorMessage
															message={errors["time_off"][index]["api__error"]}
														/>
													)}
											</div>
										))}
									<FormButtons
										push={push}
										isSubmitting={isSubmitting}
										isValid={isValid}
									/>
								</>
							)}
						</FieldArray>
					</Form>
				);
			}}
		</Formik>
	);
};

export default EditScheduleForm;
