import englishIcon from 'assets/vector/questionnaire/Languages/english_icon.svg';
import italianIcon from 'assets/vector/questionnaire/Languages/italian_icon.svg';

export const languageSelection = [
    {
        id: 1,
        label: 'English',
        value: 'en',
        icon: englishIcon
    },
    {
        id: 2,
        label: 'Italian',
        value: 'it',
        icon: italianIcon
    }
]