import React from 'react';
import './ReportContent.scss';
import uniqid from "uniqid";
// import DoubleArrows from "components/icons/DoubleArrows";

const ReportContent = ({contentData = []}) => {

    const questions = contentData.map((item, index) => {
        const {question, answers, isMarked} = item;

        return (
            <tr className={`report-content__question ${isMarked ? 'report-content__question--marked' : ''}`} key={index}  >
                <td></td>
                <td>{++index}</td>
                <td> 
                    <span className="report-content__note">Question</span>
                    {question}
                </td>
                <td>
                    <span className="report-content__note">Answer</span>
                    {
                        answers.map(elem => <div key={uniqid()}>{elem.answer}</div>)
                    }
                </td>
                <td></td>
            </tr>
        )
    });

    return (
        <table className="report-content">
            <tr className="report-content__head">
                <th></th>
                <th className='head_count'>
                    {/* <DoubleArrows className="reports-sorting__sort"/> */}
                    <span>#</span>
                </th>
                <th>
                    {/* <DoubleArrows className="reports-sorting__sort"/> */}
                    <span>Question</span>
                </th>
                <th>
                    {/* <DoubleArrows className="reports-sorting__sort"/> */}
                    <span>Answer</span>
                </th>
                <th></th>
            </tr>
            {questions}
        </table>
    );
};

export default ReportContent;