import React from 'react';
import {Field} from "formik";
import CustomMultiSelect from "components/CustomFormSelect/CustomMultiSelect";

const Industry = ({userInfo, getIndustryBackgrounds}) => {
    return (
        <div className="section-info-block industry-background">
            <h3 className={`section-title`}>Expertise</h3>
            <div className="industry-background-list">
                <label htmlFor={'functionalArea'} className="profile-row__label">Expertise <span
                    className={`prompt`}>Start typing</span></label>
                <Field
                    name="industryBackground"
                    options={getIndustryBackgrounds}
                    placeholder="Choose your option..."
                    component={CustomMultiSelect}
                    defaultValue={userInfo.industry_background}
                />
            </div>
        </div>
    );
};

export default Industry;