import React from "react";
import axios from "axios";
import "./ReportsActions.scss";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/button/Button";
import SettingsIcon from "components/icons/SettingsIcon";
import ReportsSearch from "components/pages/Reports/ReportsSearch/ReportsSearch";
import { useMediaQuery } from "react-responsive";
import ResponseError from "helpers/ResponseError";
import { showNotification } from "../../../../redux/actions";
import { GET_REPORTS_CSV } from "constants/api";
import EngagementReportsDownload from "../EngagementReportsDownload/EngagementReportsDownload";

const ReportsActions = ({
	toggleFilters,
	isAllFiltersVisible,
	setIsSearchOpen,
	isSearchOpen,
	activeTab,
	setOffsetReports,
}) => {
	const isMaxMobScreen = useMediaQuery({ maxWidth: 1193 });

	const dispatch = useDispatch();
	const accessToken = useSelector((state) => state.auth.authToken);
	const { selectedReports } = useSelector((state) => state.reports);

	const downloadReports = (type, idsArray) => {
		axios({
			method: "get",
			url: GET_REPORTS_CSV,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			params: {
				type: type,
				ids: idsArray,
			},
			accept: "text/xls",
			responseType: "blob",
		})
			.then((response) => {
				const file = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = file;
				link.target = "_blank";
				link.download = `${type}_reports.xls`;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((error) => {
				const responseError = new ResponseError(error.response.data);

				dispatch(
					showNotification({
						title: responseError.getStatus(),
						text: responseError.getValidationErrorMessages(),
					})
				);
			});
	};

	return (
		<div className="reports-actions">
			{isMaxMobScreen && (
				<ReportsSearch
					className="reports-actions__search"
					setOffsetReports={setOffsetReports}
					isMobileView={true}
					isSearchOpen={isSearchOpen}
					setIsSearchOpen={setIsSearchOpen}
					activeTab={activeTab}
				/>
			)}
			{
				<>
					<button
						type="button"
						className={`reports-actions__toggle ${
							isAllFiltersVisible ? "reports-actions__toggle--active" : ""
						}`}
						onClick={toggleFilters}
					>
						{/* fot ILTB */}
						{/* <SettingsIcon color={isAllFiltersVisible ? 'white' : '#E75B32'}/> */}
						<SettingsIcon color={isAllFiltersVisible ? "white" : "#2E79DA"} />
					</button>
					{/* for ILTB */}
					{/* <Button className="button button--orange reports-actions__download">Download report</Button> */}
					{activeTab?.name !== "engagement" ? (
						<Button
							className={`button button--blue reports-actions__download ${
								!selectedReports.length ? "disabled" : ""
							} ${isMaxMobScreen && isSearchOpen ? "hide" : ""}`}
							onClick={() => downloadReports(activeTab?.name, selectedReports)}
							disabled={!selectedReports.length}
						>
							Download report
						</Button>
					) : (
						<EngagementReportsDownload
							isSearchOpen={isSearchOpen}
							selectedReports={selectedReports}
						/>
					)}
				</>
			}
		</div>
	);
};

export default ReportsActions;
