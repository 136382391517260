import { participantsList } from "../constants";

const initialState = {
	participantsListLoading: false,
	participantsList: null,
	participantsExist: false,
	participantsListNull: true,
	participantsListErrorMessage: null,
};

export default function participantsListReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case participantsList.GET_LIST_PARTICIPANTS:
			return {
				...state,
				participantsListLoading: true,
			};

		case participantsList.GET_NEW_ITEMS_ON_SCROLL:
			return {
				...state,
				participantsListLoading: true,
			};

		case participantsList.GET_LIST_PARTICIPANTS_SUCCESS:
			return {
				...state,
				participantsList: payload,
				participantsListLoading: false,
				participantsListNull: true,
				participantsExist: true,
			};

		case participantsList.GET_LIST_PARTICIPANTS_NULL:
			return {
				...state,
				participantsList: payload,
				participantsListNull: false,
				participantsListLoading: false,
				participantsExist: false,
			};

		case participantsList.GET_LIST_PARTICIPANTS_ERROR:
			return {
				...state,
				participantsListErrorMessage: payload,
				participantsListNull: false,
				participantsListLoading: false,
				participantsExist: false,
			};

		case participantsList.CLEAR_PARTICIPANTS_LIST:
			return {
				...state,
				participantsList: null,
				participantsExist: false,
				participantsListLoading: false,
			};

		default:
			return state;
	}
}
