import React from 'react';

const GoBackArrowIcon = ({color = '#366B65', width = 24, height = 15, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.13405 15L6.23679e-07 7.86595L7.13405 0.731896L8.99749 2.59534L5.04454 6.54829L24 6.5483L24 9.1836L5.04454 9.1836L8.99749 13.1366L7.13405 15Z" fill={color}/>
        </svg>
    );
};

export default GoBackArrowIcon;