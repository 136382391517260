import { chatConstant } from "../constants";

const initialState = {
	isChatOpen: false,
	chatUser: null,

	sendMessageToUser: null,
	sendMessageToUserError: false,

	listRoomsLoading: false,
	listRooms: [],
	errorRooms: false,

	listUsers: null,
	emptyUsers: false,
	errorUsers: false,

	countMessages: null,
	checkNewMessage: false,
	messagesFromUser: null,
	emptyMessagesFromUser: null,
	errorMessagesFromUser: null,
};

export default function chatReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case chatConstant.SET_IS_CHAT_OPEN:
			return {
				...state,
				isChatOpen: payload,
			};
		case chatConstant.SET_CHAT_USER:
			return {
				...state,
				chatUser: payload,
			};
		case chatConstant.SEND_MESSAGE_TO_USER_SUCCESS:
			return {
				...state,
				sendMessageToUser: payload,
			};
		case chatConstant.SEND_MESSAGE_TO_USER_ERROR:
			return {
				...state,
				sendMessageToUserError: true,
			};

		case chatConstant.SUCCESS_USERS:
			return {
				...state,
				listUsers: payload,
			};
		case chatConstant.EMPTY_USERS:
			return {
				...state,
				emptyUsers: payload,
			};
		case chatConstant.ERROR_USERS:
			return {
				...state,
				errorUsers: payload,
			};

		case chatConstant.GET_LIST_OF_ROOMS:
			return {
				...state,
				listRoomsLoading: true,
			};
		case chatConstant.SUCCESS_ROOMS:
			return {
				...state,
				listRooms: payload,
				listRoomsLoading: false,
			};
		case chatConstant.ERROR_ROOMS:
			return {
				...state,
				errorRooms: payload,
				listRoomsLoading: false,
			};

		case chatConstant.CLEAR_CHAT_DATA:
			return {
				...state,
				sendMessageToUser: null,
				sendMessageToUserError: false,

				listRoomsLoading: false,
				listRooms: null,
				errorRooms: false,

				listUsers: null,
				emptyUsers: false,
				errorUsers: false,

				countMessages: null,
				checkNewMessage: false,
				messagesFromUser: null,
				emptyMessagesFromUser: null,
				errorMessagesFromUser: null,
			};

		case chatConstant.GET_MESSAGES_FROM_USERS_SUCCESS:
			return {
				...state,
				messagesFromUser: payload,
			};
		case chatConstant.GET_MESSAGES_FROM_USERS_ERROR:
			return {
				...state,
				errorMessagesFromUser: payload,
			};
		case chatConstant.GET_EMPTY_MESSAGES_FROM_USERS:
			return {
				...state,
				emptyMessagesFromUser: payload,
			};
		case chatConstant.CLEAR_USER_MESSAGES:
			return {
				...state,
				messagesFromUser: null,
			};
		case chatConstant.GET_TOTAL_COUNT_MESSAGES:
			return {
				...state,
				countMessages: payload,
			};
		default:
			return state;
	}
}
