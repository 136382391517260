export const ACCEPT_SESSION_INVITATION = 'ACCEPT_SESSION_INVITATION';
export const ACCEPT_SESSION_INVITATION_SUCCESS = 'ACCEPT_SESSION_INVITATION_SUCCESS';
export const ACCEPT_SESSION_INVITATION_ERROR = 'ACCEPT_SESSION_INVITATION_ERROR';
export const DECLINE_SESSION_INVITATION = 'DECLINE_SESSION_INVITATION';
export const DECLINE_SESSION_INVITATION_SUCCESS = 'DECLINE_SESSION_INVITATION_SUCCESS';
export const DECLINE_SESSION_INVITATION_ERROR = 'DECLINE_SESSION_INVITATION_ERROR';
export const LATE_CANCEL_SESSION_INVITATION = 'LATE_CANCEL_SESSION_INVITATION';
export const LATE_CANCEL_SESSION_INVITATION_SUCCESS = 'LATE_CANCEL_SESSION_INVITATION_SUCCESS';
export const LATE_CANCEL_SESSION_INVITATION_ERROR = 'LATE_CANCEL_SESSION_INVITATION_ERROR';
export const FETCH_SESSION_INFO = 'FETCH_SESSION_INFO';
export const FETCH_SESSION_INFO_SUCCESS = 'FETCH_SESSION_INFO_SUCCESS';
export const FETCH_SESSION_INFO_ERROR = 'FETCH_SESSION_INFO_ERROR';
export const ACCEPT_SESSION_BY_COACH = 'ACCEPT_SESSION_BY_COACH';
export const ACCEPT_SESSION_BY_COACH_SUCCESS = 'ACCEPT_SESSION_BY_COACH_SUCCESS';
export const ACCEPT_SESSION_BY_COACH_ERROR = 'ACCEPT_SESSION_BY_COACH_ERROR';
