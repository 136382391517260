import { calendarGoogleMembers } from "../constants";

export function getCalendarGoogleMembers(calendarGoogleMembersDefaultData) {
	return {
		type: calendarGoogleMembers.GET_CALENDAR_GOOGLE_MEMBERS,
		payload: calendarGoogleMembersDefaultData,
	};
}

export function getCalendarGoogleMembersSuccess(calendarGoogleMembersSuccess) {
	return {
		type: calendarGoogleMembers.GET_CALENDAR_GOOGLE_MEMBERS_SUCCESS,
		payload: calendarGoogleMembersSuccess,
	};
}

export function getCalendarGoogleMembersEmpty(emptylist) {
	return {
		type: calendarGoogleMembers.GET_CALENDAR_GOOGLE_MEMBERS_NULL,
		payload: emptylist,
	};
}

export function getCalendarGoogleMembersError(error) {
	return {
		type: calendarGoogleMembers.GET_CALENDAR_GOOGLE_MEMBERS_ERROR,
		payload: error,
	};
}
