import moment from 'moment';
import unavailable from "assets/images/unavailableBG.svg";

// unavailable data - week/day view - by hours
const calendarSlotPropGetter = (date, absenceSchedule) => {

    let dataSlots;

    if (absenceSchedule?.length > 0) {

        absenceSchedule.map(item => {

            dataSlots = {
                ...dataSlots,
                ...(
                    moment(date) >= moment(item.startPeriod) &&
                    moment(date) < moment(item.endPeriod) && {
                        className: `unavailableSlot ${moment(date).format('hh') === moment(item.startPeriod).format('hh')
                            ? (moment(item.startPeriod).format('mm') === '00' ? '' : 'UA')
                            : ''
                            }${moment(date).format('hh') === moment(item.endPeriod).format('hh')
                                ? 'UA'
                                : ''
                            }${moment(date).format('hh') === moment(item.endPeriod).format('hh')
                                ? (+moment(item.endPeriod).format('mm') >= 45 ? 'UA' : '')
                                : ''
                            }`,
                        style: {
                            background: `url(${unavailable})`,
                            backgroundPosition: "left center",
                            width: "100%"
                        }
                    })
            }
        })
    }

    return dataSlots;
}

export default calendarSlotPropGetter;
