import {
	SHOW_SUGGESTED_COACHES_NOTIFICATION,
	HIDE_SUGGESTED_COACHES_NOTIFICATION,
} from "../constants";

const initialState = {
	show: true,
};

export default function suggestedCoachesNotificationReducer(
	state = initialState,
	action
) {
	const { type } = action;

	switch (type) {
		case SHOW_SUGGESTED_COACHES_NOTIFICATION:
			return {
				...state,
				show: true,
			};
		case HIDE_SUGGESTED_COACHES_NOTIFICATION:
			return {
				...state,
				show: false,
			};
		default:
			return state;
	}
}
