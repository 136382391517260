import {
	GET_PROGRAMS_ACTIVITIES,
	GET_PROGRAMS_ACTIVITIES_ERROR,
	GET_PROGRAMS_ACTIVITIES_SUCCESS,
	GET_PROGRAMS_PROGRESS,
	GET_PROGRAMS_PROGRESS_ERROR,
	GET_PROGRAMS_PROGRESS_SUCCESS,
	GET_PROGRAMS_TODOS,
	GET_PROGRAMS_TODOS_ERROR,
	GET_PROGRAMS_TODOS_SUCCESS,
	GET_UPCOMING_SESSIONS,
	GET_UPCOMING_SESSIONS_ERROR,
	GET_UPCOMING_SESSIONS_SUCCESS,
	UPDATE_PROGRAMS_TODOS,
} from "../constants";
import { updateDashboardActionItems } from "./helpers/updateDashboardActionItems";

const initialState = {
	// programs progress
	programsProgress: [],
	programsProgressLoading: false,
	programsProgressError: null,
	// programs activities
	programsActivities: null,
	programsActivitiesLoading: false,
	programsActivitiesError: null,
	// programs activities
	programsTodos: null,
	programsTodosLoading: false,
	programsTodosError: null,
	// upcoming sessions
	upcomingSessions: null,
	upcomingSessionsLoading: false,
	upcomingSessionsError: null,
};

export default function participantDashboardReducer(
	state = initialState,
	action
) {
	const { type, payload } = action;

	switch (type) {
		// programs progress
		case GET_PROGRAMS_PROGRESS:
			return {
				...state,
				programsProgressLoading: true,
			};
		case GET_PROGRAMS_PROGRESS_SUCCESS:
			return {
				...state,
				programsProgress: payload,
				programsProgressLoading: false,
			};
		case GET_PROGRAMS_PROGRESS_ERROR:
			return {
				...state,
				programsProgressError: payload,
				programsProgressLoading: false,
			};
		// programs activities
		case GET_PROGRAMS_ACTIVITIES:
			return {
				...state,
				programsActivitiesLoading: true,
			};
		case GET_PROGRAMS_ACTIVITIES_SUCCESS:
			return {
				...state,
				programsActivities: payload,
				programsActivitiesLoading: false,
			};
		case GET_PROGRAMS_ACTIVITIES_ERROR:
			return {
				...state,
				programsActivitiesError: payload,
				programsActivitiesLoading: false,
			};
		// programs todos
		case GET_PROGRAMS_TODOS:
			return {
				...state,
				programsTodosLoading: true,
			};
		case GET_PROGRAMS_TODOS_SUCCESS:
			return {
				...state,
				programsTodos: payload,
				programsTodosLoading: false,
			};
		case GET_PROGRAMS_TODOS_ERROR:
			return {
				...state,
				programsTodosError: payload,
				programsTodosLoading: false,
			};

		case UPDATE_PROGRAMS_TODOS:
			return {
				...state,
				programsTodos: updateDashboardActionItems(state.programsTodos, payload),
			};

		// programs todos
		case GET_UPCOMING_SESSIONS:
			return {
				...state,
				upcomingSessionsLoading: true,
			};
		case GET_UPCOMING_SESSIONS_SUCCESS:
			return {
				...state,
				upcomingSessions: payload,
				upcomingSessionsLoading: false,
			};
		case GET_UPCOMING_SESSIONS_ERROR:
			return {
				...state,
				upcomingSessionsError: payload,
				upcomingSessionsLoading: false,
			};

		default:
			return state;
	}
}
