import moment from 'moment';

const getDatesArray = (start, end) => {

    const arr = [];
    let dt = new Date(start);
    while (dt <= end) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
    }

    return arr.map((df) => {
        return moment(df).format('YYYY-MM-DD')
    })
}

export default getDatesArray;
