import {
	SHOW_SUGGESTED_COACHES_NOTIFICATION,
	HIDE_SUGGESTED_COACHES_NOTIFICATION,
} from "../constants";

export const showSuggestedCoachesNotification = () => ({
	type: SHOW_SUGGESTED_COACHES_NOTIFICATION,
});

export const hideSuggestedCoachesNotification = () => ({
	type: HIDE_SUGGESTED_COACHES_NOTIFICATION,
});
