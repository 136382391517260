import React from 'react';
import {ErrorMessage, Field, FieldArray, getIn} from "formik";
import DatePicker from "react-datepicker";
import {ReactComponent as CloseSvg} from "assets/vector/close.svg";
import CustomFormErrorMsg from 'components/pages/EditMyProfile/CustomFormError/CustomFormErrorMsg'

const WorkExperience = ({values, errors, touched, setFieldValue, setFormErrorsFormik}) => {
    return (
        <div className="section-info-block work-experience">
            <h3 className={`section-title`}>Work experience</h3>
            <div className="work-experience-list">
                <FieldArray name="work_experience">
                    {({remove, push}) => (
                        <>
                            {
                                values.work_experience.length > 0 &&
                                values.work_experience.map((work_experience, index) => (
                                    <div className="item" key={index}>
                                        <h4 className={`counter-work-experience`}>Work
                                            experience {index + 1}</h4>
                                        <div className="company item-row">
                                            <label htmlFor={`work_experience.${index}.company`}
                                                   className="profile-row__label">Company</label>
                                            <Field
                                                name={`work_experience.${index}.company`}
                                                type="text"
                                                className={`profile-row__input ${errors.work_experience && errors.work_experience[index]?.company && (values.work_experience[index]?.company || touched.work_experience && touched.work_experience[index]?.company) ? 'input-error' : ''}`}
                                            />
                                            <CustomFormErrorMsg show={errors.work_experience && errors.work_experience[index]?.company && (values.work_experience[index]?.company || touched.work_experience && touched.work_experience[index]?.company)}
                                                                className='profile-row__error'
                                                                message={errors.work_experience && errors.work_experience[index]?.company}
                                            />
                                        </div>
                                        <div className="position item-row">
                                            <label htmlFor={`work_experience.${index}.position`}
                                                   className="profile-row__label">Position</label>
                                            <Field
                                                name={`work_experience.${index}.position`}
                                                type="text"
                                                className={`profile-row__input ${errors.work_experience && errors.work_experience[index]?.position && (values.work_experience[index]?.position || touched.work_experience && touched.work_experience[index]?.position) ? 'input-error' : ''}`}
                                            />
                                            <CustomFormErrorMsg show={errors.work_experience && errors.work_experience[index]?.position && (values.work_experience[index]?.position || touched.work_experience && touched.work_experience[index]?.position)}
                                                                className='profile-row__error'
                                                                message={errors.work_experience && errors.work_experience[index]?.position}
                                            />
                                        </div>
                                        <div className="responsibilities item-row">
                                            <label htmlFor={`work_experience.${index}.responsibilities`}
                                                   className="profile-row__label">Responsibilities</label>
                                            <Field
                                                name={`work_experience.${index}.responsibilities`}
                                                type="text"
                                                className={`profile-row__input ${errors.work_experience && errors.work_experience[index]?.responsibilities && (values.work_experience[index]?.responsibilities || touched.work_experience && touched.work_experience[index]?.responsibilities) ? 'input-error' : ''}`}
                                            />
                                            <CustomFormErrorMsg show={errors.work_experience && errors.work_experience[index]?.responsibilities && (values.work_experience[index]?.responsibilities || touched.work_experience && touched.work_experience[index]?.responsibilities)}
                                                                className='profile-row__error'
                                                                message={errors.work_experience && errors.work_experience[index]?.responsibilities}
                                            />
                                        </div>
                                        <div className="date-picker">
                                            <div className="date-from item-row">
                                                <label htmlFor={`work_experience.${index}.date_from`}
                                                       className="profile-row__label">From</label>
                                                <DatePicker
                                                    name={`work_experience[${index}]['date_from']`}
                                                    selected={getIn(values, `work_experience[${index}]['date_from']`) || ''}
                                                    value={getIn(values, `work_experience[${index}]['date_from']`) || ''}
                                                    onChange={(e) => setFieldValue(`work_experience[${index}]['date_from']`, e)}
                                                    selectsStart
                                                    startDate={new Date()}
                                                    endDate={new Date()}
                                                    maxDate={work_experience.date_to}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    className={`experience-row-input ${(errors && errors.work_experience && errors.work_experience[index] && errors.work_experience[index].date_from && touched && touched.work_experience && touched.work_experience[index] && touched.work_experience[index].date_from) ? 'input-error' : ''}`}
                                                />
                                                <CustomFormErrorMsg show={errors.work_experience && errors.work_experience[index]?.date_from && (values.work_experience[index]?.date_from || touched.work_experience && touched.work_experience[index]?.date_from)}
                                                                    className='profile-row__error'
                                                                    message={errors.work_experience && errors.work_experience[index]?.date_from}
                                                />
                                            </div>
                                            <div className="date-to item-row">
                                                <label htmlFor={`work_experience.${index}.date_to`}
                                                       className="profile-row__label">To</label>
                                                <DatePicker
                                                    name={`work_experience[${index}]['date_to']`}
                                                    selected={getIn(values, `work_experience[${index}]['date_to']`) || ''}
                                                    value={getIn(values, `work_experience[${index}]['date_to']`) || ''}
                                                    onChange={(e) => setFieldValue(`work_experience[${index}]['date_to']`, e)}
                                                    selectsEnd
                                                    startDate={new Date()}
                                                    endDate={new Date()}
                                                    minDate={work_experience.date_from}
                                                    maxDate={new Date()}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    className={`experience-row-input`}
                                                    placeholderText={`Present time`}
                                                />
                                            </div>
                                        </div>
                                        <div className="remove-row">
                                            <div className="remove-item-work-experience"
                                                 onClick={() => {
                                                     remove(index);
                                                 }}><CloseSvg/></div>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="add-new-info add-work-experience" onClick={() => {
                                push({
                                    company: '',
                                    position: '',
                                    responsibilities: '',
                                    date_from: '',
                                    date_to: ''
                                });
                                setFormErrorsFormik(false);
                            }
                            }>
                                + Add work experience
                            </div>
                        </>
                    )}
                </FieldArray>
            </div>
        </div>
    );
};

export default WorkExperience;
