import React from "react";
import ReactQuill from "react-quill";
import { ClipLoader } from "react-spinners";

function EditNote({
	textEditorOptions,
	note,
	setEditNoteValue,
	isLoading,
	handleSaveClick,
	handleCancelClick,
}) {
	return (
		<>
			<ReactQuill
				modules={{
					toolbar: textEditorOptions,
				}}
				style={{ marginBottom: "0" }}
				placeholder={`Edit your Note....`}
				theme="snow"
				value={note.content}
				onChange={(content) => setEditNoteValue(content)}
			/>
			<div className="flex gap-4">
				<button
					className="button button--blue h-12"
					onClick={handleSaveClick}
					disabled={isLoading}
				>
					{isLoading ? <ClipLoader color="white" size={28} /> : "Save"}
				</button>
				<button
					className="button button--orange-invert h-12"
					onClick={handleCancelClick}
				>
					Cancel
				</button>
			</div>
		</>
	);
}

export default EditNote;
