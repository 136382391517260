import { postData } from "../../sagas/helpers/requestHandlers";
import { CATEGORY_DESCRIPTION_ROUTE } from "constants/api";
import { put, call, select } from "redux-saga/effects";
import {
	createCategoryDescriptionSuccess,
	showNotification,
} from "../../actions";
import ResponseError from "helpers/ResponseError";
import { getNotificationMessage } from "../../../helpers/notifications";
import { getToken } from "../../sagas/helpers/helpers";

export function* categoryDescriptionRequest({
	payload: { id, category_id, description, existingCategories },
}) {
	const apiRoute = CATEGORY_DESCRIPTION_ROUTE(id);
	const token = yield select(getToken);

	const getRequestBody = () => {
		if (category_id) {
			return {
				items: [
					...existingCategories,
					{
						category_id: category_id,
						description: description,
					},
				],
			};
		}

		return {
			items: [...existingCategories],
		};
	};

	const { data } = yield call(postData(apiRoute, getRequestBody(), token));

	if (data?.status === "Success") {
		const categories = data?.data;

		yield put(
			createCategoryDescriptionSuccess({ categories, activity_id: id })
		);
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		console.log(err.getValidationErrorMessages());

		put(
			showNotification(
				{
					title: "Error",
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}
