import React, {useState} from 'react';
import './Participants.scss';
import Button from "components/button/Button";
import Participant from "components/modal/EventSessionModal/Participants/Participant/Participant";

const Participants = ({participants, toggleInviteUser, toggleInvitationList}) => {
    const transformInviteParticipants = () => participants.map(participant => {
        return {...participant, isInvited: false, isDisabled: false}
    });

    const [invitedParticipants, setInvitedParticipants] = useState(transformInviteParticipants());

    const handleInviteUser = (userId) => {
        const updatedUsersList = invitedParticipants.map(user => {
            if (user.id === userId) {
                user.isInvited = !user.isInvited;

            } else {
                user.isInvited = false;
            }

            return user;
        });

        setInvitedParticipants(updatedUsersList);
    };

    const chooseInvitedUsers = () => {
        const updatedUsersList = invitedParticipants.filter(user => user.isInvited);

        toggleInviteUser(updatedUsersList);

        toggleInvitationList();
    };

    return (
        <div className="participants">
            <h4 className="participants__title">Add participants</h4>
            <div className="participants__btn-group">
                <Button className="button
                               button--orange-invert
                               participants__add-btn"
                        onClick={chooseInvitedUsers}>
                    Add participants
                </Button>
                <Button className='button participants__close-btn' onClick={toggleInvitationList}>
                    Cancel
                </Button>
            </div>
            <ul className="participants__list">
                {
                    invitedParticipants.map(participant => <Participant participant={participant}
                                                                        key={participant.id}
                                                                        handleInviteUser={handleInviteUser}
                        />
                    )
                }
            </ul>
        </div>
    );
};

export default Participants;