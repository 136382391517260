import { fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { dashboardNotification } from "../constants";
import {
	getUserNotificationsSuccess,
	getUserNotificationsError,
	getUserNotificationsEmpty,
	loadNotifications,
	setUnreadNotificationsCount,
	setTotalNotifications,
	showNotification,
} from "../actions";
import {
	GET_USER_NOTIFICATIONS,
	GET_UNREAD_NOTIFICATIONS,
	SET_READ_ALL_NOTIFICATIONS,
	REMOVE_NOTIFICATIONS,
	GET_SESSION_ACCEPT_ROUTE,
	GET_SESSION_DECLINE_ROUTE,
} from "constants/api";
import { generalApiErrorHandler } from "../sagas/helpers/generalApiErrorHandler";
import { getNotificationMessage } from "../../helpers/notifications";
import { getToken } from "../sagas/helpers/helpers";

function* watchGetUserNotifications() {
	yield takeLatest(
		dashboardNotification.GET_NOTIFICATIONS,
		handleGetUserNotifications
	);
}
function* handleGetUserNotifications() {
	try {
		const token = yield select(getToken);

		const getUserNotifications = yield axios({
			method: "get",
			url: GET_USER_NOTIFICATIONS() + `?offset=0&take=15`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(loadNotifications(true));

		yield put(setTotalNotifications(getUserNotifications.total));

		if (getUserNotifications && getUserNotifications.notifications.length > 0) {
			yield put(
				getUserNotificationsSuccess(getUserNotifications.notifications)
			);
		} else {
			yield put(getUserNotificationsEmpty(true));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getUserNotificationsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchRemoveItemNotification() {
	yield takeLatest(
		dashboardNotification.REMOVE_ITEM_NOTIFICATION,
		handleRemoveItemNotification
	);
}
function* handleRemoveItemNotification({ payload }) {
	try {
		const token = yield select(getToken);
		const removeItemData = {
			ids: [payload],
		};

		yield axios({
			method: "post",
			url: REMOVE_NOTIFICATIONS(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
			data: removeItemData,
		}).then((response) => response.data.data);

		yield handleGetUserNotifications();
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getUserNotificationsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchClearAllNotifications() {
	yield takeLatest(
		dashboardNotification.CLEAR_ALL_NOTIFICATION,
		handleClearAllNotifications
	);
}
function* handleClearAllNotifications() {
	try {
		const token = yield select(getToken);

		yield axios({
			method: "post",
			url: REMOVE_NOTIFICATIONS(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield handleGetUserNotifications();
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getUserNotificationsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetUnreadNotifications() {
	yield takeLatest(
		dashboardNotification.GET_NOTIFICATIONS_UNREAD_COUNT,
		handleGetUnreadNotifications
	);
}
function* handleGetUnreadNotifications() {
	try {
		const token = yield select(getToken);

		const getCountNotifications = yield axios({
			method: "get",
			url: GET_UNREAD_NOTIFICATIONS(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(
			setUnreadNotificationsCount(getCountNotifications.unread_notifications)
		);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getUserNotificationsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchSetAllReadNotifications() {
	yield takeLatest(
		dashboardNotification.SEND_READ_NOTIFICATIONS,
		handleSetAllReadNotifications
	);
}
function* handleSetAllReadNotifications() {
	try {
		const token = yield select(getToken);
		yield axios({
			method: "post",
			url: SET_READ_ALL_NOTIFICATIONS(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		});
		yield handleGetUserNotifications();
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getUserNotificationsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetNewNotificationsOnScroll() {
	yield takeLatest(
		dashboardNotification.GET_NOTIFICATIONS_ON_SCROLL,
		handleGetNewNotificationsOnScroll
	);
}
function* handleGetNewNotificationsOnScroll({ payload }) {
	try {
		const token = yield select(getToken);

		const currentNotifications = yield select(
			(state) => state.notification.userNotification
		);

		const getCoachesDataByScroll = yield axios({
			method: "get",

			url:
				GET_USER_NOTIFICATIONS() +
				`?offset=${payload.offset}&take=${payload.take}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		const concatCoachesItemsData = currentNotifications.concat(
			getCoachesDataByScroll.notifications
		);
		const newCoachesItemsData = {
			...getCoachesDataByScroll,
			notifications: concatCoachesItemsData,
		};

		if (newCoachesItemsData && newCoachesItemsData.notifications.length > 0) {
			yield put(getUserNotificationsSuccess(newCoachesItemsData.notifications));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getUserNotificationsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchSendAcceptNotification() {
	yield takeLatest(
		dashboardNotification.SEND_ACCEPT_NOTIFICATIONS,
		handleSendAcceptNotification
	);
}
function* handleSendAcceptNotification({ payload }) {
	try {
		const token = yield select(getToken);

		const getAcceptNotification = yield axios({
			method: "post",
			url: GET_SESSION_ACCEPT_ROUTE(payload),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield handleGetUserNotifications();
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getUserNotificationsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchSendDeclineNotification() {
	yield takeLatest(
		dashboardNotification.SEND_DECLINE_NOTIFICATIONS,
		handleSendDeclineNotification
	);
}
function* handleSendDeclineNotification({ payload }) {
	try {
		const token = yield select(getToken);

		const getDeclineNotification = yield axios({
			method: "post",

			url: GET_SESSION_DECLINE_ROUTE(payload),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield handleGetUserNotifications();
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getUserNotificationsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* NotificationSaga() {
	yield fork(watchGetUserNotifications);
	yield fork(watchRemoveItemNotification);
	yield fork(watchClearAllNotifications);
	yield fork(watchGetUnreadNotifications);
	yield fork(watchSetAllReadNotifications);
	yield fork(watchGetNewNotificationsOnScroll);
	yield fork(watchSendAcceptNotification);
	yield fork(watchSendDeclineNotification);
}
