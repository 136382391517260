import { OPEN_MODAL, CLOSE_MODAL } from "../constants/modal";

const initialState = {
	open: false,
	modalData: {},
};

export default function modalReducer(
	state = initialState,
	{ type, modalType, payload = null }
) {
	switch (type) {
		case OPEN_MODAL:
			return {
				...state,
				open: true,
				modalType: modalType,
				modalData: payload,
			};
		case CLOSE_MODAL:
			return {
				...state,
				open: false,
				modalData: {},
			};
		default:
			return state;
	}
}
