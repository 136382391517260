import React from 'react';
import { Link } from "react-router-dom";
import routes from "constants/routes";

const FeedbackLink = (props) => {
    const {
        filledFormReport,
        sessionCompletedStatus,
        programId,
        activityId,
        userName,
        userAvatar,
        evaluatedUserRole,
        feedbackForm
    } = props;

    const { FEEDBACK_PAGE, REPORTS_PAGE } = routes;

    if (!feedbackForm) return null;

    return (
        filledFormReport ?
            <Link to={`${REPORTS_PAGE}/${filledFormReport}`} className="button button--orange-invert fill-form">
                Review the feedback
            </Link>
            :
            sessionCompletedStatus ?
                <Link to={{
                    pathname: FEEDBACK_PAGE,
                    state: {
                        participant_program_id: programId,
                        activity_id: activityId,
                        name: userName,
                        avatar: userAvatar,
                        role: evaluatedUserRole,
                        form: feedbackForm
                    }
                }}
                    className="button button--orange-invert fill-form">
                    Fill out the form
                </Link>
                :
                <button type="button" className="button button--orange-invert fill-form" disabled>Fill out the form</button>
    );
};

export default FeedbackLink;