import React from 'react';

const GoalsIcon = ({color = '#486EA0', width = 32, height = 32, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.3805 17.083H24.9058C25.158 17.7736 25.2958 18.5189 25.2958 19.2958V28.6489C25.2958 28.9728 25.2394 29.2837 25.1369 29.5728H29.2282C30.7565 29.5728 31.9998 28.3294 31.9998 26.8011V21.7023C31.9999 19.1553 29.9276 17.083 27.3805 17.083Z" fill={color}/>
            <path d="M6.70408 19.2958C6.70408 18.5189 6.84189 17.7736 7.09414 17.083H4.61938C2.07225 17.083 0 19.1553 0 21.7024V26.8012C0 28.3295 1.24331 29.5728 2.77163 29.5728H6.86301C6.76045 29.2836 6.70408 28.9728 6.70408 28.6489V19.2958Z" fill={color}/>
            <path d="M18.8288 14.6758H13.1711C10.624 14.6758 8.55176 16.748 8.55176 19.2952V28.6483C8.55176 29.1585 8.96538 29.5722 9.47563 29.5722H22.5243C23.0345 29.5722 23.4481 29.1586 23.4481 28.6483V19.2952C23.4481 16.748 21.3759 14.6758 18.8288 14.6758Z" fill={color}/>
            <path d="M16.0002 2.42773C12.9369 2.42773 10.4448 4.91986 10.4448 7.98318C10.4448 10.061 11.5916 11.8757 13.2853 12.8284C14.0886 13.2803 15.0147 13.5386 16.0002 13.5386C16.9857 13.5386 17.9118 13.2803 18.7151 12.8284C20.4088 11.8757 21.5556 10.0609 21.5556 7.98318C21.5556 4.91993 19.0635 2.42773 16.0002 2.42773Z" fill={color}/>
            <path d="M6.24503 7.60547C3.95409 7.60547 2.09033 9.46922 2.09033 11.7602C2.09033 14.0511 3.95409 15.9149 6.24503 15.9149C6.82615 15.9149 7.37953 15.7945 7.88222 15.5781C8.75134 15.2039 9.46797 14.5415 9.91141 13.7119C10.2227 13.1295 10.3997 12.4652 10.3997 11.7602C10.3997 9.46928 8.53597 7.60547 6.24503 7.60547Z" fill={color}/>
            <path d="M25.7553 7.60547C23.4643 7.60547 21.6006 9.46922 21.6006 11.7602C21.6006 12.4653 21.7776 13.1296 22.0889 13.7119C22.5323 14.5416 23.249 15.204 24.1181 15.5781C24.6208 15.7945 25.1742 15.9149 25.7553 15.9149C28.0462 15.9149 29.91 14.0511 29.91 11.7602C29.91 9.46922 28.0462 7.60547 25.7553 7.60547Z" fill={color}/>
        </svg>
    );
};

export default GoalsIcon;