import transformDateForEditScheduleTimePicker from 'helpers/transformDateForEditScheduleTimePicker';

function addFifteenMinutesToDate() {
    // 5 min = 300 sec
    const date = Math.floor(new Date().getTime() / 1000);

    return (date + 900) * 1000;
}

function createNewDate(str) {
    const arr = str.split('-')

    const date = new Date();

    date.setFullYear(+arr[0]);
    date.setMonth((+arr[1] - 1), +arr[2])

    return date;
}

const setInitialValues = (data) => {

    const setTimeOff = (data) => {
        if (data?.length > 0) {
            const timeOff = data?.map(item => {
                return {
                    start_day: createNewDate(item.start.split(' ')[0]),
                    end_day: createNewDate(item.end.split(' ')[0]),
                    start_time: transformDateForEditScheduleTimePicker(item.start.split(' ')[1]),
                    end_time: transformDateForEditScheduleTimePicker(item.end.split(' ')[1])
                }
            })

            return timeOff;
        }

        return [];
    }

    return {
        break_days: data?.break_days ? data.break_days : [],
        break_days_start: data?.break_days_time?.[0]?.start ? transformDateForEditScheduleTimePicker(data.break_days_time[0].start) : new Date(),
        break_days_end: data?.break_days_time?.[0]?.end ? transformDateForEditScheduleTimePicker(data.break_days_time[0].end) : new Date(addFifteenMinutesToDate()),
        daily_break_start: data?.daily_break_time?.[0]?.start ? transformDateForEditScheduleTimePicker(data.daily_break_time[0].start) : new Date(),
        daily_break_end: data?.daily_break_time?.[0]?.end ? transformDateForEditScheduleTimePicker(data.daily_break_time[0].end) : new Date(addFifteenMinutesToDate()),
        time_off: setTimeOff(data?.time_off)
    }
}

export { setInitialValues, addFifteenMinutesToDate };
