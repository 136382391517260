import React from "react";
import { useSelector } from "react-redux";
import Select, { components } from 'react-select';
import DownArrowIcon from "components/icons/DownArrowIcon";
import { ReactComponent as SelectCloseSvg } from "assets/vector/select-close.svg";
import { customSelectStyles } from 'helpers/customSelectStyles';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <DownArrowIcon color={'#101821'} />
        </components.DropdownIndicator>
    );
};

const QuestionnaireSelect = ({
    field,
    options,
    form: { setFieldValue }
}) => {

    console.log(field)

    const { language } = useSelector(state => state.questionnaireLanguage);

    const onChangeSelect = (optionValue) => {
        setFieldValue(field.name, optionValue?.id ? optionValue.id : optionValue?.key ? optionValue.key : optionValue.option ? optionValue.option : '');
    };

    return (
        <Select
            components={{
                DropdownIndicator,
                ClearIndicator: ({ innerRef, innerProps }) => <div ref={innerRef} {...innerProps}><SelectCloseSvg className={`multi-select-remove-item`} /></div>,
            }}
            name={field.name}
            getOptionLabel={option => option.name ? option.name : option.option}
            getOptionValue={option => option.id
                ? option.id
                : option.key ? option.key : option.option
            }
            options={options}
            classNamePrefix="survey-select"
            styles={customSelectStyles}
            isSearchable={false}
            isClearable={true}
            placeholder={language === 'it' ? 'Barra l’opzione di tua scelta' : 'Choose your option'}
            value={
                options?.filter(option => option.id
                    ? option.id === field.value
                    : option.key ? option.key === field.value
                        : option.option ? option.option === field.value : ''
                )
            }
            onChange={(optionValue) => onChangeSelect(optionValue)}
        />
    )
};

export default QuestionnaireSelect;
