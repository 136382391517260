import { fork, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { calendarGoogleEvents } from "../constants";
import {
	getCalendarGoogleEventsSuccess,
	getCalendarGoogleEventsEmpty,
	getCalendarGoogleEventsError,
} from "../actions/calendarGoogleEvents";
import { GET_LIST_OF_GOOGLE_CALENDAR_EVENTS } from "constants/api";
import { generalApiErrorHandler } from "../sagas/helpers/generalApiErrorHandler";
import { showNotification } from "../actions";
import { getNotificationMessage } from "../../helpers/notifications";
import { getToken } from "../sagas/helpers/helpers";

function* watchGetCalendarGoogleList() {
	yield takeLatest(
		calendarGoogleEvents.GET_GOOGLE_EVENTS_OF_CALENDAR,
		handleGetCalendarGoogleList
	);
}
function* handleGetCalendarGoogleList({ payload }) {
	try {
		const token = yield select(getToken);

		let data = {
			start: `${payload.start}`,
			end: `${payload.end}`,
		};
		if (payload.additional_users > 0) {
			data.additional_users = payload.additional_users;
		}

		const currentUserData = yield axios({
			method: "post",
			url: GET_LIST_OF_GOOGLE_CALENDAR_EVENTS(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
			data: data,
		}).then((response) => response.data.data);

		if (currentUserData.length > 0) {
			yield put(getCalendarGoogleEventsSuccess(currentUserData));
		} else {
			yield put(getCalendarGoogleEventsEmpty(currentUserData));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				getCalendarGoogleEventsError(getNotificationMessage(error.message))
			);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchClearFilters() {
	yield takeLatest(calendarGoogleEvents.CLEAR_FILTERS, handleClearFilters);
}
function* handleClearFilters({ payload }) {
	try {
		const token = yield select(getToken);

		let data = {
			start: `${payload.start}`,
			end: `${payload.end}`,
			additional_users: `${payload.additional_users}`,
		};

		const getCalendarGoogleDataAfterResetFilters = yield axios({
			method: "post",
			url: GET_LIST_OF_GOOGLE_CALENDAR_EVENTS(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
			data: data,
		}).then((response) => response.data.data);

		if (getCalendarGoogleDataAfterResetFilters.length > 0) {
			yield put(
				getCalendarGoogleEventsSuccess(getCalendarGoogleDataAfterResetFilters)
			);
		} else {
			yield put(getCalendarGoogleEventsEmpty(null));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				getCalendarGoogleEventsError(getNotificationMessage(error.message))
			);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* calendarGoogleListSaga() {
	yield fork(watchGetCalendarGoogleList);
	yield fork(watchClearFilters);
}
