import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AttachedFile.scss";
import uniqid from "uniqid";
import {
	clearDeleteFileInfo,
	deleteAttachActivityFile,
	deleteAttachTopicFile,
} from "../../../../../../redux/actions";
import { openModal } from "../../../../../../redux/actions/modal";
import { DECLINE_MODAL } from "../../../../../../redux/constants/modal";
import File from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/AttachedFile/File/FIle";

const AttachedFile = ({ files, type, activityId }) => {
	const dispatch = useDispatch();
	const currentUserId = useSelector(
		(state) => state.currentUserInfo.currentUserInfo.id
	);
	const { deleteFileSuccess } = useSelector((state) => state.userPrograms);

	const handleRemoveFile = (e, link = "") => {
		e.preventDefault();

		const baseUrl = `/uploads/`;
		const linkForRequest = link.split(baseUrl).pop();

		if (type === "module") {
			return dispatch(
				openModal(DECLINE_MODAL, {
					confirmAction: () =>
						dispatch(deleteAttachTopicFile(activityId, linkForRequest)),
					actionSelector: [
						"userPrograms",
						"deleteFileProgress",
						"deleteFileSuccess",
						"deleteFileError",
					],
					title: "Are you sure you want to remove attached file?",
				})
			);
		}

		return dispatch(
			openModal(DECLINE_MODAL, {
				confirmAction: () =>
					dispatch(deleteAttachActivityFile(activityId, linkForRequest)),
				actionSelector: [
					"userPrograms",
					"deleteFileProgress",
					"deleteFileSuccess",
					"deleteFileError",
				],
				title: "Are you sure you want to remove attached file?",
			})
		);
	};

	useEffect(() => {
		if (deleteFileSuccess) {
			dispatch(clearDeleteFileInfo());
		}
	}, [deleteFileSuccess]);

	const fileList = files.map((file) => (
		<File
			key={uniqid()}
			file={file}
			isRemovable={currentUserId === file.owner_id}
			handleRemoveFile={handleRemoveFile}
		/>
	));

	return (
		<div className="attached-file-wrap">
			<h4 className="file-attached-title">Attached file(s)</h4>
			{fileList}
		</div>
	);
};

export default AttachedFile;
