import React from "react";

import './Layout.scss'

export default function Layout({children}) {

    return (
        <main className={`routes-wrapper`}>
            {children}
        </main>
    );
};

