import React from 'react';
import icon from 'assets/vector/calendar-outline1.svg';
import { addFifteenMinutesToDate } from '../helpers/setInitialValues';
import { PulseLoader } from 'react-spinners';
import { useSelector } from 'react-redux';

const FormButtons = ({ push, isSubmitting, isValid }) => {

    const { currentUserInfoLoading } = useSelector(state => state.currentUserInfo);

    const spinnerProps = {
        ...{
            color: 'white',
            size: 8,
            margin: 3
        },

    }
    return (
        <div className='form-buttons__wrapper'>
            <button
                className='add_time_of__button'
                type='button'
                onClick={() => push({
                    start_day: new Date(),
                    end_day: new Date(),
                    start_time: new Date(),
                    end_time: new Date(addFifteenMinutesToDate())
                })}
            >
                <img src={icon} alt="" />
                Add time-off
            </button>
            <button
                type='submit'
                className='form__submit'
                disabled={isSubmitting || !isValid}
            >
                <PulseLoader loading={currentUserInfoLoading} {...spinnerProps} />
                {!currentUserInfoLoading && <span>Save schedule</span>}
            </button>
        </div>
    )
}

export default FormButtons;
