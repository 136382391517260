export const participantsSatisfactionChartData = (data) => {

    if (data?.participant_satisfaction?.amazing_percent === 0 &&
        data?.participant_satisfaction?.okay_percent === 0 &&
        data?.participant_satisfaction?.not_great_percent === 0) {
        return [
            {
                title: 'Amazing',
                value: 100,
                statisticValue: data?.participant_satisfaction?.amazing,
                color: `#E8EBEE`,
                className: 'amazing'
            },
            {
                title: 'Okay',
                value: data?.participant_satisfaction?.okay_percent,
                statisticValue: data?.participant_satisfaction?.okay,
                color: `#2a6bbf`,
                className: 'okay'
            },
            {
                title: 'Not great',
                value: data?.participant_satisfaction?.not_great_percent,
                statisticValue: data?.participant_satisfaction?.not_great,
                color: `#ec4e7c`,
                className: 'not_great'
            },
        ]
    }

    return [
        {
            title: 'Amazing',
            value: data?.participant_satisfaction?.amazing_percent,
            statisticValue: data?.participant_satisfaction?.amazing,
            color: `var(--db_satisfaction_good)`,
            className: 'amazing'
        },
        {
            title: 'Okay',
            value: data?.participant_satisfaction?.okay_percent,
            statisticValue: data?.participant_satisfaction?.okay,
            color: `var(--db_satisfaction_neutral)`,
            className: 'okay'
        },
        {
            title: 'Not great',
            value: data?.participant_satisfaction?.not_great_percent,
            statisticValue: data?.participant_satisfaction?.not_great,
            color: `var(--db_satisfaction_bad)`,
            className: 'not_great'
        },
    ]
}

export const numberOfSessionsChartData = (data) => {

    if (data?.number_of_sessions?.completed_percent === 0 &&
        data?.number_of_sessions?.not_attended_percent === 0 &&
        data?.number_of_sessions?.confirmed_percent === 0 &&
        data?.number_of_sessions?.assigned_percent === 0 &&
        data?.number_of_sessions?.not_assigned_percent === 0) {
        return [
            {
                title: 'Completed',
                value: 100,
                statisticValue: data?.number_of_sessions?.completed,
                color: `#E8EBEE`,
                className: 'completed'
            },
            {
                title: 'Confirmed',
                value: data?.number_of_sessions?.confirmed_percent,
                statisticValue: data?.number_of_sessions?.confirmed,
                color: `var(--db_confirmed_session)`,
                className: 'confirmed'
            },
            {
                title: 'Assigned',
                value: data?.number_of_sessions?.assigned_percent,
                statisticValue: data?.number_of_sessions?.assigned,
                color: `var(--db_assigned_session)`,
                className: 'assigned'
            },
            {
                title: 'Not Attended',
                value: data?.number_of_sessions?.not_attended_percent,
                statisticValue: data?.number_of_sessions?.not_attended,
                color: 'var(--db_not_attended_session)',
                className: 'not_attended'
            },
            {
                title: 'Not Assigned',
                value: data?.number_of_sessions?.not_assigned_percent,
                statisticValue: data?.number_of_sessions?.not_assigned,
                color: `var(--db_not_assigned_session)`,
                className: 'not_assigned'
            }
        ]
    }

    return [
        {
            title: 'Completed',
            value: data?.number_of_sessions?.completed_percent,
            statisticValue: data?.number_of_sessions?.completed,
            color: `var(--db_completed_session)`,
            className: 'completed'
        },
        {
            title: 'Confirmed',
            value: data?.number_of_sessions?.confirmed_percent,
            statisticValue: data?.number_of_sessions?.confirmed,
            color: `var(--db_confirmed_session)`,
            className: 'confirmed'
        },
        {
            title: 'Assigned',
            value: data?.number_of_sessions?.assigned_percent,
            statisticValue: data?.number_of_sessions?.assigned,
            color: `var(--db_assigned_session)`,
            className: 'assigned'
        },
        {
            title: 'Not Attended',
            value: data?.number_of_sessions?.not_attended_percent,
            statisticValue: data?.number_of_sessions?.not_attended,
            color: 'var(--db_not_attended_session)',
            className: 'not_attended'
        },
        {
            title: 'Not Assigned',
            value: data?.number_of_sessions?.not_assigned_percent,
            statisticValue: data?.number_of_sessions?.not_assigned,
            color: `var(--db_not_assigned_session)`,
            className: 'not_assigned'
        }
    ]
}

export const sessionFrequencyData = (data) => {

    if (data?.session_frequency?.avg_days === 0) {
        return [
            {
                title: 'Avg. days',
                value: 100,
                color: `#E8EBEE`,
                status: 'Average frequency between the sessions'
            }
        ]
    }
    
    return [
        {
            title: 'Avg. days',
            value: 100,
            color: `var(--db_session_frequency)`,
            status: 'Average frequency between the sessions'
        }
    ]
}