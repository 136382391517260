import {notifications} from "helpers/notifications";

class ResponseError {
    constructor({
                    status = 'Error',
                    message = 'Server error',
                    error_code,
                    errors
                }) {

        if (error_code === 'request_unauthorized') {
            throw new Error(error_code);
        }

        this.status = status;
        this.message = message;
        this.error_code = error_code;
        this.errors = errors;
    }

    getStatus() {
        return this.status;
    }

    getMessage() {
        return this.message;
    }

    getErrorCode() {
        return this.error_code;
    }

    getErrors() {
        return this.errors;
    }

    getValidationErrorMessages() {
        if (this.errors?.length) {

            return this.errors.map(message => message.message).join(` \n`);

        } else {

            return this.transformErrorMessage(this.error_code) || this.message;
        }
    }

    transformErrorMessage(errorCode) {
        return notifications[errorCode];
    }
}

export default ResponseError;