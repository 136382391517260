import React from "react";
import { ErrorMessage } from "formik";
import Checkbox from "components/UI/Checkbox/Checkbox";

const CheckboxForm = ({ name, field, question, options, values, form, goToNextStep, goToPreviousStep, stepDirection, item }) => {
    const fieldName = name || field.name;

    const exclusiveOption = options.find(option => option.exclusive);

    const { setFieldValue } = form;

    const toggleChecked = (name, value, field) => {

        if (value === exclusiveOption?.key) {
            if (field.value.includes(value)) {
                setFieldValue(name, [...field.value.filter((item) => item !== value)])
            } else {
                setFieldValue(name, [value])
            }
        } else if (field.value.includes(value)) {
            setFieldValue(name, [...field.value.filter((item) => item !== value)]);
        } else {
            if (field.value.includes(exclusiveOption?.key)) {
                setFieldValue(name, [value])
            } else {
                setFieldValue(name, [...field.value, value]);
            }
        }
    };

    React.useEffect(() => {

        if (item?.condition) {
            let array = item.condition

            let check = array?.filter(item => {
                if (typeof values[item.key] === 'number') {
                    return values[item.key] === item.val && item
                }
                return values[item.key].includes(item.val) && item
            })

            if (!check?.length) {
                if (stepDirection === "next") {
                    goToNextStep()
                }
                if (stepDirection === "back") {
                    goToPreviousStep()
                }
            }
        }

    }, [item?.condition, goToNextStep, values, goToPreviousStep, stepDirection])

    return (
        <>
            <div className="form-question">{question}</div>
            <div className="checkbox__container container">
                {options?.map((option) => (
                    <div className="questionnaire-checkbox" key={option.key ? option.key : option.option}>
                        <Checkbox
                            field={field}
                            name={fieldName}
                            label={option.option}
                            value={option.key ? option.key : option.option}
                            values={values}
                            onChange={toggleChecked}
                            exclusiveOption={exclusiveOption}
                        />
                    </div>
                ))}

                <ErrorMessage
                    name={fieldName}
                    render={(msg) => <p className="error-message">{msg}</p>}
                />
            </div>
        </>
    );
};

export default CheckboxForm;

