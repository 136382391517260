import React, { forwardRef } from 'react';

const CustomInput = ({ className, value, onClick }, ref) => {
    return (
        <div className={className} onClick={onClick} ref={ref}>
            <div className='value'>{value}</div>
        </div>
    )
}

export default forwardRef(CustomInput);
