import React, { useMemo, useState } from "react";
import Decisions from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/DecisionBox/Decisions/Decisions";
import CompleteProgram from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/DecisionBox/CompleteProgram/CompleteProgram";
import ChangeDirection from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/DecisionBox/ChangeDirection/ChangeDirection";
import ChangeProgram from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/DecisionBox/ChangeProgram/ChangeProgram";
import { useSelector } from "react-redux";

const DecisionBox = ({ decisionBox = [] }) => {
  const { continueProgramDecisionStatus } = useSelector(
    (state) => state.userPrograms
  );
  const { topics } =
    useSelector((state) => state.userPrograms.singleProgram) || {};
  const [decisionsData, setDecisionsData] = useState({
    step: "selectDecision",
    selectedDecision: decisionBox[0]?.decision.type,
    selectProgram: "",
    selectDirections: "",
  });

  const decisionTopicId = useMemo(() => {
    return topics?.find((topic) => topic[0].is_decision_box)?.[0]?.id;
  }, [topics]);

  const RenderDecisionStep = () => {
    if (continueProgramDecisionStatus) {
      return <h4>You've chosen to continue this program.</h4>;
    }

    switch (decisionsData.step) {
      case "selectDecision":
        return (
          <Decisions
            decisionBox={decisionBox}
            setDecisionsData={setDecisionsData}
            decisionsData={decisionsData}
            programTopicId={decisionTopicId}
          />
        );
      case "complete":
        return (
          <CompleteProgram
            decisionBox={decisionBox}
            decisionsData={decisionsData}
            setDecisionsData={setDecisionsData}
            programTopicId={decisionTopicId}
          />
        );
      case "selectDirections":
        return (
          <ChangeDirection
            decisionBox={decisionBox}
            decisionsData={decisionsData}
            setDecisionsData={setDecisionsData}
            programTopicId={decisionTopicId}
          />
        );
      case "selectProgram":
        return (
          <ChangeProgram
            decisionBox={decisionBox}
            decisionsData={decisionsData}
            setDecisionsData={setDecisionsData}
            programTopicId={decisionTopicId}
          />
        );
      default:
        return null;
    }
  };

  return <RenderDecisionStep />;
};

export default DecisionBox;
