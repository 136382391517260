import React from 'react';
import {PulseLoader} from "react-spinners";

const WithSpinner = WrappedComponent => ({spinnerProps, ...componentProps}) => {
    const {loading} = spinnerProps;

    return  loading ? (
        <PulseLoader {...spinnerProps} />
    ) : (
        <WrappedComponent {...componentProps}/>
    )
}

export default WithSpinner;