import React from "react";
import { ReactComponent as TrashbinSvg } from "assets/vector/notification/trashbin.svg";

export default function NotificationHeader({ setOpenPopupClearAll, userNotificationEmpty }) {

    const clearAll = () => {
        setOpenPopupClearAll(true);
    }

    return (
        <div className="header-notification">
            <h2 className={`header-notification-title`}>Notifications</h2>
            {
                !userNotificationEmpty &&
                <div className="clear" onClick={(e) => {
                    e.stopPropagation();
                    clearAll();
                }}>
                    <h3 className={`clear-all`}>Clear all</h3>
                    <TrashbinSvg className={`trash-bin-icon`} />
                </div>
            }
        </div>
    )
}