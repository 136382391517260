import React from 'react';
import { Field, ErrorMessage } from 'formik';
import QuestionnaireSelect from '../../../../CustomFormSelect/QuestionnaireSelect';

const SelectForm = (props) => {
    const { question, options, name, item, values, goToNextStep, goToPreviousStep, stepDirection } = props;

    React.useEffect(() => {
        if (item?.condition) {
            let array = item.condition

            let check = array?.filter(item => {
                if (typeof values[item.key] === 'number') {
                    return values[item.key] === item.val && item
                }
                return values[item.key].includes(item.val) && item
            })

            if (!check?.length) {
                if (stepDirection === "next") {
                    goToNextStep()
                }
                if (stepDirection === "back") {
                    goToPreviousStep()
                }
            }
        }

    }, [item?.condition, goToNextStep, values, goToPreviousStep, stepDirection])

    return (
        <>
            <div className={item?.question_type === "gender" ? "form-question gender" : "form-question"}>{question}</div>
            <div className="select__container">
                <Field
                    name={name}
                    component={QuestionnaireSelect}
                    options={options}
                />
            </div>
            <ErrorMessage name={name} render={msg => <p className="error-message">{msg}</p>} />
        </>
    )
}

export default SelectForm;