import { fork, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { coachesList } from "../constants";
import {
	getCoachesListSuccess,
	getCoachesListEmpty,
	getCoachesListError,
} from "../actions/coachesList";
import { GET_LIST_OF_COACHES } from "constants/api";
import { generalApiErrorHandler } from "../sagas/helpers/generalApiErrorHandler";
import { showNotification } from "../actions";
import { getNotificationMessage } from "../../helpers/notifications";
import { getToken } from "../sagas/helpers/helpers";

function* watchGetCoachesList() {
	yield takeLatest(coachesList.GET_LIST_COACHES, handleGetCoachesList);
}

function* handleGetCoachesList({ payload }) {
	try {
		const token = yield select(getToken);

		const currentUserData = yield axios({
			method: "get",
			url:
				GET_LIST_OF_COACHES() +
				`?offset=${payload.offset}&take=${payload.take}${
					payload.search && `&search=${payload.search}`
				}${
					payload.programs.length > 0 ? `&programs=[${payload.programs}]` : ""
				}${
					payload.functional_areas.length > 0
						? `&functional_areas=[${payload.functional_areas}]`
						: ""
				}${
					payload.industry_background.length > 0
						? `&industry_background=[${payload.industry_background}]`
						: ""
				}${
					payload.geography.length > 0
						? `&geography=[${payload.geography}]`
						: ""
				}${
					payload.seniority_level
						? `&seniority_level=${payload.seniority_level}`
						: ""
				}${
					payload.experience_years
						? `&experience_years=${payload.experience_years}`
						: ""
				}${
					payload.languages.length > 0
						? `&languages=[${payload.languages}]`
						: ""
				}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (currentUserData.coaches.length > 0) {
			yield put(getCoachesListSuccess(currentUserData));
		} else {
			yield put(getCoachesListEmpty(currentUserData));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getCoachesListError(error.message));
		});
	}
}

function* watchGetNewCoachesItemsOnScroll() {
	yield takeLatest(
		coachesList.GET_NEW_COACHES_ON_SCROLL,
		handleGetNewCoachesItemsOnScroll
	);
}

function* handleGetNewCoachesItemsOnScroll({ payload }) {
	try {
		const token = yield select(getToken);

		const currentCoachesList = yield select(
			(state) => state.coachesList.coachesList
		);

		const getCoachesDataByScroll = yield axios({
			method: "get",
			url:
				GET_LIST_OF_COACHES() +
				`?offset=${payload.offset}&take=${payload.take}${
					payload.search && `&search=${payload.search}`
				}${
					payload.programs.length > 0 ? `&programs=[${payload.programs}]` : ""
				}${
					payload.functional_areas.length > 0
						? `&functional_areas=[${payload.functional_areas}]`
						: ""
				}${
					payload.industry_background.length > 0
						? `&industry_background=[${payload.industry_background}]`
						: ""
				}${
					payload.geography.length > 0
						? `&geography=[${payload.geography}]`
						: ""
				}${
					payload.seniority_level
						? `&seniority_level=${payload.seniority_level}`
						: ""
				}${
					payload.experience_years
						? `&experience_years=${payload.experience_years}`
						: ""
				}${
					payload.languages.length > 0
						? `&languages=[${payload.languages}]`
						: ""
				}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		const concatCoachesItemsData = currentCoachesList.coaches.concat(
			getCoachesDataByScroll.coaches
		);

		const newCoachesItemsData = {
			...getCoachesDataByScroll,
			coaches: concatCoachesItemsData,
		};

		if (getCoachesDataByScroll.coaches.length > 0) {
			yield put(getCoachesListSuccess(newCoachesItemsData));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getCoachesListError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchClearCoachesFilters() {
	yield takeLatest(
		coachesList.CLEAR_COACHES_FILTERS,
		handleClearCoachesFilters
	);
}

function* handleClearCoachesFilters({ payload }) {
	try {
		const token = yield select(getToken);

		const getCoachesDataAfterResetFilters = yield axios({
			method: "get",
			url:
				GET_LIST_OF_COACHES() +
				`?offset=${payload.offset}&take=${payload.take}${
					payload.search && `&search=${payload.search}`
				}${
					payload.programs.length > 0 ? `&programs=[${payload.programs}]` : ""
				}${
					payload.functional_areas.length > 0
						? `&functional_areas=[${payload.functional_areas}]`
						: ""
				}${
					payload.industry_background.length > 0
						? `&industry_background=[${payload.industry_background}]`
						: ""
				}${
					payload.geography.length > 0
						? `&geography=[${payload.geography}]`
						: ""
				}${
					payload.seniority_level
						? `&seniority_level=${payload.seniority_level}`
						: ""
				}${
					payload.experience_years
						? `&experience_years=${payload.experience_years}`
						: ""
				}${
					payload.languages.length > 0
						? `&languages=[${payload.languages}]`
						: ""
				}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (getCoachesDataAfterResetFilters.coaches.length > 0) {
			yield put(getCoachesListSuccess(getCoachesDataAfterResetFilters));
		} else {
			yield put(getCoachesListEmpty(null));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getCoachesListError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* coachesListSaga() {
	yield fork(watchGetCoachesList);
	yield fork(watchGetNewCoachesItemsOnScroll);
	yield fork(watchClearCoachesFilters);
}
