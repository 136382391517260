import React from 'react';
// fot ILTB
// import {ReactComponent as LogoWhiteSVG} from "assets/vector/auth-page/logo.svg";
import {ReactComponent as LogoWhiteSVG} from "assets/vector/auth-page/logo-switch.svg";
import './Footer.scss';

const Footer = () => {
    return (
        <div className={`auth-footer`}>
            <LogoWhiteSVG className="auth-footer__logo"/>
            <p className={`auth-footer__copy`}>© 2021 Switch. All rights reserved</p>
        </div>
    );
};

export default Footer;