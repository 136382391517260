import React, {useState} from 'react';
import Menu from "components/common/Menu/Menu";
import Layout from "components/common/Layout/Layout";
import Header from "components/common/Header/Header";

const withLoggedLayout = (Component , ShowProgramme = false) => {
    return () => {
        const [isMenuOpen, setIsMenuOpen] = useState(false);
        const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

        return (
            <>
                <div className={`main-wrap ${isMenuOpen ? 'main-wrap--menu-open' : ''}`}>
                    <Menu isMenuOpen={isMenuOpen}/>
                    <Layout>
                        <Header
                            ShowProgramme={ShowProgramme}
                            isMenuOpen={isMenuOpen}
                            toggleMenu={toggleMenu}/>
                        <Component/>
                    </Layout>
                </div>
            </>
        );
    }
};

export default withLoggedLayout;