import { postData } from "../../sagas/helpers/requestHandlers";
import {
	ACCEPT_SESSION_BY_COACH_URL,
	GET_SESSION_ACCEPT_ROUTE,
	GET_SESSION_DECLINE_ROUTE,
	LATE_CANCEL_SESSION_ROUTE,
} from "constants/api";
import { put, call, select } from "redux-saga/effects";
import {
	acceptSessionByCoachError,
	acceptSessionByCoachSuccess,
	acceptSessionInvitationError,
	acceptSessionInvitationSuccess,
	declineSessionInvitationError,
	declineSessionInvitationSuccess,
	lateCancelSessionInvitationError,
	lateCancelSessionInvitationSuccess,
	showNotification,
	updateUserSingleProgram,
} from "../../actions";
import ResponseError from "helpers/ResponseError";
import { getNotificationMessage } from "../../../helpers/notifications";
import { getToken } from "../../sagas/helpers/helpers";

export function* acceptSessionRequest({ sessionId, token }) {
	const route = GET_SESSION_ACCEPT_ROUTE(sessionId);
	const { data } = yield call(postData(route, { id: sessionId }, token));

	if (data?.status === "Success") {
		yield put(acceptSessionInvitationSuccess(data.data));
		yield put(updateUserSingleProgram(data.data));
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(acceptSessionInvitationError(err.getValidationErrorMessages()));

		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}

export function* acceptSessionByCoachRequest(sessionId) {
	const route = ACCEPT_SESSION_BY_COACH_URL(sessionId);
	const token = yield select(getToken);
	const { data } = yield call(postData(route, null, token));

	if (data?.status === "Success") {
		yield put(acceptSessionByCoachSuccess());
		yield put(updateUserSingleProgram(data.data));
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(acceptSessionByCoachError());
		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}

export function* declineSessionRequest(sessionId) {
	const route = GET_SESSION_DECLINE_ROUTE(sessionId);
	const token = yield select(getToken);
	const { data } = yield call(postData(route, { id: sessionId }, token));

	if (data?.status === "Success") {
		yield put(declineSessionInvitationSuccess(data.data));
		yield put(updateUserSingleProgram(data.data));
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(declineSessionInvitationError(err.getValidationErrorMessages()));

		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}

export function* lateCancelSessionRequest(sessionId) {
	const route = LATE_CANCEL_SESSION_ROUTE(sessionId);
	const token = yield select(getToken);

	const { data } = yield call(postData(route, { id: sessionId }, token));

	if (data?.status === "Success") {
		yield put(lateCancelSessionInvitationSuccess(data.data));
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(
			lateCancelSessionInvitationError(err.getValidationErrorMessages())
		);

		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}
