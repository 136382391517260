import React from 'react';

const ModuleIcon = ({color = '#486EA0', width = 40, height = 32, ...props}) => {
    return (
        <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.11889 0.720703C6.11781 0.720703 6.1166 0.720703 6.11552 0.720703C5.7372 0.720703 5.38138 0.868178 5.11269 1.13619C4.84104 1.40717 4.69141 1.76798 4.69141 2.15209V23.6154C4.69141 24.4025 5.33424 25.0444 6.12454 25.0464C9.45627 25.0544 15.0382 25.7488 18.889 29.7786V7.31655C18.889 7.04974 18.8209 6.7991 18.6923 6.5917C15.5317 1.50185 9.45816 0.728515 6.11889 0.720703Z" fill={color}/>
            <path d="M35.3088 23.6156V2.15209C35.3088 1.76798 35.1592 1.40717 34.8875 1.13619C34.6188 0.868178 34.2628 0.720703 33.8848 0.720703C33.8836 0.720703 33.8824 0.720703 33.8813 0.720703C30.5422 0.728649 24.4686 1.50199 21.308 6.59183C21.1793 6.79924 21.1113 7.04988 21.1113 7.31669V29.7786C24.9621 25.7488 30.5441 25.0544 33.8758 25.0464C34.666 25.0444 35.3088 24.4025 35.3088 23.6156Z" fill={color}/>
            <path d="M38.5683 5.6709H37.5305V23.6159C37.5305 25.6254 35.8932 27.2641 33.8805 27.2691C31.0545 27.2758 26.3948 27.8284 23.0947 30.9518C28.8022 29.5543 34.819 30.4628 38.2479 31.2442C38.6761 31.3417 39.1185 31.241 39.4615 30.9676C39.8034 30.6948 39.9994 30.2871 39.9994 29.8496V7.10201C39.9996 6.31292 39.3574 5.6709 38.5683 5.6709Z" fill={color}/>
            <path d="M2.46896 23.6159V5.6709H1.43112C0.642157 5.6709 0 6.31292 0 7.10201V29.8492C0 30.2869 0.196095 30.6944 0.537914 30.9671C0.880676 31.2404 1.3227 31.3416 1.75152 31.2438C5.18049 30.4622 11.1973 29.5539 16.9046 30.9514C13.6046 27.8281 8.94495 27.2757 6.11894 27.269C4.10641 27.2641 2.46896 25.6254 2.46896 23.6159Z" fill={color}/>
        </svg>
    );
};

export default ModuleIcon;