import React from "react";

import "./EmptyPage.scss";

export default function EmptyPage({emptyPageValue}) {

    return (
        <section className="empty-page">
            <div className="empty-page-wrap">
                <img className={`page-empty-image`} src={emptyPageValue.image} alt="page-empty"/>
                <h1 className={`page-title`}>{emptyPageValue.title}</h1>
                <h2 className={`page-subtitle`}>{emptyPageValue.subTitle}</h2>
                {
                    emptyPageValue.shortDescription && <h3 className={`page-short-description`}>{emptyPageValue.shortDescription}</h3>
                }
            </div>
        </section>
    );
};