import React from 'react';

const CrossRoundIcon = ({ color = '#929292', width = 14, height = 14, ...props }) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.17389 0.474155L11.1897 4.17538C11.3254 4.42963 11.5453 4.59037 11.8333 4.64116L15.9746 5.42037C16.3134 5.47953 16.576 5.70839 16.686 6.03853C16.7876 6.36868 16.7114 6.70749 16.4741 6.96175L13.5778 10.0191C13.383 10.2306 13.2982 10.4849 13.3322 10.7729L13.8742 14.9483C13.925 15.2955 13.7894 15.6172 13.5013 15.8207C13.222 16.0239 12.8745 16.0576 12.5614 15.9053L8.75859 14.0929C8.50463 13.9743 8.22498 13.9743 7.97102 14.0929L4.15985 15.9053C3.84644 16.0576 3.50763 16.0239 3.21991 15.8207C2.94055 15.6172 2.80491 15.2955 2.84734 14.9483L3.39798 10.7729C3.43174 10.4849 3.34719 10.2309 3.15239 10.0191L0.255765 6.96175C0.0185381 6.70749 -0.0576493 6.36898 0.0439341 6.03853C0.154182 5.70839 0.416805 5.47953 0.755316 5.42037L4.89663 4.64116C5.17628 4.59037 5.40485 4.42963 5.54019 4.17538L7.55602 0.474155C7.71706 0.169405 8.01345 0 8.36062 0C8.7078 0 9.01285 0.169405 9.17389 0.474155Z" fill={color} />
        </svg>
    );
};

export default CrossRoundIcon;