import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./Dashboard-coach.scss";
import pageTitles from "constants/pageTitles";
import { updatePageTitle } from "../../../../redux/actions";
import { NavLink } from "react-router-dom";
import routes from "constants/routes";
import programmes from "assets/vector/dashboard/programmes-icon.svg";
import calendar from "assets/vector/dashboard/calendar-icon.svg";
import { ReactComponent as CalendarImg } from "assets/vector/dashboard/calendarImg.svg";
import { ReactComponent as ProgramImg } from "assets/vector/dashboard/programImg.svg";

export default function DashboardCoach() {
	const platformSetting = JSON.parse(
		window.localStorage.getItem("platformSettings")
	);

	const { DASHBOARD_TITLE } = pageTitles;

	const { PROGRAMMES, CALENDAR } = routes;

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updatePageTitle(DASHBOARD_TITLE));
	}, []);

	return (
		<section className="section-wrap page-dashboard-coach">
			<h2 className={`page-title`}>
				Welcome <br />
				to Sw!tch
			</h2>

			<h3 className={`page-subtitle`}>
				Please check one of the following options to proceed
			</h3>

			<div className="page-links">
				<NavLink className={`reset-style item_link`} to={PROGRAMMES}>
					<div className="item">
						<div className="item_row">
							<div className="img_wrapper">
								<img
									src={
										platformSetting
											? platformSetting.db_program_icon
											: programmes
									}
									className={`item-image`}
									alt="image to page"
								/>
							</div>
							<div className="link-text">Programmes</div>
						</div>
						<CalendarImg className="item_img" />
					</div>
				</NavLink>

				<NavLink className={`reset-style item_link`} to={CALENDAR}>
					<div className="item">
						<div className="item_row">
							<div className="img_wrapper">
								<img
									src={
										platformSetting
											? platformSetting.db_calendar_icon
											: calendar
									}
									className={`item-image`}
									alt="image to page"
								/>
							</div>
							<div className="link-text">Calendar </div>
						</div>
						<ProgramImg className="item_img" />
					</div>
				</NavLink>
			</div>
		</section>
	);
}
