import React, { useState } from "react";
import "./ReportsListItem.scss";
import { Link, useRouteMatch } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { GET_REPORTS_ROUTE } from "constants/api";
import StarRating from "components/starRating/StarRating";
import DownloadIcon from "components/icons/DownloadIcon";
import DownArrowIcon from "components/icons/DownArrowIcon";
import ResponseError from "helpers/ResponseError";
import { showNotification } from "../../../../redux/actions";
import ReportsCheckbox from "../ReportsCheckbox/ReportsCheckbox";

const ReportsListItem = (props) => {
	const { path } = useRouteMatch();
	const {
		showTooltipInfo,
		isActive,
		num,
		id,
		avatar,
		name,
		participant_program_name,
		stars_rating,
		qualification_type,
		topic_number,
		topic_name,
		form_template_name,
		activity_name,
		submission_date,
		evaluation_object_name,
		type,
		userRole,
	} = props;

	const minReportsDownloadScreen = useMediaQuery({ minWidth: 1780 });
	const minCoachNameDisplayingWidth = useMediaQuery({ minWidth: 1600 });

	const dispatch = useDispatch();
	const accessToken = useSelector((state) => state.auth.authToken);
	const coachesExist = useSelector((state) => state.coachesList?.coachesExist);

	const getElementPosition = (event) => {
		const pageScroll = window.pageYOffset;
		const targetLeft = event.target.getBoundingClientRect().left;
		const targetTop = event.target.getBoundingClientRect().top + pageScroll;
		const targetHeight = event.target.getBoundingClientRect().height;

		return {
			left: targetLeft,
			top: targetTop,
			offsetTop: targetHeight,
		};
	};

	const tooltipInfoBasic = {
		id: id,
		info: [
			{
				label: "Topic number",
				value: topic_number,
			},
			{
				label: "Topic name",
				value: topic_name,
			},
			{
				label: "Session name",
				value: activity_name,
			},
		],
	};

	const tooltipInfoIfCoachExist = {
		id: id,
		info: [
			{
				label: "Coach name",
				value: type === "evaluation" ? name : evaluation_object_name,
			},
			{
				label: "Topic number",
				value: topic_number,
			},
			{
				label: "Topic name",
				value: topic_name,
			},
			{
				label: "Session name",
				value: activity_name,
			},
		],
	};

	const tooltipInfo =
		coachesExist && !minCoachNameDisplayingWidth
			? tooltipInfoIfCoachExist
			: tooltipInfoBasic;

	const [isInfoShown, setIsInfoShown] = useState(false);

	const showInfo = () => {
		setIsInfoShown(!isInfoShown);
	};

	const downloadReport = (id, type, name) => {
		axios({
			method: "get",
			url: `${GET_REPORTS_ROUTE}/${id}/export-csv`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			params: { type: type },
			accept: "text/xls",
			responseType: "blob",
		})
			.then((response) => {
				const file = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = file;
				link.target = "_blank";
				link.download = `${name}_${type}_report.xls`;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((error) => {
				const responseError = new ResponseError(error.response.data);

				dispatch(
					showNotification({
						title: responseError.getStatus(),
						text: responseError.getValidationErrorMessages(),
					})
				);
			});
	};

	return (
		<tr
			className={`reports-item
            ${isActive ? "reports-item--active" : ""}
            ${isInfoShown ? "reports-item--info-shown" : ""}
            `}
		>
			<td className="reports-item__cell reports-item__cell--checkbox">
				<ReportsCheckbox id={id} />
			</td>
			<td className="reports-item__cell reports-item__cell--num">
				<Link
					to={`${path}/${id}`}
					style={{ textDecoration: "none", color: "#101821" }}
				>
					{num}
				</Link>
			</td>
			<td className="reports-item__cell reports-item__cell--user">
				<ReportsCheckbox id={id} />
				{avatar && (
					<img
						src={avatar}
						alt="user avatar"
						className="reports-item__user-img"
					/>
				)}
				<Link to={`${path}/${id}`} className="reports-item__name">
					{type === "evaluation" ? evaluation_object_name : name}
					<StarRating rating={stars_rating} />
				</Link>
				<DownArrowIcon
					className="reports-item__open"
					onClick={showInfo}
					color={isInfoShown ? "#3D8B82" : "#101821"}
				/>
			</td>
			<td className="reports-item__cell">
				{qualification_type}
				<span className="reports-item__cell-name">Qualification type</span>
			</td>
			{userRole === "school_admin" && coachesExist && (
				<td className="reports-item__cell reports-item__cell--coach-name">
					{type === "evaluation" ? name : evaluation_object_name}
					<span className="reports-item__cell-name">Coach name</span>
				</td>
			)}
			<td className="reports-item__cell reports-item__cell--program">
				{participant_program_name}
				<span className="reports-item__cell-name">Programme name</span>
			</td>
			<td className="reports-item__cell reports-item__cell--topic-num">
				{topic_number}
				<span className="reports-item__cell-name">Topic number</span>
			</td>
			<td className="reports-item__cell reports-item__cell--topic-name">
				{topic_name}
				<span className="reports-item__cell-name">Topic name</span>
			</td>
			<td className="reports-item__cell reports-item__cell--session-name">
				{activity_name}
				<span className="reports-item__cell-name">Session name</span>
			</td>
			<td className="reports-item__cell">
				{form_template_name}
				<span className="reports-item__cell-name">Form name</span>
			</td>
			<td className="reports-item__cell reports-item__cell--submission-date">
				{submission_date}
				<span className="reports-item__cell-name">Submission date</span>
			</td>
			<td className="reports-item__cell reports-item__cell--rating">
				<div className="stars_rating-mark">
					<StarRating rating={stars_rating} />
					<span
						className="reports-item__csv"
						onClick={() =>
							downloadReport(id, type, name ? name : evaluation_object_name)
						}
					>
						<DownloadIcon className="reports-item__csv-icon" />
						{userRole === "school_admin" &&
						coachesExist &&
						minReportsDownloadScreen ? null : (
							<span className="csv-span">XLS</span>
						)}
					</span>
				</div>
			</td>
			<td className="reports-item__cell reports-item__cell--tooltip">
				<span
					onClick={(e) =>
						showTooltipInfo({ ...tooltipInfo, position: getElementPosition(e) })
					}
				>
					&middot;&middot;&middot;
				</span>
			</td>
		</tr>
	);
};

export default ReportsListItem;
