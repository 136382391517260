import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import pageTitles from "constants/pageTitles";
import "./MySettings.scss";
import { getGoogleAuthStatus, updatePageTitle } from "../../../redux/actions";
import CoachesSettings from "./settings-coach";
import ParticipantSettings from "./settings-participant";
import SchoolAdminSettings from "./settings-school-admin";
import NotFound from "components/pages/NotFound/NotFound";

export default function MyProfile() {
	const { SETTINGS_TITLE } = pageTitles;

	const dispatch = useDispatch();
	const getCurrentUserInfo = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const pageTitle = useSelector((state) => state.currentPageTitle.pageTitle);

	useEffect(() => {
		if (pageTitle !== SETTINGS_TITLE) {
			dispatch(updatePageTitle(SETTINGS_TITLE));
		}
	});

	useEffect(() => {
		if (!getCurrentUserInfo?.google_account) {
			dispatch(getGoogleAuthStatus());
		}
	}, [getCurrentUserInfo?.google_account]);

	const switchRole = (userRole, getCurrentUserInfo) => {
		switch (userRole) {
			case "coach":
				return <CoachesSettings getCurrentUserInfo={getCurrentUserInfo} />;
			case "participant":
				return <ParticipantSettings getCurrentUserInfo={getCurrentUserInfo} />;
			case "school_admin":
				return <SchoolAdminSettings getCurrentUserInfo={getCurrentUserInfo} />;
			default:
				return <NotFound />;
		}
	};

	return (
		<section className="section-wrap">
			<div className="settings-page">
				{getCurrentUserInfo &&
					switchRole(getCurrentUserInfo.role, getCurrentUserInfo)}
			</div>
			{/* {!isSameTimeZone ? <div className="notification  active"><button onClick={hideNotification}  className="close-btn"></button><p>There are different time zones on your device and in your profile settings. The calendar and session scheduler will work incorrectly. Please make sure that time zones are the same.</p></div> : null } */}
		</section>
	);
}
