import React from 'react';
import {CSSTransition} from "react-transition-group";
import './ActionItemTooltip.scss';

const ActionItemTooltip = ({show, showAddActionForm, removeAllActionItems}) => {
    const tooltipRef = React.useRef(null);

    return (
        <CSSTransition
            in={show}
            timeout={600}
            unmountOnExit
            classNames='modal__overlay-'
            nodeRef={tooltipRef}
        >
            <div className="action-tooltip"
                 ref={tooltipRef}
            >
                <button className='action-tooltip__btn' onClick={showAddActionForm}>
                    Add action item
                </button>
                <button className='action-tooltip__btn' onClick={removeAllActionItems}>
                    Delete
                </button>
            </div>
        </CSSTransition>

    );
};

export default ActionItemTooltip;