import React from "react";
import parse from "html-react-parser";
import { ClipLoader } from "react-spinners";

function ViewNote({ note, onEditClick, onDeleteClick, isLoading }) {
	return (
		<div className="flex flex-col gap-6">
			{parse(note.content)}
			<div className="flex item-center gap-6">
				<button className="button button--blue h-12" onClick={onEditClick}>
					Edit
				</button>
				<button
					className="button bg-red-600 py-4.5 px-10 h-12 text-white"
					onClick={onDeleteClick}
					disabled={isLoading}
				>
					{isLoading ? <ClipLoader color="white" size={28} /> : "Delete"}
				</button>
			</div>
		</div>
	);
}

export default ViewNote;
