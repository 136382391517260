import React from 'react';
import erorIcon from 'assets/vector/customError.svg'

const CustomErrorMessage = ({ message }) => {
    return (
        <div className='edit-schedule__customError'>
            <img src={erorIcon} alt="" />
            <div className='error-message'>{message}</div>
        </div>
    )
}

export default CustomErrorMessage;
