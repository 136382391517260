import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Formik, Field, Form } from "formik";
import axios from "axios";
import { useLocation } from "react-router";
import { Button, CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import RadioFiveItems from "./FormSteps/RadioFiveItems";
import RadioTenItems from "./FormSteps/RadioTenItems";
import RadioItems from "./FormSteps/RadioItems";
import TextareaForm from "./FormSteps/TextareaForm";
import SelectForm from "./FormSteps/SelectForm";
import CheckboxForm from "./FormSteps/CheckboxForm";
import { DECISIONS_URL } from "constants/api";
import ResponseError from "helpers/ResponseError";
import { showNotification } from "../../../../redux/actions";
import feedbackValidationSchema from "./ValidationSchemas/feedbackValidationSchema";
import QuestionnaireStepper from "./QuestionnaireStepper.js/QuestionnaireStepper";
import "./Forms.scss";

const DecisionForm = () => {
	const [blockSubmit, setBlockSubmit] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const [steps, setSteps] = useState([]);
	const [formStep, setFormStep] = useState(steps[0]);
	const [initialValues, setInitialValues] = useState(false);

	const accessToken = useSelector((state) => state.auth.authToken);

	const [activeStep, setActiveStep] = useState(0);
	let isLastStep = activeStep === steps.length - 1;
	const location = useLocation();

	function _sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	const sendDecisionData = (decisionData) => {
		axios({
			method: "post",
			url: `${DECISIONS_URL}`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			accept: "application/json",
			data: decisionData,
		})
			.then((response) => {
				if (response.data.data.redirect_url) {
					const parsedUrl = new URL(response.data.data.redirect_url);
					const pathWithoutDomain = parsedUrl.pathname + parsedUrl.search;
					history.push(pathWithoutDomain);
				} else {
					history.push("/");
				}
			})
			.catch((error) => {
				const responseError = new ResponseError(error.response.data);

				dispatch(
					showNotification({
						title: responseError.getStatus(),
						text: responseError.getValidationErrorMessages(),
					})
				);

				setBlockSubmit(false);
			});
	};

	const _renderStepContentForFeedbackForm = (item, values) => {
		switch (item.type) {
			case "scale1_5":
				return (
					<Field
						name={item.id}
						options={JSON.parse(item.options).reverse()}
						component={RadioFiveItems}
						question={item.question}
					/>
				);
			case "scale1_10":
				return (
					<Field
						name={item.id}
						options={JSON.parse(item.options).reverse()}
						component={RadioTenItems}
						question={item.question}
					/>
				);
			case "radio":
				return (
					<Field
						name={item.id}
						options={JSON.parse(item.options)}
						question={item.question}
						component={RadioItems}
					/>
				);
			case "text":
				return (
					<TextareaForm
						name={item.id}
						placeholder="Enter your answer..."
						question={item.question}
					/>
				);
			case "dropdown":
				return (
					<SelectForm
						options={JSON.parse(item.options)}
						question={item.question}
						name={item.id}
					/>
				);
			case "multi_select":
				return (
					<Field
						values={values}
						name={item.id}
						options={JSON.parse(item.options)}
						component={CheckboxForm}
						question={item.question}
					/>
				);
			case "satisfaction":
				return (
					<Field
						name={item.id}
						options={JSON.parse(item.options).reverse()}
						component={RadioFiveItems}
						question={item.question}
					/>
				);
			case "evaluation":
				return (
					<Field
						name={item.id}
						options={JSON.parse(item.options).reverse()}
						component={RadioFiveItems}
						question={item.question}
					/>
				);
			default:
				return <div>Not Found</div>;
		}
	};

	const removeEmptyValues = (obj) => {
		let newObj = {};
		Object.keys(obj).forEach((prop) => {
			if (obj[prop]) {
				newObj[prop] = obj[prop];
			}
		});
		return newObj;
	};

	const _submitForm = async (values, actions) => {
		if (isLastStep) setBlockSubmit(true);

		await _sleep(1000);
		let data = [];

		for (const property in removeEmptyValues(values)) {
			const newValues = {};

			newValues["question_id"] = property;
			newValues["answers"] = [];
			if (typeof values[property] === "object") {
				values[property].forEach((answer) =>
					newValues["answers"].push({ answer: answer })
				);
			} else {
				newValues["answers"].push({ answer: values[property] });
			}

			data.push(newValues);
		}

		const formData = {
			form_template_id: location.state.form.id,
			results: JSON.stringify(data),
			participant_program_topic_id: location.state.participant_program_topic_id,
			participant_program_id: location.state.participant_program_id,
		};

		const decisionsData = {
			participant_program_id: location.state.participant_program_id,
			participant_program_topic_id: location.state.participant_program_topic_id,
			decisions_box: location.state.decisionsData,
		};

		axios({
			method: "post",
			url: `${DECISIONS_URL}/report`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			accept: "application/json",
			data: formData,
		})
			.then((response) => {
				console.log(response.status);
				sendDecisionData(decisionsData);
			})
			.catch((error) => {
				const responseError = new ResponseError(error.response.data);

				dispatch(
					showNotification({
						title: responseError.getStatus(),
						text: responseError.getValidationErrorMessages(),
					})
				);

				setBlockSubmit(false);
			});

		actions.setSubmitting(false);
	};

	const _handleSubmit = (values, actions) => {
		if (isLastStep) {
			_submitForm(values, actions);
		} else {
			setActiveStep(activeStep + 1);
			setFormStep(steps[activeStep + 1]);

			actions.setTouched({});
			actions.setSubmitting(false);
		}
	};

	const _handleBack = () => {
		setActiveStep(activeStep - 1);
		setFormStep(steps[activeStep - 1]);
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		setSteps(location.state.form.questions);
		const initial = {};
		for (let i in location.state.form.questions) {
			let obj = location.state.form.questions[i];
			initial[obj.id] = "";
		}

		setInitialValues(initial);
		setFormStep(location.state.form.questions[0]);
	}, [location.state.form.questions]);

	return (
		<section className="feedback-form">
			<div className="feedback__container">
				<QuestionnaireStepper
					stepsLength={steps.length}
					activeStep={activeStep}
				/>
				<div
					className="coach__section"
					style={{ justifyContent: location.state?.name ? "" : "flex-end" }}
				>
					{location.state?.name && (
						<div className="coach__info">
							<img
								className="coach__image"
								src={location.state?.avatar}
								alt=""
							/>
							<div className="info__content">
								<div className="coach__name">{location.state?.name}</div>
								<p>{location.state?.role}</p>
							</div>
						</div>
					)}
					<div className="section__text">
						<div className="text__title">
							{location.state?.form?.name} - <span>Confidential</span>
						</div>
						<p>Please provide feedback about your expirience with coaching</p>
					</div>
				</div>
				{initialValues && formStep && (
					<Formik
						initialValues={initialValues}
						validationSchema={feedbackValidationSchema(formStep)}
						onSubmit={_handleSubmit}
					>
						{({ isSubmitting, values, errors, ...props }) => {
							return (
								<Form id="questionnaire">
									{formStep &&
										_renderStepContentForFeedbackForm(formStep, values)}
									<div className="form__buttons">
										{activeStep !== 0 && (
											<Button
												onClick={() => _handleBack(values)}
												variant="contained"
												className="button__back btn"
											>
												Back
											</Button>
										)}
										<div>
											<Button
												type="submit"
												variant="contained"
												color="primary"
												disabled={blockSubmit}
												className="button__submit btn"
												onClick={
													Object.keys(errors).length > 0
														? null
														: () => window.scrollTo(0, 0)
												}
											>
												{isLastStep ? "Submit" : "Next"}
											</Button>
											{isSubmitting && (
												<CircularProgress
													size={24}
													className="button__progress"
												/>
											)}
										</div>
									</div>
								</Form>
							);
						}}
					</Formik>
				)}
			</div>
		</section>
	);
};

export default DecisionForm;
