import React from 'react';
import EditScheduleCheckbox from 'components/UI/EditScheduleCheckbox/EditScheduleCheckbox';

const BreakDays = ({ name, field, options, values, form }) => {

    const fieldName = name || field.name;

    const { setFieldValue } = form;

    const toggleChecked = (name, value, field) => {

        if (field.value.includes(value)) {
            setFieldValue(name, [...field.value.filter((item) => item !== value)])
        } else {
            setFieldValue(name, [...field.value, value])
        }

    };

    return (
        <div className="edit-schedule__checkboxes-container">
            <div className="checkboxes__wrapper">
                {options?.map((option) => (
                    <EditScheduleCheckbox
                        key={option.label}
                        field={field}
                        name={fieldName}
                        label={option.label}
                        value={option.value}
                        values={values}
                        onChange={toggleChecked}
                    />
                ))}
            </div>
        </div>
    )
}

export default BreakDays;
