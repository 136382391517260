import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Register.scss";
import SignUpForm from "components/forms/SignUp/SignUpForm";
import { useDispatch, useSelector } from "react-redux";
import { getSchoolInfo } from "../../../../redux/actions";
import SchoolInfo from "components/auth/schoolInfo/schoolInfo";
import WithSpinner from "components/HOC/withSpinner";
import Notification from "components/auth/notification/Notification";
import Header from "components/pages/Auth/Header/Header";
import Footer from "components/pages/Auth/Footer/Footer";

const SchoolInfoWithSpinner = WithSpinner(SchoolInfo);

const Register = () => {
	const dispatch = useDispatch();
	const { code: programCode, id: programId } = useParams();
	const {
		schoolInfo,
		registerForProgramInProgress,
		registerForProgramError,
		getSchoolInfoInProgress,
	} = useSelector((state) => state.programs);

	const [isErrorNotification, setErrorNotification] = useState(false);

	useEffect(() => {
		dispatch(getSchoolInfo({ programCode, programId }));
	}, []);

	useEffect(() => {
		let timedNotification;

		if (registerForProgramError && !registerForProgramInProgress) {
			timedNotification = setTimeout(() => setErrorNotification(false), 5000);
			setErrorNotification(true);
		} else if (registerForProgramInProgress) {
			clearTimeout(timedNotification);
			setErrorNotification(false);
		}
	}, [registerForProgramError, registerForProgramInProgress]);

	return (
		<>
			<Header />
			<article className="auth-register">
				<section className="auth-register__company">
					{/* for ILTB */}
					{/* <CompanyDecor1 className="decor decor--1"/> */}
					{/* <CompanyDecor2 className="decor decor--2"/> */}
					<SchoolInfoWithSpinner
						spinnerProps={{
							color: "#fbf8f6",
							loading: getSchoolInfoInProgress,
							size: 18,
							margin: 5,
						}}
						{...schoolInfo}
					/>
				</section>
				<section className="auth-register__form">
					<h2 className="auth-register__form-title">
						Registration
						<Notification show={isErrorNotification}>
							{registerForProgramError}
						</Notification>
					</h2>
					{/* fot ILTB */}
					{/* <RegFormDecor className="decor decor--3"/> */}
					<SignUpForm {...{ programCode, programId }} />
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Register;
