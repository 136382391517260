import Button from "components/button/Button";
import React from "react";
import styles from "../DecisionBox.module.scss";
import { customDecisionSelectStyles } from "helpers/customSelectStyles";
import Select, { components } from "react-select";
import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "constants/routes";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowSvg />
    </components.DropdownIndicator>
  );
};

const ChangeProgram = ({
  setDecisionsData,
  decisionBox,
  decisionsData,
  programTopicId,
}) => {
  const { avatar, name, role } =
    useSelector((state) => state.currentUserInfo.currentUserInfo) || {};
  const { id: programId } =
    useSelector((state) => state.userPrograms.singleProgram) || {};
  const { DECISION_FORM } = routes;
  const decision = decisionBox.find(
    (box) => box.decision?.type === "selectProgram"
  );

  const feedbackForm = decisionBox.find(
    (box) => box.decision?.type === "selectProgram"
  )?.form_template;

  let selectOptions = [];

  if (decision) {
    selectOptions.push({
      value: decision.decision_program?.id,
      label: decision.decision_program?.name,
    });
  }

  return (
    <>
      <div className={styles.selectTitle}>Change program</div>
      <div className={styles.select_wrap}>
        <Select
          options={selectOptions}
          clearable={false}
          value={decisionsData.selectProgram}
          placeholder={"Choose a program"}
          styles={customDecisionSelectStyles}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) =>
            setDecisionsData((prev) => ({ ...prev, selectProgram: e }))
          }
        />
      </div>
      <div className={styles.actions}>
        <Button
          className={`button button--orange-invert ${styles.button} decision`}
          onClick={() =>
            setDecisionsData((prev) => ({ ...prev, step: "selectDecision" }))
          }
        >
          Go back
        </Button>
        {feedbackForm ? (
          <Link
            to={{
              pathname: DECISION_FORM,
              state: {
                participant_program_id: programId,
                participant_program_topic_id: programTopicId,
                name,
                avatar,
                role,
                form: feedbackForm,
                decisionsData: {
                  id: decision.id,
                  decision_id: decision.decision.id,
                  decision_program_id: decisionsData.selectProgram.value,
                },
              },
            }}
            className={`button button--blue ${styles.button} decision`}
          >
            Fill out the form
          </Link>
        ) : (
          <Button
            type='button'
            className={`button button--orange-invert ${styles.button} decision`}
            disabled
          >
            Fill out the form
          </Button>
        )}
      </div>
    </>
  );
};

export default ChangeProgram;
