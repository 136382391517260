import React from 'react';

const SwitchArrowIcon = ({color = '#000000', width = 12, height = 8, rotate=0, ...props}) => {
    return (
        <svg width={width} height={height} transform={`rotate(${rotate})`} {...props} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.44966 0.536912L-2.61682e-07 5.98658L1.11409 7.07383L6 2.20134L10.8859 7.07383L12 5.98658L6.55033 0.536913L6 -2.62268e-07L5.44966 0.536912Z" fill={color}/>
        </svg>

    );
};

export default SwitchArrowIcon;