import React from 'react';

const Info = ({ show = false }) => {
    return (
        show && (
            <ul className='attend__list'>
                <li className='attend__list-item'>Confirmed</li>
                <li className='attend__list-item attend__list-item--completed'>Completed</li>
                <li className='attend__list-item attend__list-item--not-attended'>Not Attended</li>
            </ul>
        )
    );
};

export default Info;