import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import { useFormikContext } from "formik";
import DownArrowIcon from "components/icons/DownArrowIcon";
import { ReactComponent as SelectCloseSvg } from "assets/vector/select-close.svg";

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<DownArrowIcon color={"#101821"} />
		</components.DropdownIndicator>
	);
};

const CustomReportsFilterSelect = ({
	styles,
	isMobile,
	clearFilters,
	setClearFilters,
	className = "custom-select",
	field,
	options,
	placeholder,
	form: { touched, errors, setFieldValue },
}) => {
	const { submitForm } = useFormikContext();

	const selectInputRef = useRef();

	const classNameWithError = `${className}--error`;

	if (touched[field.name] && errors[field.name]) {
		className = `${className} ${classNameWithError}`;
	}

	const selectedFilters = useSelector(
		(state) => state.reports.selectedFilterOptions
	);

	const onChangeSelect = (optionValue) => {
		setFieldValue(
			field.name,
			optionValue?.id ? optionValue.id : optionValue?.key ? optionValue.key : ""
		);

		const focusedOption = selectInputRef.current?.state.focusedOption;
		const selectValue = selectInputRef.current?.state.selectValue;

		if (focusedOption || selectValue?.length) {
			submitForm();
		} else {
			if (!isMobile) {
				setFieldValue(field.name, "");
			}
		}
	};

	useEffect(() => {
		if (clearFilters && !isMobile) {
			selectInputRef.current.select?.clearValue();
			setClearFilters(false);
		}
	}, [clearFilters, setClearFilters, isMobile]);

	return (
		<>
			<Select
				ref={selectInputRef}
				components={{
					DropdownIndicator,
					ClearIndicator: ({ innerRef, innerProps }) => (
						<div ref={innerRef} {...innerProps}>
							<div className={`flex items-center justify-end h-full mr-1`}>
								<SelectCloseSvg className={`size-3`} />
							</div>
						</div>
					),
				}}
				name={field.name}
				getOptionLabel={(option) => option.name}
				getOptionValue={(option) => (option.id ? option.id : option.key)}
				options={options}
				className={className}
				placeholder={placeholder}
				classNamePrefix="sign-up-select"
				styles={styles}
				isSearchable={false}
				isClearable={true}
				defaultValue={
					isMobile && selectedFilters && selectedFilters[`${field.name}`]
						? options.filter((option) =>
								option.id
									? option.id === selectedFilters[`${field.name}`][0]
									: option.key ===
									  (field.name === "average_mark" ||
									  field.name === "average_evaluation" ||
									  field.name === "average_feedback" ||
									  field.name === "progress"
											? selectedFilters[`${field.name}`]
											: selectedFilters[`${field.name}`][0])
						  )
						: ""
				}
				onChange={(optionValue) => onChangeSelect(optionValue)}
			/>
			{touched[field.name] && errors[field.name] && (
				<div className="default-auth__error">{errors[field.name]}</div>
			)}
		</>
	);
};

export default CustomReportsFilterSelect;
