import React from 'react';
import PDFLogo from 'assets/vector/pdf-file.svg';
import downloadImage from 'assets/vector/download-invoice.svg';
import moment from 'moment';
import './GenerateInvoiceModal.scss';

const GenerateInvoiceModal = ({ closeModal, modalData }) => {

    const { invoices, changeActiveTab } = modalData;

    const checkOutAllInvoices = () => {
        changeActiveTab({
            label: 'Billing reports',
            name: 'invoices'
        });
        closeModal();
    }

    const downloadInvoice = (url) => {
        window.open(url)
    }

    return (
        <div className='generate-invoice-modal'>
            <div className="generate-invoice-modal__title">Generate billing report</div>
            <div className="generate-invoice-modal__invoices-list">
                {
                    invoices?.map(invoice =>
                        <div className="invoice-card" key={invoice.id}>
                            <div className="invoice-card__logo">
                                <img src={PDFLogo} alt="pdf-logo" />
                            </div>
                            <div className="invoice-card__content">
                                <div className="code">{invoice.code}</div>
                                <div className="date">{moment(invoice.updated_at).format('MMMM D, Y')}</div>
                                <div className="total">{invoice.total} {invoice.currency}</div>
                            </div>
                            <div className="invoice-card__btn" onClick={() => downloadInvoice(invoice.link)}>
                                <img src={downloadImage} alt="download" />
                                Download
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="generate-invoice-modal__btn" onClick={() => checkOutAllInvoices()}>Check out all Billing reports</div>
        </div>
    )
}

export default GenerateInvoiceModal;
