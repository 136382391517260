import { fork, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { programParticipantsList } from "../constants";
import {
	getProgramParticipantsListSuccess,
	getProgramParticipantsListEmpty,
	getProgramParticipantsListError,
} from "../actions/programParticipantsList";
import { COACH_PROGRAMS } from "constants/api";
import { generalApiErrorHandler } from "../sagas/helpers/generalApiErrorHandler";
import { showNotification } from "../actions";
import { getNotificationMessage } from "../../helpers/notifications";
import { getToken } from "../sagas/helpers/helpers";

function* watchGetProgramParticipantsList() {
	yield takeLatest(
		programParticipantsList.GET_LIST_PROGRAM_PARTICIPANTS,
		handleGetProgramParticipantsList
	);
}
function* handleGetProgramParticipantsList({ payload }) {
	try {
		const token = yield select(getToken);

		const currentUserData = yield axios({
			method: "get",
			url:
				COACH_PROGRAMS +
				`?${payload.search && `&search=${payload.search}`}${
					payload.functional_areas.length > 0
						? `&functional_areas=[${payload.functional_areas}]`
						: ""
				}${
					payload.industry_background.length > 0
						? `&industry_background=[${payload.industry_background}]`
						: ""
				}${
					payload.languages.length > 0
						? `&languages=[${payload.languages}]`
						: ""
				}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (currentUserData.length > 0) {
			yield put(getProgramParticipantsListSuccess(currentUserData));
		} else {
			yield put(getProgramParticipantsListEmpty(currentUserData));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getProgramParticipantsListError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchClearFiltersProgramParticipantsList() {
	yield takeLatest(
		programParticipantsList.CLEAR_PROGRAM_PARTICIPANTS_FILTERS,
		handleClearFiltersProgramParticipantsList
	);
}

function* handleClearFiltersProgramParticipantsList({ payload }) {
	try {
		const token = yield select(getToken);

		const getParticipantDataAfterResetFilters = yield axios({
			method: "get",
			url: COACH_PROGRAMS,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (getParticipantDataAfterResetFilters.length > 0) {
			yield put(
				getProgramParticipantsListSuccess(getParticipantDataAfterResetFilters)
			);
		} else {
			yield put(getProgramParticipantsListEmpty(null));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getProgramParticipantsListError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* participantsListSaga() {
	yield fork(watchGetProgramParticipantsList);
	yield fork(watchClearFiltersProgramParticipantsList);
}
