import React from 'react';

const LockIcon = ({color = '929292', width = 22, height = 32, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 22 32" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6284 10.6225C16.6542 11.0564 17.6029 11.6263 18.4633 12.3213V7.40894C18.4633 3.32391 15.1394 0 11.0544 0H10.9404C6.85906 0 3.53516 3.32391 3.53516 7.40894V12.3213C4.39555 11.6264 5.34419 11.0565 6.37004 10.6225C6.6458 10.5049 6.92892 10.3983 7.21204 10.3027V7.40894C7.21204 5.34988 8.88503 3.67689 10.9441 3.67689H11.0581C13.1171 3.67689 14.7901 5.34988 14.7901 7.40894V10.3063C15.0695 10.3983 15.3527 10.5049 15.6284 10.6225Z"
                fill={color}/>
            <path
                d="M0.57959 21.5793C0.57959 27.3336 5.24557 31.9995 10.9999 31.9995C16.7542 31.9995 21.4203 27.3372 21.4203 21.5793C21.4203 18.7517 20.2914 16.1853 18.464 14.3063C17.4345 13.2511 16.1844 12.4127 14.7871 11.8649C13.6142 11.4053 12.3346 11.1553 10.9999 11.1553C9.66523 11.1553 8.38567 11.4053 7.21274 11.8649C5.81552 12.4091 4.56538 13.2474 3.53585 14.3063C1.70472 16.1853 0.57959 18.7517 0.57959 21.5793ZM8.31574 19.4761C8.35251 18.0531 9.51073 16.8949 10.9337 16.8618C12.4449 16.825 13.684 18.0421 13.684 19.5459C13.684 19.7041 13.6693 19.8584 13.6436 20.0055C13.5111 20.7666 13.0589 21.4211 12.4264 21.8183C12.1654 21.9837 12.033 22.2925 12.0993 22.5904L12.8972 26.3739C12.945 26.6019 12.7722 26.8189 12.5368 26.8189H9.46297C9.22764 26.8189 9.05479 26.6056 9.10263 26.3739L9.90048 22.5904C9.96299 22.2889 9.83429 21.98 9.57327 21.8146C8.94085 21.4174 8.48859 20.7666 8.35618 20.0018C8.32677 19.8327 8.3121 19.6563 8.31574 19.4761Z"
                fill={color}/>
        </svg>

    );
};

export default LockIcon;