import React, { useEffect } from 'react';
import EditScheduleForm from 'components/forms/EditSchedule/EditScheduleForm';

import './EditScheduleModal.scss';

const EditScheduleModal = () => {

    useEffect(() => {
        const modalWrapper = document.querySelector('.modal__overlay');
        const modalClose = document.querySelector('.modal__close');

        if (modalWrapper) modalWrapper.classList.add('edit-schedule-wrapper')
        if (modalClose) modalClose.classList.add('edit-schedule-close')

        return () => {
            modalWrapper.classList.remove('edit-schedule-wrapper')
            modalClose.classList.remove('edit-schedule-close')
        }
    }, [])

    return (
        <div className='edit-schedule__section'>
            <div className='edit-schedule__title'>Schedule Availability</div>
            <EditScheduleForm />
        </div>
    )
}

export default EditScheduleModal
