import React from 'react';
import '../ReportsSorting/ReportsSorting.scss';
import ReportsCheckbox from '../ReportsCheckbox/ReportsCheckbox';
import ReportsSortingButtons from '../ReportsSortingButtons/ReportsSortingButtons';


const ReportsEngagementSorting = ({ userRole, reports, setOffsetReports }) => {

    return (
        <>
            <div className="reports-checkbox__wrapper">
                <ReportsCheckbox
                    label='Select all'
                    selectAll={true}
                    reports={reports}
                />
            </div>
            <tr className="reports-sorting engagement">
                <th className={`reports-sorting__cell reports-sorting__cell--checkbox`}>
                    <ReportsCheckbox selectAll={true} reports={reports} />
                </th>
                <th className="reports-sorting__cell reports-sorting__cell--num">
                    #
                </th>
                <th className="reports-sorting__cell reports-sorting__cell--user">
                    Participant
                </th>
                {
                    userRole === 'coach' && (
                        <th className="reports-sorting__cell reports-sorting__cell--institution">
                            Institution
                        </th>
                    )
                }
                <th className="reports-sorting__cell reports-sorting__cell--program">
                    Coaching programme
                </th>
                {
                    userRole === 'school_admin' &&
                    <>
                        <th className="reports-sorting__cell reports-sorting__cell--coach">
                            <ReportsSortingButtons setOffsetReports={setOffsetReports} sortingValue='main_coach' />
                            Coach
                        </th>
                        {/* <th className="reports-sorting__cell reports-sorting__cell--survey">
                            <ReportsSortingButtons setOffsetReports={setOffsetReports} sortingValue='survey_filled' />
                            Survey
                        </th> */}
                    </>
                }
                <th className="reports-sorting__cell reports-sorting__cell--sessions">
                    <ReportsSortingButtons setOffsetReports={setOffsetReports} sortingValue='first_session_completed_at' />
                    First session
                </th>
                <th className="reports-sorting__cell reports-sorting__cell--sessions">
                    <ReportsSortingButtons setOffsetReports={setOffsetReports} sortingValue='last_session_completed_at' />
                    Last session
                </th>
                <th className="reports-sorting__cell">
                    <ReportsSortingButtons setOffsetReports={setOffsetReports} sortingValue='sessions_completed' />
                    Sessions
                </th>
                <th className="reports-sorting__cell">
                    <ReportsSortingButtons setOffsetReports={setOffsetReports} sortingValue='average_mark' />
                    Average mark
                </th>
                <th className="reports-sorting__cell reports-sorting__cell--details"></th>
            </tr>
        </>
    );
};

export default ReportsEngagementSorting;