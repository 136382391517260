import React from "react";

import {ReactComponent as EmptyNotificationImageSvg} from "assets/vector/notification/empty-notification-image.svg";

export default function EmptyNotification() {

    return (
        <div className="empty-notification">

            <div className="empty-notification-image">
                <EmptyNotificationImageSvg />
            </div>

            <h3 className={`empty-notification-title`}>You don’t have any notifications yet...</h3>

        </div>
    )
}