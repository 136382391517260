import React from 'react';
import './GradeStats.scss';
import Grade from "components/pages/Dashboard/SchoolAdmin/Charts/Ratings/Satisfaction/GradesStats/Grade/Grade";

const GradeStats = ({ sessionStats = {} }) => {
    const questionsStats = Object.values(sessionStats);
    const allQuestions = questionsStats.map(data => data.questions).flat();
    const uniqueQuestions = allQuestions.reduce((questions, question) => {
        if (!questions.find(quest => quest.question === question.question)) {
            questions.push(question);
        }
        return questions;
    }, []);

    return (
        <div className="marks-stat">
            {
                uniqueQuestions.map(question => (
                    <div className="marks-stat__row" key={question.id}>
                        <p className="marks-stat__question">{question.question}</p>
                        <Grade grades={questionsStats} question={question} />
                    </div>
                ))
            }
        </div>
    );
};

export default GradeStats;