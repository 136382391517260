// for ILTB
// import emptyCoachesPageImage from "assets/vector/empty-pages/empty-coaches.svg";
import emptyCoachesPageImage from "assets/vector/empty-pages/empty-coaches-switch.svg";
// for ILTB
// import emptyParticipantsPageImage from "assets/vector/empty-pages/empty-participants.svg";
import emptyParticipantsPageImage from "assets/vector/empty-pages/empty-participants-switch.svg";
// for ILTB
// import emptyDashboardPageImage from "assets/vector/empty-pages/empty-dashboard.svg";
import emptyDashboardPageImage from "assets/vector/empty-pages/empty-dashboard-switch.svg";
// for ILTB
// import emptyProgramPageImage from "assets/vector/empty-pages/empty-programmes.svg";
import emptyProgramPageImage from "assets/vector/empty-pages/empty-programmes-switch.svg";

export const coachesPage = {
    image: emptyCoachesPageImage,
    title: "There are no active coaches yet...",
    subTitle: "As soon as participants are matched to their coaches, they will appear here."
};

export const participantsPage = {
    image: emptyParticipantsPageImage,
    title: "No participants have\njoined yet...",
    subTitle: "As soon as participants register, they will appear in this board."
};

export const dashboardCoachesPage = {
    image: emptyDashboardPageImage,
    title: "Welcome to Switch!",
    subTitle: "Here you will see an overall view of your participants' progress"
};

export const programmPage = (active = true) => {
    return {
        image: emptyProgramPageImage,
        title: `You don't have ${active ? 'active' : 'completed'} programmes yet`,
    }
};