import React from 'react';

const DownloadIcon = ({color = '#366B65', width = 16, height = 14, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 16 14" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M15 8C14.7348 8 14.4804 8.10536 14.2929 8.29289C14.1054 8.48043 14 8.73478 14 9V12H2V9C2 8.73478 1.89464 8.48043 1.70711 8.29289C1.51957 8.10536 1.26522 8 1 8C0.734784 8 0.48043 8.10536 0.292893 8.29289C0.105357 8.48043 0 8.73478 0 9L0 13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13V9C16 8.73478 15.8946 8.48043 15.7071 8.29289C15.5196 8.10536 15.2652 8 15 8Z"
                fill={color}/>
            <path d="M7.29298 9.707C7.3858 9.79989 7.49602 9.87357 7.61733 9.92384C7.73864 9.97412 7.86867 9.99999 7.99998 9.99999C8.1313 9.99999 8.26132 9.97412 8.38263 9.92384C8.50394 9.87357 8.61416 9.79989 8.70698 9.707L11.707 6.707C11.8025 6.61475 11.8787 6.50441 11.9311 6.3824C11.9835 6.2604 12.0111 6.12918 12.0122 5.9964C12.0134 5.86362 11.9881 5.73194 11.9378 5.60905C11.8875 5.48615 11.8133 5.3745 11.7194 5.2806C11.6255 5.18671 11.5138 5.11246 11.3909 5.06218C11.268 5.0119 11.1364 4.9866 11.0036 4.98775C10.8708 4.9889 10.7396 5.01649 10.6176 5.0689C10.4956 5.12131 10.3852 5.19749 10.293 5.293L8.99998 6.5859V1C8.99998 0.734784 8.89462 0.48043 8.70709 0.292893C8.51955 0.105357 8.2652 0 7.99998 0C7.73476 0 7.48041 0.105357 7.29287 0.292893C7.10534 0.48043 6.99998 0.734784 6.99998 1V6.5859L5.70698 5.293C5.51838 5.11084 5.26578 5.01005 5.00358 5.01233C4.74138 5.0146 4.49057 5.11977 4.30516 5.30518C4.11975 5.49059 4.01458 5.7414 4.01231 6.0036C4.01003 6.2658 4.11082 6.5184 4.29298 6.707L7.29298 9.707Z"
                fill={color}/>
        </svg>
    )
}

export default DownloadIcon;