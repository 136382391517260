import React from 'react';
import image from 'assets/vector/questionnaire/No_coach.svg';

import './MatchedCoaches.scss';

const MatchedCoaches = ({ data, participant_program_id, requestNewCoach, showMatchedCoaches }) => {

    return (
        <div className="coaches__container">
            {
                data?.matched_coaches?.length
                    ?
                    <div className="coaches__title">
                        <h1 className="title__text">Please choose a coach:</h1>
                        <p>Based on your responses, here are the coaches we think best suit you</p>
                    </div>
                    :
                    data?.manual_suggested_coaches?.length
                        ?
                        <div className="coaches__title">
                            <h1 className="title__text">Please choose a coach:</h1>
                        </div>
                        :
                        <div className="no-coach__wrapper">
                            <div className="image__wrapper">
                                <img src={image} alt="" />
                            </div>
                            <div className='text__content'>
                                <div className="title">We haven't found an immediate match!</div>
                                <div className="description">No results based on your preferences. But here are some coaches who we think might be a good fit for you.</div>
                            </div>
                        </div>
            }
            <div className="coaches__wrapper">
                {showMatchedCoaches(data?.manual_suggested_coaches?.length
                    ? data.manual_suggested_coaches : data.matched_coaches?.length
                        ? data.matched_coaches : data.suggested_coaches)}
            </div>
            <div className="another-coach">
                {/* for ILTB */}
                {/* <div className="another-coach__image">
                        <img src={image} alt="" />
                    </div> */}
                <div className="another-coach__description">
                    <div className="description__title">None of the coaches matched your expectations?</div>
                    <p>Contact us and we will be in touch soon</p>
                    {participant_program_id &&
                        <button className="another-coach__btn" onClick={() => { requestNewCoach(participant_program_id) }}>Find another coach </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default MatchedCoaches;