import { put } from "redux-saga/effects";
import { loginUserSuccess } from "../../actions";

export function* saveUserCredentials(authData = {}) {
	localStorage.setItem("user_token", authData.token);
	localStorage.setItem("survey", JSON.stringify(authData.survey));
	if (authData.school_id != null) {
		localStorage.setItem("school_id", authData.school_id);
	}

	yield put(loginUserSuccess(authData));
}
