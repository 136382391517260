import React, {useState, useEffect} from 'react';
import './ReportsMobileMenu.scss';
import ReportsFilter from "components/pages/Reports/ReportsFilter/ReportsFilter";
import CrossIcon from "components/icons/CrossIcon";
import ReportsMobileTabs from "components/pages/Reports/ReportsTabs/ReportsMobileTabs";
import ReportsMobileSorting from "components/pages/Reports/ReportsMobileSorting/ReportsMobileSorting";
import EngagementReportsFilter from '../EngagementReportsFilter/EngagementReportsFilter';

const ReportsMobileMenu = ({
    setAllFiltersVisible,
    activeTab,
    isAllFiltersVisible,
    filterOptions,
    engagementFilterOptions,
    setClearFilters,
    clearFilters,
    userRole,
    setOffsetReports
}) => {
    const [activeTabContent, setActiveTabContent] = useState(1);

    useEffect(() => {

        setClearFilters(true);
       
    }, [setClearFilters])

    return (
        <div className="reports-menu">
            <div className="reports-menu__head">
                <h3 className="reports-menu__title">Filters</h3>
                <CrossIcon color="#101821" className="reports-menu__close" onClick={() => {setAllFiltersVisible(false);}}/>
            </div>
            <ReportsMobileTabs
                setActiveTabContent={setActiveTabContent}
                setClearFilters={setClearFilters}/>
            {
                activeTabContent === 1 ?
                    (activeTab.name !== 'engagement'
                        ? <ReportsFilter
                            isMobile={true}
                            isAllFiltersVisible={isAllFiltersVisible}
                            setAllFiltersVisible={setAllFiltersVisible}
                            clearFilters={clearFilters}
                            setClearFilters={setClearFilters}
                            filterOptions={filterOptions}
                            activeTab={activeTab}
                            setOffsetReports={setOffsetReports}
                        />
                        : <EngagementReportsFilter
                            isMobile={true}
                            clearFilters={clearFilters}
                            setClearFilters={setClearFilters}
                            isAllFiltersVisible={isAllFiltersVisible}
                            setAllFiltersVisible={setAllFiltersVisible}
                            filterOptions={engagementFilterOptions}
                            activeTab={activeTab}
                            setOffsetReports={setOffsetReports}
                        />
                    )
                    :
                    <ReportsMobileSorting
                        setAllFiltersVisible={setAllFiltersVisible}
                        activeTab={activeTab}
                        userRole={userRole}
                        setOffsetReports={setOffsetReports}
                    />
            }

        </div>
    );
}

export default ReportsMobileMenu;