import {
	HIDE_TIMEZONE_NOTIFICATION,
	SHOW_TIMEZONE_NOTIFICATION,
	UPDATE_TIMEZONE_NOTIFICATION_STATUS_FROM_STORAGE,
} from "../constants";

const initialState = {
	show: "false",
};

export default function timezoneNotificationReducer(
	state = initialState,
	action
) {
	const { type, payload } = action;

	switch (type) {
		case SHOW_TIMEZONE_NOTIFICATION:
			return {
				...state,
				show: "true",
			};
		case HIDE_TIMEZONE_NOTIFICATION:
			return {
				...state,
				show: "false",
			};
		case UPDATE_TIMEZONE_NOTIFICATION_STATUS_FROM_STORAGE:
			return {
				...state,
				show: payload,
			};
		default:
			return state;
	}
}
