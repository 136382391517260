import * as Yup from "yup";

export const participantValidationScheme = Yup.object().shape({
    firstName: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .required('Required'),
    lastName: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .required('Required'),
    avatar: Yup.mixed()
        .test("fileSize", "File too large", (value) => {
            if (value) {
                if (typeof value === "string") {
                    return true;
                } else if (value.avatarSizeIsReadyToUpload !== undefined) {
                    return value.avatarSizeIsReadyToUpload;
                }
            }
        })
        .test('fileType', 'Unsupported file type. Only .jpg, .jpeg, .png or .svg.', (value) => {
            if (value) {
                if (typeof value === "string") {
                    return true;
                } else if (value.avatarFormatIsOk !== undefined) {
                    return value.avatarFormatIsOk;
                }
            }
        }),
    cvFile: Yup.mixed()
        .test(
            'fileType',
            'Unsupported file type. Only PDF.',
            (value) => {
                if (typeof value === "string") {
                    return true;
                } else if (value != null) {
                    return value.cvFormatIsOk;
                } else {
                    return true;
                }
            }
        ),
    work_experience: Yup.array()
        .of(Yup.object({
            company: Yup.string().min(3, 'Must be 3 characters or more').required('Required'),
            position: Yup.string().min(3, 'Must be 3 characters or more').required('Required'),
            responsibilities: Yup.string().min(3, 'Must be 3 characters or more').required('Required'),
            date_from: Yup.date().nullable(true).required('Required'),
        })),
    languages: Yup.array()
        .of(Yup.object({
            level: Yup.mixed().required('Required'),
            id: Yup.mixed().required('Required'),
        })),
    goal: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .max(2000, 'The goal must not be greater than 2000 characters.')
});