import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import routes from "constants/routes";

const { NOT_FOUND } = routes;

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const accessToken = useSelector((state) => state.auth.authToken);
    const coachesExist = useSelector(state => state.coachesList.coachesExist);
    const getCurrentUserInfo = useSelector(state => state.currentUserInfo.currentUserInfo);

    const location = useLocation();

    if (getCurrentUserInfo && getCurrentUserInfo.role === 'school_admin' && rest.logic && !coachesExist) {
        return <Redirect to={NOT_FOUND} />;
    }

    return <Route
        {...rest}
        render={props => accessToken
            ? <Component {...props} />
            : <Redirect to={{
                pathname: "/login",
                state: { pathname: location.pathname }
            }} />} />;
};