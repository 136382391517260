import React from 'react';

const DownArrowIcon = ({ color = '#101821', width = 9, height = 6, ...props }) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M11.15 0C12.0053 0 12.257 0.537541 11.7126 1.20013L6.3049 7.78183C6.06314 8.07607 5.67631 8.0725 5.43963 7.77226L0.27453 1.22015C-0.256689 0.546279 0.00650222 0 0.861289 0H11.15Z"
                fill={color} />
        </svg>
    );
};

export default DownArrowIcon;