import React from 'react'
import './Tooltip.scss';

const Tooltip = ({
    show = false,
    // title,
    // number = 0,
    // percent = 0,
    position = {}, data }) => {

    const { completed,
        completed_percent,
        not_attended,
        not_attended_percent,
        planned,
        planned_percent } = data;

    return (
        show && (
            <div className="tooltip" style={{ ...position }}>
                {
                    planned > 0 &&
                    <div className="tooltip__item">
                        <div className="item__title">
                            <span className='type planned'></span>
                            Confirmed
                        </div>
                        <div className="item__values">
                            <p className='tooltip__text'>{planned} <span>({planned_percent}%)</span></p>
                        </div>
                    </div>
                }
                {
                    not_attended > 0 &&
                    <div className="tooltip__item">
                        <div className="item__title">
                            <span className='type not_attended'></span>
                            Not Attended
                        </div>
                        <div className="item__values">
                            <p className='tooltip__text'>{not_attended} <span>({not_attended_percent}%)</span></p>
                        </div>
                    </div>
                }
                {
                    completed > 0 &&
                    <div className="tooltip__item">
                        <div className="item__title">
                            <span className='type completed'></span>
                            Completed
                        </div>
                        <div className="item__values">
                            <p className='tooltip__text'>{completed} <span>({completed_percent}%)</span></p>
                        </div>
                    </div>
                }
                {/* <h6 className='tooltip__title'>{title}</h6>
                <p className='tooltip__text'>{number} ({percent}%)</p> */}
            </div>
        )
    );
};

export default Tooltip;