import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field} from "formik";
import defaultAva from "assets/vector/default-avatar-light.svg";
import Avatar from "components/pages/EditMyProfile/PersonalInfo/Avatar/Avatar";
import CvFile from "components/pages/EditMyProfile/PersonalInfo/CvFile/CvFile";

const PersonalInfo = ({errors, touched, userInfo, setFieldValue, handleBlur}) => {
    const [checkUploadCVFile, setCheckUploadCVFile] = useState(false);
    const [avatar, setAvatar] = useState('');

    useEffect(() => {
        if (userInfo) {
            if (userInfo.cv_url) {
                setCheckUploadCVFile(true);
            } else {
                setCheckUploadCVFile(false);
            }

            setAvatar(userInfo.avatar || defaultAva)
        }
    }, [userInfo]);

    return (
        <div className="section-info-block personal-information">
            <h3 className={`section-title`}>Personal information</h3>
            <Avatar avatar={avatar}
                    setAvatar={setAvatar}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
            />
            <CvFile setFieldValue={setFieldValue}
                    touched={touched}
                    handleBlur={handleBlur}
                    errors={errors}
                    checkUploadCVFile={checkUploadCVFile}
                    setCheckUploadCVFile={setCheckUploadCVFile}
            />
            <div className={`profile-row first-name ${errors.firstName && touched.firstName ? 'input-error' : ''}`}>
                <label htmlFor={'firstName'} className="profile-row__label">First name</label>
                <Field name="firstName"
                       type="text"
                       placeholder="First name"
                       className={`profile-row__input`}
                />
                <ErrorMessage className={`profile-row__error`} component="div" name="firstName"/>
            </div>
            <div className={`profile-row last-name ${errors.lastName && touched.lastName ? 'input-error' : ''}`}>
                <label htmlFor={'lastName'} className="profile-row__label">Last name</label>
                <Field name="lastName"
                       type="text"
                       placeholder="First name"
                       className={`profile-row__input`}
                />
                <ErrorMessage className={`profile-row__error`} component="div" name="lastName"/>
            </div>
            <div className={`profile-row location`}>
                <label htmlFor={'location'} className="profile-row__label">Location</label>
                <Field name="location"
                       type="text"
                       placeholder="Location"
                       className={`profile-row__input`}
                />
            </div>
        </div>
    );
};

export default PersonalInfo;