import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Participants.scss";
import pageTitles from "constants/pageTitles";
import { useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import {
	updatePageTitle,
	getInfoAboutParticipant,
	clearAboutParticipantInfo,
} from "../../../redux/actions";
import routes from "constants/routes";

import Spinner from "components/Spinner/Spinner";
import backToParticipants from "assets/vector/arrow-back-to-coaches-switch.svg";

import defaultAva from "assets/vector/default-avatar.svg";
import DownloadReportButton from "components/DownloadReportButton/DownloadReportButton";
import { transformedTimezoneForMoment } from "helpers/transformedTimezoneForMoment";

const { PARTICIPANTS, PROGRAMMES } = routes;

export default function ParticipantProfile() {
	const { PARTICIPANTS_TITLE } = pageTitles;
	const { state } = useLocation();
	let { profileId } = useParams();
	const dispatch = useDispatch();

	const currentUserInfo = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const getParticipantInfo = useSelector(
		(state) => state.participant.participantInfo
	);
	const showParticipantInfo = useSelector(
		(state) => state.participant.showParticipantInfo
	);

	const userTimeZone = (timezone) => {
		return moment.tz(transformedTimezoneForMoment(timezone)).format("HH:mm");
	};
	const userTimeZoneUTC = (timezone) => {
		return moment.tz(transformedTimezoneForMoment(timezone)).format("Z");
	};

	let programToGoBack = PARTICIPANTS;

	if (state?.program) {
		programToGoBack = `${PROGRAMMES}/single-program/${state.program}`;
	}

	useEffect(() => {
		dispatch(getInfoAboutParticipant(profileId));
		dispatch(updatePageTitle(PARTICIPANTS_TITLE));

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);

	const backToParticipantsFunction = () => {
		dispatch(clearAboutParticipantInfo());
	};

	return (
		<section className="section-wrap">
			{!showParticipantInfo && <Spinner />}
			{getParticipantInfo && (
				<div className="participant-profile">
					<div className="short-info">
						<div className="back-to-all-participants">
							<Link
								className="link-to-page"
								to={programToGoBack}
								onClick={backToParticipantsFunction}
							>
								<img src={backToParticipants} alt="back to participants" />
							</Link>
						</div>
						<div className="participant-avatar">
							<img
								src={
									getParticipantInfo.avatar
										? getParticipantInfo.avatar
										: defaultAva
								}
								alt=""
							/>
						</div>
						<div className="participant-short-info">
							<h3 className={`name`}>
								{getParticipantInfo.name} {getParticipantInfo.surname}
							</h3>
							<p className={`country`}>
								{getParticipantInfo.location}
								{currentUserInfo?.role === "coach" && (
									<>
										<span
											className="utc"
											style={{
												marginLeft: getParticipantInfo.location ? 15 : 0,
											}}
										>
											UTC {userTimeZoneUTC(getParticipantInfo?.timezone)}
										</span>
										<span className="time">
											{userTimeZone(getParticipantInfo?.timezone)}
										</span>
									</>
								)}
							</p>
							<p className={`program`}>{getParticipantInfo.school}</p>
						</div>
						<div className="participant-full-info__wrapper">
							{getParticipantInfo.cv_url && (
								<div className="participant-full-info">
									<a
										target="_blank"
										rel="noreferrer"
										href={getParticipantInfo.cv_url}
										className="view-profile"
									>
										Download CV
									</a>
								</div>
							)}
							{
								// 1 - id of the SDA Bocconi questionnaire
								getParticipantInfo.survey?.all_filled?.find(
									(item) => item.id === 1
								) && (
									<DownloadReportButton participantId={getParticipantInfo.id} />
								)
							}
						</div>
					</div>
					<div className="full-info-wrap">
						<div className="full-info">
							{getParticipantInfo.languages.length > 0 && (
								<div className="section-info-block languages">
									<h3 className="block-title">Languages</h3>
									<div className="languages-block">
										{getParticipantInfo.languages.map((element, index) => (
											<div key={index} className="language">
												<img
													className={`flag`}
													src={element.flag}
													alt="language flag"
												/>
												<div className="language">{element.name}</div>
												<div className="experience">{element.level.name}</div>
											</div>
										))}
									</div>
								</div>
							)}
							{getParticipantInfo.functional_areas.length > 0 && (
								<div className="section-info-block functional-area">
									<h3 className="block-title">Functional Area</h3>
									<div className="block-items">
										{getParticipantInfo.functional_areas.map(
											(element, index) => (
												<span key={index} className="single-item">
													{element.name}
												</span>
											)
										)}
									</div>
								</div>
							)}
							{getParticipantInfo.seniority_level_id && (
								<div className="section-info-block seniority-level">
									<h3 className="block-title">Seniority Level</h3>

									<div className="block-items">
										<span className="single-item">
											{getParticipantInfo.seniority_level_id.name}
										</span>
									</div>
								</div>
							)}
							{getParticipantInfo.industry_background &&
								getParticipantInfo.industry_background.length > 0 && (
									<div className="section-info-block seniority-level">
										<h3 className="block-title">Expertise</h3>
										<div className="block-items">
											{getParticipantInfo.industry_background.map(
												(element, index) => (
													<span key={index} className="single-item">
														{element.name}
													</span>
												)
											)}
										</div>
									</div>
								)}
							{getParticipantInfo.work_experience &&
								getParticipantInfo.work_experience.length > 0 && (
									<div className="section-info-block work-experience">
										<h3 className="block-title">Work experience</h3>
										<div className="work-experience-block">
											{getParticipantInfo.work_experience.map(
												(element, index) => (
													<div key={index} className="experience-item">
														<div className="experience-item-left">
															<div className="position">{element.position}</div>
															<div className="company">{element.company}</div>
														</div>

														<div className="experience-item-right">
															<div className="date">
																<span className="from">
																	{moment(element.date_from).format(
																		"MMMM YYYY"
																	)}
																</span>{" "}
																-{" "}
																<span className="to">
																	{moment(element.date_to).format("MMMM YYYY")}
																</span>
															</div>
															<div className="job-responsibilities">
																{element.responsibilities}
															</div>
														</div>
													</div>
												)
											)}
										</div>
									</div>
								)}
							{getParticipantInfo.goal && (
								<div className="section-info-block goal">
									<h3 className="block-title">Goal</h3>
									<div className="text">{getParticipantInfo.goal}</div>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</section>
	);
}
