import React from 'react';

const FileIcon = ({color = '#486EA0', width = 28, height = 32, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.3316 3.62207H24.3959V26.6096C24.3959 28.1367 23.1528 29.3798 21.6257 29.3798H12.1453H4.60645V30.2319C4.60645 31.2077 5.39843 31.9996 6.3742 31.9996H15.8512H25.3283C26.304 31.9996 27.096 31.2077 27.096 30.2319V5.38982C27.096 4.41405 26.3074 3.62207 25.3316 3.62207Z" fill={color}/>
            <path d="M12.1453 28.3776H21.6223C22.5981 28.3776 23.39 27.5856 23.39 26.6099V3.62239V1.76775C23.39 0.79198 22.5981 0 21.6223 0H12.1453H8.99404V0.584795C9.00069 0.644946 9.00407 0.708438 9.00407 0.77193V4.6249V5.61404C9.00407 6.98747 7.88794 8.10359 6.51451 8.10359H5.52537H1.6724C1.62227 8.10359 1.56881 8.10025 1.51868 8.09691H0.903809V26.6099C0.903809 27.5856 1.69579 28.3776 2.67156 28.3776H4.60974H12.1453ZM12.5028 22.3693H6.32403C5.86288 22.3693 5.48861 21.995 5.48861 21.5338C5.48861 21.0726 5.86288 20.6984 6.32403 20.6984H12.5062C12.9673 20.6984 13.3416 21.0726 13.3416 21.5338C13.3416 21.995 12.964 22.3693 12.5028 22.3693ZM18.6849 18.1053H6.32403C5.86288 18.1053 5.48861 17.731 5.48861 17.2699C5.48861 16.8087 5.86288 16.4345 6.32403 16.4345H18.6849C19.1461 16.4345 19.5204 16.8087 19.5204 17.2699C19.5204 17.731 19.1461 18.1053 18.6849 18.1053ZM6.32403 11.9064H18.6849C19.1461 11.9064 19.5204 12.2807 19.5204 12.7419C19.5204 13.203 19.1461 13.5773 18.6849 13.5773H6.32403C5.86288 13.5773 5.48861 13.203 5.48861 12.7419C5.48861 12.2807 5.86288 11.9064 6.32403 11.9064Z" fill={color}/>
            <path d="M1.6723 7.10072H6.51441C6.51775 7.10072 6.52443 7.10072 6.52777 7.10072C7.33646 7.09404 7.99143 6.43906 7.99812 5.63038C7.99812 5.62703 7.99812 5.62035 7.99812 5.61701V0.771563C7.99812 0.323777 7.63053 0.0263672 7.24961 0.0263672C7.06582 0.0263672 6.88202 0.0932009 6.72831 0.246919L1.14431 5.83088C0.676471 6.29871 1.0073 7.10072 1.6723 7.10072Z" fill={color}/>
        </svg>
    );
};

export default FileIcon;