import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import "../EventSession/EventSessionForm.scss";
import Button from "components/button/Button";
import EventSessionInput from "components/forms/EventSession/EventSessionInput";
import CustomEventSessionFormSelect from "components/CustomFormSelect/CustomEventSessionFormSelect";
import InvitedParticipants from "components/modal/EventSessionModal/InvitedParticipants/InvitedParticipants";
import DownArrowIcon from "components/icons/DownArrowIcon";
import EventSessionChooseTime from "components/forms/EventSession/EventSessionChooseTime/EventSessionChooseTime";
import { customSelectStyles as selectStyles } from "helpers/customSelectStyles";
import { useDispatch, useSelector } from "react-redux";
import {
	clearSessionInfo,
	createSession,
} from "../../../redux/actions/createSession";
import Notification from "components/auth/notification/Notification";
import { transformDatePickerDate } from "helpers/userPrograms";
import { isPrevDateChosen, transformDateAndTime } from "helpers/createSession";
import { sessionStatuses } from "constants/static";

const CreateSessionForm = ({
	toggleInvitationList,
	closeModal,
	showDatePicker,
	selectedParticipants,
	removeFromSelectedParticipants,
	selectedDate,
	time,
	setTime,
	setSessionLink,
	sessionLink,
	setSessionDetails,
	sessionDetails,
	currentSessionDetails,
	setCurrentSessionDetails,
	currentActivity,
	setCurrentActivity,
	defaultSessionDetails,
	coachId,
}) => {
	const sessionType = "individual";
	const dispatch = useDispatch();
	const {
		role: userRole,
		id: userId,
		timezone,
	} = useSelector((state) => state.currentUserInfo.currentUserInfo);
	const {
		createSessionProgress,
		createSessionError,
		createSessionSuccess,
		sessionFilter: { defaultData, updatedData },
	} = useSelector((state) => state.createSession);
	const isCoach = userRole === "coach";
	const [isTimeBooked, setIsTimeBooked] = useState(false);
	const [groupName, setGroupName] = useState("");
	const transformedSelectedDate = transformDateAndTime(
		selectedDate,
		time,
		timezone
	);
	const isPrevDate = isPrevDateChosen(transformedSelectedDate, timezone);

	const validationSchema = {
		name: Yup.string().required("Required"),
		type: Yup.string().required("Required"),
		date: Yup.string().nullable().required("Choose date"),
		time: Yup.string().nullable().required("Choose time"),
		link: Yup.string().url("Invalid url"),
		details: Yup.string().trim(),
		program_id: Yup.string().required("Required"),
		participant_program_id: Yup.string().required("Required"),
		participant_program_topic_id: Yup.string().required("Required"),
		participant_activity_id: Yup.string().required("Required"),
	};

	if (isCoach && isPrevDate) {
		validationSchema["status"] = Yup.string().required("Required");
	}

	const closeEventSessionModal = () => {
		closeModal();
		dispatch(clearSessionInfo());
	};

	useEffect(() => {
		if (createSessionSuccess) {
			closeEventSessionModal();
		}
	}, [createSessionSuccess]);

	const handleGroupName = (e) => setGroupName(e.target.value);
	const handleSessionLink = (e) => setSessionLink(e.target.value);
	const handleSessionDetails = (e) => setSessionDetails(e.target.value);

	const filterNotCompletedPrevSteps = (options = []) =>
		options.filter((option) => !option.uncompleted_previous_step);

	const handleProgramSelect = (value = {}) => {
		setCurrentSessionDetails({
			...defaultSessionDetails,
			school_program: value,
			programChanged: !!currentSessionDetails.school_program,
		});

		setCurrentActivity(null);
		setGroupName("");
	};

	const handleTopicSelect = (value = {}) => {
		setCurrentSessionDetails({
			...currentSessionDetails,
			topic: value,
		});
	};

	const setSessionStatus = (value) => {
		setCurrentSessionDetails({
			...currentSessionDetails,
			status: value,
		});
	};

	const getDefaultGroupName = (activities) =>
		activities?.find((activity) => activity.id === currentActivity?.id);

	const getUsersIds = (userType) => {
		if (userType === userRole) {
			return [userId];
		}

		return selectedParticipants?.map((member) => member.id);
	};

	const getPlaceholderText = (type) => {
		switch (type) {
			case "program":
				return defaultData.school_programs?.length
					? "Choose the program..."
					: "No programs available";
			case "module":
				if (isCoach) {
					return selectedParticipants.length
						? "Choose the module..."
						: "Choose members first...";
				}
				return currentSessionDetails.school_program
					? "Choose the module..."
					: "Choose the program first...";
			case "activity":
				return currentSessionDetails.topic
					? "Choose the activity..."
					: "Choose module first...";
		}
	};

	useEffect(() => {
		if (currentActivity) {
			const groupName = getDefaultGroupName(updatedData.activities).name;

			setGroupName(groupName);
		}
	}, [currentActivity]);

	// console.log('currentSessionDetails', currentSessionDetails)

	return (
		<Formik
			initialValues={{
				name: groupName,
				type: sessionType,
				link: sessionLink,
				details: sessionDetails,
				date: selectedDate,
				time: time,
				start: transformedSelectedDate,
				status: currentSessionDetails.status?.value,
				program_id: currentSessionDetails.school_program?.id,
				participant_program_id:
					currentSessionDetails.topic?.participant_program_id,
				participant_program_topic_id: currentSessionDetails.topic?.id,
				participant_activity_id: currentActivity?.id,
				coaches: getUsersIds("coach"),
				participants: getUsersIds("participant"),
			}}
			enableReinitialize={true}
			validationSchema={Yup.object(validationSchema)}
			onSubmit={(values) => {
				const { date, time, ...requestParams } = values;

				dispatch(createSession(requestParams));
			}}
		>
			<Form className="event-form" autoComplete="off" noValidate={true}>
				<Field
					name="program_id"
					label="Program"
					options={defaultData.school_programs}
					isDisabled={!defaultData.school_programs?.length}
					value={currentSessionDetails.school_program}
					handleChange={handleProgramSelect}
					noOptionsMessage={`There are no available programs where you can schedule a session.`}
					placeholder={getPlaceholderText("program")}
					component={CustomEventSessionFormSelect}
					className="sign-up__select"
					styles={selectStyles}
				/>
				{isCoach ? (
					selectedParticipants.length ? (
						<InvitedParticipants
							participants={selectedParticipants}
							removeParticipant={removeFromSelectedParticipants}
							sessionTime={{ time, timezone, date: selectedDate }}
						/>
					) : (
						<div className="event-form__add-actions">
							<button
								type="button"
								className="event-form__add-participant"
								disabled={!currentSessionDetails.school_program}
								onClick={toggleInvitationList}
							>
								+ Add participant(s)
							</button>
						</div>
					)
				) : (
					selectedParticipants.length > 0 && (
						<InvitedParticipants
							participants={selectedParticipants}
							sessionTime={{ time, timezone, date: selectedDate }}
						/>
					)
				)}
				<Field
					name="participant_program_topic_id"
					label="Module"
					options={updatedData.topics}
					value={currentSessionDetails.topic}
					handleChange={handleTopicSelect}
					placeholder={getPlaceholderText("module")}
					noOptionsMessage={`There are no available modules within this program where you can schedule a session.`}
					component={CustomEventSessionFormSelect}
					className="sign-up__select"
					styles={selectStyles}
					isDisabled={!selectedParticipants.length}
				/>
				<Field
					name="participant_activity_id"
					label="Activity"
					options={filterNotCompletedPrevSteps(updatedData.activities)}
					value={currentActivity}
					handleChange={setCurrentActivity}
					placeholder={getPlaceholderText("activity")}
					noOptionsMessage={`There are no available activities within this program where you can schedule a session.`}
					component={CustomEventSessionFormSelect}
					className="sign-up__select"
					styles={selectStyles}
					isDisabled={!currentSessionDetails.topic}
				/>
				<Field
					name="name"
					type="text"
					label="Session name"
					value={groupName}
					disabled={userRole === "participant"}
					onChange={handleGroupName}
					placeholder="Enter session name..."
					className="event-form__input"
					component={EventSessionInput}
				/>
				<Field
					name="type"
					label="Type"
					value="Individual session"
					component={EventSessionInput}
					disabled={true}
					className="event-form__input"
				/>
				<div className="event-form__input-group">
					<div className="choose-time">
						<span className="event-form__label">Choose date</span>
						<button
							type="button"
							className="choose-time__open-picker"
							disabled={!currentActivity}
							onClick={showDatePicker}
						>
							<span
								className={`choose-time__placeholder ${
									selectedDate ? "choose-time__placeholder--active" : null
								}`}
							>
								{selectedDate
									? transformDatePickerDate(selectedDate)
									: currentActivity
									? `Choose date`
									: "Choose activity first"}
							</span>
							<DownArrowIcon className="choose-time__icon" />
						</button>
					</div>
					<ErrorMessage
						name="date"
						render={(error) => <div className="event-form__error">{error}</div>}
					/>
					<EventSessionChooseTime
						time={time}
						date={selectedDate}
						setTime={setTime}
						isDisabled={!currentActivity}
						isTimeBooked={isTimeBooked}
						setIsTimeBooked={setIsTimeBooked}
						duration={currentActivity?.duration}
						coachId={coachId}
					/>
					<ErrorMessage
						name="time"
						render={(error) => <div className="event-form__error">{error}</div>}
					/>
				</div>
				{isCoach && isPrevDate && (
					<Field
						name="status"
						label="Status"
						options={sessionStatuses}
						value={currentSessionDetails.status}
						handleChange={setSessionStatus}
						noOptionsMessage={`There is no available status.`}
						placeholder={"Choose status..."}
						component={CustomEventSessionFormSelect}
						className="sign-up__select"
						styles={selectStyles}
					/>
				)}
				<Field
					name="link"
					type="url"
					label="Link"
					value={sessionLink}
					labelTip="Optional"
					onChange={handleSessionLink}
					placeholder="Add zoom link..."
					className="event-form__input"
					component={EventSessionInput}
				/>
				<Field
					name="details"
					type="text"
					label="Session details"
					value={sessionDetails}
					labelTip="Optional"
					onChange={handleSessionDetails}
					placeholder="Add session details..."
					className="event-form__input"
					component={EventSessionInput}
				/>
				<Notification show={createSessionError}>
					{createSessionError}
				</Notification>
				<div className="event-form__actions">
					{/* for ILTB */}
					{/* <Button type="submit"
                            className="button button--orange event-form__submit"
                            loading={createSessionProgress}>
                        Save
                    </Button> */}
					<Button
						type="submit"
						spinner={{ color: "#4285f4" }}
						disabled={isTimeBooked}
						className="button button--blue event-form__submit"
						loading={createSessionProgress}
					>
						Save
					</Button>
					<Button
						type="button"
						className="button button--orange-invert event-form__cancel"
						onClick={closeEventSessionModal}
						loading={false}
					>
						Cancel
					</Button>
				</div>
			</Form>
		</Formik>
	);
};

export default CreateSessionForm;
