import React from 'react';
import companyLogo from "assets/vector/auth-page/SDA_logo.svg";

const SchoolInfo = ({ school_logo, title, school_name, description }) => {
    return (
        <>
            <img src={school_logo ? school_logo : companyLogo}
                className="auth-register__company-logo"
                alt="company logo" />
            <h1 className="auth-register__company-title">{title}</h1>
            <h2 className="auth-register__company-name">{school_name}</h2>
            <div className="auth-register__company-text"
                dangerouslySetInnerHTML={{ __html: description }} />
        </>
    );
};

export default SchoolInfo;