import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import "./programmeCard.scss";
import defaultImage from 'assets/images/Program/default-image.png';

const CoachProgrammeCard = ({ logo_url, name, id, completion_percentage, grouped_completion_percentage, total_session_activities, total_topics, is_deactivated, progress = 0 }) => {

    const progressStyles = {
        width: `${grouped_completion_percentage ? grouped_completion_percentage : completion_percentage}%`
    }

    const match = useRouteMatch();

    return (
        <Link
            to={{
                pathname: `${match.url}/participants-list/${id}`,
                state: { is_deactivated: is_deactivated }
            }}
            className={`programme-card ${is_deactivated ? 'deactivated' : ''}`}>

            <img src={logo_url ? logo_url : defaultImage} alt="programme-card" className="programme-card__img" />

            <div className="programme-card__content">
                <div className="programme-card__title"><span>{name}</span> {is_deactivated && <div className='deactivated-label'>Deactivated</div>}</div>
                <ul className="programme-card__info">
                    <li className="programme-card__info-item">{total_topics} Topics</li>
                    <li className="programme-card__info-item">{total_session_activities} Session activities</li>
                </ul>
                <div className="programme-card__progress">
                    <div className="programme-card__progress-bar">
                        <div style={progressStyles} className="programme-card__progress-fill"></div>
                    </div>
                    <span
                        className="programme-card__progress-num" >{grouped_completion_percentage ? grouped_completion_percentage : completion_percentage}% Completed</span>
                </div>
            </div>
        </Link>
    );
};

export default CoachProgrammeCard;