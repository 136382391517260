import React, { useEffect } from "react";
import { ReactComponent as Image } from "assets/vector/programmes/cancel-session.svg";
import Button from "components/button/Button";
import Notification from "components/auth/notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import {
	cancelGoogleAuth,
	cancelGoogleAuthClearError,
} from "../../../redux/actions";
import "./GoogleCancelAuthModal.scss";

const GoogleCancelAuthModal = ({ title, description, closeModal }) => {
	const dispatch = useDispatch();

	const { cancelGoogleAuthRequest, cancelGoogleAuthError } = useSelector(
		(state) => state.userSettings
	);

	const { currentUserInfo } = useSelector((state) => state.currentUserInfo);

	const handleConfirm = () => {
		dispatch(cancelGoogleAuth());
	};

	useEffect(() => {
		if (!currentUserInfo.google_account) {
			closeModal();
		}

		return () => {
			if (cancelGoogleAuthError) {
				dispatch(cancelGoogleAuthClearError());
			}
		};
	}, [currentUserInfo.google_account]);

	return (
		<div className="decline-session">
			<Image className="decline-session__img" />
			<h2 className="decline-session__title">{title}</h2>
			{description && <p className="decline-session__text">{description}</p>}
			<div className="decline-session__actions">
				<Button
					type="button"
					loading={cancelGoogleAuthRequest}
					className="button button--orange"
					onClick={handleConfirm}
				>
					Confirm
				</Button>
				<Button
					type="button"
					onClick={closeModal}
					className="button button--orange-invert"
				>
					Cancel
				</Button>
			</div>
			<Notification
				style={{ position: "static" }}
				show={!!cancelGoogleAuthError}
			>
				{cancelGoogleAuthError}
			</Notification>
		</div>
	);
};

export default GoogleCancelAuthModal;
