import React from 'react';
import './SelectCurrencyRadio.scss';

const SelectCurrencyRadio = ({
    selected,
    onChange,
    value,
    text
}) => {
    // props showValue used to display the value of the radio button in the middle of the circle (questions type 'range')
    return (
        <div
            className="modern-radio-container"
            onClick={() => {
                onChange(value);
            }}
        >
            <div
                className={`radio-outer-circle ${value !== selected && "unselected"}`}
            >
                <div className="radio-inner-circle" style={{ backgroundColor: selected !== value ? '#fff' : 'var(--blue-3)' }}>

                </div>
            </div>
            {text && <div className="helper-text">{text}</div>}
        </div>
    );
};

export default SelectCurrencyRadio