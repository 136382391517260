import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './ReportsList.scss';
import ReportsListItem from "components/pages/Reports/ReportsListItem/ReportsListItem";
import ReportsSorting from "components/pages/Reports/ReportsSorting/ReportsSorting";
import ReportsTooltip from "components/pages/Reports/ReportsTooltip/ReportsTooltip";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReportsEngagementSorting from "components/pages/Reports/ReportsEngagementSorting/ReportsEngagementSorting";
import ReportsEngagementListItem from "components/pages/Reports/ReportsEngagementListItem/ReportsEngagementListItem";


const ReportsList = ({ reports, engagementReports, setOffsetReports, userRole, activeTab, lastElement }) => {
    const defaultTooltipInfo = {
        id: null,
        info: [],
        position: {}
    };

    const { getReportsProgress } = useSelector(state => state.reports);

    const [tooltipInfo, setTooltipInfo] = useState(defaultTooltipInfo);

    const showTooltipInfo = (info) => {
        tooltipInfo && tooltipInfo.id === info.id ?
            setTooltipInfo(defaultTooltipInfo)
            :
            setTooltipInfo(info);
    };

    const reportsItems = activeTab?.name === 'engagement' ?
        engagementReports?.map((report, index) =>
            <ReportsEngagementListItem
                key={report.id}
                num={++index}
                userRole={userRole}
                isActive={tooltipInfo.id === report.id}
                {...report} />
        )
        :
        reports?.map((report, index) =>
            <ReportsListItem key={report.id}
                num={++index}
                userRole={userRole}
                isActive={tooltipInfo.id === report.id}
                showTooltipInfo={showTooltipInfo}
                {...report}
            />
        );

    const reportsSorting = activeTab?.name === 'engagement' ?
        <ReportsEngagementSorting setOffsetReports={setOffsetReports} reports={engagementReports} userRole={userRole} />
        :
        <ReportsSorting setOffsetReports={setOffsetReports} activeTab={activeTab} reports={reports} userRole={userRole} />;

    return (
        <>
            <PerfectScrollbar className="reports-list__table-wrap">
                <table className="reports-list">
                    <tbody>
                        {
                            reportsSorting
                        }
                        {
                            reportsItems?.length ?
                                reportsItems
                                :
                                (getReportsProgress) ?
                                   ''
                                    :
                                    <tr>
                                        <td colSpan={22} style={{ textAlign: 'center', padding: '16px', color: '#6a541f' }}>
                                            There are no reports of selected type.
                                        </td>
                                    </tr>
                        }
                    </tbody>
                </table>
                <div ref={lastElement} />
            </PerfectScrollbar>
            <ReportsTooltip show={!!tooltipInfo.id} info={tooltipInfo.info} position={tooltipInfo.position} />
        </>
    );
}

export default ReportsList;