import { useFormikContext } from 'formik';
import React, { forwardRef, useRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import CustomInput from './CustomInput';
import './EditScheduleTimePicker.scss';

const MyInput = forwardRef((props, ref) => {
    return <CustomInput {...props} ref={ref} />
})

const EditScheduleTimePicker = ({ name, field, form, disabled = false }) => {

    const inputRef = useRef();
    const [value, setValue] = useState(field.value);
    const fieldName = name || field.name;
    const { setFieldValue } = form;
    const { errors } = useFormikContext();

    const isErrorPresent = (errors, fieldName) => {

        // time_off.0.start_time - example of the field name of the FieldArray instance
        if (fieldName.includes('.')) {
            const nameArray = fieldName.split('.');

            return (errors[nameArray[0]] && errors[nameArray[0]][+nameArray[1]] && errors[nameArray[0]][+nameArray[1]][nameArray[2]])
        }

        return (errors && errors[fieldName])
    }

    useEffect(() => {
        setFieldValue(fieldName, value)
    }, [value])

    return (
        <DatePicker
            name={fieldName}
            className={`EditScheduleTImePicker ${isErrorPresent(errors, fieldName) ? 'error' : ''} ${disabled ? 'disabled' : ''}`}
            disabled={disabled}
            selected={value}
            onChange={(date) => setValue(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="h:mm aa"
            customInput={<MyInput inputRef={inputRef} />}
        />
    )
}

export default EditScheduleTimePicker;
