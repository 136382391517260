import { fork, put, takeLatest, call, select } from "redux-saga/effects";
import axios from "axios";
import api, { STATIC_DATA_ROUTE } from "constants/api";
import { GET_STATIC_DATA, LOGIN_REQUEST, LOGOUT } from "../constants";
import {
	loginUserError,
	registerForProgram,
	getStaticDataSuccess,
	showNotification,
	showTimezoneNotification,
} from "../actions";
import { clearLocalStorage } from "services/auth";
import { saveUserCredentials } from "../sagas/helpers/saveUserCredentials";
import ResponseError from "helpers/ResponseError";
import { getData } from "../sagas/helpers/requestHandlers";
import { generalApiErrorHandler } from "./helpers/generalApiErrorHandler";
import { getNotificationMessage } from "helpers/notifications";
import { getToken } from "../sagas/helpers/helpers";

const { LOGIN } = api;

function* watchLogin() {
	yield takeLatest(LOGIN_REQUEST, handleLogin);
}

function* watchStaticData() {
	yield takeLatest(GET_STATIC_DATA, handleStaticData);
}

function* handleLogin({ payload, callback }) {
	try {
		const { code: programCode, id: programId } = payload;

		const { data } = yield call(() =>
			axios
				.post(LOGIN, payload)
				.then((data) => data)
				.catch((error) => error.response)
		);

		if (data?.status === "Success") {
			const authData = data.data;

			yield saveUserCredentials(authData);
			if (callback) {
				yield callback();
			}

			yield put(showTimezoneNotification());
			yield localStorage.setItem("time_zone_notification", "true");

			if (programId && programCode) {
				yield put(registerForProgram(payload));
			}
		} else if (data?.status === "Error") {
			const err = new ResponseError(data);

			yield put(loginUserError(err.getValidationErrorMessages()));
		}
	} catch (error) {
		yield put(loginUserError(getNotificationMessage(error.message)));
	}
}

function* handleStaticData() {
	try {
		const token = yield select(getToken);
		const { data } = yield call(getData(STATIC_DATA_ROUTE, token));

		if (data?.status === "Success") {
			yield put(getStaticDataSuccess(data.data));

			// used for displaying notification for all users about the technical works on the platform
			if (data.data?.platformSettings) {
				const bannerStatus = data.data.platformSettings.find(
					(item) => item.deployment_banner
				);

				if (bannerStatus) {
					yield put(
						showNotification(
							{
								title: "Technical work is being carried out on the platform.",
								text: "We apologize for the possible temporary disruptions during the operation.",
							},
							false
						)
					);
				}
			}
		} else if (data?.status === "Error") {
			const err = new ResponseError(data);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(err.getValidationErrorMessages()),
					},
					true
				)
			);
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchLogout() {
	yield takeLatest(LOGOUT, handleLogout);
}

function* handleLogout() {
	yield clearLocalStorage();
}

export default function* AuthSagas() {
	yield fork(watchLogin);
	yield fork(watchLogout);
	yield fork(watchStaticData);
}
