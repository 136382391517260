import React from "react";
import Select, { components } from 'react-select';
import { customSelectStyles } from "helpers/customSelectStyles";
import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";

const typeSelectStyles = Object.assign({}, customSelectStyles);

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowSvg />
        </components.DropdownIndicator>
    );
};

const CustomSchoolAdminProgramsSelect = ({ options, programStatus, setProgramStatus, setProgramsOffset }) => {

    const onChangeSelect = (optionValue) => {
        setProgramsOffset(0)
        setProgramStatus(optionValue.value)
    };

    return (
        <Select
            getOptionLabel={options => options.label}
            getOptionValue={options => options.value}
            components={{ DropdownIndicator }}
            options={options}
            classNamePrefix={`coach-program-select`}
            styles={typeSelectStyles}
            isSearchable={false}
            onChange={(optionValue) => onChangeSelect(optionValue)}
            defaultValue={options.filter(option => option.value === programStatus)}
        />
    )
}

export default CustomSchoolAdminProgramsSelect;
