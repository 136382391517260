import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	schoolAdminHeaderMenu,
	participantHeaderMenu,
	coachHeaderMenu,
	schoolAdminHeaderMenuAccessDenied,
} from "constants/links";
import { Link, NavLink } from "react-router-dom";
import "./Menu.scss";
import { getThrottledInfoAboutCurrentUser } from "../../../redux/actions";
// for ILTB
// import {ReactComponent as LogoWhiteSVG} from "assets/vector/logoWhite.svg";
import { ReactComponent as LogoSVG } from "assets/vector/auth-page/logo-switch.svg";
import { ReactComponent as LogoWhiteSVG } from "assets/vector/auth-page/logo-white-switch.svg";
import uniqid from "uniqid";
import defaultAva from "assets/vector/default-avatar.svg";

export default function Menu({ isMenuOpen }) {
	const dispatch = useDispatch();
	let currentDate = new Date();
	let currentYear = currentDate.getFullYear();

	const accessToken = useSelector((state) => state.auth.authToken);
	const getCurrentUserInfo = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);

	useEffect(() => {
		if (
			getCurrentUserInfo &&
			getCurrentUserInfo.role === "school_admin" &&
			!getCurrentUserInfo.coach_visibility
		) {
			dispatch(getThrottledInfoAboutCurrentUser());
		}
	}, [getCurrentUserInfo]);

	const switchRole = (userRole) => {
		switch (userRole) {
			case "coach":
				return coachHeaderMenu.map(({ text, route, icon }, index) => (
					<NavLink
						className={`reset-style`}
						exact={index === 0}
						activeClassName="is-active"
						to={route}
						key={uniqid()}
					>
						{icon}
						<span className="menu-name">{text}</span>
					</NavLink>
				));
			case "participant":
				return participantHeaderMenu.map(({ text, route, icon }, index) => (
					<NavLink
						className={`reset-style`}
						exact={index === 0}
						activeClassName="is-active"
						to={route}
						key={uniqid()}
					>
						{icon}
						<span className="menu-name">{text}</span>
					</NavLink>
				));
			case "school_admin":
				return getCurrentUserInfo.coach_visibility
					? schoolAdminHeaderMenu.map(({ text, route, icon }, index) => {
							return (
								<NavLink
									className={`reset-style`}
									exact={index === 0}
									activeClassName="is-active"
									to={route}
									key={uniqid()}
								>
									{icon}
									<span className="menu-name">{text}</span>
								</NavLink>
							);
					  })
					: schoolAdminHeaderMenuAccessDenied.map(
							({ text, route, icon }, index) => {
								return (
									<NavLink
										className={`reset-style`}
										exact={index === 0}
										activeClassName="is-active"
										to={route}
										key={uniqid()}
									>
										{icon}
										<span className="menu-name">{text}</span>
									</NavLink>
								);
							}
					  );
			default:
				return "";
		}
	};

	return (
		<section
			className={isMenuOpen ? "navigation navigation--visible" : "navigation"}
		>
			<div className="navigation-top">
				{getCurrentUserInfo && (
					<div className="logo">
						{getCurrentUserInfo.role === "school_admin" && (
							<Link to={`/`}>
								<img
									src={
										getCurrentUserInfo.avatar
											? getCurrentUserInfo.avatar
											: defaultAva
									}
									alt="logo"
									className={`user-avatar`}
								/>
								<span className="logo-text">
									{getCurrentUserInfo.school_name}
								</span>
							</Link>
						)}

						{getCurrentUserInfo.role === "participant" && (
							<Link to={`/`}>
								<img
									src={
										getCurrentUserInfo.school_logo
											? getCurrentUserInfo.school_logo
											: defaultAva
									}
									alt="logo"
									className={`user-avatar`}
								/>
								<span className="logo-text">{getCurrentUserInfo.school}</span>
							</Link>
						)}

						{getCurrentUserInfo.role === "coach" && (
							<Link to={`/`}>
								<LogoSVG />
							</Link>
						)}
					</div>
				)}

				<nav className={`menu`}>
					{accessToken &&
						getCurrentUserInfo &&
						switchRole(getCurrentUserInfo.role)}
				</nav>
			</div>

			<div className="navigation-bottom">
				<div className="footer-logo">
					{isMenuOpen ? <LogoWhiteSVG /> : <LogoSVG />}
				</div>

				<div className="copy">
					Switch &copy; {currentYear} All rights reserved
				</div>
			</div>
		</section>
	);
}
