import React from "react";
import moment from "moment";
import sessionLinkIcon from "assets/vector/dashboard/session_link.svg";
import "./UpcomingSessionItem.scss";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../redux/actions";
import { CALENDAR_EVENT_MODAL } from "../../../../../../redux/constants/modal";

const UpcomingSessionItem = ({ session }) => {
	const dispatch = useDispatch();
	const sessionStart = moment(session.date_start).format("HH:mm A");
	const sessionEnd = moment(session.date_start)
		.add(session.duration, "minutes")
		.format("HH:mm A");

	// transform session data for event modal
	const modalEvent = {
		...session,
		title: session.name,
		start: session.date_start,
		end: moment(session.date_start).add(session.duration, "minutes").toDate(),
		session_link: `http://staging.switchapp.io/programmes/single-program/${session.coaching_program.id}?session=${session.coaching_program.activity_id}`,
		members: {
			coaches: session.coaches,
			participants: session.participants,
		},
	};

	const handleEventModal = (event) => {
		dispatch(openModal(CALENDAR_EVENT_MODAL, { event }));
	};

	return (
		<div
			className="upcoming-session-item"
			onClick={() => handleEventModal(modalEvent)}
		>
			<div className="main-info">
				<div className="main-info__data">
					<div className="name">{session.name}</div>
					<div className="date">
						{moment(session.date_start).local().format("MMMM DD, HH:mm A")},{" "}
						{`${sessionStart} - ${sessionEnd}`}
					</div>
				</div>
				{session?.link && (
					<a
						href={session.link}
						target="_blank"
						rel="noreferrer"
						className="main-info__link"
						onClick={(e) => e.stopPropagation()}
					>
						<img src={sessionLinkIcon} alt="session-link" />
					</a>
				)}
			</div>
			<div className="session-details">
				<div className="users-avatars">
					<div className="avatar">
						<img src={session.participants[0]?.avatar} alt="participant" />
					</div>
					<div className="avatar coach">
						<img src={session.coaches[0]?.avatar} alt="coach" />
					</div>
				</div>
				<div className="session-status">{session.status}</div>
			</div>
		</div>
	);
};

export default UpcomingSessionItem;
