import React from 'react';
import {ReactComponent as FileIcon} from "assets/vector/programmes/file-attachement.svg";
import {ReactComponent as DownloadAttachFile} from "assets/vector/programmes/download-attach-file.svg";
import {ReactComponent as RemoveFileIcon} from "assets/vector/basket.svg";

const File = ({file = {}, isRemovable = false, handleRemoveFile}) => {
    return (
        <div className={`attached-file-item`}>
            <a className="attached-file" href={file.file_link} target="_blank" download>
                <FileIcon className="attached-file__icon"/>
                <span className="attached-file__link">
                     {file.file_link.split('/').pop()}
                </span>
                <div className="attached-file-download__icon">
                    <DownloadAttachFile className="icon"/>
                </div>
                {
                    isRemovable && <RemoveFileIcon className="attached-file__remove"
                                                   onClick={(e) => handleRemoveFile(e, file.file_link)}
                    />
                }
            </a>
        </div>
    );
};

export default File;