import React, { useRef } from "react";
import { useFormikContext } from "formik";
import Select, { components } from 'react-select';

import { customSelectStyles } from "helpers/customSelectStyles";

import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";
import { ReactComponent as SelectCloseSvg } from "assets/vector/select-close.svg";
const typeSelectStyles = Object.assign({}, customSelectStyles);

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowSvg />
        </components.DropdownIndicator>

    );
};

const CustomCoachFormSelect = ({ clearSelect, callbackClaerSelect, customFunction, value, field, options, placeholder, defaultValue, noOptionsMessage, form: { setFieldValue } }) => {

    const { submitForm } = useFormikContext();

    const onChangeSelect = (optionValue, action) => {

        submitForm();

        if (optionValue) {
            setFieldValue(field.name, optionValue.id);
        } else {
            setFieldValue(field.name, []);
        }

        switch (action.action) {
            case 'clear':
                customFunction('clear');
                return;
            case 'remove-value':
                customFunction('remove-value');
                return;
            default:
                return;
        }
    };
    const selectInputRef = useRef();

    if (clearSelect) {
        selectInputRef.current.select.clearValue();
        callbackClaerSelect(false)
    }


    return (

        <Select
            ref={selectInputRef}
            isClearable={true} // <-- added this
            getOptionLabel={options => options.name}
            getOptionValue={options => options.id}
            components={
                {
                    DropdownIndicator,
                    ClearIndicator: ({ innerRef, innerProps }) => <div ref={innerRef} {...innerProps}><SelectCloseSvg className={`multi-select-remove-item`} /></div>,
                    MultiValueRemove: ({ innerRef, innerProps }) => <div ref={innerRef} {...innerProps}><SelectCloseSvg className={`multi-select-remove-item`} /></div>

                }
            }
            name={field.name}
            options={options}
            placeholder={placeholder}
            classNamePrefix={`row-select`}
            styles={typeSelectStyles}
            isSearchable={false}
            onChange={(optionValue, action) => onChangeSelect(optionValue, action)}
            defaultValue={defaultValue}
        />
    )
}


export default CustomCoachFormSelect;