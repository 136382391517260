import { calendarList } from "../constants";

export function getCalendarList(calendarDefaultData) {
	return {
		type: calendarList.GET_LIST_CALENDAR_EVENTS,
		payload: calendarDefaultData,
	};
}

export function getCalendarListSuccess(calendarSuccessList) {
	return {
		type: calendarList.GET_LIST_CALENDAR_EVENTS_SUCCESS,
		payload: calendarSuccessList,
	};
}

export function getCalendarListEmpty(emptylist) {
	return {
		type: calendarList.GET_LIST_CALENDAR_EVENTS_NULL,
		payload: emptylist,
	};
}

export function getCalendarListError(error) {
	return {
		type: calendarList.GET_LIST_CALENDAR_EVENTS_ERROR,
		payload: error,
	};
}

export function setCalenderListClearFilters(clearSetNewData) {
	return {
		type: calendarList.CLEAR_FILTERS,
		payload: clearSetNewData,
	};
}

export function clearCalendarList() {
	return {
		type: calendarList.CLEAR_CALENDAR_EVENTS_LIST,
	};
}
