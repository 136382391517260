import React from "react";
import "./MyProfile.scss";

import { Link } from "react-router-dom";
import routes from "constants/routes";
import moment from "moment";
import defaultAva from 'assets/vector/default-avatar.svg';
import { ReactComponent as EditProfileSvg } from "assets/vector/my-profile/edit.svg";
import { useMediaQuery } from "react-responsive";
import mediaQuery from "constants/mediaQuery";
import DownloadReportButton from 'components/DownloadReportButton/DownloadReportButton';

const { MOBILE_QUERY_767 } = mediaQuery;

export default function ParticipantProfile({ getCurrentUserInfo }) {

    const { EDIT_MY_PROFILE } = routes;
    const isMobile = useMediaQuery({ maxDeviceWidth: MOBILE_QUERY_767 });
    const downloadCV = (cv) => {
        window.open(cv);
    };

    return (

        getCurrentUserInfo &&

        <div className="my-profile">
            <div className="short-info">

                <div className="my-avatar"><img src={getCurrentUserInfo.avatar ? getCurrentUserInfo.avatar : defaultAva} alt="" /></div>

                <div className="my-short-info">
                    <h3 className={`name`}>{getCurrentUserInfo.name} {getCurrentUserInfo.surname} </h3>

                    {
                        getCurrentUserInfo.location &&
                        <p className={`country`} >{getCurrentUserInfo.location}</p>
                    }

                    {
                        getCurrentUserInfo.school &&
                        <p className={`program`} >{getCurrentUserInfo.school}</p>
                    }

                </div>

                {
                    getCurrentUserInfo.cv_url &&
                    <div className="download-cv" onClick={() => downloadCV(getCurrentUserInfo.cv_url)}>
                        <div className="text-download">Download CV</div>
                    </div>
                }

                {
                    // 1 - id of the SDA Bocconi questionnaire
                    getCurrentUserInfo.survey?.all_filled?.find(item => item.id === 1) && <DownloadReportButton />
                }

                {
                    isMobile ?
                        <Link className={`edit-profile-icon`} to={EDIT_MY_PROFILE}>
                            <EditProfileSvg />
                        </Link>
                        :
                        <Link className="view-profile edit-profile" to={EDIT_MY_PROFILE}>
                            Edit
                        </Link>
                }

            </div>

            {
                getCurrentUserInfo &&
                (
                    getCurrentUserInfo.languages.length > 0 ||
                    getCurrentUserInfo.functional_areas.length > 0 ||
                    getCurrentUserInfo.seniority_level_id ||
                    getCurrentUserInfo.industry_background.length > 0 ||
                    (getCurrentUserInfo.work_experience && getCurrentUserInfo.work_experience.length > 0) ||
                    getCurrentUserInfo.goal
                )
                &&

                <div className="full-info">

                    {
                        getCurrentUserInfo.languages.length > 0 &&
                        <div className="section-info-block languages">
                            <h3 className="block-title">Languages</h3>

                            <div className="languages-block">
                                {
                                    getCurrentUserInfo.languages.map(
                                        (element, index) => (
                                            <div key={index} className="language">

                                                <img className={`flag`} src={element.flag} alt="language flag" />
                                                <div className="language">{element.name}</div>
                                                <div className="experience">{element.level.name}</div>
                                            </div>
                                        )
                                    )
                                }
                            </div>


                        </div>
                    }

                    {
                        getCurrentUserInfo.functional_areas.length > 0 &&
                        <div className="section-info-block functional-area">
                            <h3 className="block-title">Functional Area</h3>

                            <div className="block-items">
                                {
                                    getCurrentUserInfo.functional_areas.map(
                                        (element, index) => (
                                            <span key={index} className="single-item">{element.name}</span>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    }

                    {
                        getCurrentUserInfo.seniority_level_id &&
                        <div className="section-info-block seniority-level">
                            <h3 className="block-title">Seniority Level</h3>

                            <div className="block-items">

                                <span className="single-item">{getCurrentUserInfo.seniority_level_id.name}</span>

                            </div>
                        </div>
                    }

                    {
                        getCurrentUserInfo.industry_background.length > 0 &&
                        <div className="section-info-block industry-background">
                            <h3 className="block-title">Expertise</h3>

                            <div className="block-items">
                                {
                                    getCurrentUserInfo.industry_background.map(
                                        (element, index) => (
                                            <span key={index} className="single-item">{element.name}</span>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    }

                    {
                        getCurrentUserInfo.work_experience.length > 0 &&
                        <div className="section-info-block work-experience">
                            <h3 className="block-title">Work experience</h3>

                            <div className="work-experience-block">
                                {
                                    getCurrentUserInfo.work_experience.map(
                                        (element, index) => (
                                            <div key={index} className="experience-item">

                                                <div className="experience-item-left">
                                                    <div className="position">{element.position}</div>
                                                    <div className="company">{element.company}</div>
                                                </div>

                                                <div className="experience-item-right">
                                                    <div className="date">
                                                        <span className="from">{moment(element.date_from).format("MMMM YYYY")}</span> - <span className="to">{element.date_to ? moment(element.date_to).format("MMMM YYYY") : "Present time"}</span>
                                                    </div>
                                                    <div className="job-responsibilities">{element.responsibilities}</div>
                                                </div>

                                            </div>
                                        )
                                    )
                                }
                            </div>


                        </div>
                    }

                    {
                        getCurrentUserInfo.goal &&
                        <div className="section-info-block ">
                            <h3 className="block-title">Goal</h3>
                            <div className="goal-block">

                                <div className="text">{getCurrentUserInfo.goal}</div>

                            </div>
                        </div>
                    }


                </div>

            }

        </div>
    )
}