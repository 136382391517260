import React from 'react';
import {ReactComponent as EnvelopeIcon} from "../../../../assets/vector/auth-page/envelope.svg";
import {Link} from "react-router-dom";
import routes from "constants/routes";

const CheckEmail = () => {
    const {SIGN_IN} = routes;

    return (
        <div className="default-auth__check-email">
            <EnvelopeIcon className="envelope-icon"/>
            <h1 className="default-auth__title default-auth__title--email">Check your email</h1>
            <p className="default-auth__info-text">And follow the instructions create new password</p>
            <div className="sign-up__bottom">
                <Link to={SIGN_IN} className="sign-up__login-link">Return to Login page</Link>
            </div>
        </div>
    );
};

export default CheckEmail;