import {transformDatePickerDate} from "helpers/userPrograms";
import moment from "moment";

const transformTimeTo24h = (time) => {
    if (time) {
        const defaultSeconds = '00';
        let hours = Number(time.split(':')[0]),
            minutes = Number(time.split(':')[1]),
            timeFormat = time.split(':')[2],
            halfDayHours = 12;

        if (timeFormat === 'PM' && hours < halfDayHours) {
            hours += 12;
        } else if (timeFormat === 'AM' && hours === halfDayHours) {
            hours = 0;
        }

        let newHours = String(hours).length > 1 ? String(hours) : '0' + String(hours);
        let newMinutes = String(minutes).length > 1 ? String(minutes) : '0' + String(minutes);

        return `${newHours}:${newMinutes}:${defaultSeconds}`;
    }
};

export const transformDateAndTime = (date, time, timeZone) => {
    if (date && time) {
        const transformedDate = transformDatePickerDate(date);
        const transformedTime = transformTimeTo24h(time);
        const timeZOneOffset = moment(transformedDate).tz(timeZone).format('ZZ');

        return moment(`${transformedDate} ${transformedTime}`).format(`YYYY-MM-DDTHH:mm:ss${timeZOneOffset}`);
    }
};

export const isPrevDateChosen = (chosenDate, timeZone) => {
    const currentDate = moment.tz(timeZone);

    return currentDate.isAfter(chosenDate);
};

export const transformSelectOptions = (options) => options?.map(option => {
    return {...option, label: option.name}
});