import React from "react";
import { useDispatch } from "react-redux";
import ReportsSortingIconUp from "components/icons/ReportsSortingIconUp";
import ReportsSortingIconDown from "components/icons/ReportsSortingIconDown";
import "./ReportsSortingButtons.scss";
import { setReportsSortingOptionAndOrder } from "../../../../redux/actions";

const ReportsSortingButtons = ({ sortingValue, setOffsetReports }) => {
	const dispatch = useDispatch();

	const _setReportsSortingOptionAndOrder = (sortingValue, order) => {
		setOffsetReports(0);
		dispatch(
			setReportsSortingOptionAndOrder({
				order_by: sortingValue,
				order: order,
			})
		);
	};

	return (
		<div className="sorting-buttons__wrapper">
			<ReportsSortingIconUp
				className="icon icon__up"
				onClick={() => _setReportsSortingOptionAndOrder(sortingValue, "asc")}
			/>
			<ReportsSortingIconDown
				className="icon icon__down"
				onClick={() => _setReportsSortingOptionAndOrder(sortingValue, "desc")}
			/>
		</div>
	);
};

export default ReportsSortingButtons;
