import { fork, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { chatConstant } from "../constants";
import {
	getListRoomsSuccess,
	getErrorListRooms,
	getListUsersSuccess,
	getEmptyListUsers,
	getErrorListUsers,
	getMessagesFromUser,
	getMessagesFromUserSuccess,
	getEmptyMessagesFromUser,
	getMessagesFromUserError,
	getTotalMessages,
	sendMessageToUserSuccess,
	sendMessageToUserError,
} from "../actions/chat";
import {
	SEND_MESSAGES_TO_USER,
	GET_MESSAGES_FROM_CHAT_ROOM,
	GET_LIST_ROOMS,
	GET_LIST_USERS,
	SEARCH_CHAT_ROOM,
	GET_MESSAGES_FOR_CHAT_ROOM,
} from "constants/api";
import { generalApiErrorHandler } from "../sagas/helpers/generalApiErrorHandler";
import { showNotification } from "../actions";
import { getNotificationMessage } from "../../helpers/notifications";
import { getToken } from "../sagas/helpers/helpers";

function* watchGetListRooms() {
	yield takeLatest(chatConstant.GET_LIST_OF_ROOMS, handleGetListRooms);
}

function* handleGetListRooms() {
	try {
		const token = yield select(getToken);

		const allChatRooms = yield axios({
			method: "get",
			url: GET_LIST_ROOMS(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(getListRoomsSuccess(allChatRooms));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getErrorListRooms(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetListUsers() {
	yield takeLatest(chatConstant.GET_LIST_OF_USERS, handleGetListUsers);
}

function* handleGetListUsers() {
	try {
		const token = yield select(getToken);

		const allListUsers = yield axios({
			method: "get",
			url: GET_LIST_USERS(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (allListUsers.length > 0) {
			yield put(getListUsersSuccess(allListUsers));
		} else {
			yield put(getEmptyListUsers(allListUsers));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getErrorListUsers(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetMessagesWithUser() {
	yield takeLatest(
		chatConstant.GET_MESSAGES_FROM_USERS,
		handleGetMessagesWithUser
	);
}

function* handleGetMessagesWithUser({ payload }) {
	try {
		const token = yield select(getToken);

		const getMessagesWithUser = yield axios({
			method: "post",
			url: GET_MESSAGES_FROM_CHAT_ROOM(payload),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (getMessagesWithUser.messages.length > 0) {
			yield put(getTotalMessages(getMessagesWithUser.total));
			yield put(getMessagesFromUserSuccess(getMessagesWithUser.messages));
		} else {
			yield put(getEmptyMessagesFromUser(null));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getMessagesFromUserError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchSendMessagesToUser() {
	yield takeLatest(chatConstant.SEND_MESSAGE_TO_USER, handleSendMessagesToUser);
}

function* handleSendMessagesToUser({ payload }) {
	try {
		const token = yield select(getToken);

		let newDataToUser = {
			body: payload.message,
			member_id: payload.userId,
		};
		if (payload.userRole === "super_admin") {
			newDataToUser.toSuperAdmin = 1;
		} else {
			newDataToUser.toSuperAdmin = false;
		}

		const sendMessagesToUser = yield axios({
			method: "post",
			url: SEND_MESSAGES_TO_USER(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
			data: newDataToUser,
		}).then((response) => response.data.data);

		yield put(sendMessageToUserSuccess(sendMessagesToUser));
		yield put(getMessagesFromUser(sendMessagesToUser.id));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(sendMessageToUserError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetNewMessagesWithUser() {
	yield takeLatest(
		chatConstant.GET_MESSAGES_FROM_USER_ON_SCROLL,
		handleGetNewMessagesWithUser
	);
}

function* handleGetNewMessagesWithUser({ payload }) {
	try {
		const token = yield select(getToken);

		const messagesFromUser = yield select(
			(state) => state.chat.messagesFromUser
		);

		const getNewMessagesWithUser = yield axios({
			method: "post",
			url:
				GET_MESSAGES_FROM_CHAT_ROOM(payload.roomID) +
				`?offset=${payload.offset}&take=${payload.take}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		const concatMessagesFromUser = [
			...getNewMessagesWithUser.messages,
			...messagesFromUser,
		];

		yield put(getMessagesFromUserSuccess(concatMessagesFromUser));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getMessagesFromUserError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchChatSearchRoom() {
	yield takeLatest(chatConstant.CHAT_SEARCH_ROOM, handleChatSearchRoom);
}

function* handleChatSearchRoom({ payload }) {
	try {
		const token = yield select(getToken);

		const getNewChatRooms = yield axios({
			method: "get",
			url: SEARCH_CHAT_ROOM() + `?search=${payload}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(getListRoomsSuccess(getNewChatRooms));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getErrorListRooms(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetMessagesSpecifiedUser() {
	yield takeLatest(
		chatConstant.GET_MESSAGES_SPECIFIED_USER,
		handleGetMessagesSpecifiedUser
	);
}

function* handleGetMessagesSpecifiedUser({ payload }) {
	try {
		const token = yield select(getToken);

		const getMessagesSpecifiedUser = yield axios({
			method: "post",
			url:
				GET_MESSAGES_FOR_CHAT_ROOM(payload.id) +
				`?toSuperAdmin=${payload.role === "super_admin" ? 1 : 0}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (getMessagesSpecifiedUser.length > 0) {
			yield put(getMessagesFromUserSuccess(getMessagesSpecifiedUser));
		} else {
			yield put(getEmptyMessagesFromUser(null));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* coachesListSaga() {
	yield fork(watchGetListRooms);
	yield fork(watchGetListUsers);
	yield fork(watchGetMessagesWithUser);
	yield fork(watchSendMessagesToUser);
	yield fork(watchGetNewMessagesWithUser);
	yield fork(watchChatSearchRoom);
	yield fork(watchGetMessagesSpecifiedUser);
}
