import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "components/icons/SearchIcon";
import CrossIcon from "components/icons/CrossIcon";
import { CSSTransition } from "react-transition-group";
import { addSearchQuery } from "../../../../redux/actions";
import "./ReportsSearch.scss";
import useDebounce from "hooks/useDebounce";

const ReportsSearch = ({
	isMobileView,
	isSearchOpen,
	setIsSearchOpen,
	setOffsetReports,
}) => {
	const searchRef = React.useRef(null);
	const [searchValue, setSearchValue] = useState("");
	const [isFocus, setIsFocus] = useState(false);

	const { searchQuery } = useSelector((state) => state.reports);

	const dispatch = useDispatch();

	const openSearch = () => setIsSearchOpen(true);

	const getSearchedReportsList = (searchQuery) => {
		setOffsetReports(0);

		dispatch(addSearchQuery(searchQuery));
	};

	const debounceSearch = useDebounce(getSearchedReportsList, 500);

	const closeSearch = () => {
		setIsSearchOpen(false);
		if (searchValue !== "") {
			setSearchValue("");
			dispatch(addSearchQuery(""));
			getSearchedReportsList("");
		}
	};

	const handleSearchValue = (event) => {
		setSearchValue(event.target.value);
		debounceSearch(event.target.value);
	};

	const handleSearch = () => {
		if (!isSearchOpen) openSearch();
	};

	const searchIconColor = () => {
		if (isMobileView && isSearchOpen) {
			return "#929292";
		} else if (isMobileView) {
			return "#E75B32";
		}

		return "#929292";
	};

	useEffect(() => {
		if (searchQuery) {
			setSearchValue(searchQuery);
		} else {
			setSearchValue("");
		}
	}, [searchQuery]);

	return (
		<div
			className={`reports-search
            ${isSearchOpen ? "reports-search--is-open" : ""}
            ${isMobileView ? "reports-search--mobile" : ""}
            ${isFocus ? "focus" : ""}
            `}
		>
			<SearchIcon
				className="reports-search__search"
				onClick={handleSearch}
				color={searchIconColor()}
			/>
			<CSSTransition
				in={isSearchOpen}
				timeout={500}
				unmountOnExit
				nodeRef={searchRef}
				classNames="reports-search__input-group-"
			>
				<div className="reports-search__input-group" ref={searchRef}>
					<input
						type="text"
						className="reports-search__input"
						value={searchValue}
						onChange={handleSearchValue}
						onFocus={() => setIsFocus(true)}
						onBlur={() => setIsFocus(false)}
						placeholder="Enter key word for search..."
					/>
					<CrossIcon
						color="#101821"
						className="reports-search__clear"
						onClick={closeSearch}
					/>
				</div>
			</CSSTransition>
		</div>
	);
};

export default ReportsSearch;
