import React from 'react'
import { Link } from 'react-router-dom'
import defaultImage from 'assets/images/Program/default-image.png';

import './ProgramCard.scss';

const ProgramCard = ({ id, logoUrl, name, topicsCount, sessions, coach }) => {
    const progressStyles = {
        width: `${sessions?.completion_percentage}%`
    }

    return (
        <Link to={`/programmes/single-program/${id}`} className='program-card'>
            <img src={logoUrl ? logoUrl : defaultImage} alt="program-card" className="program-card__img" />
            <div className="program-card__content">
                <div className='content__wrapper'>
                    <div className="program__data">
                        <div className="program-card__title">{name}</div>
                        <ul className="program-card__info">
                            <li className="program-card__info-item">{topicsCount} Topics</li>
                            <li className="program-card__info-item">Sessions: {sessions?.completed} out of {sessions?.planned} </li>
                        </ul>
                    </div>
                    {(coach.avatar || coach.name) &&
                        <div className="program-card__coach">
                            <img className="coach-avatar" src={coach.avatar} alt="coach-avatar" />
                            <div className="coach-info">
                                <span>Coach</span>
                                <span className='coach-info__name'>{coach.name}</span>
                            </div>
                        </div>
                    }
                </div>
                <div className="program-card__progress">
                    <div className="program-card__progress-bar">
                        <div style={progressStyles} className="program-card__progress-fill"></div>
                    </div>
                    <span className="program-card__progress-num">{sessions?.completion_percentage}%</span>
                </div>
            </div>

        </Link>
    )
}

export default ProgramCard