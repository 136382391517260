import {
	ACCEPT_SESSION_BY_COACH,
	ACCEPT_SESSION_BY_COACH_ERROR,
	ACCEPT_SESSION_BY_COACH_SUCCESS,
	ACCEPT_SESSION_INVITATION,
	ACCEPT_SESSION_INVITATION_ERROR,
	ACCEPT_SESSION_INVITATION_SUCCESS,
	DECLINE_SESSION_INVITATION,
	DECLINE_SESSION_INVITATION_ERROR,
	DECLINE_SESSION_INVITATION_SUCCESS,
	FETCH_SESSION_INFO,
	FETCH_SESSION_INFO_ERROR,
	FETCH_SESSION_INFO_SUCCESS,
	LATE_CANCEL_SESSION_INVITATION,
	LATE_CANCEL_SESSION_INVITATION_ERROR,
	LATE_CANCEL_SESSION_INVITATION_SUCCESS,
} from "../constants";

export function acceptSessionInvitation(sessionId, token) {
	return {
		type: ACCEPT_SESSION_INVITATION,
		payload: { sessionId, token },
	};
}

export function acceptSessionInvitationSuccess(successData) {
	return {
		type: ACCEPT_SESSION_INVITATION_SUCCESS,
		payload: successData,
	};
}

export function acceptSessionInvitationError(errorData) {
	return {
		type: ACCEPT_SESSION_INVITATION_ERROR,
		payload: errorData,
	};
}

export function declineSessionInvitation(sessionId) {
	return {
		type: DECLINE_SESSION_INVITATION,
		payload: sessionId,
	};
}

export function declineSessionInvitationSuccess(successData) {
	return {
		type: DECLINE_SESSION_INVITATION_SUCCESS,
		payload: successData,
	};
}

export function declineSessionInvitationError(errorData) {
	return {
		type: DECLINE_SESSION_INVITATION_ERROR,
		payload: errorData,
	};
}

export function lateCancelSessionInvitation(sessionId) {
	return {
		type: LATE_CANCEL_SESSION_INVITATION,
		payload: sessionId,
	};
}

export function lateCancelSessionInvitationSuccess(successData) {
	return {
		type: LATE_CANCEL_SESSION_INVITATION_SUCCESS,
		payload: successData,
	};
}

export function lateCancelSessionInvitationError(errorData) {
	return {
		type: LATE_CANCEL_SESSION_INVITATION_ERROR,
		payload: errorData,
	};
}

export function fetchSessionInfo(programId, activityId, callbackFunc) {
	return {
		type: FETCH_SESSION_INFO,
		payload: { programId, activityId, callbackFunc },
	};
}

export function fetchSessionInfoSuccess(successData) {
	return {
		type: FETCH_SESSION_INFO_SUCCESS,
		payload: successData,
	};
}

export function fetchSessionInfoError(errorData) {
	return {
		type: FETCH_SESSION_INFO_ERROR,
		payload: errorData,
	};
}

export function acceptSessionByCoach(sessionId) {
	return {
		type: ACCEPT_SESSION_BY_COACH,
		payload: sessionId,
	};
}

export function acceptSessionByCoachSuccess() {
	return {
		type: ACCEPT_SESSION_BY_COACH_SUCCESS,
	};
}

export function acceptSessionByCoachError() {
	return {
		type: ACCEPT_SESSION_BY_COACH_ERROR,
	};
}
