import React from 'react';
import {ErrorMessage, Field, FieldArray} from "formik";
import {getUniqueLanguages} from "components/pages/EditMyProfile/helpers/helpers";
import CustomProfileLanguagesSelect from "components/CustomFormSelect/CustomProfileLanguagesSelect";
import {ReactComponent as CloseSvg} from "assets/vector/close.svg";

const UserLanguages = ({values, errors, touched, getLanguages, getLanguageLevels, setFormErrorsFormik}) => {
    return (
        <div className="section-info-block languages">
            <h3 className={`section-title`}>Languages</h3>
            <FieldArray name="languages">
                {({remove, push}) => (
                    <div className="languages-list">
                        {
                            values.languages.length > 0 &&
                            values.languages.map((language, index) => {
                                    return (
                                        <div className="item" key={index}>
                                            <div
                                                className={`language ${(errors && errors.languages && errors.languages[index] && errors.languages[index].id && touched && touched.languages && touched.languages[index] && touched.languages[index].id) ? 'input-error' : ''}`}>
                                                <label htmlFor={`languages.${index}.id`}
                                                       className="profile-row__label">Language</label>
                                                <Field
                                                    name={`languages.${index}.id`}
                                                    options={getUniqueLanguages(getLanguages, values.languages)}
                                                    defaultOptions={getLanguages}
                                                    placeholder="Choose language..."
                                                    component={CustomProfileLanguagesSelect}
                                                    defaultValue={language}
                                                />
                                                <ErrorMessage className={`profile-row__error`}
                                                              component="div"
                                                              name={`languages.${index}.id`}/>
                                            </div>
                                            <div
                                                className={`level ${(errors && errors.languages && errors.languages[index] && errors.languages[index].level && touched && touched.languages && touched.languages[index] && touched.languages[index].level) ? 'input-error' : ''}`}>
                                                <label htmlFor={`languages.${index}.level`}
                                                       className="profile-row__label">Level</label>
                                                <Field
                                                    name={`languages.${index}.level`}
                                                    options={getLanguageLevels}
                                                    placeholder="Choose level..."
                                                    component={CustomProfileLanguagesSelect}
                                                    defaultValue={language.level}
                                                />
                                                <ErrorMessage className={`profile-row__error`}
                                                              component="div"
                                                              name={`languages.${index}.level`}/>
                                            </div>
                                            <div className="remove-row">
                                                <div className="remove-item-language" onClick={() => {
                                                    remove(index);
                                                }}><CloseSvg/></div>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                        <div className="add-new-info add-language" onClick={() => {
                            push({id: '', level: ''});
                            setFormErrorsFormik(false);
                        }
                        }>
                            + Add another language
                        </div>
                    </div>
                )}
            </FieldArray>
        </div>
    );
};

export default UserLanguages;