import React from 'react';

const EventSessionInput = ({type, field, form: {touched, errors}, className, label, labelTip, ...props}) => {
    const classNameWithError = 'event-form__input--error';

    if (touched[field.name] && errors[field.name]) {
        className = `${className} ${classNameWithError}`;
    }

    return (
        <>
            <label htmlFor={field.name} className="event-form__label">
                {label}
                {
                    labelTip ?
                        <span className="event-form__label-tip">{labelTip}</span>
                        :
                        null
                }
            </label>
            <input
                type={type}
                className={className}
                {...field}
                {...props} />
            {
                touched[field.name] && errors[field.name] &&
                <div className='event-form__error'>{errors[field.name]}</div>
            }
        </>
    )

};

export default EventSessionInput;