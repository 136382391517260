import React, {useRef} from 'react';
import {ReactComponent as IconAttachedFileSvg} from "assets/vector/my-profile/icon-attached-file.svg";
import {ReactComponent as ClearCVSvg} from "assets/vector/my-profile/clear-attached-file.svg";
import {ReactComponent as CvUploadSvg} from "assets/vector/my-profile/cv-upload.svg";
import {handleAttachCvChange} from "components/pages/EditMyProfile/helpers/helpers";
import {ErrorMessage} from "formik";

const CvFile = ({errors, touched, checkUploadCVFile, setFieldValue, setCheckUploadCVFile, handleBlur}) => {
    const hiddenCVFileInput = useRef(null);
    const handleAttachCvClick = () => hiddenCVFileInput.current.click();

    const clearAttachCvClick = (event, setFieldValue) => {
        const clearCVFile = event.target.value = null;
        setFieldValue('cvFile', clearCVFile);
        setCheckUploadCVFile(false);
    };

    return (
        <div className="cv-file">
            <div className={`attach-cv ${errors.cvFile && touched.cvFile ? 'input-error' : ''}`}>
                {
                    checkUploadCVFile ?
                        <div className="uploaded-cv-file" onClick={event => clearAttachCvClick(event, setFieldValue)}>
                            <IconAttachedFileSvg className={`icon-attached`}/>
                            <div className="text">My CV</div>
                            <ClearCVSvg className={`icon-clear-cv`}/>
                        </div>
                        :
                        <div className="default-show" onClick={handleAttachCvClick}>
                            <CvUploadSvg className={`icon-cv-upload`}/> Attach CV
                        </div>
                }
            </div>
            <input accept='application/pdf'
                   type="file"
                   ref={hiddenCVFileInput}
                   name="cvFile"
                   onChange={event => handleAttachCvChange(event, setFieldValue, handleBlur, setCheckUploadCVFile)}
                   style={{display: "none"}}
            />
            <ErrorMessage className={`profile-row__error`} component="div" name="cvFile"/>
        </div>
    );
};

export default CvFile;