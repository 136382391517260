export function calculatePlaceholdersQuantity(width) {
    let count = 15;

    if (width < 100) {
        count = 2
    }
    if (width > 150 && width < 200) {
        count = 3
    }
    if (width > 200 && width < 245) {
        count = 4
    }
    if (width >= 245 && width < 300) {
        count = 5
    }
    if (width >= 300 && width < 350) {
        count = 6
    }
    if (width >= 350 && width < 400) {
        count = 7
    }
    if (width >= 400 && width < 450) {
        count = 8
    }
    if (width >= 450 && width < 500) {
        count = 9
    }
    if (width >= 500 && width < 550) {
        count = 10
    }
    if (width >= 550 && width < 600) {
        count = 11
    }
    if (width >= 600 && width < 650) {
        count = 12
    }
    if (width >= 650 && width < 700) {
        count = 13
    }
    if (width >= 700 && width < 750) {
        count = 14
    }

    return count;
}
