export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';
export const CLEAR_REPORT_DATA = 'CLEAR_REPORTS_DATA';

export const GET_EVALUATION_REPORTS = 'GET_EVALUATION_REPORTS';
export const GET_EVALUATION_REPORTS_SUCCESS = 'GET_EVALUATION_REPORTS_SUCCESS';
export const GET_EVALUATION_REPORTS_EMPTY = 'GET_EVALUATION_REPORTS_SUCCESS_EMPTY';

export const GET_FEEDBACK_REPORTS = 'GET_FEEDBACK_REPORTS';
export const GET_FEEDBACK_REPORTS_SUCCESS = 'GET_FEEDBACK_REPORTS_SUCCESS';
export const GET_FEEDBACK_REPORTS_EMPTY = 'GET_FEEDBACK_REPORTS_SUCCESS_EMPTY';

export const GET_ENGAGEMENT_REPORTS = 'GET_ENGAGEMENT_REPORTS';
export const GET_ENGAGEMENT_REPORTS_EMPTY = 'GET_ENGAGEMENT_REPORTS_EMPTY';
export const GET_ENGAGEMENT_REPORTS_SUCCESS = 'GET_ENGAGEMENT_REPORTS_SUCCESS';

export const GET_BILLING_OVERVIEW_REPORTS = 'GET_BILLING_OVERVIEW_REPORTS';
export const GET_BILLING_OVERVIEW_REPORTS_EMPTY = 'GET_BILLING_OVERVIEW_REPORTS_EMPTY';
export const GET_BILLING_OVERVIEW_REPORTS_SUCCESS = 'GET_BILLING_OVERVIEW_REPORTS_SUCCESS';

export const GET_COACH_INVOICES = 'GET_COACH_INVOICES';
export const GET_COACH_INVOICES_EMPTY = 'GET_COACH_INVOICES_EMPTY';
export const GET_COACH_INVOICES_SUCCESS = 'GET_COACH_INVOICES_SUCCESS';

export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';

export const CLEAR_REPORTS_DATA = 'CLEAR_REPORTS_DATA';

export const ADD_SELECTED_REPORTS = 'ADD_SELECTED_REPORTS';
export const ADD_SEARCH_QUERY = 'ADD_SEARCH_QUERY';
export const ADD_SELECTED_FILTER_OPTIONS = 'ADD_SELECTED_FILTER_OPTIONS';
export const SET_SORTING_OPTION_AND_ORDER = 'SET_SORTING_OPTION_AND_ORDER';
