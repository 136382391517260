import React from 'react';

const CrossRoundIcon = ({color = '#929292', background = 'white', width = 28, height = 28, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="14" fill={background}/>
            <path d="M18.6668 10.274L17.7268 9.33398L14.0002 13.0607L10.2735 9.33398L9.3335 10.274L13.0602 14.0007L9.3335 17.7273L10.2735 18.6673L14.0002 14.9407L17.7268 18.6673L18.6668 17.7273L14.9402 14.0007L18.6668 10.274Z" fill={color}/>
        </svg>
    );
};

export default CrossRoundIcon;