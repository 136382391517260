import React from "react";
// import {useFormikContext} from "formik";
import Select, { components } from 'react-select';
import { useFormikContext } from "formik";

import { customMultiSelectStyles } from "helpers/customSelectStyles";

import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";
// import { ReactComponent as SelectCloseSvg } from "assets/vector/select-close.svg";

const typeSelectStyles = Object.assign({}, customMultiSelectStyles);

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowSvg />
        </components.DropdownIndicator>

    );
};

const InvoicesFilterMultiSelect = ({
    field,
    options,
    placeholder,
    form: { setFieldValue },
}) => {

    const { submitForm } = useFormikContext();

    const onChangeSelect = (optionValue) => {
        setFieldValue(field.name, optionValue);
        submitForm()
    };

    return (
        <Select
            isMulti
            components={
                {
                    DropdownIndicator,
                    // ClearIndicator: ({ innerRef, innerProps }) => <div ref={innerRef} {...innerProps}><SelectCloseSvg className={`multi-select-remove-item`} /></div>,
                    // MultiValueRemove: ({ innerRef, innerProps }) => <div ref={innerRef} {...innerProps}><SelectCloseSvg className={`multi-select-remove-item`} /></div>
                }
            }
            name={field.name}
            getOptionLabel={options => options.label}
            getOptionValue={options => options.value}
            closeMenuOnSelect={true}
            options={options}
            placeholder={placeholder}
            styles={typeSelectStyles}
            onChange={(optionValue) => onChangeSelect(optionValue)}
            // defaultValue={defaultValue}
            noOptionsMessage={() => "No options!"}
            className="multi-select"
            classNamePrefix="row-select"
        />
    )
}

export default InvoicesFilterMultiSelect;
