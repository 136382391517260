import React from 'react';
import '../Event.scss';
import { ReactComponent as ClockIcon } from 'assets/vector/clock.svg';

const EventDuration = ({ text }) => {
  return (
    <p className="event-info">
      <ClockIcon className="event-info__icon"/>
      {text}
    </p>
  );
};

export default EventDuration;
