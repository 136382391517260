export const getOffsetPosition = (refElement, offsetTop = 0) => {
    const elementOffsetTop = refElement.getBoundingClientRect().top;

    return window.scrollY + elementOffsetTop - offsetTop;
};

export const scrollToEl = (top = 0, left = 0, behavior = 'smooth') => {
    window.scrollTo({
        top: top,
        left: left,
        behavior: behavior
    });
};

export const getRelativeLink = (link = '', fallback) => {
    try {
        const url = new URL(link);

        return `${url.pathname}${url.search}`;

    } catch (err) {
        console.error(err.message);
    }

    return fallback ? fallback : '/';
};