import React, { useEffect, useState } from 'react';
import './Credits.scss';

const Credits = ({ credits }) => {
    const [inProgressWidth, setLInProgressWidth] = useState(0);
    const [usedWidth, setUsedWidth] = useState(0);
    const [availableWidth, setAvailableWidth] = useState(0);

    const totalScaleWidth = 100;

    useEffect(() => {
        if (credits?.total > 0) {
            setLInProgressWidth((credits?.in_progress / credits?.total) * totalScaleWidth)
            setUsedWidth((credits?.used / credits?.total) * totalScaleWidth)
            setAvailableWidth((credits?.available / credits?.total) * totalScaleWidth)
        } else {
            setLInProgressWidth(0)
            setUsedWidth(0)
            setAvailableWidth(0)
        }
    }, [credits?.total])
    

    return (
        <div className='credit-graph'>
            <div className='credit-graph__head'>
                <h4 className='credit-graph__title'>Session Credits:</h4>
                <p className='credit-graph__info'>
                    <span className='accent'>{credits?.available} available</span>
                    <span>out of {credits?.total} purchased</span>
                </p>
            </div>
            <div className='credit-graph__row'>
                {<div className='credit-graph__line' style={{ width: `${usedWidth}%`, minWidth: `${usedWidth > 0 ? '4px' : '0'}` }}></div>}
                {<div className='credit-graph__line credit-graph__line--in-progress' style={{ width: `${inProgressWidth}%`, minWidth: `${inProgressWidth > 0 ? '4px' : '0'}` }}></div>}
                {<div className='credit-graph__line credit-graph__line--available' style={{ width: `${availableWidth}%`, minWidth: `${availableWidth > 0 ? '4px' : '0'}` }}></div>}
            </div>
        </div>
    );
};

export default Credits;