import React from 'react'
import './EditScheduleCheckbox.scss'

const EditScheduleCheckbox = ({ name, field, label, values, value, onChange }) => {
    return (
        <label
            className={`edit-schedule-checkbox__item ${values[name].includes(value) ? "edit-schedule-checkbox__item--checked" : ""
                }`}
        >
            <div className={`edit-schedule-checkbox__item-box`}>
                {label && <span className={`edit-schedule-checkbox__item-label`}>{label}</span>}
            </div>
            <input
                type="checkbox"
                className="edit-schedule-checkbox__item-input"
                name={name}
                value={value}
                checked={values[name].includes(value)}
                onChange={() => onChange(name, value, field)}
            />
        </label>
    )
}

export default EditScheduleCheckbox