import React from 'react';
import {useUserSyncTime} from "../../../../../hooks/useUserSyncTime";

const Participant = ({participant, handleInviteUser}) => {
    const {id, avatar, name, isInvited, isDisabled, timezone} = participant;
    const {currentUserTime, userUTC} = useUserSyncTime(timezone);

    return (
        <li className={`participants__user ${isInvited ? 'participants__user--invited' : ''}`}>
            <img src={avatar}
                 alt={`user ${name} avatar`}
                 className="participants__user-img"/>
            <div className='participants__user-info'>
                <p className="participants__user-name">{name}</p>
                <p className="participants__user-zone">
                    UTC{userUTC} <span className='hours'>{currentUserTime}</span>
                </p>
            </div>
            <button className="participants__invite-btn"
                    disabled={isDisabled}
                    onClick={() => handleInviteUser(id)}
            >
                {
                    isInvited ? 'Cancel' : 'Invite'
                }
            </button>
        </li>
    );
};

export default Participant;