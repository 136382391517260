import React from 'react';
import { useSelector } from "react-redux";
import { englishDescription, italianDescription } from './helpers/legendPageTranslations';

const LegendPageStep = () => {

    const { role } = useSelector(state => state.currentUserInfo.currentUserInfo);
    const { language } = useSelector(state => state.questionnaireLanguage);

    return (
        <div className="legend-page">
            <div className="form-question">
                {language === 'it' ? italianDescription(role) : englishDescription(role)}
            </div>
        </div>
    )
}

export default LegendPageStep;
