import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Reports.scss";
import {
	clearReportsData,
	getBillingOverviewReports,
	getCoachInvoices,
	getEngagementReports,
	getEvaluationReports,
	getFeedbackReports,
	updatePageTitle,
} from "../../../redux/actions";
import pageTitles from "constants/pageTitles";
import ReportsDesk from "components/pages/Reports/ReportsDesk/ReportsDesk";
import EmptyProgrammes from "components/pages/Programmes/EmptyProgrammes/EmptyProgrammes";
import EmptyImage from "assets/vector/empty-pages/empty-reports-switch.svg";
import Spinner from "components/Spinner/Spinner";
import { removeEmptyValues } from "helpers/removeEmptyValues";

export default function Reports() {
	const dispatch = useDispatch();
	const { authToken } = useSelector((state) => state.auth);
	const { role } =
		useSelector((state) => state.currentUserInfo.currentUserInfo) || {};
	const {
		evaluationReports,
		engagementReports,
		feedbackReports,
		billingReports,
		invoices,
		reportsExist,
	} = useSelector((state) => state.reports);

	const { REPORTS_TITLE } = pageTitles;
	const reportsLoading =
		role !== "participant" ? engagementReports : feedbackReports;

	const reportsLimit = 20;
	const offsetReports = 0;

	useEffect(() => {
		const params = (type) => {
			return {
				type: type,
				take: reportsLimit,
				offset: offsetReports,
			};
		};

		if (role === "coach") {
			dispatch(getEngagementReports(authToken, removeEmptyValues(params(""))));
			dispatch(
				getEvaluationReports(authToken, removeEmptyValues(params("evaluation")))
			);
			dispatch(
				getBillingOverviewReports(authToken, removeEmptyValues(params("")))
			);
			dispatch(getCoachInvoices(authToken, removeEmptyValues(params(""))));
		}

		if (role === "participant") {
			dispatch(
				getFeedbackReports(authToken, removeEmptyValues(params("feedback")))
			);
		}

		if (role === "school_admin") {
			dispatch(getEngagementReports(authToken, removeEmptyValues(params(""))));
			dispatch(
				getEvaluationReports(authToken, removeEmptyValues(params("evaluation")))
			);
			dispatch(
				getFeedbackReports(authToken, removeEmptyValues(params("feedback")))
			);
		}

		return () => {
			dispatch(clearReportsData());
		};
	}, [role]);

	useEffect(() => {
		dispatch(updatePageTitle(REPORTS_TITLE));
	}, []);

	return (
		<div className="page-reports">
			{reportsLoading ? (
				// reportsExist - used to prevent disappearing reports tabs and filters options when a user doesn't have any reports after filtering
				reportsExist ? (
					<ReportsDesk
						feedbackReports={feedbackReports}
						evaluationReports={evaluationReports}
						engagementReports={engagementReports}
						billingReports={billingReports}
						invoices={invoices}
					/>
				) : (
					<EmptyProgrammes>
						<img
							src={EmptyImage}
							alt=""
							className="page-programmes__empty-img"
						/>
						<h2 className="page-programmes__empty-title">
							You don’t have any reports yet
						</h2>
					</EmptyProgrammes>
				)
			) : (
				<Spinner />
			)}
		</div>
	);
}
