import React from 'react';

const CalendarIcon = ({color = '#101821', width = 22, height = 24, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="11" width="22" height="13" rx="2" fill={color}/>
            <path d="M5 1.5C5 0.671573 5.67157 0 6.5 0C7.32843 0 8 0.671573 8 1.5V3.5C8 4.32843 7.32843 5 6.5 5C5.67157 5 5 4.32843 5 3.5V1.5Z" fill={color}/>
            <path d="M14 1.5C14 0.671573 14.6716 0 15.5 0C16.3284 0 17 0.671573 17 1.5V3.5C17 4.32843 16.3284 5 15.5 5C14.6716 5 14 4.32843 14 3.5V1.5Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12 3H10V3.5C10 5.433 8.433 7 6.5 7C4.567 7 3 5.433 3 3.5V3H2C0.895431 3 0 3.89543 0 5V7C0 8.10457 0.89543 9 2 9H20C21.1046 9 22 8.10457 22 7V5C22 3.89543 21.1046 3 20 3H19V3.5C19 5.433 17.433 7 15.5 7C13.567 7 12 5.433 12 3.5V3ZM14 3V3.5C14 4.32843 14.6716 5 15.5 5C16.3284 5 17 4.32843 17 3.5V3H14ZM5 3.5V3H8V3.5C8 4.32843 7.32843 5 6.5 5C5.67157 5 5 4.32843 5 3.5Z" fill={color}/>
        </svg>
    );
};

export default CalendarIcon;