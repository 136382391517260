import React from 'react';
import { useSelector } from 'react-redux';

// For ILTB only
// import decor_image from 'assets/vector/questionnaire/Vector-2.svg';

const Description = ({ role }) => {

    const { survey } = useSelector(state => state.currentUserInfo.currentUserInfo);

    const duration = role === 'coach'
        ? '20'
        : survey?.survey_id === 2 ? '15' : '30';

    const sectionQuantity = role === 'coach' ? 'two' : 'three';

    const showList = (role) => {
        
        if (role === "coach") {
            return (
                <ol className="list">
                    <li className="list__item">Your Background & Aims</li>
                    <li className="list__item">Your Coaching Skills and Experience</li>
                </ol>
            )
        }

        return (
            <ol className="list">
                <li className="list__item">Your Background & Aims</li>
                <li className="list__item">Your Coaching Goals and Priorities</li>
                <li className="list__item">Your Inner and Outer Self</li>
            </ol>
        )
    }

    return (
        <div className="questionnaire__description">
            {/* for ITLB */}
            {/* <img className="decor_img" src={decor_image} alt="" /> */}
            <div className="description__text column">
                In the following <span>{duration} minutes</span> you will go through <span>{sectionQuantity} sections</span> of questions:
            </div>
            <div className="description__list column">
                {showList(role)}
            </div>
        </div>
    )
}

export default Description;