import React from 'react';
import uniqid from "uniqid";
import './CategoryDescriptions.scss';
// import CrossIcon from "components/icons/CrossIcon";
// import CategoryDescriptionEditor
//     from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/CategoryDescriptionEditor/CategoryDescriptionEditor";
// import EditIcon from "components/icons/EditIcon";
import CategoryDescriptionItem
    from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/CategoryDescriptionItem/CategoryDescriptionItem";

const CategoryDescriptions = ({ descriptions, categories, removeDescription, editDescription }) => {
    const descriptionList = descriptions?.map(
        (description, index) => <CategoryDescriptionItem description={description}
            num={++index}
            key={uniqid()}
            removeDescription={removeDescription}
            editDescription={editDescription}
            categories={categories}

        />);

    return (
        <div className="category-description">
            {descriptionList}
        </div>
    );
};

export default CategoryDescriptions;