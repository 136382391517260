import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as ArrowSvg } from "assets/vector/questionnaire/Vector.svg";
import { REACT_APP_REPORT_DOWNLOADING_URL } from "constants/api";
import { languageSelection } from 'helpers/languageSelection';
import useClickOutside from 'hooks/useClickOutside';
import './DownloadReportButton.scss';

const DownloadReportButton = ({ participantId }) => {

    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [showLanguageOptions, setShowLanguageOptions] = useState(false);
    const wrapperRef = useRef()

    const { id, role } = useSelector(state => state.currentUserInfo.currentUserInfo);

    const downloadReportHandler = (lang) => {
        const userId = role === 'participant' ? id : participantId;

        if (role === 'participant') {
            window.open(`${REACT_APP_REPORT_DOWNLOADING_URL}/participant-report/${userId}/${lang}`)
        } else {
            window.open(`${REACT_APP_REPORT_DOWNLOADING_URL}/participant-report/${userId}/${lang}/1`)
        }
    }

    useClickOutside(wrapperRef, () => {
        if (showLanguageOptions) setShowLanguageOptions(false)
    })

    return (
        <div ref={wrapperRef} className='download-report-button__wrapper' onClick={() => setShowLanguageOptions(!showLanguageOptions)}>
            <div className='download-report__button'>Download report</div>
            <div className={`select-language__btn ${showLanguageOptions ? 'active' : ''}`}>
                <ArrowSvg />
            </div>
            <div className={`download-report__languages ${showLanguageOptions ? 'active' : ''}`}>
                {
                    languageSelection.map(language =>
                        <div className={`language__option ${selectedLanguage === language.value ? 'active' : ''}`}
                            key={language.id}
                            onClick={() => {
                                setSelectedLanguage(language.value)
                                downloadReportHandler(language.value);
                                setShowLanguageOptions(!showLanguageOptions)
                            }}>
                            <img src={language.icon} alt="" />
                            {language.label}
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default DownloadReportButton;
