import React from 'react';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import Register from "components/pages/Auth/Register/Register";
import DefaultAuth from "components/pages/Auth/DefaultAuth/DefaultAuth";
import routes from "constants/routes";
import {useSelector} from "react-redux";
import LoginEnter from "components/pages/Auth/LoginEnter";
import RegisterEnter from "components/pages/Auth/RegisterEnter";

const Auth = () => {
    const {path} = useRouteMatch();
    const {REGISTER_FOR_PROGRAM} = routes;
    const {authToken} = useSelector((state) => state.auth);

    return (
        <Switch>
            <Route exact path={REGISTER_FOR_PROGRAM}
                   render={({match}) => authToken ? <RegisterEnter match={match} /> : <Register/>}
            />
            <Route path={`${path}`}
                   render={(match) => authToken ? <LoginEnter match={match} /> : <DefaultAuth/>}
            />
        </Switch>
    );
}

export default Auth;