import React from 'react';

const MenuToggle = ({toggleMenu}) => {
    return (
        <div className='menu-toggle' onClick={toggleMenu}>
            <div className="menu-toggle__line"></div>
            <div className="menu-toggle__line"></div>
            <div className="menu-toggle__line"></div>
        </div>
    );
};

export default MenuToggle;