import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./EditMyProfile.scss";
import CoachesEditProfile from "./edit-profile-coach";
import ParticipantEditProfile from "./edit-profile-participant";
import SchoolAdminEditProfile from "./edit-profile-school-admin";
import { updatePageTitle } from "../../../redux/actions";
import pageTitles from "constants/pageTitles";

export default function EditMyProfile() {
	const dispatch = useDispatch();
	const userInfo = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const { MY_PROFILE_TITLE } = pageTitles;

	useEffect(() => {
		dispatch(updatePageTitle(MY_PROFILE_TITLE));
	}, []);

	const renderProfile = () => {
		switch (userInfo?.role) {
			case "coach":
				return <CoachesEditProfile userInfo={userInfo} />;
			case "participant":
				return <ParticipantEditProfile userInfo={userInfo} />;
			case "school_admin":
				return <SchoolAdminEditProfile userInfo={userInfo} />;
			default:
				return null;
		}
	};

	return (
		<div className="section-wrap">
			<div className="edit-my-profile">{renderProfile()}</div>
		</div>
	);
}
