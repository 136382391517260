import React, { useMemo } from 'react';
import './SessionCard.scss';
import EventLink
  from 'components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/SessionInvitation/SessionCard/Event/EventLink/EventLink';
import EventDuration
  from 'components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/SessionInvitation/SessionCard/Event/EventDuration/EventDuration';
import EventDate
  from 'components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/SessionInvitation/SessionCard/Event/EventDate/EventDate';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';

const SessionCard = ({ session, status, duration, actions }) => {
  const { id: userId } = useSelector(state => state.currentUserInfo.currentUserInfo);
  const { name, date_start, link, details, participants, coaches } = session;
  const startDate = moment(date_start).local().format('MMMM DD, HH:mm A');
  const isAlert = status === 'late_canceled' || status === 'declined' || status === 'canceled';
  const getStatusText = () => {
    switch (status) {
      case 'pending':
        return 'awaiting confirmation';
      case 'accepted':
        return 'confirmed';
      case 'expired':
        return 'session passed';
      case 'declined':
        return 'canceled';
      case 'noCoach':
        return 'Other coach';
      default:
        return status.replace('_', ' ');
    }
  };

  const sessionUsers = useMemo(() => {
    const users = [...participants, ...coaches].filter(user => user.id !== userId);

    return users.map((user, index) => (
      <img src={user.avatar}
           alt=""
           className="session-card__user" key={user.id}
           style={{ zIndex: users.length - index, right: `${12 * index}px` }}/>
    ));
  }, [participants, coaches, userId]);

  return (
    <div className={`session-card session-card--${status}`}>
      <div className="session-card__line"/>
      <div className="session-card__header">
        <h3 className="session-card__title">{name}</h3>
        <p className="session-card__status">{getStatusText()}</p>
      </div>
      <EventDate text={startDate}/>
      <EventDuration text={`${duration}m`}/>
      <EventLink link={link} className="session-card__link"/>
      {details && !isAlert && <p className="session-card__details">{details}</p>}
      {isAlert && (
        <p className="session-card__alert">Event was {status || 'cancelled'}. Please check or request for another
          invitation
        </p>
      )}
      <footer className="session-card__footer">
        <div className="session-card__users">{sessionUsers}</div>
        <div className="session-card__actions">
          {actions}
        </div>
      </footer>
    </div>
  );
};

export default SessionCard;
