import React from 'react';
import CategorizedActionItem
    from "components/pages/Programmes/SingleProgramme/CategorizedActionItem/CategorizedActionItem";
import uniqid from "uniqid";
import './CategorizedActionItems.scss';

const CategorizedActionItems = ({categorized_todo_tasks, categories, activityId, removeActionItem, toggleCompleted}) => {
    const categorizedToDoList = categorized_todo_tasks.map((todoTask, index) => (
        <CategorizedActionItem num={++index}
                               key={uniqid()}
                               activityId={activityId}
                               todo={todoTask}
                               categories={categories}
                               toggleCompleted={toggleCompleted}
                               removeActionItem={removeActionItem}
        />
    ));

    return (
        <div className='category-actions'>
            {categorizedToDoList}
        </div>
    );
};

export default CategorizedActionItems;