import React from 'react';

import { useField, ErrorMessage } from 'formik';

const TextareaForm = ({ label, item, values, tooltip, goToNextStep, goToPreviousStep, stepDirection, question, ...props }) => {

    const [field] = useField(props);

    const { name } = props;

    React.useEffect(() => {

        if (item?.condition) {
            let array = item.condition

            let check = array?.filter(item => {
                if (typeof values[item.key] === 'number') {
                    return values[item.key] === item.val && item
                }
                return values[item.key].includes(item.val) && item
            })

            if (!check?.length) {
                if (stepDirection === "next") {
                    goToNextStep()
                }
                if (stepDirection === "back") {
                    goToPreviousStep()
                }
            }
        }

    }, [item?.condition, goToNextStep, values, goToPreviousStep, stepDirection])

    return (
        <>
            <div className="textarea-form">
                <div className="form-question">{question}</div>
                {tooltip && <div className="question_tooltip">{tooltip}</div>}
                <textarea {...field} {...props} id="textarea" cols="30" rows="10"></textarea>
            </div>
            <ErrorMessage name={name} render={msg => <p className="error-message">{msg}</p>} />
        </>
    )
}

export default TextareaForm;