const filterUniquePrograms = (programs) => {
    const uniqueProgramsId = [];

    return programs.filter(program => {
        if (!uniqueProgramsId.find(id => id === program.id)) {
            return uniqueProgramsId.push(program.id);
        }

        return false;
    });
}

export const transformSessionFilter = (stateFilters, filterData) => {
    const {defaultData} = stateFilters;
    const uniquePrograms = filterUniquePrograms(filterData.school_programs);

    if (Object.keys(defaultData).length === 0) {
        stateFilters['defaultData'] = {...filterData, school_programs: uniquePrograms};

        return stateFilters;

    } else {
        stateFilters['updatedData'] = {...filterData, school_programs: uniquePrograms};

        return stateFilters;
    }
};
