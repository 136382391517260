import React from 'react'
import './TotalStudents.scss'
import icon from 'assets/vector/total_participants_icon.svg'

const TotalStudents = ({total = 0, active = 0}) => {
  return (
      <div className='total-participants__wrapper'>
          <img className='icon' src={icon} alt="icon" />
          <div className='total-values'>Total Students: <span>{total}</span> (Active: <span>{active}</span>)</div>
    </div>
  )
}

export default TotalStudents