import React from "react";
import uniqid from "uniqid";

import { Radio, RadioGroup, FormControlLabel } from "@mui/material";

import vector1 from "assets/images/FeedbackForm/Vector_73.svg";
import vector2 from "assets/images/FeedbackForm/Vector_77.svg";

const CheckedIcon = () => {
	return (
		<svg
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.6274 1.30797C17.2175 0.75259 18.2241 -0.184623 19.1266 0.301339C21.4523 1.5509 18.5018 4.46673 17.5646 5.64692C16.072 7.55606 14.51 9.39583 12.9827 11.2703C11.8025 12.6934 10.7612 14.2555 9.4768 15.6439C8.74786 16.4423 7.49824 17.9002 6.28333 17.8307C5.2767 17.796 4.40891 17.0671 3.74939 16.3729C2.18737 14.7415 0.069957 12.1728 0.000533835 9.77766C-0.0341778 7.93795 1.63198 8.49333 2.63862 9.25699C4.02708 10.333 5.10314 11.652 6.35276 12.867C6.90814 11.8256 7.81064 10.9231 8.64372 10.0901C10.1016 8.59741 11.4207 7.0701 12.8438 5.54279C14.1282 4.15433 15.2737 2.62702 16.6274 1.30797Z"
				fill="white"
			/>
		</svg>
	);
};

const RadioTenItems = ({
	field,
	name,
	options,
	children,
	question,
	...props
}) => {
	const fieldName = name || field.name;

	// used to change the default colors for the checked radio buttons when navigating between shape steps
	const _addClasses = (item) => {
		switch (item) {
			case 0:
				return "first";
			case 1:
				return "second";
			case 2:
				return "third";
			case 3:
				return "fourth";
			case 4:
				return "fifth";
			case 5:
				return "sixth";
			case 6:
				return "seventh";
			case 7:
				return "eighth";
			case 8:
				return "ninth";
			case 9:
				return "tenth";
			default:
				return "";
		}
	};

	const renderOptions = (options) => {
		const colors = [
			"#366B65",
			"#3D8B82",
			"#35A684",
			"#93CBFF",
			"#4296E5",
			"#FDC94C",
			"#ECAF2B",
			"#E75B32",
			"#E07B93",
			"#9F3C47",
		];
		const backgroundColors = [
			"rgb(54, 107, 101, 0.2)",
			"rgb(61, 139, 130, 0.2)",
			"rgb(53, 166, 132, 0.2)",
			"rgb(147, 203, 255, 0.2)",
			"rgb(66, 150, 229, 0.2)",
			"rgb(253, 201, 76, 0.2)",
			"rgb(236, 175, 43, 0.2)",
			"rgb(231, 91, 50, 0.2)",
			"rgb(224, 123, 147, 0.2)",
			"rgb(159, 60, 71, 0.2)",
		];

		const changeColorAfterChecked = (index) => {
			const radioButtons = document.querySelectorAll(
				".MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-1.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary"
			);
			for (let i = 0; i < radioButtons.length; i++) {
				if (i === index) {
					radioButtons[i].style.backgroundColor = colors[i];
				} else {
					radioButtons[i].style.backgroundColor = backgroundColors[i];
				}
			}
		};

		return options.map((option, index) => (
			<FormControlLabel
				style={{
					width: "64px",
					height: "64px",
				}}
				key={uniqid()}
				value={option.option}
				control={
					<Radio
						className={_addClasses(index)}
						onChange={() => changeColorAfterChecked(index)}
						checkedIcon={<CheckedIcon />}
						style={{
							color: colors[index],
							backgroundColor: backgroundColors[index],
							width: "64px",
							height: "64px",
						}}
					/>
				}
			/>
		));
	};

	return (
		<>
			<div className="form-question">{question}</div>
			<div
				className="radio__buttons ten__buttons"
				role="group"
				aria-labelledby="my-radio-group"
			>
				<img className="vector vector_1" src={vector1} alt="" />
				<img className="vector vector_2" src={vector2} alt="" />
				<img className="vector vector_3" src={vector1} alt="" />
				<img className="vector vector_4" src={vector2} alt="" />
				<img className="vector vector_5" src={vector1} alt="" />
				<img className="vector vector_6" src={vector2} alt="" />
				<img className="vector vector_7" src={vector1} alt="" />
				<img className="vector vector_8" src={vector2} alt="" />
				<img className="vector vector_9" src={vector1} alt="" />
				<div className="criteria min mobile">{options[0].option}</div>
				<React.Fragment>
					<RadioGroup {...field} {...props} name={fieldName}>
						{options ? renderOptions(options) : children}
					</RadioGroup>
				</React.Fragment>
				<div className="criteria max mobile">
					{options[options.length - 1].option}
				</div>
			</div>
			<div className="radio__criterias">
				<div className="criteria min">{options[0].option}</div>
				<div className="criteria max">{options[options.length - 1].option}</div>
			</div>
		</>
	);
};

export default RadioTenItems;
