export const programStatusList = [
    {
        value: 'active',
        label: 'Active'
    },
    {
        value: 'deactivated',
        label: 'Deactivated'
    },
    {
        value: '',
        label: 'All'
    },
]

export const programStatusListSchoolAdmin = [
    {
        value: 'active',
        label: 'Active'
    },
    {
        value: 'deactivated',
        label: 'Completed'
    },
    {
        value: '',
        label: 'All'
    },
]