import React from 'react';
import './LockedContent.scss';
import { ReactComponent as LockIcon } from "assets/vector/programmes/lock.svg";
import { ReactComponent as LockImage } from "assets/vector/programmes/locked-activity.svg";

const LockedContent = ({ typeName, num, topicNum, name, coachId, isDeactivated }) => {
    return (
        <div className="topics-content__head topics-content__head--blocked">
            <div>
                <span className="topics-content__num">
                    {topicNum ? `${topicNum}.` : null}
                    {num ? `${num}. ` : null}
                    {typeName}
                </span>
                <h2 className='topics-content__title'>{name}</h2>
            </div>
            <h4 className="topics-content__block">
                Activity is not available
                <LockIcon className="topics-content__block-icon" />
            </h4>
            <div className="locked-info">
                <LockImage className="locked-info__img" />
                {
                    isDeactivated
                        ? <h3 className="locked-info__title">Program is deactivated</h3>
                        : <h3 className="locked-info__title">Activity is not available yet...</h3>
                }
                {isDeactivated
                    ? null
                    : coachId
                        ? <p className="locked-info__text">Please complete previous activity to unlock this one</p>
                        : <p className="locked-info__text">Please wait when a coach will be allocated</p>
                }
            </div>
        </div>
    );
};

export default LockedContent;