import React from 'react';
import { Field, ErrorMessage } from 'formik';
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';

const DatepickerForFormik = ({ form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    const { language } = useSelector(state => state.questionnaireLanguage);

    return (
        <DatePicker
            {...field}
            selected={(value && new Date(value)) || null}
            name={name}
            onChange={val => setFieldValue(name, val)}
            dateFormat="dd-MM-yyyy"
            maxDate={new Date()}
            placeholderText={`${language === 'it' ? 'Inserisci la data in formato' : 'Enter date in format'}: 01-01-1970`}
            showDayMonthYearPicker
        />
    );
};

const DateStep = (props) => {
    const { question, name } = props;

    return (
        <>
            <div className="form-question date-step">{question}</div>
            <div className="select__container">
                <Field name={name} component={DatepickerForFormik}/>
            </div>
            <ErrorMessage name={name} render={msg => <p className="error-message">{msg}</p>}/>
        </>
    )
}

export default DateStep;