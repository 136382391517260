import React from "react";
import Select, { components } from 'react-select';
import { customSelectStyles } from "helpers/customSelectStyles";
import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";

const typeSelectStyles = Object.assign({}, customSelectStyles);

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowSvg />
        </components.DropdownIndicator>
    );
};

const CustomProfileLanguagesSelect = ({ defaultOptions, field, options, placeholder, defaultValue, form: { setFieldValue } }) => {

    const onChangeSelect = (optionValue) => {
        setFieldValue(field.name, optionValue.id)
    };

    const getValue = (options, val) => {
        if (options) {
            return options.find(option => option.id === val);
        } else {
            return "";
        }
    };

    return (
        <Select
            getOptionLabel={options => options.name}
            getOptionValue={options => options.id}
            components={{ DropdownIndicator }}
            name={field.name}
            options={options}
            placeholder={placeholder}
            classNamePrefix={`row-select`}
            styles={typeSelectStyles}
            isSearchable={false}
            onChange={(optionValue) => onChangeSelect(optionValue)}
            value={getValue(defaultOptions || options, defaultValue?.id ? Number(defaultValue.id) : defaultValue)}
        />
    )
}

export default CustomProfileLanguagesSelect;
