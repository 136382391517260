import React from 'react';
import './Switch.scss';

const Switch = ({ toggleActive, active = false }) => {
    return (
        <div className={`switch__wrapper ${active ? 'active' : ''}`} onClick={() => toggleActive(!active)}>
            <div className='switch__ellipse'></div>
        </div>
    )
}

export default Switch