import React, { useState } from 'react';
import CategoryDescriptionEditor
    from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/CategoryDescriptionEditor/CategoryDescriptionEditor";
import EditIcon from "components/icons/EditIcon";
import CrossIcon from "components/icons/CrossIcon";
import { useSelector } from "react-redux";
import Linkify from "linkify-react";

const CategoryDescriptionItem = ({ categories, description, num, removeDescription, editDescription }) => {
    const userRole = useSelector(state => state.currentUserInfo.currentUserInfo.role);

    const categoryRelatedNames = categories.reduce((list, category) => {
        list[category.id] = category.name;

        return list;
    }, {});

    const [isCategoryEditing, setIsCategoryEditing] = useState(false);

    const showCategoryEditor = () => {
        setIsCategoryEditing(true);
    };

    const hideCategoryEditor = () => {
        setIsCategoryEditing(false);
    };

    function createMarkup(content) {
        return { __html: content };
    }

    return (
        <>
            {
                isCategoryEditing ?
                    <CategoryDescriptionEditor textLabel={'Description'}
                        selectLabel={'Topic'}
                        options={categories}
                        itemId={description.itemId}
                        handleSubmit={editDescription}
                        selectPlaceholder={'Choose topic...'}
                        hideCategoryEditor={hideCategoryEditor}
                        defaultCategory={{
                            id: description.category_id,
                            label: categoryRelatedNames[description.category_id]
                        }}
                        defaultText={description.description}
                    />

                    :

                    <>
                        <h5 className='category-description__topic'>
                            Topic {num}
                        </h5>
                        <h4 className='category-description__title'>
                            {categoryRelatedNames[description.category_id]}
                            {
                                userRole === 'coach' &&
                                <>
                                    <EditIcon className='category-description__edit'
                                        onClick={showCategoryEditor}
                                    />
                                    <CrossIcon className='category-description__remove'
                                        onClick={() => removeDescription(description.itemId)}
                                    />
                                </>
                            }
                        </h4>
                        {
                            userRole === 'coach' && (
                                <div className='category-description__text' dangerouslySetInnerHTML={createMarkup(description.description)} />
                            )
                        }
                    </>
            }
        </>
    );
};

export default CategoryDescriptionItem;