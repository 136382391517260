import { CURRENT_PAGE_TITLE } from "../constants";

const initialState = {
	pageTitle: "",
};

export default function currentPageTitleReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CURRENT_PAGE_TITLE:
			return {
				...state,
				pageTitle: payload,
			};

		default:
			return state;
	}
}
