const transformDateForEditScheduleTimePicker = (time) => {

    // time - it's an initial value of the field
    // when we open form at first time and have some data from the API - the type of the field is string ("00:00")
    // when we remove the daily break and after that move it back - the type of field is Object (instance of new Date())
    // so we need to check the type of field value
    if (typeof time === 'string') {
        const today = new Date();
        const existTime = time?.split(':');
        const myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), +existTime[0], +existTime[1], 0);

        return myToday;
    }

    return time;
}

export default transformDateForEditScheduleTimePicker;
