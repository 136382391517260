import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {ReactComponent as LinkArrow} from "assets/vector/auth-page/return-arrow.svg";
import SignInForm from "components/forms/SignIn/SignInForm";
import {useSelector} from "react-redux";
import Notification from "components/auth/notification/Notification";
import routes from "constants/routes";

const SignIn = () => {
        const {errorLogged, doingRequestLogin} = useSelector((state) => state.auth);
        const {registerForProgramError, registerForProgramInProgress} = useSelector((state) => state.programs);
        const [isErrorNotification, setErrorNotification] = useState(false);
        const urlSearchParams = new URLSearchParams(useLocation().search)
        const programCode = urlSearchParams.get('code');
        const programId = urlSearchParams.get('id');
        const {REGISTER_PROGRAMS} = routes;

        useEffect(() => {
            let timedNotification;

            if (errorLogged && !doingRequestLogin || registerForProgramError && !registerForProgramInProgress) {
                timedNotification = setTimeout(() => setErrorNotification(false), 5000);
                setErrorNotification(true);

            } else if (doingRequestLogin || registerForProgramInProgress) {
                clearTimeout(timedNotification);
                setErrorNotification(false);
            }
        }, [errorLogged, doingRequestLogin, registerForProgramError, registerForProgramInProgress]);

        return (
            <>
                {
                    programCode && programId && (
                        <Link to={`${REGISTER_PROGRAMS}/${programCode}/${programId}`} className="default-auth__return-link">
                            <LinkArrow className="default-auth__return-icon"/>
                            Back to registration
                        </Link>
                    )
                }
                <h2 className="default-auth__title">
                    <Notification show={isErrorNotification}>{errorLogged || registerForProgramError}</Notification>
                    Login
                </h2>
                <SignInForm code={programCode} id={programId}/>
            </>
        );
    }
;

export default SignIn;