import { calendarGoogleEvents } from "../constants";

const initialState = {
	calendarGoogleEvents: null,
	calendarGoogleEventsExist: false,
	calendarGoogleEventsNull: true,
	calendarGoogleEventsErrorMessage: null,
};

export default function calendarGoogleEventsReducer(
	state = initialState,
	action
) {
	const { type, payload } = action;

	switch (type) {
		case calendarGoogleEvents.GET_GOOGLE_EVENTS_OF_CALENDAR_SUCCESS:
			return {
				...state,
				calendarGoogleEvents: payload,
				calendarGoogleEventsNull: true,
				calendarGoogleEventsExist: true,
			};

		case calendarGoogleEvents.GET_GOOGLE_EVENTS_OF_CALENDAR_NULL:
			return {
				...state,
				calendarGoogleEvents: payload,
				calendarGoogleEventsNull: false,
			};

		case calendarGoogleEvents.GET_GOOGLE_EVENTS_OF_CALENDAR_ERROR:
			return {
				...state,
				calendarGoogleEventsErrorMessage: payload,
				calendarGoogleEventsNull: false,
			};

		case calendarGoogleEvents.CLEAR_CALENDAR_GOOGLE_EVENTS_LIST:
			return {
				...state,
				calendarGoogleEvents: null,
				calendarGoogleEventsExist: false,
			};

		default:
			return state;
	}
}
