import { chatConstant } from "../constants";

export function setChatOpen(isOpen) {
	return {
		type: chatConstant.SET_IS_CHAT_OPEN,
		payload: isOpen,
	};
}

export function setChatUser(user) {
	return {
		type: chatConstant.SET_CHAT_USER,
		payload: user,
	};
}

export function sendMessageToUser(userData) {
	return {
		type: chatConstant.SEND_MESSAGE_TO_USER,
		payload: userData,
	};
}

export function sendMessageToUserSuccess(message) {
	return {
		type: chatConstant.SEND_MESSAGE_TO_USER_SUCCESS,
		payload: message,
	};
}

export function sendMessageToUserError(error) {
	return {
		type: chatConstant.SEND_MESSAGE_TO_USER_ERROR,
		payload: error,
	};
}

export function getListRooms() {
	return {
		type: chatConstant.GET_LIST_OF_ROOMS,
	};
}

export function getListRoomsSuccess(listRooms) {
	return {
		type: chatConstant.SUCCESS_ROOMS,
		payload: listRooms,
	};
}

export function getErrorListRooms(error) {
	return {
		type: chatConstant.ERROR_ROOMS,
		payload: error,
	};
}

export function getListUsers() {
	return {
		type: chatConstant.GET_LIST_OF_USERS,
	};
}

export function getListUsersSuccess(listUsers) {
	return {
		type: chatConstant.SUCCESS_USERS,
		payload: listUsers,
	};
}

export function getEmptyListUsers(emptylistUsers) {
	return {
		type: chatConstant.EMPTY_USERS,
		payload: emptylistUsers,
	};
}

export function getErrorListUsers(error) {
	return {
		type: chatConstant.ERROR_USERS,
		payload: error,
	};
}

export function getMessagesFromUser(roomID) {
	return {
		type: chatConstant.GET_MESSAGES_FROM_USERS,
		payload: roomID,
	};
}

export function getMessagesFromUserSuccess(messages) {
	return {
		type: chatConstant.GET_MESSAGES_FROM_USERS_SUCCESS,
		payload: messages,
	};
}

export function getEmptyMessagesFromUser(messages) {
	return {
		type: chatConstant.GET_EMPTY_MESSAGES_FROM_USERS,
		payload: messages,
	};
}

export function getMessagesFromUserError(error) {
	return {
		type: chatConstant.GET_MESSAGES_FROM_USERS_ERROR,
		payload: error,
	};
}

export function clearUserMessages() {
	return {
		type: chatConstant.CLEAR_USER_MESSAGES,
	};
}

export function getMessagesFromUserOnScroll(newMessages) {
	return {
		type: chatConstant.GET_MESSAGES_FROM_USER_ON_SCROLL,
		payload: newMessages,
	};
}

export function getTotalMessages(countMessages) {
	return {
		type: chatConstant.GET_TOTAL_COUNT_MESSAGES,
		payload: countMessages,
	};
}

export function chatSearchRoom(searchText) {
	return {
		type: chatConstant.CHAT_SEARCH_ROOM,
		payload: searchText,
	};
}

export function chatWithUser() {
	return {
		type: chatConstant.CHAT_WITH_USER,
	};
}

export function statusCheckMessages(getStatus) {
	return {
		type: chatConstant.GET_NEW_CHAT_MESSAGE,
		payload: getStatus,
	};
}

export function statusReadedChat(getStatus) {
	return {
		type: chatConstant.CHAT_READED,
		payload: getStatus,
	};
}

export function getMessagesSpecifiedUser(userData) {
	return {
		type: chatConstant.GET_MESSAGES_SPECIFIED_USER,
		payload: userData,
	};
}

export function clearChatData() {
	return {
		type: chatConstant.CLEAR_CHAT_DATA,
	};
}
