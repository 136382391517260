import React from 'react'
import SelectCurrencyRadio from 'components/UI/SelectCurrencyRadio/SelectCurrencyRadio'
import './SelectCurrency.scss'

// USD, GBP, EUR, CHF
const currencyList = [
    {
        value: '',
        label: 'All'
    },
    {
        value: 'USD',
        label: 'USD'
    },
    {
        value: 'GBP',
        label: 'GBP'
    },
    {
        value: 'EUR',
        label: 'EUR'
    },
    {
        value: 'CHF',
        label: 'CHF'
    },
]

const SelectCurrency = ({ currency, setCurrency }) => {
    return (
        <div className='select-currency'>
            <div className="select-currency__title">Select currency:</div>
            <div className="select-currency__list">
                {
                    currencyList.map(item =>
                        <SelectCurrencyRadio
                            key={item.value}
                            text={item.label}
                            value={item.value}
                            selected={currency}
                            onChange={setCurrency}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default SelectCurrency