import { transformedTimezoneForMoment } from 'helpers/transformedTimezoneForMoment';
import moment from "moment-timezone";
import { useEffect, useState } from "react";

export const useUserSyncTime = (timezone) => {
    const userTime = moment.tz(transformedTimezoneForMoment(timezone));
    const userUTC = userTime.format('Z');
    const userHours = userTime.format('HH:mm');
    const [currentUserTime, setUserTime] = useState(userHours);

    const syncUserTime = () => {
        const currentHours = moment.tz(transformedTimezoneForMoment(timezone)).format(('HH:mm'));
        setUserTime(currentHours);
    };

    useEffect(() => {
        const timerId = setInterval(syncUserTime, 60000);

        return () => clearInterval(timerId);
    }, []);

    return { currentUserTime, userUTC };
};