import React, {useState} from 'react';
import '../ReportsListItem/ReportsListItem.scss';
import StarRating from "components/starRating/StarRating";
import DownArrowIcon from "components/icons/DownArrowIcon";
import SessionDataRows from "components/pages/Reports/SessionDataRows/SessionDataRows";
import { useMediaQuery } from "react-responsive";
import ReportsCheckbox from '../ReportsCheckbox/ReportsCheckbox';

const ReportsEngagementListItem = ({
    userRole,
    num,
    avatar,
    student_name,
    program_name,
    program_id,
    first_session_completed_at,
    last_session_completed_at,
    institution,
    total_session_activities,
    sessions_completed,
    average_feedback_rate,
    average_evaluation_rate,
    sessions,
    // survey_filled,
    main_coach
}) => {

    const isMaxTabletScreen = useMediaQuery({maxWidth: 1319});
    const isMinPcScreen = useMediaQuery({minWidth: 1320});

    const [isSessionShown, setIsSessionShown] = useState(false);
    const [isInfoShown, setIsInfoShown] = useState(false);

    const showSessions = () => {
        setIsSessionShown(!isSessionShown);
    };

    const showInfo = () => {
        setIsInfoShown(!isInfoShown);

        if (isSessionShown) {
            setIsSessionShown(false);
        }
    };

    return (
        <>
            <tr className={
                `reports-item engagement 
            ${isInfoShown ? 'reports-item--info-shown' : ''}
            `
            }>
                <td className="reports-item__cell reports-item__cell--checkbox">
                    <ReportsCheckbox id={program_id} />
                </td>
                <td className="reports-item__cell reports-item__cell--num">
                    {num}
                </td>
                <td className="reports-item__cell reports-item__cell--user">
                    <ReportsCheckbox id={program_id} />
                    {
                        avatar && <img src={avatar} alt="user avatar" className="reports-item__user-img"/>
                    }
                    <span className="reports-item__name">
                    {student_name}
                        <StarRating rating={userRole === 'coach' ? average_evaluation_rate : average_feedback_rate}/>
                </span>
                    <DownArrowIcon className="reports-item__open"
                                   onClick={showInfo}
                                   color={isInfoShown ? '#3D8B82' : '#101821'}/>
                </td>
                {
                    userRole === 'coach' && (
                        <td className="reports-item__cell reports-item__cell--institution">
                            {institution}
                        </td>
                    )
                }
                <td 
                    className="reports-item__cell reports-item__cell--program" 
                   
                >
                    {program_name}
                    <span className="reports-item__cell-name" >Coaching programme</span>
                </td>
                {
                    userRole === 'school_admin' && 
                    <>
                        <td 
                            className="reports-item__cell reports-item__cell--coach" 
                           
                        >
                            {main_coach ? 'Yes' : '-'}
                            <span className="reports-item__cell-name">Coach</span>
                        </td>
                        {/* <td 
                            className="reports-item__cell reports-item__cell--survey"
                           
                        >
                            {survey_filled ? 'Yes' : '-'}
                            <span className="reports-item__cell-name" >Survey</span>
                        </td> */}
                    </>
                    
                }
                <td 
                    className="reports-item__cell reports-item__cell--sessions"
                   
                >
                    {first_session_completed_at || '-'}
                    <span className="reports-item__cell-name">First session</span>
                </td>
                <td 
                    className="reports-item__cell reports-item__cell--sessions"
                   
                >
                    {last_session_completed_at || '-'}
                    <span className="reports-item__cell-name">Last session</span>
                </td>
                <td 
                    className="reports-item__cell"
                       
                >
                    {
                        `${sessions_completed ? sessions_completed : 0}/${total_session_activities ? total_session_activities : 0}`
                    }
                    <span className="reports-item__cell-name">Sessions</span>
                </td>
                <td className="reports-item__cell">
                    <div className="stars_rating-mark">
                        <StarRating rating={userRole === 'coach' ? average_evaluation_rate : average_feedback_rate}/>
                    </div>
                </td>
                <td className="reports-item__cell reports-item__cell--details">
                    {
                        sessions?.length > 0 && (
                            <button onClick={showSessions} className='reports-item__open-details'>
                                {isSessionShown ? 'Hide' : 'Details'}
                            </button>
                        )
                    }
                </td>
                {
                    isSessionShown && isMaxTabletScreen && <SessionDataRows userRole={userRole} sessions={sessions}/>
                }
            </tr>
            {
                isSessionShown && isMinPcScreen && <SessionDataRows userRole={userRole} sessions={sessions}/>
            }
        </>
    );
};

export default ReportsEngagementListItem;