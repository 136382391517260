
const EventPropGetter = (e) => { 

    let newStyle = { backgroundColor: "#efefef",  border: '0'};

    if (!e.user_id && e.status === "pending"){ newStyle.backgroundColor = `var(--session_pending)`}
    if (!e.user_id && e.status === "confirmed"){ newStyle.backgroundColor = `var(--session_confirmed)` }
    if (!e.user_id && e.status === "late_canceled" ){ newStyle.backgroundColor = `var(--session_late_canceled)`}
    if (e.calendar_id ){ 
        newStyle.backgroundColor = `#fff`; 
        newStyle.border = '1px solid #D8D8D8'
    }
   
    return {
        className: e.user_id ? 'rbc-event additional' : (e.calendar_id ? `rbc-event google-event` : `rbc-event ${e.status}`) ,
        style: newStyle
    }
};

export default EventPropGetter;