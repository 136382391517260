import React from 'react'
import { NavLink } from 'react-router-dom'
import './DashboardLinkItem.scss'

const DashboardLinkItem = ({ to, image, label }) => {
    return (
        <NavLink className='link-item' to={to}>
            <img src={image} className='link-item__image' alt="programmes" />
            <div className='link-item__label'>{label}</div>
        </NavLink>
    )
}

export default DashboardLinkItem