// eslint-disable-next-line no-unused-vars
import axios, { AxiosHeaders } from "axios";
import { useSelector } from "react-redux";
const { useState, useEffect, useCallback, useRef } = require("react");

/**
 * @typedef {Object} useFetchOptions
 * @property {string} initialUrl
 * @property {InitialRequestOptions} [initialRequestOptions]
 * @property {InitialOptions} [initialOptions]
 */

/**
 * @typedef {Object} InitialOptions
 * @property {boolean} [immediate=true]
 */

/**
 * @typedef {Object} InitialRequestOptions
 * @property {string} [url]
 * @property {"GET"|"POST"|"PUT"|"DELETE"|"PATCH"} [method]
 * @property {AxiosHeaders} [headers]
 */

/**
 * Custom hook for fetching data.
 * @param {useFetchOptions} useFetchOptions - Options for the fetch hook.
 */
function useFetch(useFetchOptions) {
	const { authToken } = useSelector((state) => state.auth);
	const { initialUrl, initialOptions, initialRequestOptions } = useFetchOptions;

	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [options, updateOptions] = useState(
		initialOptions || { immediate: true }
	);
	const [requestOptions, updateRequestOptions] = useState(
		initialRequestOptions || { method: "GET" }
	);
	const abortController = useRef(new AbortController());
	const urlRef = useRef(initialUrl);

	const execute = useCallback(
		async (payload) => {
			abortController.current.abort();
			abortController.current = new AbortController();
			setData(null);

			if (!urlRef.current) {
				setError("URL is required");
				return;
			}

			setError(null);
			setIsLoading(true);

			try {
				const requestInit = requestOptions || {};
				requestInit.signal = abortController.current.signal;
				const currentController = abortController.current;

				const response = await axios({
					url: urlRef.current,
					data: payload,
					headers: { Authorization: `Bearer ${authToken}` },
					...requestInit,
				});

				if (currentController.signal.aborted) return;

				setData(response.data);
			} catch (error) {
				if (error.name === "AbortError") {
					setData(null);
					setError(null);
				} else {
					const errorMessage = error.response
						? error.response.data.message
						: error.message;

					setError(errorMessage);
				}
			} finally {
				setIsLoading(false);
			}
		},
		[requestOptions, authToken]
	);

	useEffect(() => {
		if (options.immediate) {
			execute();
		}

		return () => {
			abortController.current.abort();
		};
	}, [options, execute]);

	function refetch() {
		execute();
	}

	function updateRequestOptionsWithAuth(newOptions) {
		updateRequestOptions((prevOptions) => ({
			...prevOptions,
			...newOptions,
			method: newOptions.method || prevOptions.method, // Ensure method is updated
			headers: {
				...prevOptions.headers,
				...newOptions.headers,
			},
		}));
	}

	const updateUrl = useCallback((newUrl) => {
		urlRef.current = newUrl;
	}, []);

	return {
		data,
		isLoading,
		error,
		refetch,
		execute,
		updateOptions,
		updateRequestOptions: updateRequestOptionsWithAuth,
		updateUrl,
	};
}

export default useFetch;
