import React from 'react';
import DatePickersGroup from './DatePickersGroup';
import TimePickersGroup from './TimePickersGroup';
import icon from 'assets/vector/trash.svg';

const TimeOff = ({ values, groupName, index, dateFirstName, dateSecondName, timeFirsName, timeSecondName, remove }) => {
    return (
        <div className='edit-schedule__timeOff'>
            <div className="label">
                <span>Time-off</span>
                <img className='remove__icon' onClick={() => remove(index)} src={icon} alt='' />
            </div>
            <DatePickersGroup
                values={values}
                groupName={groupName}
                index={index}
                firstName={dateFirstName}
                secondName={dateSecondName}
            />
            <TimePickersGroup
                values={values}
                groupName={groupName}
                index={index}
                firstName={timeFirsName}
                secondName={timeSecondName}
            />
            <img className='remove__icon' onClick={() => remove(index)} src={icon} alt='' />
        </div>
    )
}

export default TimeOff;
