import axios from "axios";
import moment from "moment";
import {GET_DATA_COACH_PROFILE, REACT_APP_API_BASE_URL} from "constants/api";

const updateParticipantProfile = (userId, valuesData, userToken) => {
    const newResultFunctionalArea = valuesData.functionalArea.map(function (ellement) {
        return ellement.id ? ellement.id : [];
    });
    const newResultIndustryBackground = valuesData.industryBackground.map(function (ellement) {
        return ellement.id ? ellement.id : [];
    });
    const newResultLanguages = valuesData.languages.map(function (ellement) {
        return {
            id: ellement.language_id ? ellement.language_id : ellement.id,
            level: ellement.level.id ? ellement.level.id : ellement.level,
        }
    });
    const newResultWorkExperience = valuesData.work_experience.map(function (ellement) {
        return {
            company: ellement.company,
            position: ellement.position,
            responsibilities: ellement.responsibilities,
            date_from: moment(ellement.date_from).format("YYYY-MM-DD"),
            date_to: ellement.date_to ? moment(ellement.date_to).format("YYYY-MM-DD") : null,
        }
    });

    return axios({
        method: "post",
        url: `${REACT_APP_API_BASE_URL}/participant/${userId}`,
        headers: {
            'Authorization': `Bearer ${userToken}`
        },
        accept: "application/json",
        data: {
            avatar: valuesData.avatar.imageUrl,
            location: valuesData.location,
            cv_url: valuesData.cvFile ? valuesData.cvFile.cvUrl : null,
            email: valuesData.email,
            functional_areas: JSON.stringify(newResultFunctionalArea) ,
            goal: valuesData.goal,
            industry_background: JSON.stringify(newResultIndustryBackground) ,
            languages: JSON.stringify(newResultLanguages),
            name: valuesData.firstName,
            phone: valuesData.phone,
            school: valuesData.school,
            seniority_level_id: valuesData.seniority_level,
            surname: valuesData.lastName,
            work_experience: JSON.stringify(newResultWorkExperience),
        }
    })
        .then(response => response)
};

const updateCoachProfile = (userId, valuesData, userToken,setStatus) => {

    const newResultFunctionalArea = valuesData.functionalArea.map(function (ellement) {
        return ellement.id ? ellement.id : [];
    });

    const newResultIndustryBackground = valuesData.industryBackground.map(function (ellement) {
        return ellement.id ? ellement.id : [];
    });

    const newResultGeography = valuesData.geography.map(function (ellement) {
        return ellement.id;
    });

    const newResultCoachingAreas = valuesData.coachingInfo.map(function (ellement) {
        return ellement.id;
    });

    const newResultWorkExperience = valuesData.work_experience.map(function (ellement) {

        return {
            company: ellement.company,
            position: ellement.position,
            responsibilities: ellement.responsibilities,
            date_from: moment(ellement.date_from).format("YYYY-MM-DD"),
            date_to: ellement.date_to ? moment(ellement.date_to).format("YYYY-MM-DD") : null,
        }
    });

    const newResultLanguages = valuesData.languages.map(function (ellement) {
        return {
            id: ellement.language_id ? ellement.language_id : ellement.id,
            level: ellement.level.id ? ellement.level.id : ellement.level,
        }
    });

    return axios({
        method: "post",
        url: `${REACT_APP_API_BASE_URL}/coach/`,
        headers: {
            'Authorization': `Bearer ${userToken}`
        },
        accept: "application/json",
        data: {
            avatar: valuesData.avatar.imageUrl,
            cv: valuesData.cvFile ? valuesData.cvFile.cvUrl : null,
            name: valuesData.firstName,
            surname: valuesData.lastName,
            location: valuesData.location,
            languages: JSON.stringify(newResultLanguages),
            seniority_level_id: valuesData.seniority_level,
            work_experience: JSON.stringify(newResultWorkExperience),
            publications: JSON.stringify(valuesData.publications),
            functional_areas: newResultFunctionalArea,
            coaching_areas: newResultCoachingAreas,
            industry_background: newResultIndustryBackground,
            certification: valuesData.certification,
            coaching_hours: valuesData.coachingExperienceHours,
            coaching_years: valuesData.coachingExperienceYears,
            geography: newResultGeography,
            summary: valuesData.summary,

        }
    })
        .then(response => response)
};

const updateSchoolAdminProfile = (valuesData, userToken) => {
    return axios({
        method: "post",
        url: `${REACT_APP_API_BASE_URL}/school/edit-settings`,
        headers: {
            'Authorization': `Bearer ${userToken}`
        },
        accept: "application/json",
        data: {
            logo_url: valuesData.avatar.imageUrl,
            name: valuesData.school_name,
        }
    })
};

const getDefinedData = (userToken, lang = 'en') => {

    return axios({
        method: "get",
        url: `${REACT_APP_API_BASE_URL}/defined-data?lang=${lang}`,
        headers: {
            'Authorization': `Bearer ${userToken}`
        },
        accept: "application/json",
    })
        .then(response => response.data.data)
        .catch(error => console.log(error));
}

const getInfoAboutCurrentUser = (userToken) => {
    return axios({
        method: "get",
        url: `${REACT_APP_API_BASE_URL}/me`,
        headers: {
            'Authorization': `Bearer ${userToken}`
        },
        accept: "application/json",
    })
        .then(response => response.data.data)
        .catch(error => console.log(error));
}

const getInfoAboutCouch = (userToken, coachID) => {
    return axios({
        method: "get",
        url: GET_DATA_COACH_PROFILE(coachID),
        headers: {
            'Authorization': `Bearer ${userToken}`
        },
        accept: "application/json",
    })
        .then(response => response.data.data)
}

export default {
    updateParticipantProfile,
    updateCoachProfile,
    updateSchoolAdminProfile,
    getDefinedData,
    getInfoAboutCurrentUser,
    getInfoAboutCouch,
}
