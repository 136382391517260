import React from 'react';

const ArrowSlimIcon = ({color = '#4296E5', width = 14, height = 16, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 14 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.72591 16C6.99954 16 7.26196 15.8913 7.45545 15.6978C7.64894 15.5043 7.75764 15.2419 7.75764 14.9683L7.75764 3.65274L11.6679 7.84673C11.7589 7.95132 11.87 8.03651 11.9946 8.09722C12.1192 8.15792 12.2548 8.19289 12.3932 8.20004C12.5316 8.20719 12.6701 8.18637 12.8003 8.13883C12.9305 8.09129 13.0498 8.018 13.151 7.92333C13.2523 7.82867 13.3334 7.71457 13.3896 7.58785C13.4458 7.46114 13.4759 7.3244 13.478 7.1858C13.4802 7.04721 13.4544 6.9096 13.4022 6.78119C13.35 6.65277 13.2725 6.5362 13.1742 6.43842L7.48165 0.327978C7.38512 0.224487 7.26832 0.141964 7.13853 0.0855405C7.00874 0.0291171 6.86872 0 6.7272 0C6.58567 0 6.44566 0.0291171 6.31587 0.0855405C6.18607 0.141964 6.06928 0.224487 5.97274 0.327978L0.277576 6.43842C0.0908222 6.63851 -0.00879669 6.9046 0.000636101 7.17814C0.0100689 7.45168 0.12778 7.71027 0.327874 7.89703C0.527967 8.08378 0.794055 8.1834 1.0676 8.17397C1.34114 8.16453 1.59973 8.04682 1.78649 7.84673L5.69417 3.65274L5.69417 14.9683C5.69417 15.2419 5.80288 15.5043 5.99636 15.6978C6.18985 15.8913 6.45227 16 6.72591 16Z"
                fill={color}/>
        </svg>

    );
};

export default ArrowSlimIcon;