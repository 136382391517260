import React from 'react';
import {Link} from "react-router-dom";
import routes from "constants/routes";

const UserProfile = ({userInfo: {programId, id = null, role, avatar, name} = {}}) => {
    const {COACHES, PARTICIPANTS} = routes;
    const path = role === 'participant' ? PARTICIPANTS : COACHES;
    const roleName = role === 'participant' ? 'Participant' : 'Coach';

    return (
        id && (
            <Link to={{pathname: `${path}/${id}`, state: {program: programId}}}
                  className="single-programme__user">
                <div className="single-programme__user-info">
                    {name}
                    <span className="single-programme__user-role">{roleName}</span>
                </div>
                <img src={avatar} alt='user avatar' className="single-programme__user-img"/>
            </Link>
        )
    );
};

export default UserProfile;