import React from "react";
import Select, { components } from 'react-select';
import { customSelectStyles } from "helpers/customSelectStyles";
import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";

const typeSelectStyles = Object.assign({}, customSelectStyles);

const DropdownIndicator = (props) => {
    return (

        <components.DropdownIndicator {...props}>
            <ArrowSvg />
        </components.DropdownIndicator>

    );
};


const CustomFormSelect = ({ field, options, error, placeholder, value, defaultValue, form: { setFieldValue } }) => {

    const onChangeSelect = (optionValue) => {
        setFieldValue(field.name, optionValue.id)
    };

    return (
        <Select
            getOptionLabel={options => options.name}
            getOptionValue={options => options.id}
            components={{ DropdownIndicator }}
            name={field.name}
            options={options}
            placeholder={placeholder}
            classNamePrefix={`row-select`}
            styles={typeSelectStyles}
            isSearchable={false}
            onChange={(optionValue) => onChangeSelect(optionValue)}
            defaultValue={defaultValue ? defaultValue : value}
        />

    )
}

export default CustomFormSelect;
