import React from 'react';
import CrossRoundIcon from "components/icons/CrossRoundIcon";
import CrossIcon from "components/icons/CrossIcon";
import moment from "moment";

const ActionListItem = ({ id, description, due_date, is_done, toggleCompleted, removeActionItem, activityId, isDashboardPage = false }) => {
    const markAsCompleted = () => toggleCompleted(id, due_date, description, is_done);
    const removeItem = () => removeActionItem(id, activityId);
    const transformedDueDate = () => {
        return due_date
            ? isDashboardPage ? moment(due_date).format('MMM DD') : moment(due_date).format('YYYY-MM-DD')
            : null
    };

    function createMarkup(content) {
        return { __html: content };
    }

    return (
        <li className={`action-list__item ${is_done ? 'action-list__item--completed' : ''} topic-id=${id}`}>
            <label className="action-list__item-label">
                <div className="action-list__item-box"></div>
                <input type="checkbox" style={{ display: 'none' }} checked={is_done} onChange={markAsCompleted} />
                <div className="action-list__item-text" dangerouslySetInnerHTML={createMarkup(description)} />
            </label>
            <div className="action-list__item-actions">
                <span className="action-list__item-due">{transformedDueDate()}</span>
                {isDashboardPage ? <CrossIcon className="action-list__item-delete" onClick={removeItem} /> : <CrossRoundIcon className="action-list__item-delete" onClick={removeItem} />}
            </div>
        </li>
    );
};

export default ActionListItem;