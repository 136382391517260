import { put, fork, takeLatest } from "redux-saga/effects";
import {
	ACCEPT_SESSION_BY_COACH,
	ACCEPT_SESSION_INVITATION,
	DECLINE_SESSION_INVITATION,
	LATE_CANCEL_SESSION_INVITATION,
} from "../../constants";
import { showNotification } from "../../actions";
import {
	acceptSessionByCoachRequest,
	acceptSessionRequest,
	declineSessionRequest,
	lateCancelSessionRequest,
} from "../../sagas/sessionInvitation/sessionInvitationRequests";
import { generalApiErrorHandler } from "../../sagas/helpers/generalApiErrorHandler";
import { getNotificationMessage } from "../../../helpers/notifications";

function* watchAcceptSession() {
	yield takeLatest(ACCEPT_SESSION_INVITATION, handleAcceptSession);
}

function* handleAcceptSession({ payload: data }) {
	try {
		yield acceptSessionRequest(data);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchAcceptSessionByCoach() {
	yield takeLatest(ACCEPT_SESSION_BY_COACH, handleAcceptSessionByCoach);
}

function* handleAcceptSessionByCoach({ payload: sessionId }) {
	try {
		yield acceptSessionByCoachRequest(sessionId);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchDeclineSession() {
	yield takeLatest(DECLINE_SESSION_INVITATION, handleDeclineSession);
}

function* handleDeclineSession({ payload: sessionId }) {
	try {
		yield declineSessionRequest(sessionId);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchLateCancelSession() {
	yield takeLatest(LATE_CANCEL_SESSION_INVITATION, handleLateCancelSession);
}

function* handleLateCancelSession({ payload: sessionId }) {
	try {
		yield lateCancelSessionRequest(sessionId);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* sessionInvitation() {
	yield fork(watchAcceptSession);
	yield fork(watchAcceptSessionByCoach);
	yield fork(watchDeclineSession);
	yield fork(watchLateCancelSession);
}
