import moment from 'moment';
import checkIsOneDateIsBetweenTwoDates from './checkIfOneDateIsBetweenTwoDates';

const transformedAbsenceScheduleForSessionCreating = (absenceSchedule, currentDate) => {
    // used to display unavailable dates as occupied time slots when the session is creating
    const arr = [];

    if (absenceSchedule?.break_days?.includes(moment(currentDate).day())) {
        const newDate = {}
        newDate.date_start = `${moment(currentDate).format('YYYY-MM-DD')} ${absenceSchedule.break_days_time[0].start}`;
        newDate.date_end = `${moment(currentDate).format('YYYY-MM-DD')} ${absenceSchedule.break_days_time[0].end}`;

        arr.push(newDate);
    }

    if (absenceSchedule?.daily_break_time?.length > 0) {
        const newDate = {}
        newDate.date_start = `${moment(currentDate).format('YYYY-MM-DD')} ${absenceSchedule.daily_break_time[0].start}`;
        newDate.date_end = `${moment(currentDate).format('YYYY-MM-DD')} ${absenceSchedule.daily_break_time[0].end}`;

        arr.push(newDate);
    }

    if (absenceSchedule?.time_off?.length > 0) {
        absenceSchedule.time_off.map(item => {
            const start = moment(item.start).format('yyyy-MM-DDTHH:mm:ss');
            const end = moment(item.end).format('yyyy-MM-DDTHH:mm:ss');

            // get duration of the time-off period
            const duration = moment.duration(moment(end).diff(moment(start)));
            const days = Math.round(duration.asDays());

            const newDate = {}

            if (checkIsOneDateIsBetweenTwoDates(item.start, item.end, currentDate)) {
                newDate.date_start = `${moment(currentDate).format('YYYY-MM-DD')} 00:00`;
                newDate.date_end = `${moment(currentDate).format('YYYY-MM-DD')} 23:59`;
            }

            if (moment(item.start).format('yyyy-MM-DD') === moment(currentDate).format('yyyy-MM-DD')) {
                if (days >= 1) {
                    newDate.date_start = item.start;
                    newDate.date_end = `${moment(currentDate).format('YYYY-MM-DD')} 23:59`;
                } else {
                    newDate.date_start = item.start;
                    newDate.date_end = item.end;
                }
            }

            if (moment(item.end).format('yyyy-MM-DD') === moment(currentDate).format('yyyy-MM-DD')) {
                if (days >= 1) {
                    newDate.date_start = `${moment(currentDate).format('yyyy-MM-DD')} 00:00`;
                    newDate.date_end = item.end;
                } else {
                    newDate.date_start = item.start;
                    newDate.date_end = item.end;
                }
            }

            return arr.push(newDate);
        })
    }

    return arr.filter(item => Object.keys(item).length).filter(item => item.date_start !== item.date_end);
}

export default transformedAbsenceScheduleForSessionCreating;
