import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addSelectedReports } from "../../../../redux/actions";
import "./ReportsCheckbox.scss";

const ReportsCheckbox = ({ selectAll, reports, id, label }) => {
	const { selectedReports } = useSelector((state) => state.reports);
	const dispatch = useDispatch();

	const toggleChecked = useCallback(
		(selectAll, reports, selectedReports, id, dispatch) => {
			if (selectAll) {
				if (selectedReports?.length === reports.length) {
					dispatch(addSelectedReports([]));
				} else {
					dispatch(
						addSelectedReports(
							reports.map((report) =>
								report.id ? report.id : report.program_id
							)
						)
					);
				}
			} else {
				if (selectedReports?.includes(id)) {
					dispatch(
						addSelectedReports(
							selectedReports?.filter((report) => report !== id)
						)
					);
				} else {
					dispatch(addSelectedReports([...selectedReports, id]));
				}
			}
		},
		[]
	);

	return (
		<label
			className={`checkbox__item ${
				(
					selectAll
						? selectedReports?.length === reports?.length &&
						  selectedReports?.length !== 0
						: selectedReports?.includes(id)
				)
					? "checkbox__item--checked"
					: ""
			}`}
		>
			<div className="checkbox__item-box"></div>
			{label && <span className="checkbox__item-label">{label}</span>}
			<input
				type="checkbox"
				className="checkbox__item-input"
				checked={
					selectAll
						? selectedReports?.length === reports?.length &&
						  selectedReports?.length !== 0
						: selectedReports?.includes(id)
				}
				onChange={() => {
					toggleChecked(selectAll, reports, selectedReports, id, dispatch);
				}}
			/>
		</label>
	);
};

export default ReportsCheckbox;
