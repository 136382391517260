import React, {useState} from 'react';
import './InvitedParticipants.scss';
import Participant from "./Participant/Participant";

const InvitedParticipants = ({participants, removeParticipant, sessionTime}) => {
    const [hoverUser, setHoverUser] = useState(null);

    const toggleParticipantInfo = userId => {
        userId ? setHoverUser(userId) : setHoverUser(null);
    };

    const handleRemoveParticipant = (userId) => {
        const updatedList = participants.filter(participant => participant.id !== userId);

        removeParticipant(updatedList);
    };

    return (
        <div className="invited-participants">
            <span className="invited-participants__label">Invited users</span>
            <ul className="invited-participants__list">
                {
                    participants.map(participant => <Participant toggleParticipantInfo={toggleParticipantInfo}
                                                                 participant={participant}
                                                                 key={participant.id}
                                                                 hoverUser={hoverUser}
                                                                 sessionTime={sessionTime}
                                                                 removeParticipant={removeParticipant}
                                                                 handleRemoveParticipant={handleRemoveParticipant}
                        />
                    )
                }
            </ul>
        </div>
    );
};

export default InvitedParticipants;