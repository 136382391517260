import React, { useEffect, useRef, useState } from 'react';
import './SessionsStats.scss';
import uniqid from "uniqid";
import { calculatePlaceholdersQuantity } from '../helpers/calculatePlaceholdersQuantity';

const mockSessionData = {
    completed_feedback_form_percent_of_sessions_total: -1,
    count_feedback_form: '',
}

const SessionsStats = ({ sessionStats = {} }) => {

    const ref = useRef();
    const [width, setWidth] = useState(0);

    const [graphs, setGraphs] = useState(null);

    useEffect(() => {
        setWidth(ref.current.offsetWidth);
        // to handle page resize
        const getwidth = () => {
            setWidth(ref.current.offsetWidth);
        }
        window.addEventListener("resize", getwidth);
        // remove the event listener before the component gets unmounted
        return () => window.removeEventListener("resize", getwidth)
    }, []);

    useEffect(() => {
        const sessions = Object.values(sessionStats);
        const count = calculatePlaceholdersQuantity(width);
        const minCountOfGraphs = (count - sessions.length);
        const newSessionsArray = [...sessions];
        if (minCountOfGraphs > 0) {
            for (let i = 0; i < minCountOfGraphs; i++) {
                newSessionsArray.push(mockSessionData)
            }
        }
        setGraphs(newSessionsArray)
    }, [sessionStats, width])

    return (
        <div className='form-stat' >
            <h4 className="form-stat__name">Feedback forms submitted</h4>
            <div className="form-stat__sessions" ref={ref}>
                {
                    graphs?.map((session, index) => (
                        <div className="form-stat__session" key={uniqid()}>
                            <p className='form-stat__session-cell form-stat__session-cell--title'>{session.title ? `S.${index + 1}` : ''}</p>
                            <p className='form-stat__session-cell'>{session.count_feedback_form >= 0 ? session.count_feedback_form : ''}</p>
                            {session.completed_feedback_form_percent_of_sessions_total >= 0 &&
                                <p className='form-stat__session-cell form-stat__session-cell--percent'>{session.completed_feedback_form_percent_of_sessions_total}%</p>
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default SessionsStats;
