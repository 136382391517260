import moment from 'moment';

const customHeaderUnavailableDates = (date, absencePeriods, callbackFunc, role) => {
    let partDay = []
    let fullDay = []

    if (absencePeriods?.length > 0) {
        absencePeriods.map((item) => {
            const startDate = new Date(item.startPeriod);
            const endDate = new Date(item.endPeriod);

            const getDateArray = function (start, end) {
                const arr = [];
                const dt = new Date(start);
                while (dt <= end) {
                    arr.push(new Date(dt));
                    dt.setDate(dt.getDate() + 1);
                }

                return arr.map((df) => {
                    return moment(df).format('YYYY-MM-DD')
                })
            }

            if (startDate.getDate() === endDate.getDate()) {

                if (endDate.getHours() - startDate.getHours() < (role === 'participant' ? 24 : 23)) {
                    partDay.push(...getDateArray(startDate, endDate))
                } else {
                    fullDay.push(...getDateArray(startDate, endDate))
                }
            }
        })

        let newPartData = partDay.find((element, index) => {
            if ((element === moment(date).format('YYYY-MM-DD')))
                return partDay[index]
        });

        let newFullDay = fullDay.find((element, index) => {
            if ((element === moment(date).format('YYYY-MM-DD')))
                return fullDay[index]
        });

        if (newPartData !== undefined && newPartData === moment(date).format('YYYY-MM-DD')) {

            if (newFullDay !== undefined && (newFullDay === newPartData)) {
                return callbackFunc(false)
            }

            return callbackFunc(true)
        }
    }
}

export default customHeaderUnavailableDates;
