import React, { useEffect, useState } from "react";
import "./FileUploader.scss";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as LoaderIcon } from "assets/vector/programmes/upload-file.svg";
import {
	uploadAttachFileProgramTopicSingleProgram,
	uploadAttachFileProgramTopicActivitySingleProgram,
	getFilesDownloadError,
	showNotification,
} from "../../../../../../redux/actions";

const FileUploader = ({ type, activityId }) => {
	const dispatch = useDispatch();
	const [inputAttachedValue] = useState("");

	const handleLoadAttachFileChange = (event) => {
		const maxFileName = 250;
		const attachedFileUploaded = event.target.files[0];

		if (attachedFileUploaded.name.length > maxFileName) {
			return dispatch(
				showNotification(
					{
						title: "Error",
						text: `File name is ${maxFileName} chars max.`,
					},
					true
				)
			);
		}

		const updatedFile = new File(
			[attachedFileUploaded],
			attachedFileUploaded.name.replace(/[^0-9A-Za-z_.-]/g, "_")
		);

		dispatch(getFilesDownloadError(null));

		if (updatedFile && type === "module") {
			dispatch(
				uploadAttachFileProgramTopicSingleProgram({
					importFile: updatedFile,
					topic: activityId,
				})
			);
		} else {
			dispatch(
				uploadAttachFileProgramTopicActivitySingleProgram({
					importFile: updatedFile,
					topicActivity: activityId,
				})
			);
		}
	};

	const downloadFilesError = useSelector(
		(state) => state.userPrograms.downloadFilesError
	);
	const [downloadFilesMessagesTesxtError, setDownloadFilesMessagesTesxtError] =
		useState(null);

	useEffect(() => {
		setDownloadFilesMessagesTesxtError(downloadFilesError);

		if (downloadFilesError) {
			setTimeout(function () {
				dispatch(getFilesDownloadError(null));
			}, 2500);
		}
	}, [downloadFilesError]);

	return (
		<div className="file-uploader">
			<div className="file-uploader__head">
				<h4 className="file-uploader__title">Attach file</h4>
				<span className="file-uploader__note">
					*Use the Latin alphabet in file names
				</span>
			</div>
			<label htmlFor="file-load" className="file-uploader__loader">
				<input
					type="file"
					name={`attached-file`}
					id="file-load"
					value={inputAttachedValue}
					onChange={(event) => handleLoadAttachFileChange(event)}
				/>
				<LoaderIcon className="file-uploader__icon" />
				<div className="file-uploader__description">
					<p className="file-uploader__text">Drop files here to upload</p>
					<p className="file-uploader__text">
						(or click to choose from computer)
					</p>
				</div>
			</label>

			{downloadFilesMessagesTesxtError && (
				<div className="errors-messages">{downloadFilesMessagesTesxtError}</div>
			)}
		</div>
	);
};

export default FileUploader;
