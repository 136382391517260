import { put, fork, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import {
	GET_MATCHED_COACHES,
	GET_CAREER_MATCHED_COACHES,
} from "../constants/matchedCoaches";
import {
	getMatchedCoachesSuccess,
	getMatchedCoachesError,
	showNotification,
} from "../actions";
import { REACT_APP_API_BASE_URL } from "constants/api";
import ResponseError from "helpers/ResponseError";
import { generalApiErrorHandler } from "../sagas/helpers/generalApiErrorHandler";
import { getNotificationMessage } from "../../helpers/notifications";
import { getToken } from "../sagas/helpers/helpers";

function* watchMatchedCoaches() {
	yield takeLatest(GET_MATCHED_COACHES, handleMatchedCoachesRequest);
}

function* handleMatchedCoachesRequest() {
	const token = yield select(getToken);
	const last_school_program_id = yield select(
		(state) => state.currentUserInfo?.currentUserInfo?.last_school_program_id
	);

	try {
		const data = yield axios({
			method: "get",
			url: `${REACT_APP_API_BASE_URL}/participant/matched-coaches?school_program_id=${last_school_program_id}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((data) => data.data);

		if (data?.status === "Success") {
			const responseData = data.data;

			yield put(getMatchedCoachesSuccess(responseData));
		} else if (data?.status === "Error") {
			const err = new ResponseError(data);

			yield put(getMatchedCoachesError(err.getValidationErrorMessages()));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getMatchedCoachesError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchCareerMatchedCoaches() {
	yield takeLatest(
		GET_CAREER_MATCHED_COACHES,
		handleCareerMatchedCoachesRequest
	);
}

function* handleCareerMatchedCoachesRequest() {
	const token = yield select(getToken);
	// const last_school_program_id = yield select(state => state.currentUserInfo?.currentUserInfo?.last_school_program_id);

	try {
		const data = yield axios({
			method: "get",
			url: `${REACT_APP_API_BASE_URL}/participant/suggested-coaches`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((data) => data.data);

		if (data?.status === "Success") {
			const responseData = data.data;

			yield put(getMatchedCoachesSuccess(responseData));
		} else if (data?.status === "Error") {
			const err = new ResponseError(data);

			yield put(getMatchedCoachesError(err.getValidationErrorMessages()));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getMatchedCoachesError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* matchedCoaches() {
	yield fork(watchMatchedCoaches);
	yield fork(watchCareerMatchedCoaches);
}
