import React, { useEffect } from 'react';
import Switch from 'components/UI/Switch/Switch';
import TimePickersGroup from './TimePickersGroup';
import CustomErrorMessage from './CustomErrorMessage';
import { useFormikContext } from 'formik';
import { addFifteenMinutesToDate } from '../helpers/setInitialValues';

const DailyBreak = ({ switchActive, toggleActive, values, firstName, secondName, errors }) => {

    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (!switchActive) {
            if (errors.daily_break_end) {
                setFieldValue('daily_break_start', new Date())
                setFieldValue('daily_break_end', new Date(addFifteenMinutesToDate()))
            }
        }
    }, [switchActive, errors])

    return (
        <>
            <div className='edit-schedule__dailyBreak'>
                <div className="switcher">
                    <Switch active={switchActive} toggleActive={toggleActive} />
                    <div className="switcher__label">Daily break</div>
                </div>
                {switchActive && <TimePickersGroup values={values} firstName={firstName} secondName={secondName} />}
            </div>
            {errors?.daily_break_end && <CustomErrorMessage message={errors.daily_break_end} />}
            {/* used to handle API errors */}
            {errors?.daily_break_start && <CustomErrorMessage message={errors.daily_break_start} />}
        </>
    )
}

export default DailyBreak;
