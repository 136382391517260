import React, {useEffect, useState} from 'react';
import './ReportsTooltip.scss';
import {CSSTransition} from "react-transition-group";

const ReportsTooltip = ({info, show, position = {}}) => {
    const tooltipRef = React.useRef(null);
    const [tooltipCoords, setTooltipCoords] = useState({
        left: 0,
        top: 0,
    });

    useEffect(() => {
        setTooltipPosition(position);
    }, [position]);

    const setTooltipPosition = (position) => {
        if (tooltipRef.current) {
            const tooltip = tooltipRef.current;
            const {left, top, offsetTop} = position;
            const screenHeight = window.innerHeight;
            const pageScroll = window.pageYOffset;
            const screenViewport = pageScroll + screenHeight;
            const tooltipHeight = tooltip.getBoundingClientRect().height;
            const tooltipWidth = tooltip.getBoundingClientRect().width;

            const tooltipPosition = {
                left: left - tooltipWidth,
                top: top + offsetTop,
            }

            if (tooltipPosition.top + tooltipHeight > screenViewport) {
                tooltipPosition.top = screenViewport - tooltipHeight;
            }

            setTooltipCoords(tooltipPosition);
        }
    }

    const infoList = info.map((item, index) => (
        <div className="reports-tooltip__item" key={index}>
            <p className="reports-tooltip__label">{item.label}</p>
            <time>{item.value}</time>
        </div>
    ));

    return (
        <CSSTransition
            in={show}
            timeout={600}
            unmountOnExit
            classNames='modal__overlay-'
            nodeRef={tooltipRef}
        >
            <div className="reports-tooltip"
                 ref={tooltipRef}
                 style={
                     {
                         left: `${tooltipCoords.left}px`,
                         top: `${tooltipCoords.top}px`,
                     }
                 }>
                {infoList}
            </div>
        </CSSTransition>

    );
};

export default ReportsTooltip;