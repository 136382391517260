import React from 'react';
import ReportsSearch from "components/pages/Reports/ReportsSearch/ReportsSearch";
import DownArrowIcon from "components/icons/DownArrowIcon";
import { useMediaQuery } from "react-responsive";
import './ReportsTabs.scss';


const ReportsTabs = ({ isMobileTabsOpen, setMobileTabsOpen, isSearchOpen, setIsSearchOpen, setOffsetReports, tabs, activeTab, changeActiveTab }) => {
    const isMaxMobScreen = useMediaQuery({ maxWidth: 1193 });
    const isMinTabletScreen = useMediaQuery({ minWidth: 1194 });

    const tabButtons = tabs.map((tab, index) => {
        return <button type="button"
            key={index}
            className={`reports-tabs__btn ${activeTab?.name === tab.name ? 'reports-tabs__btn--active' : ''}`}
            onClick={() => changeActiveTab(tab)}
        >
            {tab.label}
        </button>
    }
    );

    return (
        <div className="reports-tabs">
            {
                isMaxMobScreen && (
                    <div className="reports-tabs__toggle" onClick={() => setMobileTabsOpen(!isMobileTabsOpen)}>
                        {activeTab?.label}
                        <DownArrowIcon className="reports-tabs__toggle-icon" />
                    </div>
                )
            }
            {
                isMobileTabsOpen || isMinTabletScreen ?
                    <div className="reports-tabs__btn-group">
                        {tabButtons}
                    </div>
                    :
                    null
            }
            {
                isMinTabletScreen && activeTab?.name !== 'billing' && activeTab?.name !== 'invoices' &&
                <ReportsSearch setOffsetReports={setOffsetReports} isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
            }
        </div>
    );
};

export default ReportsTabs;
