export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const UPDATE_SURVEY_REQUIRED_AFTER_REGISTRETION_TO_NEW_PROGRAM = "UPDATE_SURVEY_REQUIRED_AFTER_REGISTRETION_TO_NEW_PROGRAM";
export const UPDATE_TOKEN_FROM_LOCALSTORAGE = "UPDATE_TOKEN_FROM_LOCALSTORAGE";
export const UPDATE_SURVEY_DATA_FROM_LOCALSTORAGE = "UPDATE_SURVEY_DATA_FROM_LOCALSTORAGE"
export const UPDATE_SCHOOL_ID_FROM_LOCALSTORAGE = "UPDATE_SCHOOL_ID_FROM_LOCALSTORAGE";
export const CREATE_PASSWORD_REQUEST = "CREATE_PASSWORD_REQUEST";
export const CREATE_PASSWORD_ERROR = "CREATE_PASSWORD_ERROR";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const CREATE_COACH_PASSWORD_REQUEST = "CREATE_COACH_PASSWORD_REQUEST";
export const CHAT_LOADED_SUCCESS = "CHAT_LOADED_SUCCESS";
export const GET_STATIC_DATA = "GET_STATIC_DATA";
export const GET_STATIC_DATA_SUCCESS = "GET_STATIC_DATA_SUCCESS";
