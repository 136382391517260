import { getData } from "../../sagas/helpers/requestHandlers";
import { SINGLE_PROGRAM } from "constants/api";
import { put, call, select } from "redux-saga/effects";
import {
	cleanSingleProgramLoading,
	fetchSessionInfoError,
	fetchSessionInfoSuccess,
	getUserSingleProgramSuccess,
	setSingleProgramLoading,
	showNotification,
	updateUserSingleProgram,
} from "../../actions";
import { transformTopicsList } from "../../sagas/helpers/userPrograms";
import ResponseError from "helpers/ResponseError";
import {
	getSession,
	hasSessionChanged,
} from "../../reducers/helpers/updateSingleProgram";
import { getNotificationMessage } from "../../../helpers/notifications";

export function* handleSingleProgram(programId, activityId, callbackFunc) {
	try {
		yield put(setSingleProgramLoading());

		const { authToken } = yield select((state) => state.auth);
		const { role } = yield select(
			(state) => state.currentUserInfo.currentUserInfo
		);
		const { singleProgram } = yield select((state) => state.userPrograms);
		const { data } = yield call(
			getData(SINGLE_PROGRAM(role, programId), authToken)
		);

		if (data?.status === "Success") {
			const program = data.data;
			const transformedTopics = transformTopicsList(program.topics);

			if (activityId && callbackFunc) {
				const existedSession = getSession(singleProgram?.topics, activityId);
				const updatedSession = getSession(transformedTopics, activityId);
				const isSessionChanged = hasSessionChanged(
					existedSession,
					updatedSession
				);

				yield put(updateUserSingleProgram(updatedSession));
				yield put(fetchSessionInfoSuccess());
				yield call(callbackFunc, isSessionChanged);
			} else if (activityId) {
				const currentTopicUniqId = transformedTopics
					.flat()
					.find((topic) => topic.id === activityId).uniqId;

				yield put(
					getUserSingleProgramSuccess({
						...program,
						topics: transformedTopics,
						activeTopic: currentTopicUniqId,
					})
				);
			} else {
				yield put(
					getUserSingleProgramSuccess({
						...program,
						topics: transformedTopics,
					})
				);
			}
		} else if (data?.status === "Error") {
			const err = new ResponseError(data);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(err.getValidationErrorMessages()),
					},
					true
				)
			);

			console.log(err.getValidationErrorMessages());

			if (activityId) {
				yield put(fetchSessionInfoError(err.getValidationErrorMessages()));
				yield call(callbackFunc());
			}
		}
	} finally {
		yield put(cleanSingleProgramLoading());
	}
}
