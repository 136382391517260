import React from 'react';

const TaskIcon = ({color = '#486EA0', width = 32, height = 32, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.0625 10H29.1875V24.5C29.1875 25.0177 28.7677 25.4375 28.25 25.4375H19.75C18.2022 25.4375 16.9424 26.6943 16.9375 28.2409C16.9362 28.6592 16.6725 29.0374 16.2696 29.1499C15.6362 29.3267 15.0625 28.8554 15.0625 28.25C15.0625 26.6992 13.8008 25.4375 12.25 25.4375H3.75C3.23225 25.4375 2.8125 25.0177 2.8125 24.5V10H0.9375C0.41975 10 0 10.4197 0 10.9375V27.3125C0 27.8302 0.41975 28.25 0.9375 28.25H11.4067C11.8422 30.3869 13.7363 32 16 32C18.2637 32 20.1577 30.3869 20.5933 28.25H31.0625C31.5802 28.25 32 27.8302 32 27.3125V10.9375C32 10.4197 31.5802 10 31.0625 10Z" fill={color}/>
            <path d="M26.375 7.5H19.75C18.1967 7.5 16.9375 8.75919 16.9375 10.3125V24.5022C17.7214 23.9125 18.6957 23.5625 19.75 23.5625H27.3125V8.4375C27.3125 7.91975 26.8927 7.5 26.375 7.5ZM24.0312 20.2188H20.2188C19.701 20.2188 19.2812 19.799 19.2812 19.2812C19.2812 18.7635 19.701 18.3438 20.2188 18.3438H24.0312C24.549 18.3438 24.9688 18.7635 24.9688 19.2812C24.9688 19.799 24.549 20.2188 24.0312 20.2188ZM24.0312 16.4688H20.2188C19.701 16.4688 19.2812 16.049 19.2812 15.5312C19.2812 15.0135 19.701 14.5938 20.2188 14.5938H24.0312C24.549 14.5938 24.9688 15.0135 24.9688 15.5312C24.9688 16.049 24.549 16.4688 24.0312 16.4688ZM24.0312 12.7188H20.2188C19.701 12.7188 19.2812 12.299 19.2812 11.7812C19.2812 11.2635 19.701 10.8438 20.2188 10.8438H24.0312C24.549 10.8438 24.9688 11.2635 24.9688 11.7812C24.9688 12.299 24.549 12.7188 24.0312 12.7188Z" fill={color}/>
            <path d="M4.6875 8.4375V23.5625H12.25C13.3043 23.5625 14.2786 23.9125 15.0625 24.5022V10.3125C15.0625 8.75919 13.8033 7.5 12.25 7.5H5.625C5.10725 7.5 4.6875 7.91975 4.6875 8.4375ZM7.03125 19.2812C7.03125 18.7635 7.451 18.3438 7.96875 18.3438H11.7812C12.299 18.3438 12.7188 18.7635 12.7188 19.2812C12.7188 19.799 12.299 20.2188 11.7812 20.2188H7.96875C7.451 20.2188 7.03125 19.799 7.03125 19.2812ZM7.03125 15.5312C7.03125 15.0135 7.451 14.5938 7.96875 14.5938H11.7812C12.299 14.5938 12.7188 15.0135 12.7188 15.5312C12.7188 16.049 12.299 16.4688 11.7812 16.4688H7.96875C7.451 16.4688 7.03125 16.049 7.03125 15.5312ZM7.03125 11.7812C7.03125 11.2635 7.451 10.8438 7.96875 10.8438H11.7812C12.299 10.8438 12.7188 11.2635 12.7188 11.7812C12.7188 12.299 12.299 12.7188 11.7812 12.7188H7.96875C7.451 12.7188 7.03125 12.299 7.03125 11.7812Z" fill={color}/>
            <path d="M7.5625 0H9.4375V4.375H7.5625V0Z" fill={color}/>
            <path d="M5.6875 0H3.5C2.63706 0 1.9375 0.699562 1.9375 1.5625V2.8125C1.9375 3.67544 2.63706 4.375 3.5 4.375H5.6875V0Z" fill={color}/>
            <path d="M29.3284 1.27236L24.4375 0.185547V4.18961L29.3284 3.10273C29.7573 3.00736 30.0625 2.62692 30.0625 2.18755C30.0625 1.74817 29.7573 1.36773 29.3284 1.27236Z" fill={color}/>
            <path d="M11.3125 0H22.5625V4.375H11.3125V0Z" fill={color}/>
        </svg>
    );
};

export default TaskIcon;