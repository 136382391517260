import React, { useRef, useState } from "react";
import "./SiteNotification.scss";
import { useSelector } from "react-redux";

import NotificationHeader from "./NotificationHeader";
import EmptyNotification from "./EmptyNotification";
import NotificationItems from "./NotificationItems";
import PopupClearAll from "./PopupClearAll";
import useClickOutside from 'hooks/useClickOutside';

export default function SiteNotification({ isNotificationOpened, setIsNotificationOpened }) {

    const loadNotifications = useSelector(state => state.notification.loadNotifications);
    const userNotificationEmpty = useSelector(state => state.notification.userNotificationEmpty);
    const userNotification = useSelector(state => state.notification.userNotification);
    const siteNotificationRef = useRef();

    const [openPopupClearAll, setOpenPopupClearAll] = useState(false);

    useClickOutside(siteNotificationRef, () => {
        if (isNotificationOpened) setIsNotificationOpened(false)
    })

    return (
        <div className="site-notification-overlay">
            <div className="site-notification-wrapper" ref={siteNotificationRef}>
                {
                    openPopupClearAll &&

                    <PopupClearAll setOpenPopupClearAll={setOpenPopupClearAll} />

                }
                <NotificationHeader userNotificationEmpty={userNotificationEmpty} setOpenPopupClearAll={setOpenPopupClearAll} />
                {
                    loadNotifications &&
                    <>
                        {
                            userNotificationEmpty ?
                                <EmptyNotification />
                                :
                                <NotificationItems userNotification={userNotification} />
                        }
                    </>
                }

            </div>
        </div>
    )
}