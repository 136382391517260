import React from 'react'

const ReportsSortingIconDown = ({ color = '#FFFFFF', width = 10, height = 10, ...props }) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 10 10" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.53557 6.46357L5.00004 2.92804L1.46451 6.46357L0.050293 5.04936L5.00004 0.0996094L9.9444 5.05475L8.53557 6.46357Z"
                fill={color} />
        </svg>
    )
}

export default ReportsSortingIconDown;
