import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./Evaluations.scss";
import pageTitles from "constants/pageTitles";
import { updatePageTitle } from "../../../redux/actions";

export default function Evaluations() {
	const { EVALUATIONS_TITLE } = pageTitles;

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updatePageTitle(EVALUATIONS_TITLE));
	}, []);

	return (
		<section className="section-wrap page-evaluations">
			<h1>page Evaluations</h1>
		</section>
	);
}
