import { put, fork, takeLatest } from "redux-saga/effects";
import {
	GET_ENGAGEMENT_REPORTS,
	GET_REPORT,
	GET_FEEDBACK_REPORTS,
	GET_EVALUATION_REPORTS,
	GET_BILLING_OVERVIEW_REPORTS,
	GET_COACH_INVOICES,
} from "../../constants/reports";
import {
	getEngagementReportsRequest,
	getReportRequest,
	getFeedbackReportsRequest,
	getEvaluationReportsRequest,
	getBillingReportsRequest,
	getInvoicesRequest,
} from "../../sagas/reports/handleReports";
import { generalApiErrorHandler } from "../../sagas/helpers/generalApiErrorHandler";
import { showNotification } from "../../actions";
import { getNotificationMessage } from "../../../helpers/notifications";

function* watchGetFeedbackReports() {
	yield takeLatest(GET_FEEDBACK_REPORTS, handleGetFeedbackReports);
}

function* watchGetEvaluationReports() {
	yield takeLatest(GET_EVALUATION_REPORTS, handleGetEvaluationReports);
}

function* watchGetEngagementReports() {
	yield takeLatest(GET_ENGAGEMENT_REPORTS, handleGetEngagementReports);
}

function* watchGetBillingReports() {
	yield takeLatest(GET_BILLING_OVERVIEW_REPORTS, handleGetBillingReports);
}

function* watchGetCoachInvoices() {
	yield takeLatest(GET_COACH_INVOICES, handleCoachInvoices);
}

function* watchGetReport() {
	yield takeLatest(GET_REPORT, handleGetReport);
}

function* handleGetFeedbackReports(requestData) {
	try {
		yield getFeedbackReportsRequest(requestData);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* handleGetEvaluationReports(requestData) {
	try {
		yield getEvaluationReportsRequest(requestData);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* handleGetEngagementReports(requestData) {
	try {
		yield getEngagementReportsRequest(requestData);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* handleGetBillingReports(requestData) {
	try {
		yield getBillingReportsRequest(requestData);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* handleCoachInvoices(requestData) {
	try {
		yield getInvoicesRequest(requestData);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* handleGetReport({ payload: reportId }) {
	try {
		yield getReportRequest(reportId);
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* reports() {
	yield fork(watchGetFeedbackReports);
	yield fork(watchGetEvaluationReports);
	yield fork(watchGetEngagementReports);
	yield fork(watchGetReport);
	yield fork(watchGetBillingReports);
	yield fork(watchGetCoachInvoices);
}
