import React, { useEffect, useState } from "react";
import "./EventSessionChooseTime.scss";
import DownArrowIcon from "components/icons/DownArrowIcon";
import EventSessionTimePicker from "components/forms/EventSession/EventSessionTimePicker/EventSessionTimePicker";
import CrossIcon from "components/icons/CrossIcon";
import { useSelector } from "react-redux";
import uniqid from "uniqid";
import moment from "moment";
import transformedAbsenceScheduleForSessionCreating from "helpers/transformedAbsenceScheduleForSessionCreating";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { getCoachAbsenceSchedule } from "../../../../redux/actions/createSession";

const EventSessionChooseTime = ({
	time,
	date,
	setTime,
	isDisabled,
	sessionId,
	duration,
	isTimeBooked,
	setIsTimeBooked,
	coachId,
}) => {
	const dispatch = useDispatch();
	const { authToken } = useSelector((state) => state.auth);
	const { sessionsTime, coachAbsenceSchedule } = useSelector(
		(state) => state.createSession
	);
	const { absence_schedule, role } = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const toggleContainer = React.createRef();
	const menu = React.createRef();
	const [isMenuOpen, setMenuOpen] = useState(false);

	const currentAbsenceSchedule = useMemo(
		() => (role === "coach" ? absence_schedule : coachAbsenceSchedule?.[0]),
		[role, absence_schedule, coachAbsenceSchedule]
	);

	const toggleMenu = (e) => {
		if (isDisabled) {
			return false;
		}

		if (isMenuOpen && !menu.current.contains(e.target)) {
			if (isTimeBooked) {
				closeAndClearTimer();
			} else {
				setMenuOpen(false);
			}
		} else {
			setMenuOpen(true);
		}
	};

	const closeMenu = () => {
		setTimeout(() => setMenuOpen(false));
	};

	const closeAndClearTimer = () => {
		setTime(null);
		setTimeout(() => setMenuOpen(false));
	};

	const unavailableDatesToScheduleSession = useMemo(
		() =>
			transformedAbsenceScheduleForSessionCreating(
				currentAbsenceSchedule,
				date
			),
		[currentAbsenceSchedule, date]
	);

	const uniqSessionTime = useMemo(
		() =>
			[
				...sessionsTime?.filter((session) => session.session_id !== sessionId),
				...unavailableDatesToScheduleSession,
			].reduce((uniqTime, time) => {
				if (
					uniqTime.findIndex(
						(item) =>
							time["date_start"] === item["date_start"] &&
							time["date_end"] === item["date_end"]
					) === -1
				) {
					uniqTime.push(time);
				}

				return uniqTime;
			}, []),
		[unavailableDatesToScheduleSession, sessionsTime, sessionId]
	);

	const occupiedTimeSlots = (uniqSessionTime) => {
		const fullDayUnavailable = uniqSessionTime.find(
			(item) =>
				moment(item.date_end).format("HH") -
					moment(item.date_start).format("HH") >=
				23
		);

		if (fullDayUnavailable) {
			return (
				<div key={uniqid()} className="choose-time__slot">
					{`${moment(fullDayUnavailable.date_start).format("LT")} - ${moment(
						fullDayUnavailable.date_end
					).format("LT")}`}
				</div>
			);
		}

		return uniqSessionTime.map((session) => {
			return (
				<div key={uniqid()} className="choose-time__slot">
					{`${moment(session.date_start).format("LT")} - ${moment(
						session.date_end
					).format("LT")}`}
				</div>
			);
		});
	};

	const isSelectedTimeBooked = (time, date, sessionsTime) => {
		const transformedTime = moment(time, ["h:mm:A"]).format("HH:mm");
		const transformedDate = moment(date).format("YYYY-MM-DD");
		const requestedFullDateStart = moment(
			`${transformedDate} ${transformedTime}`
		);

		return sessionsTime.find((session) => {
			const sessionStartWithDurationOffset = moment(
				session.date_start
			).subtract(duration, "m");
			const sessionEnd = moment(session.date_end);

			return (
				requestedFullDateStart.isSameOrAfter(sessionStartWithDurationOffset) &&
				requestedFullDateStart.isSameOrBefore(sessionEnd)
			);
		});
	};

	useEffect(() => {
		if (time && date && uniqSessionTime) {
			setIsTimeBooked(isSelectedTimeBooked(time, date, uniqSessionTime));
		}

		return () => setIsTimeBooked(false);
	}, [time, date, uniqSessionTime]);

	useEffect(() => {
		if (role === "participant" && coachId) {
			const params = {
				list_id: [coachId],
			};
			dispatch(getCoachAbsenceSchedule(authToken, params));
		}
	}, [role, coachId]);

	return (
		<div className="choose-time">
			<span className="event-form__label">Choose time</span>
			<div
				ref={toggleContainer}
				className={`${
					isDisabled
						? "choose-time__open-picker choose-time__open-picker--disabled"
						: "choose-time__open-picker"
				}`}
				onClick={toggleMenu}
			>
				<span
					className={`choose-time__placeholder ${
						time ? "choose-time__placeholder--active" : null
					}`}
				>
					{time ? time : isDisabled ? "Choose activity first" : `Time...`}
				</span>
				<DownArrowIcon
					className={`choose-time__icon ${
						isMenuOpen ? "choose-time__icon--active" : ""
					}`}
				/>
				{isMenuOpen && (
					<div className="choose-time__menu" ref={menu}>
						<div className="choose-time__menu-head">
							Timepicker
							<CrossIcon
								onClick={closeAndClearTimer}
								className="choose-time__close"
							/>
						</div>
						<EventSessionTimePicker time={time} setTime={setTime} />
						{(duration && isTimeBooked) || isTimeBooked ? (
							<div className="choose-time__error">
								Chosen time with {duration}m duration is not available
							</div>
						) : duration ? (
							<p className="choose-time__duration">
								* Session duration: {duration}m
							</p>
						) : null}
						<button
							onClick={closeMenu}
							disabled={isDisabled || isTimeBooked}
							type="button"
							className="choose-time__set-time button button--orange-invert"
						>
							Set time
						</button>
					</div>
				)}
			</div>
			{uniqSessionTime?.length && date ? (
				<>
					<p className="choose-time__slots-title">Occupied time slots:</p>
					<div className="choose-time__slots">
						{occupiedTimeSlots(uniqSessionTime)}
					</div>
				</>
			) : null}
		</div>
	);
};

export default EventSessionChooseTime;
