export const CREATE_INDIVIDUAL_SESSION = 'CREATE_INDIVIDUAL_SESSION';
export const CREATE_INDIVIDUAL_SESSION_SUCCESS = 'CREATE_INDIVIDUAL_SESSION_SUCCESS';
export const CREATE_INDIVIDUAL_SESSION_ERROR = 'CREATE_INDIVIDUAL_SESSION_ERROR';
export const CLEAR_INDIVIDUAL_SESSION_INFO = 'CLEAR_INDIVIDUAL_SESSION_INFO';
export const EDIT_INDIVIDUAL_SESSION = 'EDIT_INDIVIDUAL_SESSION';
export const EDIT_INDIVIDUAL_SESSION_SUCCESS = 'EDIT_INDIVIDUAL_SESSION_SUCCESS';
export const EDIT_INDIVIDUAL_SESSION_ERROR = 'EDIT_INDIVIDUAL_SESSION_ERROR';
export const GET_SESSION_TIME = 'GET_SESSION_TIME';
export const GET_SESSION_TIME_SUCCESS = 'GET_SESSION_TIME_SUCCESS';
export const GET_SESSION_TIME_ERROR = 'GET_SESSION_TIME_ERROR';
export const GET_SESSION_FILTER = 'GET_SESSION_FILTER';
export const GET_SESSION_FILTER_SUCCESS = 'GET_SESSION_FILTER_SUCCESS';
export const GET_SESSION_FILTER_ERROR = 'GET_SESSION_FILTER_ERROR';
export const CLEAR_SESSION_FILTER = 'CLEAR_SESSION_FILTER';
export const GET_COACH_ABSENCE_SCHEDULE = 'GET_COACH_ABSENCE_SCHEDULE';
export const GET_COACH_ABSENCE_SCHEDULE_SUCCESS = 'GET_COACH_ABSENCE_SCHEDULE_SUCCESS';