import React from 'react';
import { Field } from 'formik';
import EditScheduleDatePicker from 'components/UI/EditScheduleDatePicker/EditScheduleDatePicker';

const DatePickersGroup = ({ values, firstName, secondName, groupName, index }) => {
    return (
        <div className='edit-schedule__datePickers'>
            <Field
                name={`${groupName}.${index}.${firstName}`}
                component={EditScheduleDatePicker}
            />
            <div className='separator'></div>
            <Field
                name={`${groupName}.${index}.${secondName}`}
                component={EditScheduleDatePicker}
                minDate={values?.[groupName]?.[index]?.[firstName] !== '' ? values[groupName][index][firstName] : false}
            />
        </div>
    )
}

export default DatePickersGroup;
