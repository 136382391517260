import { useFormikContext } from 'formik';
import React, { forwardRef, useRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import CustomInput from './CustomInput';
import './EditScheduleDatePicker.scss';

const MyInput = forwardRef((props, ref) => {
    return <CustomInput {...props} ref={ref} />
})

const EditScheduleDatePicker = ({ name, field, form, minDate = false }) => {

    const inputRef = useRef();
    const [startDate, setStartDate] = useState(field.value);

    const fieldName = name || field.name;

    const { setFieldValue } = form;

    const { errors } = useFormikContext();

    const isErrorPresent = (errors, fieldName) => {

        // time_off.0.start_day - example of the field name of the FieldArray instance
        if (fieldName.includes('.')) {
            const nameArray = fieldName.split('.');

            return (errors[nameArray[0]] && errors[nameArray[0]][+nameArray[1]] && errors[nameArray[0]][+nameArray[1]][nameArray[2]])
        }

        return false;
    }

    useEffect(() => {
        setFieldValue(fieldName, startDate)
    }, [startDate])

    return (
        <DatePicker
            className={`EditScheduleDatePicker ${isErrorPresent(errors, fieldName) && 'error'}`}
            name={fieldName}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat='dd MMM yyyy'
            minDate={minDate}
            customInput={<MyInput inputRef={inputRef} />}
        />
    )
}

export default EditScheduleDatePicker;
