import {
    FILE_SIZE_FOR_AVATAR,
    SUPPORTED_FORMATS_AVATAR,
    SUPPORTED_FORMATS_CV_FILE
} from "components/pages/EditMyProfile/helpers/constants";

export const getUniqueLanguages = (languages, chosenLanguages) => (
    languages.filter(language => !chosenLanguages.find(chosen => chosen.id === language.id))
);

export const handleAttachCvChange = (event, setFieldValue, handleBlur, setCheckUploadCVFile) => {
    const cvFileUploaded = event.target.files[0];

    handleBlur(event);

    if (cvFileUploaded) {
        let cvUrl;
        let reader = new FileReader();

        let cvFormatIsOk = SUPPORTED_FORMATS_CV_FILE.includes(cvFileUploaded.type);

        reader.onloadend = () => {
            cvUrl = reader.result;
            setCheckUploadCVFile(true);
            setFieldValue('cvFile', { cvFileUploaded, cvUrl, cvFormatIsOk });
        };

        reader.readAsDataURL(cvFileUploaded);
    }
};

export const handleLoadImgChange = (event, setFieldValue, handleBlur, setNewLogo) => {
    const avatarFileUploaded = event.target.files[0];

    handleBlur(event);

    let avatarFormatIsOk = SUPPORTED_FORMATS_AVATAR.includes(avatarFileUploaded?.type);

    if (avatarFileUploaded) {
        let imageUrl;
        let reader = new FileReader();

        reader.onloadend = () => {
            imageUrl = reader.result;

            let avatarSizeIsReadyToUpload = null;

            if (avatarFileUploaded.size >= FILE_SIZE_FOR_AVATAR) {
                avatarSizeIsReadyToUpload = false;
            } else {
                if (avatarFormatIsOk) {
                    setNewLogo(imageUrl);
                }

                avatarSizeIsReadyToUpload = true;
            }


            setFieldValue('avatar', { avatarFileUploaded, imageUrl, avatarSizeIsReadyToUpload, avatarFormatIsOk });
        };

        reader.readAsDataURL(avatarFileUploaded);
    }
};