import React from 'react';
import './Session.scss';
import EmptySessionNotify
    from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/EmptySessionNotify/EmptySessionNotify";
import SessionInvitation
    from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/SessionInvitation/SessionInvitation";
import FeedbackLink from "components/pages/Programmes/SingleProgramme/FeedbackLink/FeedbackLink";
import {isSessionCompleted} from "helpers/userPrograms";

const Session = ({session, duration, activityName, activityId, type, topicId, ...otherProps}) => {
    const {filledFormReport, programId, userName, userAvatar, evaluatedUserRole, feedbackForm} = otherProps;
    const sessionCompletedStatus = isSessionCompleted(session, duration);

    return (
        <>
            <div className="tabs__content">
                <h4 
                    className="session__title" 
                >Session invitation</h4>
            </div>

            {
                session && session.status !== 'canceled' ?
                    <SessionInvitation duration={duration}
                                       programId={programId}
                                       session={session}
                                       activityName={activityName}
                                       activityId={activityId}
                                       topicId={topicId}
                                       type={type}
                    />
                    :
                    <EmptySessionNotify type={type}
                                        duration={duration}
                                        programId={programId}
                                        activityName={activityName}
                                        activityId={activityId}
                                        topicId={topicId}
                    />
            }
            {
                type === 'session_and_evaluation' && <FeedbackLink filledFormReport={filledFormReport}
                                                                   sessionCompletedStatus={sessionCompletedStatus}
                                                                   programId={programId}
                                                                   activityId={activityId}
                                                                   userName={userName}
                                                                   userAvatar={userAvatar}
                                                                   evaluatedUserRole={evaluatedUserRole}
                                                                   feedbackForm={feedbackForm} />
            }
        </>
    );
};

export default Session;