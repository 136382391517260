import React from 'react';
import routes from "constants/routes";
import ProgramImg from "assets/vector/dashboard/programImgSmall.svg";
import CalendarImg from "assets/vector/dashboard/calendarImgSmall.svg";
import DashboardLinkItem from './DashboardLinkItem/DashboardLinkItem';
import './DashboardLinks.scss';

const DashboardLinks = () => {
    const { PROGRAMMES, CALENDAR } = routes;

    return (
        <div className='dashboard-links'>
            <DashboardLinkItem to={PROGRAMMES} image={ProgramImg} label='Programmes' />
            <DashboardLinkItem to={CALENDAR} image={CalendarImg} label='Calendar' />
        </div>
    )
}

export default DashboardLinks;
