import { uploadCsv } from "../constants";

const initialState = {
	successed: null,
	loading: false,
	errors: null,
};

export default function uploadCsvProgrammesReducer(
	state = initialState,
	action
) {
	const { type, payload } = action;

	switch (type) {
		case uploadCsv.SEND_CSV_FILE:
			return {
				...state,
				loading: true,
			};

		case uploadCsv.SEND_CSV_FILE_SUCCESS:
			return {
				...state,
				successed: payload,
				loading: false,
			};
		case uploadCsv.SEND_CSV_FILE_ERRORS:
			return {
				...state,
				errors: payload,
				loading: false,
			};

		default:
			return state;
	}
}
