import * as Yup from 'yup';

export default function questionnaireValidationSchema(item) {

    if (item.question_type === "dropdown" || item.question_type === "industry" || item.question_type === "geographic_area" || item.question_type === "language" || item.question_type === "functional_area") {

        if (item.rules) {
            const maxQuantity = parseInt(item.rules.split("max:").pop());

            if (maxQuantity > 1) {
                return Yup.object().shape({
                    [item.key]: Yup.array().required("At list one option is required")
                        .max(maxQuantity, `You cannot choose more than ${maxQuantity} options`)
                        .min(1, "Please, choose at least one option")
                })
            }

            return Yup.object().shape({
                [item.key]: Yup.string().required("Field is required")
            })

        }

        return Yup.object().shape({
            [item.key]: Yup.array().required("At list one option is required").min(1, "Please, choose at least one option")
        })
    }

    if (item.question_type === "checkboxes") {
        if (item.rules) {
            const maxQuantity = parseInt(item.rules.split("max:").pop());

            return Yup.object().shape({
                [item.key]: Yup.array().required("At list one option is required")
                    .max(maxQuantity, `You cannot choose more than ${maxQuantity} options`)
                    .min(1, "Please, choose at least one option")
            })

        }

        return Yup.object().shape({
            [item.key]: Yup.array().required("At list one option is required").min(1, "Please, choose at least one option")
        })
    }

    if (item.question_type === "legend_page") {
        return Yup.object().shape({
            [item.key]: Yup.string()
        })
    }

    if (item.question_type === "date") {
        return Yup.object().shape({
            [item.key]: Yup.date('Date type').nullable()
                .transform((curr, orig) => orig === '' ? null : curr)
                .required('Field is required')
        })
    }

    if (item.question_type === 'text') {
        return Yup.object().shape({
            [item.key]: Yup.string().required('Field is required').trim().max(1000, "This field must not be greater than 1000 characters")
        })
    }

    if (item.question_type === 'textarea') {
        return Yup.object().shape({
            [item.key]: Yup.string().required('Field is required').trim().max(5000, "This field must not be greater than 5000 characters")
        })
    }

    return Yup.object().shape({
        [item.key]: Yup.string().required('Field is required').trim()
    })

}