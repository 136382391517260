import React, { useEffect, useState } from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

import axios from "axios";

import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowSvg />
        </components.DropdownIndicator>

    );
};

export default function SelectGoogleCalendar() {
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.user_token;

    const [listOfCalendars, setlistOfCalendars] = useState(null);
    useEffect(() => {
        let targetUrl = `${REACT_APP_API_BASE_URL}/user/google/calendars`;
        const res = fetch(targetUrl, {
            method: "get",
            url: targetUrl,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            accept: "application/json",
        })
            .then((res) => res.json())
            .then((data) => {
                let selectedItems = data.data.filter(item => item.selected);
                setlistOfCalendars([...calendarListMapper(data.data)]);
                setOptionSelected(selectedItems.map(({ calendar_id: id, summary: value, summary: label, ...props }) => ({
                    id,
                    value,
                    label,
                    ...props
                })))
            })
            .catch((err) => console.log(err))

    }, []);

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const calendarListMapper = item => {
        return item.map(({ calendar_id: id, summary: value, summary: label, ...props }) => ({
            id,
            value,
            label,
            ...props
        }));
    };
    const [optionSelected, setOptionSelected] = useState(null);
    const handleChange = selected => {
        setOptionSelected([...selected]);
    };

    const onMenuClose = () => {
        let targetUrl = `${REACT_APP_API_BASE_URL}/user/calendars/select`;
        const body = {
            calendars: optionSelected?.map((item) => (item.id))
        }
        const headers = { 'Authorization': `Bearer ${token}` };
        axios.post(targetUrl, body, { headers })
    }

    const colourStyles = {
        control: (styles, state) => {
            let opened = state.menuIsOpen ? '1px solid #2E79DA' : '1px solid #EDEBF9';
            return {
                display: 'flex',
                border: opened,
                cursor: 'pointer',
                width: '100%',
                backgroundColor: '#fff',
                borderRadius: '8px',
                minHeight: '44px',
                padding: '0 12px',
                fontSize: '14px',
                lineHeight: '140%',
                ":hover": {
                    border: "1px solid #2E79DA",
                },
            }
        },
        menu: styles => ({ ...styles, borderRadius: '8px', zIndex: '10', overflow: 'hidden', width: '100%', }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                minHeight: '48px',
                padding: '0px 32px',
                backgroundColor: '#fff',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#000',
                zIndex: '10',
            };
        },
        dropdownIndicator: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        }),
        placeholder: (styles) => {
            return {
                ...styles,
                fontSize: '14px',
                lineHeight: '140%'
            }
        }
    };

    return (
        <section className="section-select">
            <div className="filter_item select_checkboxes">
                <label className="settings-row__label">Select your Google account(s)</label>
                {listOfCalendars != null ?
                    <ReactSelect
                        classNamePrefix='google-account'
                        options={listOfCalendars}
                        isMulti
                        closeMenuOnSelect={false}
                        placeholder='Choose calendar(s)....'
                        hideSelectedOptions={false}
                        components={{
                            Option,
                            DropdownIndicator
                        }}
                        styles={colourStyles}
                        onChange={handleChange}
                        onMenuClose={onMenuClose}
                        value={optionSelected}
                    />
                    : null}
            </div>
        </section>
    );
};