import React from 'react';

const SearchIcon = ({color = '#929292', width = 26, height = 26, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 26 26" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.5 11.5C3.5 7.08172 7.08172 3.5 11.5 3.5C15.9183 3.5 19.5 7.08172 19.5 11.5C19.5 15.9183 15.9183 19.5 11.5 19.5C7.08172 19.5 3.5 15.9183 3.5 11.5ZM11.5 0.5C5.42487 0.5 0.5 5.42487 0.5 11.5C0.5 17.5751 5.42487 22.5 11.5 22.5C13.9967 22.5 16.2991 21.6682 18.1453 20.2666L22.9393 25.0607L25.0607 22.9393L20.2666 18.1453C21.6682 16.2991 22.5 13.9967 22.5 11.5C22.5 5.42487 17.5751 0.5 11.5 0.5Z"
                  fill={color}/>
        </svg>
    );
};

export default SearchIcon;