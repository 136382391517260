import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from "react-responsive";
import './ReportsSorting.scss';
import ReportsCheckbox from '../ReportsCheckbox/ReportsCheckbox';
import ReportsSortingButtons from '../ReportsSortingButtons/ReportsSortingButtons';

const ReportsSorting = ({ reports, userRole, activeTab, setOffsetReports }) => {

    const maxTypeWidth = useMediaQuery({ maxWidth: 1700 });
    const maxSubmittionTypeWidth = useMediaQuery({ minWidth: 1750 });

    const coachesExist = useSelector(state => state.coachesList?.coachesExist);

    return (
        <>
            <div className="reports-checkbox__wrapper">
                <ReportsCheckbox
                    label='Select all'
                    selectAll={true}
                    reports={reports}
                />
            </div>
            <tr className="reports-sorting">
                <th className={`reports-sorting__cell reports-sorting__cell--checkbox `}>
                    <ReportsCheckbox selectAll={true} reports={reports} />
                </th>
                <th className="reports-sorting__cell reports-sorting__cell--num">
                    #
                </th>
                <th className="reports-sorting__cell">
                    Participant name
                </th>
                <th className="reports-sorting__cell">
                    {userRole === 'school_admin' && coachesExist
                        ? `Qualification ${!maxTypeWidth ? '' : 'type'}`
                        : 'Qualification type'
                    }
                </th>
                {
                    userRole === 'school_admin' && coachesExist &&
                    <th className="reports-sorting__cell reports-sorting__cell--coach-name">
                        Coach name
                    </th>
                }
                <th className="reports-sorting__cell">
                    Programme name
                </th>
                <th className="reports-sorting__cell reports-sorting__cell--hidden">
                    {userRole === 'school_admin' && coachesExist
                        ? 'Topic #'
                        : 'Topic number'
                    }
                </th>
                <th className="reports-sorting__cell reports-sorting__cell--hidden">
                    Topic name
                </th>
                <th className="reports-sorting__cell reports-sorting__cell--hidden">
                    Session name
                </th>
                <th className="reports-sorting__cell">
                    Form name
                </th>
                <th className="reports-sorting__cell submission" style={{ display: 'inline-flex' }}>
                    <ReportsSortingButtons sortingValue='created_at' setOffsetReports={setOffsetReports} />
                    {userRole === 'school_admin' && coachesExist && maxSubmittionTypeWidth && activeTab?.name === 'feedback'
                        ? <div style={{ textAlign: 'center' }}>Submission<br /> date</div>
                        : 'Submission date'
                    }
                </th>
                <th valign="center" className="reports-sorting__cell reports-sorting__cell--rating rating">
                    <ReportsSortingButtons setOffsetReports={setOffsetReports} sortingValue='average_mark' />
                    <span>Rating</span>
                </th>
                <th className="reports-sorting__cell reports-sorting__cell--tooltip"></th>
            </tr>
        </>
    );
};

export default ReportsSorting;