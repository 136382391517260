import { participants } from "../constants";

export function getInfoAboutParticipant(participantId) {
	return {
		type: participants.GET_INFO_ABOUT_PARTICIPANT,
		payload: participantId,
	};
}

export function getInfoAboutParticipantSuccess(participantInfo) {
	return {
		type: participants.GET_INFO_ABOUT_PARTICIPANT_SUCCESS,
		payload: participantInfo,
	};
}

export function clearAboutParticipantInfo() {
	return {
		type: participants.CLEAR_ABOUT_PARTICIPANT_INFO,
	};
}
