export default [
    '--background',
    '--loader-color',
    '--active_menu',
    '--reports-sorting_bg',
    '--session_late_canceled',
    '--session_confirmed',
    '--session_pending',
    '--opened_activity',
    '--completed_activity',
    '--db_active',
    '--db_average_mark',
    '--db_booked_session',
    '--db_btn_bg_color',
    '--db_completed_session',
    '--db_confirmed_session',
    '--db_assigned_session',
    '--db_not_assigned_session',
    '--db_not_attended_session',
    '--db_session_credits_purchased',
    '--db_session_credits_used',
    '--db_session_credits_available',
    '--db_session_credits_in_progress',
    '--db_attendance_rate_planed',
    '--db_attendance_rate_completed',
    '--db_attendance_rate_late_cancelled',
    '--db_deactivated',
    '--db_disengaged',
    '--db_session_frequency',
    '--db_satisfaction_bad',
    '--db_satisfaction_good',
    '--db_satisfaction_level',
    '--db_satisfaction_neutral',
    '--db_total',
    '--gen_button_color',
    '--gen_font_color',
    '--db_satisfaction_mark_1',
    '--db_satisfaction_mark_2',
    '--db_satisfaction_mark_3',
    '--db_satisfaction_mark_4',
    '--db_satisfaction_mark_5',
    '--db_satisfaction_mark_6',
    '--db_satisfaction_mark_7',
    '--db_satisfaction_mark_8',
    '--db_satisfaction_mark_9',
];