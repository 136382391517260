import React from 'react';
import BillingTable from './BillingTable/BillingTable';
import './BillingOverview.scss';
import ReportsSortingButtons from '../ReportsSortingButtons/ReportsSortingButtons';

const BillingOverview = ({ data, lastElement, setOffsetReports }) => {
    return (
        <div className='billing-overview-section'>
            <div className="billing-overview-section__head">
                <div className='head__columns'>
                    <ReportsSortingButtons setOffsetReports={setOffsetReports} sortingValue='period' />
                    Period
                </div>
                <div className='head__columns'>
                    <ReportsSortingButtons setOffsetReports={setOffsetReports} sortingValue='number_of_sessions' />
                    Number of sessions
                </div>
            </div>
            {
                data?.periods?.length > 0
                    ? data?.periods.map(item => <BillingTable key={item.period} data={item} />)
                    : <div className='empty-data'>There are no reports of selected type</div>
            }
            <div ref={lastElement} />
        </div>
    )
}

export default BillingOverview;
