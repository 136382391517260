import { fork, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { participants } from "../constants";
import { getInfoAboutParticipantSuccess } from "../actions/participantProfile";
import { GET_DATA_PARTICIPANT_PROFILE } from "constants/api";
import { generalApiErrorHandler } from "../sagas/helpers/generalApiErrorHandler";
import { showNotification } from "../actions";
import { getNotificationMessage } from "../../helpers/notifications";
import { getToken } from "../sagas/helpers/helpers";

function* watchGetParticipant() {
	yield takeLatest(
		participants.GET_INFO_ABOUT_PARTICIPANT,
		handleGetParticipant
	);
}

function* handleGetParticipant({ payload }) {
	try {
		const token = yield select(getToken);

		const currentUserData = yield axios({
			method: "get",
			url: GET_DATA_PARTICIPANT_PROFILE(payload),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		})
			.then((response) => response.data.data)
			.catch((error) => console.log(error));

		yield put(getInfoAboutParticipantSuccess(currentUserData));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* personalInfoSaga() {
	yield fork(watchGetParticipant);
}
