import React from "react";
import "./Checkbox.scss";

const Checkbox = ({ name, field, label, values, value, onChange, exclusiveOption }) => {

    return (
        <label
            className={`custom-checkbox__item ${values[name].includes(value) ? "custom-checkbox__item--checked" : ""
                }`}
        >
            <div className={`custom-checkbox__item-box ${(value !== exclusiveOption?.key && values[name].includes(exclusiveOption?.key)) ? 'disabled' : ''}`}></div>
            {label && <span className={`custom-checkbox__item-label ${value !== exclusiveOption?.key && values[name].includes(exclusiveOption?.key) ? 'disabled' : ''}`}>{label}</span>}
            <input
                type="checkbox"
                className="custom-checkbox__item-input"
                name={name}
                value={value}
                checked={values[name].includes(value)}
                disabled={
                    value !== exclusiveOption?.key
                        ?
                        values[name].includes(exclusiveOption?.key)
                        : false
                }
                onChange={() => onChange(name, value, field)}
            />
        </label>
    );
};

export default Checkbox;
