import React from 'react';
import {Field} from "formik";
import CustomMultiSelect from "components/CustomFormSelect/CustomMultiSelect";

const FunctionalArea = ({getFunctionalAreas, userInfo}) => {
    return (
        <div className="section-info-block functional-area">
            <h3 className={`section-title`}>Functional Area</h3>
            <div className="functional-area-list">
                <label htmlFor={'functionalArea'} className="profile-row__label">Functional Area <span
                    className={`prompt`}>Start typing</span></label>
                <Field
                    name="functionalArea"
                    options={getFunctionalAreas}
                    placeholder="Choose your option..."
                    component={CustomMultiSelect}
                    defaultValue={userInfo.functional_areas}
                />
            </div>
        </div>
    );
};

export default FunctionalArea;