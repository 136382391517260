import React, { useEffect } from "react";
import Scrollbar from "react-scrollbars-custom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner/Spinner";
import { getProgramsActivities } from "../../../../../redux/actions";
import ActivityItem from "./ActivityItem/ActivityItem";
import EmptyData from "../EmptyData/EmptyData";
import "./Activities.scss";

const Activities = () => {
	const dispatch = useDispatch();
	const { programsActivities, programsActivitiesLoading } = useSelector(
		(state) => state.participantDashboard
	);

	useEffect(() => {
		dispatch(getProgramsActivities());
	}, []);

	return (
		<div className="activities">
			<div className="activities__title">Activities</div>
			<div className="activities__list">
				{programsActivitiesLoading ? (
					<Spinner size={50} />
				) : (
					<Scrollbar
						className="custom-scroll__wrapper"
						trackyelement={null}
						translateContentSizesToHolder
						disableTracksWidthCompensation
					>
						{programsActivities?.length > 0 ? (
							programsActivities?.map((activity) => (
								<ActivityItem
									key={activity.id}
									activityId={activity.id}
									type={activity.type}
									name={activity.name}
									program={activity.participant_program}
								/>
							))
						) : (
							<EmptyData type="activity" />
						)}
					</Scrollbar>
				)}
			</div>
		</div>
	);
};

export default Activities;
