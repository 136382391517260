import React from 'react';
import { Field } from 'formik';
import EditScheduleTimePicker from 'components/UI/EditScheduleTimePicker/EditScheduleTimePicker';

const TimePickersGroup = ({ firstName, secondName, groupName = false, index = false, disabled = false }) => {

    return (
        <div className='edit-schedule__timepickers'>
            <Field
                name={
                    groupName && (index !== false) ? `${groupName}.${index}.${firstName}` : firstName
                }
                component={EditScheduleTimePicker}
                disabled={disabled}
            />
            <div className='separator'></div>
            <Field
                name={
                    groupName && (index !== false) ? `${groupName}.${index}.${secondName}` : secondName
                }
                component={EditScheduleTimePicker}
                disabled={disabled}
            />
        </div>
    )
}

export default TimePickersGroup;
