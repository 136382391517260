import React from 'react'

const ProgramStatusIcon = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 3.58182 3.58182 0 8 0C12.4182 0 16 3.58182 16 8C16 12.4182 12.4182 16 8 16C3.58182 16 0 12.4182 0 8ZM11.6106 6.39721C11.5801 6.48927 11.5315 6.57429 11.4676 6.64727L7.83127 11.0109C7.76658 11.0885 7.68643 11.1518 7.59594 11.1967C7.50544 11.2416 7.40658 11.2672 7.30565 11.2717C7.20473 11.2763 7.10395 11.2598 7.00976 11.2233C6.91556 11.1868 6.83001 11.1311 6.75855 11.0596L4.57673 8.87782C4.44425 8.74065 4.37094 8.55694 4.3726 8.36626C4.37426 8.17557 4.45075 7.99316 4.58559 7.85831C4.72043 7.72347 4.90284 7.64699 5.09353 7.64533C5.28422 7.64367 5.46793 7.71698 5.60509 7.84946L7.22327 9.46836L10.3505 5.71636C10.4108 5.6404 10.4857 5.57725 10.5707 5.53064C10.6558 5.48403 10.7493 5.45491 10.8457 5.44497C10.9422 5.43503 11.0397 5.44448 11.1324 5.47277C11.2252 5.50106 11.3114 5.54762 11.3859 5.6097C11.4604 5.67179 11.5217 5.74814 11.5662 5.83428C11.6108 5.92041 11.6377 6.01459 11.6453 6.11126C11.6529 6.20794 11.6411 6.30516 11.6106 6.39721Z" fill={color} />
        </svg>
    )
}

export default ProgramStatusIcon