import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as AddSVG } from "assets/vector/chat/add.svg";
import { ReactComponent as SearchSVG } from "assets/vector/chat/search.svg";
import { ReactComponent as CloseSearchSVG } from "assets/vector/chat/close-search.svg";
import { chatSearchRoom, getListRooms } from "../../redux/actions";

import "./Chatlist.scss";

export default function ChatHeader({ setModalParticipants }) {
	const dispatch = useDispatch();
	const [checkOpenSearch, setOpenSearch] = useState(false);
	const [searchUserValue, setSearchUserValue] = useState("");

	const connectWithNewUser = () => {
		setModalParticipants(true);
	};

	const searchUser = () => {
		setOpenSearch(true);
	};

	const closeSearch = () => {
		setOpenSearch(false);
		setSearchUserValue("");
		dispatch(getListRooms());
	};

	const handlerSearchUser = (event) => {
		setSearchUserValue(event.target.value);
		dispatch(chatSearchRoom(event.target.value));
	};

	return (
		<div className="chat-header">
			{checkOpenSearch ? (
				<>
					<input
						type="text"
						className={`chat-search-user`}
						onChange={handlerSearchUser}
						name="chat-search-user"
						value={searchUserValue}
						placeholder={`Key word for search...`}
					/>
					<CloseSearchSVG
						onClick={(e) => {
							e.stopPropagation();
							closeSearch();
						}}
						className={`icon icon-close-search`}
					/>
				</>
			) : (
				<>
					<h2 className="text-chat">Chats</h2>
					<div className="icon-block">
						<AddSVG
							onClick={(e) => {
								e.stopPropagation();
								connectWithNewUser();
							}}
							className={`icon icon-add`}
						/>

						<SearchSVG
							onClick={(e) => {
								e.stopPropagation();
								searchUser();
							}}
							className={`icon icon-search`}
						/>
					</div>
				</>
			)}
		</div>
	);
}
