import React, { useRef } from "react";
import "./Chatlist.scss";

import avatar from "assets/vector/default-avatar.svg";
import { useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getMessagesFromUser } from "../../redux/actions";
import moment from "moment-timezone";
import uniqid from "uniqid";
export default function ChatList({
	setUserRoom,
	getListRoomsChat,
	setCheckChatWithUser,
	handlerParticipantsData,
}) {
	const scrollOnTopChatListRef = useRef();
	const dispatch = useDispatch();
	const specifiedUser = false;
	const setChatWithUser = (roomID, userData) => {
		dispatch(getMessagesFromUser(roomID));
		setUserRoom(roomID);
		handlerParticipantsData(userData, specifiedUser);
		// setCheckChatWithUser(true);
		scrollOnTopList();
	};

	function scrollOnTopList() {
		const currentScrollSectionRef = scrollOnTopChatListRef.current;
		if (currentScrollSectionRef) {
			currentScrollSectionRef.scrollTop = 0;
		}
	}

	return (
		<PerfectScrollbar
			className="chat-list"
			containerRef={(el) => (scrollOnTopChatListRef.current = el)}
		>
			<div className={`chat-list-items`}>
				{getListRoomsChat.map((element) => {
					return (
						<div key={uniqid()} className={`item room-${element.id}`}>
							{element.users.map((user) => {
								return (
									<div
										className={`user ${
											element.has_unread && "chat-not-readed"
										}`}
										key={user.id}
										onClick={(e) => {
											e.stopPropagation();
											setChatWithUser(element.id, user);
										}}
									>
										<div className="avatar">
											<img
												src={user.avatar ? user.avatar : avatar}
												alt="avatar"
											/>
										</div>
										<div className="info">
											<div className="top">
												<div className="user-name">{user.full_name}</div>
												<div className="time">
													{moment(element.updated_at).calendar(
														element.updated_at,
														{
															sameDay: "HH:mm",
															lastDay: "[Yesterday]",
															lastWeek: "MMMM, DD",
															sameElse: "MMMM, DD",
														}
													)}
												</div>
											</div>
											<div className="bottom">
												<div
													className="last-message"
													dangerouslySetInnerHTML={{
														__html: element.last_message_body,
													}}
												/>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</PerfectScrollbar>
	);
}
