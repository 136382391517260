import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { CHOOSE_COACH_MODAL } from "../../redux/constants/modal";
import { openModal } from "../../redux/actions/modal";
import { CHOOSE_COACH_API } from "../../constants/api";
import ResponseError from "helpers/ResponseError";
import {
	hideSuggestedCoachesNotification,
	showNotification,
} from "../../redux/actions";

import "./CoachPreview.scss";

const CoachPreview = ({
	id,
	image,
	choose_coach = false,
	// matching = false,
	experience,
	name,
	surname,
	location,
	participant_program_id,
}) => {
	const dispatch = useDispatch();
	const accessToken = useSelector((state) => state.auth.authToken);

	const chooseMainCoach = () => {
		const data = {
			participant_program_id: participant_program_id,
			coach_id: id,
		};
		axios({
			method: "post",
			url: CHOOSE_COACH_API,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			accept: "application/json",
			data: data,
		})
			.then((response) => {
				if (response.status === 200) {
					dispatch(openModal(CHOOSE_COACH_MODAL));
					dispatch(hideSuggestedCoachesNotification());
				}
			})
			.catch((error) => {
				const responseError = new ResponseError(error.response.data);

				dispatch(
					showNotification({
						title: responseError.getStatus(),
						text: responseError.getValidationErrorMessages(),
					})
				);
			});
	};

	const experienceList = (arr) => {
		return arr.map((item) => {
			return (
				<li className="experience-item" key={item.id}>
					{item.name}
				</li>
			);
		});
	};

	return (
		<section className="coach-preview">
			<div className="container">
				<div className="coach-image">
					<img src={image} alt="coach-img" />
				</div>
				<div className="info__wrapper">
					<div className="coach-info">
						<div className="coach-name">
							{name} {surname}
						</div>
						<div className="coach-location">{location}</div>
						{experience?.length > 0 && (
							<ul className="coach-experience">{experienceList(experience)}</ul>
						)}
						<div className="coach-buttons mobile">
							{/* temporary hidden */}
							{/* {matching &&
                                <div className="match__wrapper">
                                    <div className='matching-percentage'>{matching}% Match</div>
                                </div>
                            } */}
							<div className="buttons__wrapper">
								{choose_coach && (
									<button
										className="choose-btn"
										onClick={() => chooseMainCoach()}
									>
										Choose coach
									</button>
								)}
								<Link to={`./coach-matching/${id}`} className="profile-btn">
									View Profile
								</Link>
							</div>
						</div>
					</div>
					<div className="coach-buttons">
						{/* temporary hidden */}
						{/* {matching && <div className='matching-percentage'>{matching}% Match</div>} */}
						{choose_coach && (
							<button className="choose-btn" onClick={() => chooseMainCoach()}>
								Choose coach
							</button>
						)}
						<Link to={`./coach-matching/${id}`} className="profile-btn">
							View Profile
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CoachPreview;
