import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import routes from "constants/routes";
import { openModal } from "../../../../../redux/actions";
import { QUESTIONNAIRE_LANGUAGE_MODAL } from "../../../../../redux/constants/modal";

const Start = () => {
	const dispatch = useDispatch();

	const { QUESTIONNAIRE_FORM } = routes;

	const { role, survey } = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);

	return (
		<div className="questionnaire__start">
			{/* for ILTB */}
			{/* <div className="start__title">Ready to <span>Go</span>?</div> */}
			<div className="start__title">Ready to Go?</div>
			<div className="start__button">
				{role === "participant" && survey?.survey_id === 2 ? (
					<Link className="start_link" to={QUESTIONNAIRE_FORM}>
						Start
					</Link>
				) : (
					<button
						className="start_link"
						onClick={() => dispatch(openModal(QUESTIONNAIRE_LANGUAGE_MODAL))}
					>
						Start
					</button>
				)}
			</div>
			{/* for ITLB */}
			{/* <img className="start__decor" src={decor_image} alt="" /> */}
		</div>
	);
};

export default Start;
