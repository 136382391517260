import {
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_ERROR,
	CREATE_PASSWORD_REQUEST,
	CREATE_PASSWORD_ERROR,
	CREATE_COACH_PASSWORD_REQUEST,
} from "../constants";

const initialState = {
	resetPasswordSuccess: false,
	resetPasswordError: null,
	resetPasswordInProgress: false,
	createPasswordSuccess: false,
	createPasswordError: null,
	createPasswordInProgress: false,
};

export default function setPasswordReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_PASSWORD_REQUEST:
			return {
				...state,
				createPasswordInProgress: true,
			};
		case CREATE_COACH_PASSWORD_REQUEST:
			return {
				...state,
				createPasswordInProgress: true,
			};
		case CREATE_PASSWORD_ERROR:
			return {
				...state,
				createPasswordSuccess: false,
				createPasswordError: payload,
				createPasswordInProgress: false,
			};
		case RESET_PASSWORD_REQUEST:
			return {
				...state,
				resetPasswordInProgress: true,
			};
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				resetPasswordSuccess: true,
				resetPasswordError: null,
				resetPasswordInProgress: false,
			};
		case RESET_PASSWORD_ERROR:
			return {
				...state,
				resetPasswordSuccess: false,
				resetPasswordError: payload,
				resetPasswordInProgress: false,
			};
		default:
			return state;
	}
}
