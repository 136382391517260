export function getUserTokenFromStorage() {
    return localStorage.user_token;
}

export function getSchoolIdFromStorage() {
    return localStorage.school_id;
}

export function getPlatformSettingsFromStorage() {
    return localStorage.platformSettings;
}

export function getSurveyDataFromStorage() {
    return localStorage.survey;
}

export function getTimeZoneNotificationStatusFronStorage() {
    return localStorage.time_zone_notification;
}

export function getSuggestedCoachesNotificationStatus() {
    return localStorage.suggested_coaches_notification;
}

export function clearLocalStorage() {
    delete localStorage.user_token;
    delete localStorage.user_short_info;
    delete localStorage.survey;
    delete localStorage.school_id;
    delete localStorage.platformSettings;
    delete localStorage.survey_language;
    delete localStorage.time_zone_notification;
    delete localStorage.suggested_coaches_notification;
}