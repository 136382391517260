import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./ReportsMobileSorting.scss";
import ArrowSlimIcon from "components/icons/ArrowSlimIcon";
import { setReportsSortingOptionAndOrder } from "../../../../redux/actions";

const engagementSortingOptions = [
	{
		label: "First session date",
		value: "first_session_completed_at",
	},
	{
		label: "Last session date",
		value: "last_session_completed_at",
	},
	{
		label: "Number attended sessions",
		value: "sessions_completed",
	},
	{
		label: "Average mark 1 to 5",
		value: "average_feedback_rate",
	},
];

const schoolAdminSortingOptions = [
	{
		label: "First session date",
		value: "first_session_completed_at",
	},
	{
		label: "Last session date",
		value: "last_session_completed_at",
	},
	{
		label: "Number attended sessions",
		value: "sessions_completed",
	},
	{
		label: "Average mark 1 to 5",
		value: "average_mark",
	},
	{
		label: "Coach",
		value: "main_coach",
	},
	// {
	//     label: 'Survey',
	//     value: 'survey_filled',
	// }
];

const sortingOptions = [
	{
		label: "Submission date",
		value: "created_at",
	},
	{
		label: "Average mark",
		value: "average_mark",
	},
];

const ReportsMobileSorting = ({
	userRole,
	setAllFiltersVisible,
	activeTab,
	setOffsetReports,
}) => {
	const dispatch = useDispatch();
	const [activeOption, setActiveOption] = useState({});
	const [sortingOrder, setSortingOrder] = useState("");

	const handleSortingOrder = () => {
		if (sortingOrder !== "") {
			setSortingOrder("");
		}

		if (sortingOrder === "asc") {
			setSortingOrder("desc");
		} else {
			setSortingOrder("asc");
		}
	};

	const handleSortingOptionClick = (item) => {
		setActiveOption(item);
		handleSortingOrder();
	};

	const currentEngagementSortingOptions =
		userRole === "school_admin"
			? schoolAdminSortingOptions
			: engagementSortingOptions;

	const reportsSortingList =
		activeTab?.name !== "engagement"
			? sortingOptions
			: currentEngagementSortingOptions;

	const handleSorting = (sortingValue, order) => {
		setOffsetReports(0);
		dispatch(
			setReportsSortingOptionAndOrder({
				order_by: sortingValue,
				order: order,
			})
		);
		setAllFiltersVisible(false);
	};

	return (
		<>
			<ul className="mobile-sorting">
				{reportsSortingList.map((item) => (
					<li
						key={item.value}
						className={`mobile-sorting__sort ${
							activeOption?.value === item.value
								? "mobile-sorting__sort--active"
								: ""
						}`}
						onClick={() => handleSortingOptionClick(item)}
					>
						{item.label}
						<div className="mobile-sorting__direct">
							<ArrowSlimIcon
								className={`mobile-sorting__icon ${
									sortingOrder === "desc" && activeOption?.value === item.value
										? "mobile-sorting__icon--down"
										: ""
								}`}
								color={activeOption?.value === item.value ? "#2E79DA" : "white"}
							/>
						</div>
					</li>
				))}
			</ul>
			<button
				className={`mobile-sorting__btn ${
					sortingOrder ? "" : "mobile-sorting__btn--disabled"
				}`}
				onClick={() => handleSorting(activeOption?.value, sortingOrder)}
				disabled={!sortingOrder}
			>
				Apply sorting
			</button>
		</>
	);
};

export default ReportsMobileSorting;
