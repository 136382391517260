import React from "react";
import "./MyProfile.scss";
import {Link} from "react-router-dom";
import {ReactComponent as EditProfileSvg} from "assets/vector/my-profile/edit.svg";
import routes from "constants/routes";
import {useMediaQuery} from "react-responsive";
import mediaQuery from "constants/mediaQuery";
import defaultAva from 'assets/vector/default-avatar.svg';

const {MOBILE_QUERY_767} = mediaQuery;

export default function SchoolAdminProfile({getCurrentUserInfo}) {


    const {EDIT_MY_PROFILE} = routes;
    const isMobile = useMediaQuery({maxDeviceWidth: MOBILE_QUERY_767});

    return (
        <div className="my-profile">

            <div className="short-info">
 
                <div className="my-avatar"><img src={getCurrentUserInfo.avatar ? getCurrentUserInfo.avatar : defaultAva} alt="" /></div>

                <div className="my-short-info">
                    <h3 className={`name`} >{getCurrentUserInfo.school_name}</h3>
                </div>

                <div className="my-full-info">

                    {
                        isMobile ?
                            <Link className={`edit-profile-icon`} to={EDIT_MY_PROFILE}>
                                <EditProfileSvg />
                            </Link>
                            :
                            <Link className="view-profile" to={EDIT_MY_PROFILE}>
                                Edit
                            </Link>
                    }

                </div>


            </div>

        </div>
    )
}