import React, { useEffect, useState } from "react";
import useFetch from "../../../../../../hooks/useFetch";
import Spinner from "../../../../../Spinner/Spinner";
import CreateNote from "./CreateNote";
import ViewNote from "./ViewNote";
import EditNote from "./EditNote";
import { PROGRAMMES_URLS } from "../../../../../../constants/api";

function Note({ activityId }) {
	const [note, setNote] = useState(undefined);
	const [createNoteValue, setCreateNoteValue] = useState("");
	const [edit, setEdit] = useState(false);

	const {
		data,
		isLoading: isNoteLoading,
		execute: getNote,
		updateUrl: updateGetNoteUrl,
	} = useFetch({
		initialOptions: { immediate: false },
	});

	const {
		execute: getNotes,
		isLoading: isNotesLoading,
		data: notes,
	} = useFetch({
		initialUrl: PROGRAMMES_URLS.NOTES.PARTICIPANT.LIST.GET,
		initialOptions: { immediate: false },
	});

	const { isLoading: isCreateNoteLoading, execute: createNote } = useFetch({
		initialUrl: PROGRAMMES_URLS.NOTES.POST,
		initialOptions: { immediate: false },
		initialRequestOptions: { method: "POST" },
	});

	const {
		isLoading: isUpdateNoteLoading,
		execute: updateNote,
		updateUrl: updateNoteUrl,
	} = useFetch({
		initialOptions: { immediate: false },
		initialRequestOptions: { method: "PUT" },
	});

	const {
		isLoading: isDeleteNoteLoading,
		execute: deleteNote,
		updateUrl,
	} = useFetch({
		initialOptions: { immediate: false },
		initialRequestOptions: { method: "DELETE" },
	});

	const textEditorOptions = [
		["bold", "italic", "underline"],
		[{ list: "ordered" }, { list: "bullet" }],
	];

	useEffect(() => {
		if (data) {
			setNote(data);
		}
	}, [data]);

	useEffect(() => {
		updateGetNoteUrl(PROGRAMMES_URLS.NOTES.PARTICIPANT.GET(activityId));
		getNotes();
	}, [activityId, getNotes, updateGetNoteUrl]);

	useEffect(() => {
		if (notes?.length) {
			const foundNote = notes.find(
				(note) => note.participant_activity_id === activityId
			);

			if (foundNote) {
				setNote(foundNote);
			} else {
				setNote(undefined);
			}
		}
	}, [notes, activityId]);

	const handleCreateNote = async () => {
		await createNote({
			content: createNoteValue,
			participant_activity_id: activityId,
		});
		await getNote();
		setCreateNoteValue("");
	};

	const handleSaveEdit = async () => {
		updateNoteUrl(PROGRAMMES_URLS.NOTES.PUT(note?.id));
		await updateNote({ content: note?.content });
		getNote();
		setEdit(false);
	};

	const handleCancelEdit = () => {
		setEdit(false);
		setNote(note);
	};

	const handleEditNoteChange = (content) => {
		setNote((prevNote) =>
			prevNote.id === note.id ? { ...prevNote, content } : prevNote
		);
	};

	const handleDeleteNote = async () => {
		updateUrl(PROGRAMMES_URLS.NOTES.DELETE(note?.id));
		await deleteNote();
		setNote(undefined);
		setCreateNoteValue("");
	};

	if (isNoteLoading || isNotesLoading) {
		return <Spinner className="h-full" />;
	}

	return (
		<div className="mt-6 space-y-6">
			{!note && (
				<CreateNote
					textEditorOptions={textEditorOptions}
					createNoteValue={createNoteValue}
					setCreateNoteValue={setCreateNoteValue}
					isLoading={isCreateNoteLoading}
					handleCreateClick={handleCreateNote}
					activityId={activityId}
				/>
			)}

			{!edit && note && (
				<ViewNote
					note={note}
					onEditClick={() => {
						setEdit(true);
					}}
					isLoading={isDeleteNoteLoading}
					onDeleteClick={handleDeleteNote}
				/>
			)}

			{edit && note && (
				<EditNote
					textEditorOptions={textEditorOptions}
					note={note}
					setEditNoteValue={handleEditNoteChange}
					isLoading={isUpdateNoteLoading}
					handleSaveClick={handleSaveEdit}
					handleCancelClick={handleCancelEdit}
				/>
			)}
		</div>
	);
}

export default Note;
