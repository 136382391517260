import {
	CLEAR_INDIVIDUAL_SESSION_INFO,
	CREATE_INDIVIDUAL_SESSION,
	CREATE_INDIVIDUAL_SESSION_ERROR,
	CREATE_INDIVIDUAL_SESSION_SUCCESS,
	GET_SESSION_TIME,
	GET_SESSION_TIME_SUCCESS,
	GET_SESSION_TIME_ERROR,
	GET_SESSION_FILTER,
	GET_SESSION_FILTER_SUCCESS,
	GET_SESSION_FILTER_ERROR,
	CLEAR_SESSION_FILTER,
	GET_COACH_ABSENCE_SCHEDULE,
	GET_COACH_ABSENCE_SCHEDULE_SUCCESS,
} from "../constants";
import { transformSessionFilter } from "../sagas/helpers/createSession";

const initialState = {
	createSessionProgress: false,
	createSessionSuccess: null,
	createSessionError: null,
	coachAbsenceScheduleLoading: false,
	coachAbsenceSchedule: [],
	sessionsTime: [],
	sessionsTimeRequest: false,
	sessionsTimeRequestError: null,
	sessionFilter: {
		defaultData: {},
		updatedData: {},
	},
	sessionFilterRequest: false,
	sessionFilterRequestError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function createSessionReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_INDIVIDUAL_SESSION:
			return {
				...state,
				createSessionProgress: true,
				createSessionSuccess: null,
				createSessionError: null,
			};
		case CREATE_INDIVIDUAL_SESSION_SUCCESS:
			return {
				...state,
				createSessionProgress: false,
				createSessionSuccess: payload,
				createSessionError: null,
			};
		case CREATE_INDIVIDUAL_SESSION_ERROR:
			return {
				...state,
				createSessionProgress: false,
				createSessionSuccess: null,
				createSessionError: payload,
			};
		case GET_SESSION_TIME:
			return {
				...state,
				sessionsTimeRequest: true,
				sessionsTime: [],
				sessionsTimeRequestError: null,
			};
		case GET_SESSION_TIME_SUCCESS:
			return {
				...state,
				sessionsTimeRequest: false,
				sessionsTime: payload,
				sessionsTimeRequestError: null,
			};
		case GET_COACH_ABSENCE_SCHEDULE:
			return {
				...state,
				coachAbsenceScheduleLoading: true,
			};
		case GET_COACH_ABSENCE_SCHEDULE_SUCCESS:
			return {
				...state,
				coachAbsenceScheduleLoading: false,
				coachAbsenceSchedule: payload,
			};
		case GET_SESSION_TIME_ERROR:
			return {
				...state,
				sessionsTimeRequest: false,
				sessionsTime: [],
				sessionsTimeRequestError: payload,
			};
		case GET_SESSION_FILTER:
			return {
				...state,
				sessionFilterRequest: true,
				sessionFilterRequestError: null,
			};
		case GET_SESSION_FILTER_SUCCESS:
			return {
				...state,
				sessionFilter: transformSessionFilter(
					{ ...state.sessionFilter },
					payload
				),
				sessionFilterRequest: false,
			};
		case GET_SESSION_FILTER_ERROR:
			return {
				...state,
				sessionFilterRequest: false,
				sessionFilterRequestError: payload,
			};
		case CLEAR_SESSION_FILTER:
			return {
				...state,
				sessionFilter: {
					defaultData: {},
					updatedData: {},
				},
				sessionFilterRequest: false,
				sessionFilterRequestError: null,
			};
		case CLEAR_INDIVIDUAL_SESSION_INFO:
			return {
				...state,
				...initialState,
			};
		default:
			return state;
	}
}
