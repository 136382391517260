import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGOUT,
	UPDATE_TOKEN_FROM_LOCALSTORAGE,
	UPDATE_SCHOOL_ID_FROM_LOCALSTORAGE,
	UPDATE_SURVEY_DATA_FROM_LOCALSTORAGE,
	CHAT_LOADED_SUCCESS,
	GET_STATIC_DATA,
	GET_STATIC_DATA_SUCCESS,
} from "../constants";

// Login
export function loginUserRequest(requestData, callback) {
	return {
		type: LOGIN_REQUEST,
		payload: requestData,
		callback,
	};
}
export function loginUserSuccess(successData) {
	return {
		type: LOGIN_SUCCESS,
		payload: successData,
	};
}
export function loginUserError(errorData) {
	return {
		type: LOGIN_ERROR,
		payload: errorData,
	};
}

// Logout
export function logout() {
	return {
		type: LOGOUT,
	};
}

export function updateToken(token) {
	return {
		type: UPDATE_TOKEN_FROM_LOCALSTORAGE,
		payload: token,
	};
}

export function updateSurveyDataFromLocalstorage(surveyData) {
	return {
		type: UPDATE_SURVEY_DATA_FROM_LOCALSTORAGE,
		payload: surveyData,
	};
}

export function updateSchoolId(schoolId) {
	return {
		type: UPDATE_SCHOOL_ID_FROM_LOCALSTORAGE,
		payload: schoolId,
	};
}

export function successChatConnection() {
	return {
		type: CHAT_LOADED_SUCCESS,
	};
}

export function getStaticData() {
	return {
		type: GET_STATIC_DATA,
	};
}

export function getStaticDataSuccess(data) {
	return {
		type: GET_STATIC_DATA_SUCCESS,
		payload: data,
	};
}
