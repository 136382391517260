import React from 'react';
import SelectForm from './SelectForm';
import MultiSelectStep from './MultiSelectStep';

const DropdownStep = ({ name, options, stepDirection, question, goToNextStep, goToPreviousStep, item, values, rules }) => {

    if (rules.includes("max:1")) {
        return <SelectForm
            options={options}
            question={question}
            name={name}
            item={item}
            goToNextStep={goToNextStep}
            values={values}
            stepDirection={stepDirection}
            goToPreviousStep={goToPreviousStep}
        />
    }
    return <MultiSelectStep
        options={options}
        question={question}
        name={name}
        item={item}
        goToNextStep={goToNextStep}
        values={values}
        stepDirection={stepDirection}
        goToPreviousStep={goToPreviousStep}
    />
}

export default DropdownStep;