import axios from "axios";
import { fork, put, select, takeLatest } from "redux-saga/effects";
import {
	GET_PROGRAMS_ACTIVITIES,
	GET_PROGRAMS_PROGRESS,
	GET_PROGRAMS_TODOS,
	GET_UPCOMING_SESSIONS,
} from "../constants";
import { participantDashboard } from "constants/api";
import { generalApiErrorHandler } from "../sagas/helpers/generalApiErrorHandler";
import { getNotificationMessage } from "../../helpers/notifications";
import { getToken } from "../sagas/helpers/helpers";
import {
	getProgramsActivitiesError,
	getProgramsActivitiesSuccess,
	getProgramsProgressError,
	getProgramsProgressSuccess,
	getProgramsTodosError,
	getProgramsTodosSuccess,
	getUpcomingSessionsError,
	getUpcomingSessionsSuccess,
	showNotification,
} from "../actions";

function* watchGetProgramsProgress() {
	yield takeLatest(GET_PROGRAMS_PROGRESS, handleGetProgramsProgress);
}

function* handleGetProgramsProgress() {
	try {
		const token = yield select(getToken);

		const programsProgress = yield axios({
			method: "get",
			url: participantDashboard.PROGRAMS_PROGRESS,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (programsProgress.length > 0) {
			yield put(getProgramsProgressSuccess(programsProgress));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			yield put(getProgramsProgressError(error.message));
			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetProgramsActivities() {
	yield takeLatest(GET_PROGRAMS_ACTIVITIES, handleGetProgramsActivities);
}

function* handleGetProgramsActivities() {
	try {
		const token = yield select(getToken);

		const programsActivities = yield axios({
			method: "get",
			url: participantDashboard.PROGRAMS_ACTIVITIES,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(getProgramsActivitiesSuccess(programsActivities));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			yield put(getProgramsActivitiesError(error.message));
			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetProgramsTodos() {
	yield takeLatest(GET_PROGRAMS_TODOS, handleGetProgramsTodos);
}

function* handleGetProgramsTodos() {
	try {
		const token = yield select(getToken);

		const programsTodos = yield axios({
			method: "get",
			url: participantDashboard.PROGRAMS_TODOS,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(getProgramsTodosSuccess(programsTodos));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			yield put(getProgramsTodosError(error.message));
			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetUpcomingSessions() {
	yield takeLatest(GET_UPCOMING_SESSIONS, handleGetUpcomingSessions);
}

function* handleGetUpcomingSessions() {
	try {
		const token = yield select(getToken);

		const programsTodos = yield axios({
			method: "get",
			url: participantDashboard.UPCOMING_SESSIONS,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(getUpcomingSessionsSuccess(programsTodos));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			yield put(getUpcomingSessionsError(error.message));
			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* participantDashboardSaga() {
	yield fork(watchGetProgramsProgress);
	yield fork(watchGetProgramsActivities);
	yield fork(watchGetProgramsTodos);
	yield fork(watchGetUpcomingSessions);
}
