import React, { useEffect, useState } from "react";
import CheckIcon from "components/icons/CheckIcon";
import Button from "components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { completeActivity } from "../../../../../../../redux/actions";
import { checkCompleteActivityPermission } from "helpers/userPrograms";

const CompleteAction = ({ completed, activityId, activityType }) => {
	const dispatch = useDispatch();
	const authToken = useSelector((state) => state.auth.authToken);
	const { completeActivityError } =
		useSelector((state) => state.userPrograms.error) || {};
	const [isCompleteRequest, setIsCompleteRequest] = useState(false);
	const hasCompleteActivityRights =
		checkCompleteActivityPermission(activityType);

	const handleCompleteActivity = () => {
		setIsCompleteRequest(true);
		dispatch(completeActivity({ activityId, authToken }));
	};

	useEffect(() => {
		if (completeActivityError || completed) {
			setIsCompleteRequest(false);
		}
	}, [completed, completeActivityError]);

	return (
		<>
			{completed ? (
				<span className="topics-content__status">
					Completed
					<CheckIcon color="#35A684" className="topics-content__status-icon" />
				</span>
			) : hasCompleteActivityRights ? (
				<Button
					className="topics-content__action-btn"
					type="button"
					onClick={handleCompleteActivity}
					loading={isCompleteRequest}
					spinner={{ color: "#35A684" }}
				>
					Complete activity
				</Button>
			) : null}
		</>
	);
};

export default CompleteAction;
