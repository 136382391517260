import React, { useState, useEffect, useRef } from 'react';
import icon from 'assets/vector/bx_time-five.svg';
import moment from 'moment';
import { useSelector } from 'react-redux';
import customHeaderUnavailableDates from './helpers/customHeaderUnavailableDates';
import { useMediaQuery } from 'react-responsive';
import useClickOutside from 'hooks/useClickOutside';

const MonthDateHeader = ({ date, absencePeriods, coachName, label, onDrillDown }) => {

    const isMobile = useMediaQuery({ query: `(max-width: 920px)` });
    const unavailableDatesRef = useRef();
    const { absence_schedule, role } = useSelector(state => state.currentUserInfo.currentUserInfo);
    const [showIcon, setShowIcon] = useState(false);
    const [unavailableDates, setUnavailableDates] = useState([]);
    const [showUnavailableList, setShowUnavailableList] = useState(false);

    const uniqueTimeSlots = (timeSlots) => {
        const fullDayUnavailable = timeSlots?.find(item => (moment(item.endPeriod).format('HH') - moment(item.startPeriod).format('HH')) >= 23)

        if (fullDayUnavailable) {
            return (
                <li className='unavailable-dates__list-item'>
                    {moment(fullDayUnavailable.startPeriod).format('h:mm')}{' '}
                    <span>{moment(fullDayUnavailable.startPeriod).format('A')}</span>
                    {' '}-{' '}
                    {moment(fullDayUnavailable.endPeriod).format('h:mm')}{' '}
                    <span>{moment(fullDayUnavailable.endPeriod).format('A')}</span>
                </li>
            )
        }

        return timeSlots?.map((item, index) => {
            return (
                <li className='unavailable-dates__list-item' key={index}>
                    {moment(item.startPeriod).format('h:mm')}{' '}
                    <span>{moment(item.startPeriod).format('A')}</span>
                    {' '}-{' '}
                    {moment(item.endPeriod).format('h:mm')}{' '}
                    <span>{moment(item.endPeriod).format('A')}</span>
                </li>
            )
        });
    }

    useEffect(() => {
        customHeaderUnavailableDates(date, absencePeriods, setShowIcon, role);
        setUnavailableDates([
            ...absencePeriods
                .filter(item => new Date(item.startPeriod).getDate() === new Date(item.endPeriod).getDate())
                .filter(item => moment(item.startPeriod).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))
        ])
    }, [absence_schedule, absencePeriods])

    useClickOutside(unavailableDatesRef, () => {
        if (showUnavailableList) setShowUnavailableList(false)
    })

    return (
        <>
            <a href="#" role='cell' onClick={onDrillDown}>{label}</a>
            {
                isMobile
                    ?
                    <div
                        ref={unavailableDatesRef}
                        className="absence-dates__wrapper"

                    >
                        {showIcon && <img onClick={() => setShowUnavailableList(prevState => !prevState)} className='month-header-icon' src={icon} alt="" />}
                        {showIcon &&
                            <div className={`unavailable-dates__list ${showUnavailableList ? 'active' : ''}`}>
                                <div className="unavailable-dates__list-title">
                                    {
                                        role === 'participant' && coachName
                                            ? `${coachName} unavailable:`
                                            : 'Unavailable:'
                                    }
                                </div>
                                <ul>
                                    {uniqueTimeSlots(unavailableDates)}
                                </ul>
                            </div>
                        }
                    </div>
                    :
                    <div
                        className="absence-dates__wrapper"
                        onMouseEnter={() => setShowUnavailableList(prevState => !prevState)}
                        onMouseLeave={() => setShowUnavailableList(prevState => !prevState)}
                    >
                        {showIcon && <img className='month-header-icon' src={icon} alt="" />}
                        {showIcon &&
                            <div className={`unavailable-dates__list ${showUnavailableList ? 'active' : ''}`}>
                                <div className="unavailable-dates__list-title">
                                    {
                                        role === 'participant' && coachName
                                            ? `${coachName} unavailable:`
                                            : 'Unavailable:'
                                    }
                                </div>
                                {uniqueTimeSlots(unavailableDates)}
                            </div>
                        }
                    </div>
            }
        </>
    )
}

export default MonthDateHeader;
