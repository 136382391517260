import moment from 'moment';

const separateVisiblePeriodToDays = (periodStart, periodEnd) => {

    const arr = [];

    const start = moment(periodStart).format('yyyy-MM-DDTHH:mm:ss');
    const end = moment(periodEnd).format('yyyy-MM-DDTHH:mm:ss');

    const duration = moment.duration(moment(end).diff(moment(start)));
    const days = Math.round(duration.asDays());

    if (days >= 1) {
        for (let i = 0; i <= days; i++) {
            if (i === 0) {
                arr.push({
                    startPeriod: start,
                    endPeriod: moment(start).endOf("day").format('yyyy-MM-DDTHH:mm:ss')
                })
            } else if (i === days) {
                arr.push({
                    startPeriod: moment(end).startOf("day").format('yyyy-MM-DDTHH:mm:ss'),
                    endPeriod: end
                })
            } else {
                arr.push({
                    startPeriod: moment(start).add('days', i).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
                    endPeriod: moment(start).add('days', i).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
                })
            }
        }
    } else {
        // if time-off period less than 24h
        arr.push({
            startPeriod: start,
            endPeriod: end
        })
    }

    return arr;
}

export default separateVisiblePeriodToDays;
