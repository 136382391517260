import { getData } from "../../sagas/helpers/requestHandlers";
import {
	GET_BILLING_OVERVIEW_REPORTS_ROUTE,
	GET_COACH_INVOICES_ROUTE,
	GET_ENGAGEMENT_REPORTS_ROUTE,
	GET_REPORTS_ROUTE,
	GET_SINGLE_REPORT_ROUTE,
} from "constants/api";
import { put, call, select } from "redux-saga/effects";
import {
	getReportsError,
	getFeedbackReportsSuccess,
	getFeedbackReportsEmpty,
	getEvaluationReportsSuccess,
	getEvaluationReportsEmpty,
	getEngagementReportsSuccess,
	getEngagementReportsEmpty,
	getReportError,
	getReportSuccess,
	getBillingOverviewSuccess,
	getBillingOverviewEmpty,
	getCoachInvoicesSuccess,
	getCoachInvoicesEmpty,
} from "../../actions/reports";
import ResponseError from "helpers/ResponseError";
import { showNotification } from "../../actions";
import { getNotificationMessage } from "../../../helpers/notifications";
import { getToken } from "../../sagas/helpers/helpers";

export function* getFeedbackReportsRequest({ payload: token, params }) {
	const { feedbackReports } = yield select((state) => state.reports);

	const { data } = yield call(
		getData(GET_REPORTS_ROUTE, token, { params: { ...params } })
	);

	if (data?.status === "Success") {
		const reports = data.data;

		const newReportsData = {
			...reports,
		};

		if (feedbackReports?.reports.length > 0 && params?.offset > 0) {
			newReportsData.reports = [
				...feedbackReports?.reports,
				...reports.reports,
			];
		}

		if (reports.reports.length > 0) {
			yield put(getFeedbackReportsSuccess(newReportsData));
		} else {
			yield put(getFeedbackReportsEmpty(newReportsData));
		}
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(getReportsError(err.getValidationErrorMessages()));

		console.log(err.getValidationErrorMessages());

		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}

export function* getEvaluationReportsRequest({ payload: token, params }) {
	const { evaluationReports } = yield select((state) => state.reports);

	const { data } = yield call(
		getData(GET_REPORTS_ROUTE, token, { params: { ...params } })
	);

	if (data?.status === "Success") {
		const reports = data.data;

		const newReportsData = {
			...reports,
		};

		if (evaluationReports?.reports.length > 0 && params?.offset > 0) {
			newReportsData.reports = [
				...evaluationReports?.reports,
				...reports.reports,
			];
		}

		if (reports.reports.length > 0) {
			yield put(getEvaluationReportsSuccess(newReportsData));
		} else {
			yield put(getEvaluationReportsEmpty(newReportsData));
		}
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(getReportsError(err.getValidationErrorMessages()));

		console.log(err.getValidationErrorMessages());

		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}

export function* getEngagementReportsRequest({ payload: token, params }) {
	const { engagementReports } = yield select((state) => state.reports);

	const { data } = yield call(
		getData(GET_ENGAGEMENT_REPORTS_ROUTE, token, { params: { ...params } })
	);

	if (data?.status === "Success") {
		const reports = data.data;

		const newReportsData = {
			...reports,
		};

		if (
			engagementReports?.engagement_reports.length > 0 &&
			params?.offset > 0
		) {
			newReportsData.engagement_reports = [
				...engagementReports?.engagement_reports,
				...reports.engagement_reports,
			];
		}

		if (reports.engagement_reports.length > 0) {
			yield put(getEngagementReportsSuccess(newReportsData));
		} else {
			yield put(getEngagementReportsEmpty(newReportsData));
		}
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(getReportsError(err.getValidationErrorMessages()));

		console.log(err.getValidationErrorMessages());

		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}

export function* getBillingReportsRequest({ payload: token, params }) {
	const { billingReports } = yield select((state) => state.reports);

	const { data } = yield call(
		getData(GET_BILLING_OVERVIEW_REPORTS_ROUTE, token, {
			params: { ...params },
		})
	);

	if (data?.status === "Success") {
		const reports = data.data;

		const newReportsData = {
			...reports,
		};

		if (billingReports?.periods.length > 0 && params?.offset > 0) {
			newReportsData.periods = [...billingReports?.periods, ...reports.periods];
		}

		if (reports.length > 0) {
			yield put(getBillingOverviewSuccess(newReportsData));
		} else {
			yield put(getBillingOverviewEmpty(newReportsData));
		}
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(getReportsError(err.getValidationErrorMessages()));

		console.log(err.getValidationErrorMessages());

		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}
export function* getInvoicesRequest({ payload: token, params }) {
	const { invoices } = yield select((state) => state.reports);

	const { data } = yield call(
		getData(GET_COACH_INVOICES_ROUTE, token, { params: { ...params } })
	);

	if (data?.status === "Success") {
		const coachInvoices = data.data;

		const newInvoicesData = {
			...coachInvoices,
		};

		if (coachInvoices?.invoices.length > 0 && params?.offset > 0) {
			newInvoicesData.invoices = [
				...invoices?.invoices,
				...coachInvoices.invoices,
			];
		}

		if (coachInvoices.length > 0) {
			yield put(getCoachInvoicesSuccess(newInvoicesData));
		} else {
			yield put(getCoachInvoicesEmpty(newInvoicesData));
		}
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(getReportsError(err.getValidationErrorMessages()));

		console.log(err.getValidationErrorMessages());

		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}

export function* getReportRequest(reportId) {
	const token = yield select(getToken);
	const route = GET_SINGLE_REPORT_ROUTE(reportId);

	const { data } = yield call(getData(route, token));

	if (data?.status === "Success") {
		yield put(getReportSuccess(data.data));
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(getReportError(err.getValidationErrorMessages()));

		console.log(err.getValidationErrorMessages());

		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}
