import React from "react";
import ReactQuill from "react-quill";
import { ClipLoader } from "react-spinners";

function CreateNote({
	textEditorOptions,
	createNoteValue,
	setCreateNoteValue,
	isLoading,
	handleCreateClick,
}) {
	return (
		<>
			<ReactQuill
				modules={{
					toolbar: textEditorOptions,
				}}
				style={{ marginBottom: "0" }}
				placeholder={`Create your Note...`}
				theme="snow"
				value={createNoteValue}
				onChange={setCreateNoteValue}
			/>
			<button
				className="button button--blue h-12"
				onClick={handleCreateClick}
				disabled={isLoading}
			>
				{isLoading ? <ClipLoader color="white" size={28} /> : "Create Note"}
			</button>
		</>
	);
}

export default CreateNote;
