import React from "react";
import Select, { components } from 'react-select';
import { customMultiSelectStyles } from "helpers/customSelectStyles";
import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";
import { ReactComponent as SelectCloseSvg } from "assets/vector/select-close.svg";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowSvg />
        </components.DropdownIndicator>
    );
};

const QuestionnaireMultiSelect = ({ field, options, placeholder, form: { setFieldValue } }) => {

    const onChangeSelect = (optionValue) => {
        setFieldValue(field.name, optionValue.map(item => item.id ? item.id : item.key));
    };

    return (
        <Select
            isMulti
            components={{
                DropdownIndicator,
                ClearIndicator: ({ innerRef, innerProps }) => <div ref={innerRef} {...innerProps}><SelectCloseSvg className={`multi-select-remove-item`} /></div>,
                MultiValueRemove: ({ innerRef, innerProps }) => <div ref={innerRef} {...innerProps}><SelectCloseSvg className={`multi-select-remove-item`} /></div>
            }}
            getOptionLabel={options => options.name ? options.name : options.option}
            getOptionValue={options => options.id ? options.id : options.key}
            closeMenuOnSelect={true}
            isSearchable={false}
            name={field.name}
            options={options}
            placeholder={placeholder}
            styles={customMultiSelectStyles}
            onChange={(optionValue) => onChangeSelect(optionValue)}
            noOptionsMessage={() => "No options!"}
            className="multi-select"
            classNamePrefix="row-select"
            value={
                options?.filter(option => option.id
                    ? field.value.includes(option.id)
                    : (option.key ? field.value.includes(option.key) : '')
                )
            }
        />
    )
}

export default QuestionnaireMultiSelect;
