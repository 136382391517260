import moment from 'moment/moment';

const compareDates = (a, b) => {
    const date1 = new Date(a).getTime();
    const date2 = new Date(b).getTime();

    return date1 - date2;
}

const absenceScheduleTransform = (data, calendarDaysView) => {

    let arr = [];

    // check if we have a break days
    if (data?.break_days?.length > 0) {
        for (let i = 0; i < calendarDaysView.length; i++) {

            // ccheck if break_days array includes current day from calendar view
            if (data.break_days.includes(moment(calendarDaysView[i].startPeriod).day())) {
                const currentCalendarViewDay = new Date(calendarDaysView[i].startPeriod);

                // split break_days_time string to set the time of the current calendar day slot
                const existStartTime = data.break_days_time[0].start.split(':');
                const existEndTime = data.break_days_time[0].end.split(':');

                const myDayStart = new Date(currentCalendarViewDay.getFullYear(), currentCalendarViewDay.getMonth(), currentCalendarViewDay.getDate(), +existStartTime[0], +existStartTime[1], 0);
                const myDayEnd = new Date(currentCalendarViewDay.getFullYear(), currentCalendarViewDay.getMonth(), currentCalendarViewDay.getDate(), +existEndTime[0], +existEndTime[1], 0);

                const start = moment(myDayStart).format('yyyy-MM-DDTHH:mm:ss');
                const end = moment(myDayEnd).format('yyyy-MM-DDTHH:mm:ss');

                // push transformed time period to the array with all date periods
                arr.push({
                    startPeriod: start,
                    endPeriod: end
                })
            }
        }
    }

    // check if we have daily break
    if (data?.daily_break_time?.length > 0) {
        for (let i = 0; i < calendarDaysView.length; i++) {

            // add daily break as a time period for current day
            const currentCalendarViewDay = new Date(calendarDaysView[i].startPeriod);

            const existStartTime = data.daily_break_time[0].start.split(':');
            const existEndTime = data.daily_break_time[0].end.split(':');

            const myDayStart = new Date(currentCalendarViewDay.getFullYear(), currentCalendarViewDay.getMonth(), currentCalendarViewDay.getDate(), +existStartTime[0], +existStartTime[1], 0);
            const myDayEnd = new Date(currentCalendarViewDay.getFullYear(), currentCalendarViewDay.getMonth(), currentCalendarViewDay.getDate(), +existEndTime[0], +existEndTime[1], 0);

            const start = moment(myDayStart).format('yyyy-MM-DDTHH:mm:ss');
            const end = moment(myDayEnd).format('yyyy-MM-DDTHH:mm:ss');

            // push transformed daily break period to the array with all date periods
            arr.push({
                startPeriod: start,
                endPeriod: end
            })
        }

    }

    // check if we have time-off periods
    if (data?.time_off?.length > 0) {
        data.time_off.map(item => {
            const start = moment(item.start).format('yyyy-MM-DDTHH:mm:ss');
            const end = moment(item.end).format('yyyy-MM-DDTHH:mm:ss');

            const startDay = moment(item.start).format('yyyy-MM-DD')
            const endDay = moment(item.end).format('yyyy-MM-DD')

            // get duration of time-off period
            const duration = moment.duration(moment(end).diff(moment(start)));
            const days = Math.round(duration.asDays());

            // if the time-off period is more than 24h we separate it into periods for all days between start and end dates
            if (days >= 1) {
                for (let i = 0; i <= days; i++) {
                    if (i === 0) {
                        arr.push({
                            startPeriod: start,
                            endPeriod: moment(start).endOf("day").format('yyyy-MM-DDTHH:mm:ss')
                        })
                    } else if (i === days) {
                        arr.push({
                            startPeriod: moment(end).startOf("day").format('yyyy-MM-DDTHH:mm:ss'),
                            endPeriod: end
                        })
                    } else {
                        if (duration.asDays() - i < 1 && duration.asDays() - i > 0.5) {
                            arr.push({
                                startPeriod: moment(end).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
                                endPeriod: end
                            })
                        } else {
                            arr.push({
                                startPeriod: moment(start).add(i, 'days',).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
                                endPeriod: moment(start).add(i, 'days').endOf('day').format('yyyy-MM-DDTHH:mm:ss')
                            })
                        }
                    }
                }
            } else {
                // if time-off period less than 24h

                if (startDay !== endDay) {
                    // if period includes different days
                    arr.push({
                        startPeriod: start,
                        endPeriod: moment(start).endOf("day").format('yyyy-MM-DDTHH:mm:ss')
                    })
                    arr.push({
                        startPeriod: moment(end).startOf("day").format('yyyy-MM-DDTHH:mm:ss'),
                        endPeriod: end
                    })
                } else {
                    // if period includes only one day
                    arr.push({
                        startPeriod: start,
                        endPeriod: end
                    })
                }
            }
        })
    }
    const finalArr = arr.sort((a, b) => compareDates(a.startPeriod, b.startPeriod)).filter(item => item.startPeriod !== item.endPeriod);
    // need to remove duplicated values from the array
    const uniqueArray = finalArr.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === finalArr.findIndex(obj => {
            return JSON.stringify(obj) === _value;
        });
    });

    return uniqueArray;

}

export default absenceScheduleTransform;
