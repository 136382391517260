import {
	GET_SCHOOL_INFO,
	GET_SCHOOL_INFO_SUCCESS,
	GET_SCHOOL_INFO_ERROR,
	REGISTER_FOR_PROGRAM,
	REGISTER_FOR_PROGRAM_SUCCESS,
	REGISTER_FOR_PROGRAM_ERROR,
} from "../constants";

const initialState = {
	schoolInfo: {},
	getSchoolInfoInProgress: false,
	getSchoolInfoError: null,
	registerForProgramInProgress: false,
	registerForProgramError: null,
	registeredProgramData: null,
};

export default function registerForProgramReducer(
	state = initialState,
	action
) {
	const { type, payload } = action;

	switch (type) {
		case GET_SCHOOL_INFO:
			return {
				...state,
				getSchoolInfoInProgress: true,
			};
		case GET_SCHOOL_INFO_SUCCESS:
			return {
				...state,
				schoolInfo: payload,
				getSchoolInfoInProgress: false,
				getSchoolInfoError: null,
			};
		case GET_SCHOOL_INFO_ERROR:
			return {
				...state,
				schoolInfo: null,
				getSchoolInfoInProgress: false,
				getSchoolInfoError: payload,
			};
		case REGISTER_FOR_PROGRAM:
			return {
				...state,
				registerForProgramInProgress: true,
				registerForProgramError: null,
			};
		case REGISTER_FOR_PROGRAM_SUCCESS:
			return {
				...state,
				registerForProgramInProgress: false,
				registerForProgramError: null,
				registeredProgramData: payload,
			};
		case REGISTER_FOR_PROGRAM_ERROR:
			return {
				...state,
				registerForProgramInProgress: false,
				registerForProgramError: payload,
			};
		default:
			return state;
	}
}
