import React, {useState} from 'react';
import "./AddActionForm.scss";
import Button from "components/button/Button";
import CategoryDescriptionEditor
    from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/CategoryDescriptionEditor/CategoryDescriptionEditor";
import {useSelector} from "react-redux";

const AddActionForm = ({addAction, hideAddActionForm}) => {
    const userRole = useSelector(state => state.currentUserInfo.currentUserInfo.role);
    const {staticData} = useSelector(state => state.auth);

    return (
        <CategoryDescriptionEditor selectLabel={'Topic'}
                                   isTodo={true}
                                   selectPlaceholder={'-'}
                                   textLabel={'Action item description'}
                                   hideCategoryEditor={hideAddActionForm}
                                   handleSubmit={addAction}
                                   options={userRole === 'coach' ? staticData.activityCategories : null}

        />
    );
};

export default AddActionForm;