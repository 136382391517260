import React from 'react';
import Button from 'components/button/Button';
import EditIcon from 'components/icons/EditIcon';
import { useSelector } from 'react-redux';
import './../SessionCard.scss';

const Actions = ({ edit, cancel, decline, accept, complete }) => {
  const { acceptSessionByCoachProgress } = useSelector(state => state.sessionInvitation);
  const { completeSessionProgress } = useSelector(state => state.userPrograms);

  return (
    <>
      {
        edit && (
          <Button type="button"
                  onClick={edit}
                  className="session-card__edit-btn">
            <EditIcon className="icon" color="#929292"/>
            Edit
          </Button>
        )
      }
      {
        cancel && (
          <Button type="button"
                  onClick={cancel}
                  className="button session-card__cancel-btn">
            Cancel
          </Button>
        )
      }
      {
        decline && (
          <Button type="button"
                  onClick={decline}
                  className="button session-card__btn">
            Decline
          </Button>
        )
      }
      {
        accept && (
          <Button type="button"
                  loading={acceptSessionByCoachProgress}
                  onClick={accept}
                  className="button session-card__btn">
            Accept
          </Button>
        )
      }
      {
        complete && (
          <Button onClick={complete}
                  disabled={completeSessionProgress}
                  loading={completeSessionProgress}
                  className="button session-card__btn"
                  type="button">
            Complete session
          </Button>
        )
      }
    </>
  );
};

export default Actions;
