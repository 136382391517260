import React from 'react';
// for ILTB
// import {ReactComponent as LogoWhiteSVG} from "assets/vector/auth-page/logo.svg";
import {ReactComponent as LogoWhiteSVG} from "assets/vector/auth-page/logo-switch.svg";
import './Header.scss';

const Header = () => {
    return (
        <div className={`auth-header`}>
            <LogoWhiteSVG className="auth-header__logo"/>
        </div>
    );
};

export default Header;