import React from "react";
import { useMemo } from "react";
import "./QuestionnaireStepper.scss";

const QuestionnaireStepper = ({ activeStep, stepsLength }) => {
  function widthCalculation(currentStep, maxStep) {
    return (currentStep * 100) / maxStep;
  }

  const lineWidth = useMemo(
    () => widthCalculation(activeStep, stepsLength),
    [activeStep, stepsLength]
  );
  console.log("lineWidth", lineWidth);
  return (
    <div className='questionnaire-stepper__wrapper'>
      {activeStep !== undefined && stepsLength ? (
        <>
          <div className='questionnaire-stepper__graph'>
            <span className='line' style={{ width: `${lineWidth}%` }}></span>
          </div>
          <div className='questionnaire-stepper__count'>
            {Math.round(lineWidth)}%
          </div>
        </>
      ) : null}
    </div>
  );
};

export default QuestionnaireStepper;
