import { participants } from "../constants";

const initialState = {
	participantInfo: null,
	showParticipantInfo: false,
};

export default function participantInfoReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case participants.GET_INFO_ABOUT_PARTICIPANT_SUCCESS:
			return {
				...state,
				participantInfo: payload,
				showParticipantInfo: true,
			};

		case participants.CLEAR_ABOUT_PARTICIPANT_INFO:
			return {
				...state,
				participantInfo: null,
				showParticipantInfo: false,
			};

		default:
			return state;
	}
}
