import React, { useRef } from "react";
import "./SessionInvitation.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	isEditAllowed,
	isSessionExpired,
	isSessionEditor,
	isDeclineAllowed,
	isLateCancelAllowed,
	isCompleteSessionAllowed,
} from "helpers/userPrograms";

import {
	DECLINE_MODAL,
	SESSION_EVENT_MODAL,
} from "../../../../../../../redux/constants/modal";
import {
	acceptSessionByCoach,
	acceptSessionInvitation,
	completeSession,
	declineSessionInvitation,
	fetchSessionInfo,
	lateCancelSessionInvitation,
	openModal,
	showNotification,
} from "../../../../../../../redux/actions";
import { getUserInvitationStatus } from "components/pages/Programmes/helpers/helpers";
import { PulseLoader } from "react-spinners";
import { CSSTransition } from "react-transition-group";
import SessionCard from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/SessionInvitation/SessionCard/SessionCard";
import Actions from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/SessionInvitation/SessionCard/Actions/Actions";

const SessionInvitation = ({ session, duration, ...otherProps }) => {
	const dispatch = useDispatch();
	const nodeRef = useRef(null);
	const { programId, activityId } = otherProps;
	const { organizer, id: sessionId, name: sessionName } = session;
	const {
		id: userId,
		role: userRole,
		timezone,
	} = useSelector((state) => state.currentUserInfo.currentUserInfo);
	const authToken = useSelector((state) => state.auth.authToken);
	const isSessionOrganizer = organizer?.id === userId;
	const isUserSessionEditor = isSessionEditor(session, userRole, userId);
	const hoursBeforeAction = 48;
	const minutesBeforeCompleteSession = 10;
	const isAcceptByCoachAllowed =
		userRole === "coach" && session.session_type === "individual";
	const isSessionEditAllowed = isEditAllowed(
		session,
		userRole,
		isSessionOrganizer,
		isUserSessionEditor,
		hoursBeforeAction,
		timezone,
		duration
	);
	const isSessionDeclineAllowed = isDeclineAllowed(
		session,
		userRole,
		hoursBeforeAction,
		timezone,
		duration,
		userId
	);
	const isSessionLateCancelAllowed = isLateCancelAllowed(
		session,
		userRole,
		hoursBeforeAction,
		timezone
	);
	const isSessionCompleteAllowed = isCompleteSessionAllowed(
		session,
		{
			userRole,
			userId,
		},
		minutesBeforeCompleteSession,
		timezone,
		duration
	);
	const { fetchSessionInfoProgress } = useSelector(
		(state) => state.sessionInvitation
	);

	const handleAcceptInvitation = () =>
		dispatch(
			fetchSessionInfo(programId, activityId, () => {
				dispatch(acceptSessionInvitation(sessionId, authToken));
			})
		);

	const handleDeclineInvitation = () =>
		dispatch(
			fetchSessionInfo(programId, activityId, (hasSessionChanged) => {
				if (hasSessionChanged) {
					dispatch(
						showNotification(
							{
								title: "Session decline",
								text: "It seems that the session has been changed by someone recently. Please try to decline again",
							},
							6000
						)
					);
				} else {
					dispatch(
						openModal(DECLINE_MODAL, {
							confirmAction: () =>
								dispatch(declineSessionInvitation(sessionId)),
							actionSelector: [
								"sessionInvitation",
								"declineSessionInvitationProgress",
								"declineSessionInvitationSuccess",
								"declineSessionInvitationError",
							],
							title: "Are you sure you want to decline scheduled session?",
							description: "There is a chance your sit will be lost",
						})
					);
				}
			})
		);

	const handleEditInvitation = () =>
		dispatch(
			fetchSessionInfo(programId, activityId, (hasSessionChanged) => {
				if (hasSessionChanged) {
					dispatch(
						showNotification(
							{
								title: "Session edit",
								text: "It seems that the session has been changed by someone recently. Please try to edit again",
							},
							6000
						)
					);
				} else {
					dispatch(
						openModal(SESSION_EVENT_MODAL, {
							editedSession: session,
							sessionId,
							duration,
							sessionName,
							...otherProps,
						})
					);
				}
			})
		);

	const handleCompleteSession = () =>
		dispatch(
			fetchSessionInfo(programId, activityId, () => {
				dispatch(completeSession(sessionId));
			})
		);

	const handleAcceptInvitationByCoach = () =>
		dispatch(
			fetchSessionInfo(programId, activityId, () => {
				dispatch(acceptSessionByCoach(session.id));
			})
		);

	const handleLateCancel = () =>
		dispatch(
			fetchSessionInfo(programId, activityId, (hasSessionChanged) => {
				if (hasSessionChanged) {
					dispatch(
						showNotification(
							{
								title: "Session cancel",
								text: "It seems that the session has been changed by someone recently. Please try to cancel again",
							},
							6000
						)
					);
				} else {
					dispatch(
						openModal(DECLINE_MODAL, {
							confirmAction: () =>
								dispatch(lateCancelSessionInvitation(sessionId)),
							actionSelector: [
								"sessionInvitation",
								"lateCancelInvitationProgress",
								"lateCancelInvitationSuccess",
								"lateCancelInvitationError",
							],
							title: "Are you sure you want to cancel scheduled session?",
							description: "There is a chance your sit will be lost",
						})
					);
				}
			})
		);

	const renderInvitation = () => {
		const invitationStatus = getUserInvitationStatus(session, userRole, userId);
		const isExpired = isSessionExpired(session, duration, timezone);

		if (isExpired && invitationStatus !== "late_canceled") {
			return (
				<SessionCard
					session={session}
					status={invitationStatus === "completed" ? "completed" : "expired"}
					duration={duration}
					actions={
						<Actions
							edit={isSessionEditAllowed ? handleEditInvitation : null}
							complete={
								session?.status !== "completed" && isSessionCompleteAllowed
									? handleCompleteSession
									: null
							}
							decline={isSessionDeclineAllowed ? handleDeclineInvitation : null}
							cancel={isSessionLateCancelAllowed ? handleLateCancel : null}
						/>
					}
				/>
			);
		}

		switch (invitationStatus) {
			case "confirmed":
				return (
					<SessionCard
						session={session}
						status={invitationStatus}
						duration={duration}
						actions={
							<Actions
								edit={isSessionEditAllowed ? handleEditInvitation : null}
								complete={
									session?.status !== "completed" && isSessionCompleteAllowed
										? handleCompleteSession
										: null
								}
								decline={
									isSessionDeclineAllowed ? handleDeclineInvitation : null
								}
								cancel={isSessionLateCancelAllowed ? handleLateCancel : null}
							/>
						}
					/>
				);
			case "accepted":
				if (isUserSessionEditor) {
					return (
						<SessionCard
							session={session}
							status={invitationStatus}
							duration={duration}
							actions={
								<Actions
									edit={isSessionEditAllowed ? handleEditInvitation : null}
									cancel={isSessionLateCancelAllowed ? handleLateCancel : null}
									decline={
										isSessionDeclineAllowed ? handleDeclineInvitation : null
									}
									accept={
										isAcceptByCoachAllowed
											? handleAcceptInvitationByCoach
											: null
									}
								/>
							}
						/>
					);
				} else {
					return (
						<SessionCard
							session={session}
							status={invitationStatus}
							duration={duration}
							actions={
								<Actions
									edit={isSessionEditAllowed ? handleEditInvitation : null}
									complete={
										session?.status !== "completed" && isSessionCompleteAllowed
											? handleCompleteSession
											: null
									}
									decline={
										isSessionDeclineAllowed ? handleDeclineInvitation : null
									}
									cancel={isSessionLateCancelAllowed ? handleLateCancel : null}
								/>
							}
						/>
					);
				}
			case "declined":
				return (
					<SessionCard
						session={session}
						status={invitationStatus}
						duration={duration}
					/>
				);
			case "canceled":
			case "late_canceled":
				return (
					<SessionCard
						session={session}
						status={invitationStatus}
						duration={duration}
					/>
				);
			case "noCoach":
				return (
					<SessionCard
						session={session}
						status={invitationStatus}
						duration={duration}
						actions={
							<Actions
								edit={isSessionEditAllowed ? handleEditInvitation : null}
							/>
						}
					/>
				);
			default:
				return (
					<SessionCard
						session={session}
						status={invitationStatus}
						duration={duration}
						actions={
							<Actions
								edit={isSessionEditAllowed ? handleEditInvitation : null}
								decline={
									isSessionDeclineAllowed ? handleDeclineInvitation : null
								}
								accept={handleAcceptInvitation}
								cancel={isSessionLateCancelAllowed ? handleLateCancel : null}
							/>
						}
					/>
				);
		}
	};

	const spinnerProps = {
		color: `var(--loader-color)`,
		loading: true,
		size: 16,
		margin: 4,
	};

	return (
		<div className="session-invitation__wrap">
			<CSSTransition
				in={fetchSessionInfoProgress}
				timeout={800}
				unmountOnExit
				classNames="modal__overlay-"
				nodeRef={nodeRef}
			>
				<div ref={nodeRef} className="session-invitation__overlay">
					<PulseLoader {...spinnerProps} />
				</div>
			</CSSTransition>
			{renderInvitation()}
		</div>
	);
};

export default SessionInvitation;
