import React from 'react';
import '../Event.scss';
import { ReactComponent as CalendarIcon } from 'assets/vector/calendar-outline1.svg';

const EventDate = ({ text }) => {
  return (
    <p className="event-info">
      <CalendarIcon className="event-info__icon"/>
      {text}
    </p>
  );
};

export default EventDate;
