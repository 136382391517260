import { fork, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { calendarFilters } from "../constants";
import {
	getFiltersOfCalendarSuccess,
	getFiltersOfCalendarEmpty,
	getFiltersOfCalendarError,
} from "../actions/calendarFilters";

import { GET_ALL_FILTERS_OF_CALENDAR } from "constants/api";
import { showNotification } from "../actions";
import { getNotificationMessage } from "../../helpers/notifications";
import { generalApiErrorHandler } from "../sagas/helpers/generalApiErrorHandler";
import { getToken } from "../sagas/helpers/helpers";

function* watchGetFiltersOfCalendar() {
	yield takeLatest(
		calendarFilters.GET_FILTERS_OF_CALENDAR,
		handleGetFiltersOfCalendar
	);
}

function* handleGetFiltersOfCalendar({ payload }) {
	try {
		const token = yield select(getToken);

		const currentUserData = yield axios({
			method: "get",
			url: GET_ALL_FILTERS_OF_CALENDAR(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (currentUserData.length > 0) {
			yield put(getFiltersOfCalendarSuccess(currentUserData));
		} else {
			yield put(getFiltersOfCalendarEmpty(currentUserData));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getFiltersOfCalendarError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetNewFiltersOfCalendarItemsOnScroll() {
	yield takeLatest(
		calendarFilters.GET_NEW_FILTERS_OF_CALENDAR_ON_SCROLL,
		handleGetNewFiltersOfCalendarItemsOnScroll
	);
}

function* handleGetNewFiltersOfCalendarItemsOnScroll({ payload }) {
	try {
		const token = yield select(getToken);

		const getCalendarDataByScroll = yield axios({
			method: "get",
			url: GET_ALL_FILTERS_OF_CALENDAR(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (getCalendarDataByScroll.length > 0) {
			yield put(getFiltersOfCalendarSuccess(getCalendarDataByScroll));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getFiltersOfCalendarError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchClearFiltersOfCalendar() {
	yield takeLatest(
		calendarFilters.CLEAR_FILTERS_OF_CALENDAR_FILTERS,
		handleClearFiltersOfCalendar
	);
}

function* handleClearFiltersOfCalendar() {
	try {
		const token = yield select(getToken);

		const getCalendarFiltersAfterReset = yield axios({
			method: "get",
			url: GET_ALL_FILTERS_OF_CALENDAR(),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		if (getCalendarFiltersAfterReset.length > 0) {
			yield put(getFiltersOfCalendarSuccess(getCalendarFiltersAfterReset));
		} else {
			yield put(getFiltersOfCalendarEmpty(null));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getFiltersOfCalendarError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* calendarFiltersSaga() {
	yield fork(watchGetFiltersOfCalendar);
	yield fork(watchGetNewFiltersOfCalendarItemsOnScroll);
	yield fork(watchClearFiltersOfCalendar);
}
