import React from "react";
import "./SiteNotification.scss";
import { useDispatch } from "react-redux";
import { ReactComponent as ActionsRemoveNotification } from "assets/vector/notification/actions-remove-notification.svg";
import { ReactComponent as SelectCloseSvg } from "assets/vector/select-close.svg";
import { claerAllNotifications } from "../../../redux/actions";

export default function PopupClearAll({ setOpenPopupClearAll }) {
	const dispatch = useDispatch();

	const confirmDeleteAll = () => {
		dispatch(claerAllNotifications());
		setOpenPopupClearAll(false);
	};

	const cancelDeleteAll = () => {
		setOpenPopupClearAll(false);
	};

	const closePopup = () => {
		setOpenPopupClearAll(false);
	};
	return (
		<div className="popup-clear-all-notification">
			<div className="clear-all-notification">
				<div className="image">
					<ActionsRemoveNotification />
				</div>
				<h3 className="text-alarm">
					Are you sure you want to delete all notifications?
				</h3>
				<div className="actions">
					<div
						className="confirm"
						onClick={(e) => {
							e.stopPropagation();
							confirmDeleteAll();
						}}
					>
						Confirm
					</div>
					<div
						className="cancel"
						onClick={(e) => {
							e.stopPropagation();
							cancelDeleteAll();
						}}
					>
						Cancel
					</div>
				</div>

				<div className="close-popup">
					<SelectCloseSvg
						onClick={(e) => {
							e.stopPropagation();
							closePopup();
						}}
					/>
				</div>
			</div>
		</div>
	);
}
