import { transformedTimezoneForMoment } from 'helpers/transformedTimezoneForMoment';
import moment from "moment-timezone";

export const calculateTimeWithZone = (time, user = {}, me = {}) => {
    const { timezone: userTimeZone, id: userId } = user;
    const { timezone: myTimeZone, id: myId = null } = me;

    const isMe = myId === userId;
    const userZoneOffset = moment.tz(transformedTimezoneForMoment(userTimeZone)).format('Z');
    const myZoneOffset = moment.tz(transformedTimezoneForMoment(myTimeZone)).format('Z');
    const myTime = moment.parseZone(time).format('YYYY-MM-DD HH:mm');

    // used to check the daylight saving time (DST) for current and session time to display the correct time for both users
    const currentDayIsDTS = moment().isDST();
    const sessionTimeIsDTS = moment(time).isDST();

    if (isMe) {
        return {
            userHours: time ? moment(time).format('HH:mm') : '',
            userUTC: myZoneOffset
        }
    }

    if (currentDayIsDTS) {
        return {
            userHours: time ? moment.tz(`${myTime}${myZoneOffset}`, transformedTimezoneForMoment(userTimeZone))
                .add(sessionTimeIsDTS ? 0 : 1, 'h').format('HH:mm') : '',
            userUTC: userZoneOffset
        }
    }

    if (sessionTimeIsDTS) {
        return {
            userHours: time ? moment.tz(`${myTime}${myZoneOffset}`, transformedTimezoneForMoment(userTimeZone))
                .add(currentDayIsDTS ? 0 : -1, 'h').format('HH:mm') : '',
            userUTC: userZoneOffset
        }
    }

    return {
        userHours: time ? moment.tz(`${myTime}${myZoneOffset}`, transformedTimezoneForMoment(userTimeZone)).format('HH:mm') : '',
        userUTC: userZoneOffset
    }
};
