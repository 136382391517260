import { personalInfo } from "../constants";

export function getInfoAboutCurrentUser(requestId, callback) {
	return {
		type: personalInfo.GET_INFO_ABOUT_CURRENT_USER,
		payload: requestId,
		callback,
	};
}

export function getThrottledInfoAboutCurrentUser() {
	return {
		type: personalInfo.GET_THROTTLED_INFO_ABOUT_CURRENT_USER,
	};
}

export function getInfoAboutCurrentUserError() {
	return {
		type: personalInfo.GET_INFO_ABOUT_CURRENT_USER_ERROR,
	};
}

export function getInfoAboutCurrentUserSuccess(userInfo) {
	return {
		type: personalInfo.GET_INFO_ABOUT_CURRENT_USER_SUCCESS,
		payload: userInfo,
	};
}

export function clearDataAboutCurrentUser() {
	return {
		type: personalInfo.CLEAR_DATA_ABOUT_CURRENT_USER,
	};
}

export function updateGoogleAuthStatus(data) {
	return {
		type: personalInfo.UPDATE_GOOGLE_AUTH_STATUS,
		payload: data,
	};
}
