import React, { useState } from 'react';
import './Ratings.scss';
import UserTable from "components/pages/Dashboard/SchoolAdmin/Charts/Ratings/UserTable/UserTable";
import Satisfaction from "components/pages/Dashboard/SchoolAdmin/Charts/Ratings/Satisfaction/Satisfaction";

const Ratings = ({ rating = {} }) => {
    const [isFirstTab, setIsFirstTab] = useState(true);

    return (
        <section className='ratings'>
            <div className="ratings__head">
                <h4 className='ratings__title'>Participants Ratings</h4>
                <div className="ratings__tabs">
                    <button className={`ratings__tab-btn ${isFirstTab ? 'active' : ''}`}
                        onClick={() => setIsFirstTab(true)}>Satisfaction by session
                    </button>
                    <button className={`ratings__tab-btn ${isFirstTab ? '' : 'active'}`}
                        onClick={() => setIsFirstTab(false)}>Disengaged Participants
                    </button>
                </div>
            </div>
            {
                isFirstTab ?
                    <Satisfaction satisfaction={rating.satisfaction_by_session?.satisfaction_info} />
                    :
                    <UserTable users={rating.disengaged_participants} />
            }
        </section>
    );
};

export default Ratings;