import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import "./EventSessionForm.scss";
import Button from "components/button/Button";
import EventSessionInput from "components/forms/EventSession/EventSessionInput";
// import CustomEventSessionFormSelect from 'components/CustomFormSelect/CustomEventSessionFormSelect';
import InvitedParticipants from "components/modal/EventSessionModal/InvitedParticipants/InvitedParticipants";
import DownArrowIcon from "components/icons/DownArrowIcon";
import EventSessionChooseTime from "components/forms/EventSession/EventSessionChooseTime/EventSessionChooseTime";
// import {customEventSessionSelectStyles as selectStyles} from "helpers/customSelectStyles";
import { useDispatch, useSelector } from "react-redux";
import {
	clearSessionInfo,
	createSession,
	editSession,
} from "../../../redux/actions/createSession";
import Notification from "components/auth/notification/Notification";
import { transformDatePickerDate } from "helpers/userPrograms";
import {
	isPrevDateChosen,
	transformDateAndTime,
	transformSelectOptions,
} from "helpers/createSession";
import { getUsersIds } from "components/modal/helpers/helpers";
import { sessionStatuses } from "constants/static";
import CustomEventSessionFormSelect from "components/CustomFormSelect/CustomEventSessionFormSelect";
import { customSelectStyles as selectStyles } from "helpers/customSelectStyles";

const EventSessionForm = ({
	closeModal,
	showDatePicker,
	selectedDate,
	time,
	setTime,
	groupName,
	setGroupName,
	sessionLink,
	setSessionLink,
	sessionDetails,
	setSessionDetails,
	sessionType,
	setSessionStatus,
	sessionStatus,
	coachId,
}) => {
	const dispatch = useDispatch();
	const {
		timezone,
		role: userRole,
		id: userId,
	} = useSelector((state) => state.currentUserInfo.currentUserInfo);
	const { createSessionProgress, createSessionError, createSessionSuccess } =
		useSelector((state) => state.createSession);
	const currentProgram = useSelector(
		(state) => state.userPrograms.singleProgram
	);
	const additionalRequestData =
		useSelector((state) => state.modal.modalData) || {};
	const { topicId, activityId, duration, editedSession, sessionId } =
		additionalRequestData;
	const [sessionInvitedUser, setSessionInvitedUser] = useState([]);
	const [isTimeBooked, setIsTimeBooked] = useState(false);
	const currentProgramData = useSelector(
		(state) => state.userPrograms.programs
	).find((program) => program.id === currentProgram.id);
	const transformedSelectedDate = transformDateAndTime(
		selectedDate,
		time,
		timezone
	);
	const isPrevDate = isPrevDateChosen(transformedSelectedDate, timezone);
	const isCoach = userRole === "coach";
	const isParticipant = userRole === "participant";

	const validationSchema = {
		name: Yup.string().required("Required"),
		type: Yup.string().required("Required"),
		date: Yup.string().nullable().required("Choose date"),
		time: Yup.string().nullable().required("Choose time"),
		link: Yup.string().url("Invalid url"),
		details: Yup.string().trim(),
	};

	if (isCoach && isPrevDate) {
		validationSchema["status"] = Yup.string().required("Required");
	}

	const closeEventSessionModal = () => {
		closeModal();
		dispatch(clearSessionInfo());
	};

	const handleSessionStatus = (value) => {
		setSessionStatus(value);
	};

	useEffect(() => {
		if (createSessionSuccess) {
			closeEventSessionModal();
		}
	}, [createSessionSuccess]);

	useEffect(() => {
		if (isParticipant) {
			setSessionInvitedUser([
				{
					id: currentProgram.coach_id,
					name: currentProgramData.coach_name,
					avatar: currentProgramData.coach_avatar,
					timezone: currentProgramData.timezone,
				},
			]);
		}

		if (editedSession && isParticipant) {
			setSessionInvitedUser([
				...editedSession.coaches,
				...editedSession.participants?.filter(
					(participant) => participant.id !== userId
				),
			]);
		}

		if (sessionType === "individual" && isCoach) {
			setSessionInvitedUser([
				{
					id: currentProgramData.participant.id,
					name: currentProgramData.participant.name,
					avatar: currentProgramData.participant.avatar,
					timezone: currentProgramData.participant.timezone,
				},
			]);
		}

		if (editedSession && isCoach) {
			setSessionInvitedUser([
				...editedSession.participants,
				...editedSession.coaches?.filter(
					(coach) => coach.id !== currentProgram.coach_id
				),
			]);
		}
	}, [sessionType, userRole]);

	const getAdditionalFormData = (sessionType) => {
		let additionalData = {};

		if (sessionType === "individual") {
			additionalData = {
				program_id: currentProgram.school_program_id,
				participant_program_id: currentProgram.id,
				participant_program_topic_id: topicId,
				participant_activity_id: activityId,
				coaches: [currentProgram.coach_id],
				participants: [currentProgram.participant_id],
			};
		} else if (sessionType === "group") {
			additionalData = {
				program_id: currentProgram.school_program_id,
				program_topic_id: editedSession?.school_program?.topic_id,
				activity_id: editedSession?.school_program?.activity_id,
				coaches: getUsersIds(editedSession.coaches),
				participants: getUsersIds(editedSession.participants),
			};
		}

		if (sessionStatus?.value) additionalData["status"] = sessionStatus.value;

		return additionalData;
	};

	const handleGroupName = (e) => setGroupName(e.target.value);
	const handleSessionLink = (e) => setSessionLink(e.target.value);
	const handleSessionDetails = (e) => setSessionDetails(e.target.value);

	return (
		<Formik
			initialValues={{
				name: groupName,
				type: sessionType,
				link: sessionLink,
				details: sessionDetails,
				date: selectedDate,
				time: time,
				status: sessionStatus?.value,
				start: transformedSelectedDate,
			}}
			enableReinitialize={true}
			validationSchema={Yup.object(validationSchema)}
			onSubmit={(values) => {
				const additionalData = getAdditionalFormData(values.type);

				if (editedSession) {
					dispatch(
						editSession({
							sessionId,
							name: values.name,
							type: values.type,
							link: values.link,
							details: values.details,
							start: values.start,
							...additionalData,
						})
					);
				} else {
					dispatch(
						createSession({
							name: values.name,
							type: values.type,
							link: values.link,
							details: values.details,
							start: values.start,
							...additionalData,
						})
					);
				}
			}}
		>
			<Form className="event-form" autoComplete="off" noValidate={true}>
				<Field
					name="name"
					type="text"
					label="Session name"
					value={groupName}
					disabled={isParticipant}
					onChange={handleGroupName}
					placeholder="Enter session name..."
					className="event-form__input"
					component={EventSessionInput}
				/>
				<Field
					name="type"
					label="Type"
					value={`${
						sessionType === "individual" ? "Individual" : "Group"
					} session`}
					component={EventSessionInput}
					disabled={true}
					className="event-form__input"
				/>
				<InvitedParticipants
					participants={sessionInvitedUser}
					sessionTime={{ time, timezone, date: selectedDate }}
				/>
				<div className="event-form__input-group">
					<div className="choose-time">
						<span className="event-form__label">Choose date</span>
						<button
							type="button"
							className="choose-time__open-picker"
							onClick={showDatePicker}
						>
							<span
								className={`choose-time__placeholder ${
									selectedDate ? "choose-time__placeholder--active" : null
								}`}
							>
								{selectedDate
									? transformDatePickerDate(selectedDate)
									: `Choose date`}
							</span>
							<DownArrowIcon className="choose-time__icon" />
						</button>
					</div>
					<ErrorMessage
						name="date"
						render={(error) => <div className="event-form__error">{error}</div>}
					/>
					<EventSessionChooseTime
						time={time}
						date={selectedDate}
						setTime={setTime}
						sessionId={sessionId}
						isTimeBooked={isTimeBooked}
						setIsTimeBooked={setIsTimeBooked}
						duration={duration}
						coachId={coachId}
					/>
					<ErrorMessage
						name="time"
						render={(error) => <div className="event-form__error">{error}</div>}
					/>
				</div>
				{isCoach && isPrevDate && (
					<Field
						name="status"
						label="Status"
						options={transformSelectOptions(sessionStatuses)}
						value={sessionStatus}
						handleChange={handleSessionStatus}
						noOptionsMessage={`There is no available status.`}
						placeholder={"Choose status..."}
						component={CustomEventSessionFormSelect}
						className="sign-up__select"
						styles={selectStyles}
					/>
				)}
				<Field
					name="link"
					type="url"
					label="Link"
					value={sessionLink}
					labelTip="Optional"
					onChange={handleSessionLink}
					placeholder="Add zoom link..."
					className="event-form__input"
					component={EventSessionInput}
				/>
				<Field
					name="details"
					type="text"
					label="Session details"
					value={sessionDetails}
					labelTip="Optional"
					onChange={handleSessionDetails}
					placeholder="Add session details..."
					className="event-form__input"
					component={EventSessionInput}
				/>
				<Notification show={createSessionError}>
					{createSessionError}
				</Notification>
				<div className="event-form__actions">
					{/* for ILTB */}
					{/* <Button type="submit"
                            className="button button--orange event-form__submit"
                            loading={createSessionProgress}>
                        Save
                    </Button> */}
					<Button
						type="submit"
						spinner={{ color: "#4285f4" }}
						disabled={isTimeBooked}
						className="button button--blue event-form__submit"
						loading={createSessionProgress}
					>
						Save
					</Button>
					<Button
						type="button"
						className="button button--orange-invert event-form__cancel"
						onClick={closeEventSessionModal}
						loading={false}
					>
						Cancel
					</Button>
				</div>
			</Form>
		</Formik>
	);
};

export default EventSessionForm;
