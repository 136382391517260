import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./programmeCard.scss";
import {
	sendCsvFile,
	sendCsvFileError,
	sendCsvFileSuccess,
} from "../../../../redux/actions";
import { ReactComponent as ModalCloseSvg } from "assets/vector/close.svg";
import { ReactComponent as ModalUploadScv } from "assets/vector/programmes/modal-upload-scv.svg";
import { ReactComponent as ModalSuccessScv } from "assets/vector/programmes/modal-upload-success.svg";
import { ReactComponent as LoaderIcon } from "assets/vector/programmes/drop-scv.svg";
import { ReactComponent as ErrorItem } from "assets/vector/programmes/error-item.svg";
import { ReactComponent as DocumentUpload } from "assets/vector/programmes/document-upload.svg";
import { ReactComponent as ClearCsv } from "assets/vector/programmes/clear-csv.svg";
import exampleCSV from "assets/files/example.csv";
import { Default } from "react-spinners-css";

const ModalUploadCSV = ({ programIdForModal, setStatusOpenModalUploadCSV }) => {
	const dispatch = useDispatch();
	const uploadFileCsvErrors = useSelector(
		(state) => state.uploadCsvProgrammes.errors
	);

	const checkAfterLoadinguploadScv = useSelector(
		(state) => state.uploadCsvProgrammes.successed
	);
	const { loading } = useSelector((state) => state.uploadCsvProgrammes);

	const CSVFileInput = useRef(null);

	const [successSendCSVFile, setSuccessSendCSVFile] = useState(false);
	const [errorsSendCSVFile, setErrorsSendCSVFile] = useState(null);
	const [errorsImportToServer, setErrorsImportToServer] = useState(null);
	const [infoAboutCSV, setInfoAboutCSV] = useState(null);
	const [inputValueCSV, setInputValueCSV] = useState("");

	useEffect(() => {
		if (uploadFileCsvErrors) {
			setErrorsSendCSVFile(uploadFileCsvErrors);
		}
	}, [uploadFileCsvErrors]);

	const CloseModalUploadCSV = () => {
		setStatusOpenModalUploadCSV(false);
		ClearCSVInput();
	};

	const ClearCSVInput = () => {
		setErrorsSendCSVFile(null);
		setInputValueCSV("");
		dispatch(sendCsvFileError(null));
		dispatch(sendCsvFileSuccess(null));
		setErrorsImportToServer(null);
		setSuccessSendCSVFile(false);
	};

	useEffect(() => {
		if (checkAfterLoadinguploadScv) {
			if (
				checkAfterLoadinguploadScv.successed &&
				checkAfterLoadinguploadScv.errors.length === 0
			) {
				setSuccessSendCSVFile(true);
			} else {
				setErrorsImportToServer(checkAfterLoadinguploadScv.errors);
			}
		}
	}, [checkAfterLoadinguploadScv]);

	const handleLoadCSVChange = (event, programIdForModal) => {
		const csvFileUploaded = event.target.files[0];
		setInfoAboutCSV(csvFileUploaded);

		if (csvFileUploaded) {
			dispatch(
				sendCsvFile({
					import_file: csvFileUploaded,
					programID: programIdForModal,
				})
			);
		}
	};

	return (
		<div className={`modal-upload-csv ${programIdForModal}`}>
			<div className={`modal-upload-csv-wrap`}>
				{!loading && (
					<span className={"modal-close"} onClick={() => CloseModalUploadCSV()}>
						<ModalCloseSvg />
					</span>
				)}
				{successSendCSVFile ? (
					<div className="success-uploads">
						<div className="icon-success">
							<ModalSuccessScv />
						</div>
						<h2 className="modal-title">Participants list saved</h2>
						<h3 className="modal-sub-title">
							We send invitations to the participants automatically
						</h3>
						<div
							className="button-success-ok"
							onClick={() => CloseModalUploadCSV()}
						>
							ok
						</div>
					</div>
				) : (
					<>
						<div className="upload-csv">
							<div className="instruction-before-uploads">
								<div className="icon">
									<ModalUploadScv />
								</div>
								<div className="text-wrap">
									<h2 className="modal-title">Upload CSV</h2>
									<h3 className="modal-sub-title">
										Before you start uploading please check that you have all
										reqiured information (please check example{" "}
										<a
											className={`check-example`}
											target="_blank"
											rel="noreferrer"
											href={exampleCSV}
										>
											here
										</a>
										):
									</h3>
									<ul className={`modal-list-items`}>
										<li className={`item`}>First and last name</li>
										<li className={`item`}>Email address</li>
									</ul>
								</div>
							</div>
							{errorsSendCSVFile ||
							(errorsImportToServer && errorsImportToServer.length > 0) ? (
								<div className="sended-file">
									<div className="icon">
										<DocumentUpload />
									</div>
									<div className="info">
										<div className="file-name">
											{infoAboutCSV ? infoAboutCSV.name : ""}
										</div>
										<div className="file-size">
											{infoAboutCSV ? (infoAboutCSV.size / 1024).toFixed() : ""}
											KB
										</div>
									</div>
									<div
										className="clear-csv-file"
										onClick={() => ClearCSVInput()}
									>
										<ClearCsv />
									</div>
								</div>
							) : (
								<label
									className="drop-file-upload"
									htmlFor={`file-load-${programIdForModal}`}
								>
									<div className="icon">
										{loading ? (
											<Default color="#3D8B82" size={50} />
										) : (
											<LoaderIcon />
										)}
									</div>
									<div className="text-csv-upload">
										<input
											accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
											type="file"
											name={`csv-upload-file-${programIdForModal}`}
											id={`file-load-${programIdForModal}`}
											ref={CSVFileInput}
											value={inputValueCSV}
											onChange={(event) =>
												handleLoadCSVChange(event, programIdForModal)
											}
										/>

										<h3>
											Drop CSV file here to upload
											<br />
											(or click to choose from computer)
										</h3>
									</div>
								</label>
							)}
						</div>
						{errorsSendCSVFile && (
							<div className={`errors-messages`}>
								<ul className="errors-item-wrap">
									<li className={`error-item`}>
										<div className="icon">
											<ErrorItem />
										</div>
										<div className="text-error">{errorsSendCSVFile}</div>
									</li>
								</ul>
							</div>
						)}
						{errorsImportToServer && errorsImportToServer.length > 0 && (
							<div className={`errors-import-messages`}>
								<ul className="errors-item-wrap">
									<li className={`error-item-header`}>
										<div className="row">Row</div>
										<div className="attribute">Attribute</div>
										<div className="text-error">Issue description</div>
									</li>
									{errorsImportToServer.map((errorItem, index) => (
										<li key={index} className={`error-item`}>
											<div className="row">{errorItem.row}</div>
											<div className="attribute">{errorItem.attribute}</div>
											<div className={`text-error`}>
												{errorItem.errors.map((err) => (
													<span key={index}>{err}</span>
												))}
											</div>
										</li>
									))}
								</ul>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default ModalUploadCSV;
