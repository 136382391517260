import React from "react";
// import {useFormikContext} from "formik";
import Select, { components } from 'react-select';
import { customSelectStyles } from "helpers/customSelectStyles";

import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";

const typeSelectStyles = Object.assign({}, customSelectStyles);

const DropdownIndicator = (props) => {
    return (

        <components.DropdownIndicator {...props}>
            <ArrowSvg />
        </components.DropdownIndicator>

    );
};


const SettingsSelect = ({ field, options, error, placeholder, value, defaultValue, form: { setFieldValue }, toggled }) => {

    const onChangeSelect = (optionValue) => {
        setFieldValue(field.name, optionValue.tzCode);
    };

    return (
        <Select
            getOptionLabel={options => options.tzCode}
            getOptionValue={options => options.tzCode}
            components={{ DropdownIndicator }}
            name={field.name}
            options={options}
            placeholder={placeholder}
            classNamePrefix={`row-select`}
            styles={typeSelectStyles}
            isSearchable={false}
            onChange={(optionValue) => onChangeSelect(optionValue)}
            defaultValue={defaultValue}
        />
    )
}


export default SettingsSelect;