import React from 'react';
import './SearchChatUsers.scss';

import { ReactComponent as CloseSearchSVG } from "assets/vector/chat/close-search.svg";
import { ReactComponent as SearchSVG } from "assets/vector/chat/search.svg";


const SearchChatUsers = ({ users, setChatUsersList, isSearchOpen, setIsSearchOpen }) => {

    const onChangeHandler = (query) => {
        const searchedUsersList = users.filter(user => user.full_name.toLowerCase().includes(query.toLowerCase()));
        setChatUsersList(searchedUsersList)
    }

    const closeSearchHandler = () => {
        setChatUsersList(users);
        setIsSearchOpen(false);
    }

    return (
        <div className={`search-users__section ${isSearchOpen ? 'search-users__section--open' : ''}`}>
            {
                isSearchOpen
                    ?
                    <div className='input__wrapper'>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Key word for search...'
                            onChange={(e) => onChangeHandler(e.target.value)}
                        />
                        <CloseSearchSVG onClick={(e) => {
                            e.stopPropagation();
                            closeSearchHandler();
                        }} />
                    </div>
                    :
                    <div className='search-icon__wrapper'>
                        <SearchSVG onClick={(e) => {
                            e.stopPropagation();
                            setIsSearchOpen(true)
                        }} />
                    </div>
            }
        </div>
    )
}

export default SearchChatUsers
