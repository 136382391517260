import React, {useRef} from 'react';
import {handleLoadImgChange} from "components/pages/EditMyProfile/helpers/helpers";
import {ErrorMessage} from "formik";

const Avatar = ({avatar, errors, touched, setFieldValue, handleBlur, setAvatar}) => {
    const hiddenFileInput = useRef(null);
    const handleLoadImg = () => hiddenFileInput.current.click();

    return (
        <div className={`avatar ${errors.avatar && touched.avatar ? 'input-error' : ''}`}>
            <div className="image">
                <img src={avatar} alt="avatar"/>
            </div>
            <div className="upload-image-wrap">
                <div className="load-img" onClick={handleLoadImg}>Change photo</div>
                <input accept='image/*'
                       type="file"
                       ref={hiddenFileInput}
                       name="avatar"
                       onChange={event => handleLoadImgChange(event, setFieldValue, handleBlur, setAvatar)}
                       style={{display: "none"}}
                />
                <div className={`prompt`}>
                    Format .jpg, .png or .svg<br/>
                    File size up to 5MB
                </div>
                <ErrorMessage className={`profile-row__error`} component="div" name="avatar"/>
            </div>
        </div>
    );
};

export default Avatar;