import React, {useState} from 'react';
import {ReactComponent as EyeIcon} from "assets/vector/eye.svg";


const AuthFormField = ({type, field, form: {touched, errors}, className, ...props}) => {
    const [passwordVisibility, togglePasswordVisibility] = useState(false);
    const classNameWithError = 'sign-up__input--error';

    const showHidePassword = () => {
        togglePasswordVisibility(!passwordVisibility);
    }

    if (touched[field.name] && errors[field.name]) {
        className = `${className} ${classNameWithError}`;
    }

    if (type === 'password' || passwordVisibility) {
        return (
            <>
                <label htmlFor={field.name} className="sign-up__label">{props.label}</label>
                <div className="sign-up__input-wrap sign-up__input-wrap--password">
                <input
                    type={passwordVisibility ? 'text' : type}
                    className={className}
                    {...field}
                    {...props} />
                <EyeIcon className={passwordVisibility ? 'icon is-shown' : 'icon'} onClick={showHidePassword}/>
                {
                    touched[field.name] && errors[field.name] &&
                    <div className='default-auth__error'>{errors[field.name]}</div>
                }
                </div>
            </>
        );
    } else {
        return (
            <>
                <label htmlFor={field.name} className="sign-up__label">{props.label}</label>
                <input
                    type={type}
                    className={className}
                    {...field}
                    {...props} />
                {
                    touched[field.name] && errors[field.name] &&
                    <div className='default-auth__error'>{errors[field.name]}</div>
                }
            </>
        )
    }
};

export default AuthFormField;