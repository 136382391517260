import React from "react";
import "./Chatlist.scss";

// for ILTB
// import EmptyChat from "assets/vector/chat/empty-chat.svg";
import EmptyChat from "assets/vector/chat/empty-chat-switch.svg";

export default function EmptyChatList() {

    return (
        <div className="empty-page-chat">

            <img src={EmptyChat} alt=""/>

            <h3 className={`empty-page-title`}> You don’t have any chats yet...</h3>
        </div>
    )
}