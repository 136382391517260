import {
	GET_MATCHED_COACHES,
	GET_MATCHED_COACHES_SUCCESS,
	GET_MATCHED_COACHES_ERROR,
	GET_CAREER_MATCHED_COACHES,
} from "../constants/matchedCoaches";

export const getMatchedCoaches = (requestData) => {
	return {
		type: GET_MATCHED_COACHES,
		payload: requestData,
	};
};

export const getCareerMatchedCoaches = (requestData) => {
	return {
		type: GET_CAREER_MATCHED_COACHES,
		payload: requestData,
	};
};

export const getMatchedCoachesSuccess = (successData) => {
	return {
		type: GET_MATCHED_COACHES_SUCCESS,
		payload: successData,
	};
};

export const getMatchedCoachesError = (errorData) => {
	return {
		type: GET_MATCHED_COACHES_ERROR,
		payload: errorData,
	};
};
