import React, { useEffect } from "react";
import Spinner from "components/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { getUpcomingSessions } from "../../../../../redux/actions";
import EmptyData from "../EmptyData/EmptyData";
import UpcomingSessionItem from "./UpcomingSessionItem/UpcomingSessionItem";
import "./UpcomingSessions.scss";

const UpcomingSessions = () => {
	const dispatch = useDispatch();
	const { upcomingSessions, upcomingSessionsLoading } = useSelector(
		(state) => state.participantDashboard
	);

	useEffect(() => {
		dispatch(getUpcomingSessions());
	}, []);

	return (
		<div className="upcoming-sessions">
			<div className="upcoming-sessions__title">Upcoming sessions</div>
			<div
				className={`upcoming-sessions__list ${
					upcomingSessions?.length === 0 ? "empty-data" : ""
				}`}
			>
				{upcomingSessionsLoading ? (
					<Spinner size={50} />
				) : upcomingSessions?.length > 0 ? (
					upcomingSessions?.map((session) => (
						<UpcomingSessionItem key={session.id} session={session} />
					))
				) : (
					<EmptyData text="No sessions booked" button={true} />
				)}
			</div>
		</div>
	);
};

export default UpcomingSessions;
