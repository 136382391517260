import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import CustomReportsFilterSelect from "components/CustomFormSelect/CustomReportsFilterSelect";
import { customSelectStyles as selectStyles } from "helpers/customSelectStyles";
import { addSelectedFilterOptions } from "../../../../redux/actions";
import { removeEmptyValues } from "helpers/removeEmptyValues";

import "./ReportsFilter.scss";

const ReportsFilter = ({
	setOffsetReports,
	activeTab,
	isAllFiltersVisible,
	setAllFiltersVisible,
	filterOptions,
	setClearFilters,
	clearFilters,
	isMobile = false,
}) => {
	const dispatch = useDispatch();
	const { userRole } = useSelector((state) => state.auth);

	const { searchQuery } = useSelector((state) => state.reports);

	const _handleSubmit = (values) => {
		let filtersValues = {};

		for (const property in removeEmptyValues(values)) {
			if (property === "average_mark") {
				filtersValues[property] = values[property];
			} else {
				filtersValues[property] = [];
				filtersValues[property].push(values[property]);
			}
		}

		setOffsetReports(0);

		filtersValues.search = searchQuery ? searchQuery : "";

		dispatch(addSelectedFilterOptions(filtersValues));
	};

	return (
		<Formik
			initialValues={{
				schools: "",
				program_types: "",
				programs: "",
				topics: "",
				sessions: "",
				average_mark: "",
			}}
			onSubmit={(values) => _handleSubmit(values)}
		>
			{({ values }) => (
				<Form className="reports-filter">
					{userRole === "coach" && (
						<Field
							name="schools"
							options={filterOptions?.schools}
							placeholder="All institutions..."
							component={CustomReportsFilterSelect}
							styles={selectStyles}
							className="reports-filter__filter"
							clearFilters={clearFilters}
							setClearFilters={setClearFilters}
							isMobile={isMobile}
							activeTab={activeTab}
						/>
					)}
					<Field
						name="program_types"
						options={filterOptions?.program_types}
						placeholder="All programme types..."
						component={CustomReportsFilterSelect}
						styles={selectStyles}
						className="reports-filter__filter"
						clearFilters={clearFilters}
						setClearFilters={setClearFilters}
						isMobile={isMobile}
						activeTab={activeTab}
					/>
					<Field
						name="programs"
						options={filterOptions?.programs}
						placeholder="All programmes..."
						component={CustomReportsFilterSelect}
						styles={selectStyles}
						className="reports-filter__filter"
						clearFilters={clearFilters}
						setClearFilters={setClearFilters}
						isMobile={isMobile}
						activeTab={activeTab}
					/>
					{userRole !== "coach" && (
						<Field
							name="topics"
							options={filterOptions?.topics}
							placeholder="All topics..."
							component={CustomReportsFilterSelect}
							styles={selectStyles}
							className="reports-filter__filter"
							clearFilters={clearFilters}
							setClearFilters={setClearFilters}
							isMobile={isMobile}
							activeTab={activeTab}
						/>
					)}
					{isAllFiltersVisible && (
						<>
							{userRole === "coach" && (
								<Field
									name="topics"
									options={filterOptions?.topics}
									placeholder="All topics..."
									component={CustomReportsFilterSelect}
									styles={selectStyles}
									className="reports-filter__filter"
									clearFilters={clearFilters}
									setClearFilters={setClearFilters}
									isMobile={isMobile}
									activeTab={activeTab}
								/>
							)}
							<Field
								name="sessions"
								options={filterOptions?.sessions}
								placeholder="All sessions..."
								component={CustomReportsFilterSelect}
								styles={selectStyles}
								className="reports-filter__filter"
								clearFilters={clearFilters}
								setClearFilters={setClearFilters}
								isMobile={isMobile}
								activeTab={activeTab}
							/>
							<Field
								name="average_mark"
								options={filterOptions?.average_mark}
								placeholder="All average marks..."
								component={CustomReportsFilterSelect}
								styles={selectStyles}
								className="reports-filter__filter"
								clearFilters={clearFilters}
								setClearFilters={setClearFilters}
								isMobile={isMobile}
								activeTab={activeTab}
							/>
						</>
					)}
					{isMobile && (
						<button
							type="button"
							className="mobile__submit"
							onClick={() => {
								setAllFiltersVisible(false);
							}}
						>
							Apply filters
						</button>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default React.memo(ReportsFilter);
