import moment from 'moment';
import unavailableMonth from "assets/images/unavailableBG_month.svg";
import getDatesArray from './getDatesArray';

// used to display unavailable dates, which takes all day (duration > 23h) on month view of the Calendar
const calendarDayPropGetter = (date, absenceDates, role) => {

    let fullDayUnavailabilities = [];
    let partDayUnavailabilities = [];

    if (absenceDates?.length > 0) {
        absenceDates.map((item) => {
            const startDate = new Date(item.startPeriod);
            const endDate = new Date(item.endPeriod);

            if (startDate.getDate() === endDate.getDate()) {
                if (endDate.getHours() - startDate.getHours() < 23) {
                    partDayUnavailabilities.push(...getDatesArray(startDate, endDate))
                } else {
                    fullDayUnavailabilities.push(...getDatesArray(startDate, endDate))
                }
            } else {
                fullDayUnavailabilities.push(...getDatesArray(startDate, endDate))
            }

            return fullDayUnavailabilities;
        })

        const dayOff = fullDayUnavailabilities.find((element, index) => {
            if ((element === moment(date).format('YYYY-MM-DD'))) {
                return fullDayUnavailabilities[index]
            }
            return null;
        });

        if (dayOff && dayOff === moment(date).format('YYYY-MM-DD')) {
            // used to change custom bg-image to the calendar slot
            if (role === 'coach') {
                return {
                    className: `unavailableData`,
                    style: {
                        background: `url(${unavailableMonth})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: 'no-repeat',
                        zIndex: 4
                    }
                }
            }
        }
    }
}

export default calendarDayPropGetter;
