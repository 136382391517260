import { put, fork, takeLatest } from "redux-saga/effects";
import { CREATE_CATEGORY_DESCRIPTION } from "../../constants";
import { categoryDescriptionRequest } from "../../sagas/programCategoryDescriptions/handleCreateCategoryDescription";
import { showNotification } from "../../actions";
import { getNotificationMessage } from "../../../helpers/notifications";

function* watchCategoryDescription() {
	yield takeLatest(CREATE_CATEGORY_DESCRIPTION, handleCategoryDescription);
}

function* handleCategoryDescription(requestData) {
	try {
		yield categoryDescriptionRequest(requestData);
	} catch (error) {
		console.log(error.message);

		put(
			showNotification(
				{
					title: "Error",
					text: getNotificationMessage(error.message),
				},
				true
			)
		);
	}
}

export default function* categoryDescriptions() {
	yield fork(watchCategoryDescription);
}
