import {
	SHOW_MISSING_FEEDBACK_FORM_NOTIFICATION,
	HIDE_MISSING_FEEDBACK_FORM_NOTIFICATION,
} from "../constants";

export const showMissingFeedbackFormNotification = () => ({
	type: SHOW_MISSING_FEEDBACK_FORM_NOTIFICATION,
});

export const hideMissingFeedbackFormNotification = () => ({
	type: HIDE_MISSING_FEEDBACK_FORM_NOTIFICATION,
});
