import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import "./Programmes.scss";
import ParticipantsProgrammeCard from "components/pages/Programmes/programmeCard/participantsProgrammeCard";
import SingleProgramme from "components/pages/Programmes/SingleProgramme/SingleProgramme";
import EmptyPage from "../EmptyPage/EmptyPage"
import { programmPage } from "constants/emptyPages";


export default function ParticipantProgram() {
    const { path } = useRouteMatch();

    const userProgramsList = useSelector(state => state.userPrograms.programs);

    return (
        <Switch>
            <Route exact path={path}>
                {
                    userProgramsList.length > 0
                        ?
                        <div className="section-wrap">
                            {userProgramsList.map(item => <ParticipantsProgrammeCard key={item.id} {...item} />)}
                        </div>
                        :
                        <EmptyPage emptyPageValue={programmPage()} />
                }
            </Route>
            <Route exact path={`${path}/single-program/:programID`} component={SingleProgramme} />
        </Switch>
    );
};
