import React from 'react';
import './SessionDataRows.scss';
import uniqid from "uniqid";

const SessionDataRows = ({ sessions, userRole }) => {

    const getStatusIcon = (status) => {
        switch (status?.toLowerCase()) {
            case 'pending':
                return <span className='session-table__icon session-table__icon--booked'>Booked</span>;
            case 'completed':
                return <span className='session-table__icon session-table__icon--completed'>Completed</span>;
            default:
                return <span className='session-table__icon session-table__icon--canceled'>{status}</span>;
        }
    };

    const sessionData = sessions.map(session => {
        const {
            session_number,
            session_name,
            categories,
            status,
            created_at,
            completed_at,
            // canceled_at
        } = session;

        const statusIcon = getStatusIcon(status);

        return (
            <tr key={uniqid()} className='session-table__row'>
                <td className='session-table__col session__number'>{session_number}.</td>
                <td
                    className='session-table__col session__name'
                    style={{ minWidth: window.innerWidth >= 1320 && userRole !== 'coach' ? '220px' : 'unset'}}
                >
                    {session_name}
                </td>
                <td className='session-table__col session__topics'>
                    <ul className='categories__list'>
                        {categories?.map(category =>
                            <li key={category.id}>{category.name}</li>
                        )}
                    </ul>
                    {
                        categories?.length !== 0 && <span className='session-table__col--name'>Topics</span>
                    }
                </td>
                <td className='session-table__col session__scheduled'>
                    {created_at}
                    <span className='session-table__col--name'>Scheduled</span>
                </td>
                <td className='session-table__col session__completed'>
                    {completed_at ? completed_at : '-'}
                    <span className='session-table__col--name'>Completed</span>
                </td>
                <td className='session-table__col session__status'>{statusIcon}</td>
                {/* <td className='session-table__col session__cancelled'>
                    {canceled_at ? canceled_at : '-'}
                    <span className='session-table__col--name'>Cancelled</span>
                </td> */}
            </tr>
        )
    });

    return (
        <tr className='reports-item__inner-row'>
            <td colSpan={12} className='reports-item__inner-col'>
                <div className='session-table__wrap'>
                    <table className='session-table'>
                        <tbody>
                            <tr className='session-table__head'>
                                <th className='session-table__head-col'>#</th>
                                <th className='session-table__head-col'>Sessions name</th>
                                <th className='session-table__head-col'>Topics</th>
                                <th className='session-table__head-col'>Scheduled</th>
                                <th className='session-table__head-col'>Completed</th>
                                <th className='session-table__head-col'>Status</th>
                                {/* <th className='session-table__head-col'>Cancelled</th> */}
                            </tr>
                            {sessionData}
                        </tbody>
                    </table>
                </div>
            </td>
        </tr>

    )
    // return (
    // <tr className={`reports-item`}>
    //     <td className="reports-item__cell">
    //         {session_number}
    //     </td>
    //     <td className="reports-item__cell">
    //         {session_name}
    //     </td>
    //     <td className="reports-item__cell">
    //         {status}
    //     </td>
    //     <td className="reports-item__cell">
    //         {created_at}
    //     </td>
    //     <td className="reports-item__cell">
    //         {completed_at}
    //     </td>
    //     <td className="reports-item__cell"></td>
    //     <td className="reports-item__cell">
    //         {canceled_at}
    //     </td>
    //     <td className="reports-item__cell"></td>
    // </tr>
    // );
};

export default SessionDataRows;