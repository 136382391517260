export default {
    DASHBOARD_TITLE: "Dashboard",
    PARTICIPANTS_TITLE: "Participants",
    COACHES_TITLE: "Coaches",
    PROGRAMMES_TITLE: "Programmes",
    CALENDAR_TITLE: "Calendar",
    EVALUATIONS_TITLE: "Evaluations",
    LOGIN_PAGE_TITLE: "Login",
    PAGE_NOT_FOUND_TITLE: "Page Not Found",
    MY_PROFILE_TITLE: "Profile",
    SETTINGS_TITLE: "Settings",
    REPORTS_TITLE: "Reports",
};