import React from 'react';

const TriggerUploadSvg = ({color = '#929292', circleColor = '#F5F5F5', width = 40, height = 40, ...props}) => {
    return (

        <svg {...props} width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill={circleColor}/>
            <circle cx="20" cy="11" r="2" fill={color}/>
            <circle cx="20" cy="20" r="2" fill={color}/>
            <circle cx="20" cy="29" r="2" fill={color}/>
        </svg>

    );
};

export default TriggerUploadSvg;



