import React from "react";
import TopicsContent from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/TopicsContent/TopicsContent";
import { checkBlockedActivity } from "helpers/userPrograms";

const DesktopTopics = ({
  topics = [],
  coachId,
  activeListItem,
  isDeactivated,
  ...otherProps
}) => {
  const currentTopic =
    topics.flat().find((topic) => topic.uniqId === activeListItem) || {};
  let isBlocked = false;
  const isDecisionBox =
    currentTopic.type === "decision" || currentTopic.type === "decisionForm";

  if (!isDecisionBox) {
    isBlocked = checkBlockedActivity(
      topics,
      currentTopic.id,
      currentTopic.previous_steps,
      coachId
    );
  }

  const isCurrentTopicDisabled = topics.find((topic) =>
    topic.find((item) => item.is_disabled)
  );

  const isCurrentActivityFromDisabledTopic = isCurrentTopicDisabled?.find(
    (activity) => activity.id === currentTopic.id
  );

  return Object.keys(currentTopic).length > 0 ? (
    <TopicsContent
      isDeactivated={isDeactivated}
      type={currentTopic.type}
      coachId={coachId}
      activityId={currentTopic.id}
      isBlocked={isBlocked || Boolean(isCurrentActivityFromDisabledTopic)}
      name={currentTopic.name}
      topicNum={currentTopic.topicNum}
      num={currentTopic.num}
      typeName={currentTopic.typeName}
      {...currentTopic}
      {...otherProps}
    />
  ) : null;
};

export default DesktopTopics;
