import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showNotification } from "../../../redux/actions";
import arrow from "assets/vector/suggested-coaches-notification/arrow.svg";
import ResponseError from "helpers/ResponseError";
import axios from "axios";
import { REACT_APP_API_BASE_URL } from "constants/api";

import "./SuggestedCoachesNotification.scss";

const SuggestedCoachesNotification = ({ notification }) => {
	const dispatch = useDispatch();
	const [suggestedCoachesExist, setSuggestedCoachesExist] = useState([]);
	const { show } = useSelector((state) => state.suggestedCoachesNotification);
	const { authToken } = useSelector((state) => state.auth);
	const link = notification?.data?.link?.split(".io")[1];

	const getSuggestedCoachesList = (school_program_id) => {
		axios({
			method: "get",
			url: `${REACT_APP_API_BASE_URL}/participant/matched-coaches?school_program_id=${school_program_id}`,
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
			accept: "application/json",
		})
			.then((response) => {
				const data = response.data.data;
				setSuggestedCoachesExist(data?.manual_suggested_coaches);
			})
			.catch((error) => {
				const responseError = new ResponseError(error.response.data);

				dispatch(
					showNotification({
						title: responseError.getStatus(),
						text: responseError.getValidationErrorMessages(),
					})
				);
			});
	};

	useEffect(() => {
		if (authToken && notification?.data?.school_program_id) {
			getSuggestedCoachesList(notification.data.school_program_id);
		}
	}, [authToken, notification]);

	if (suggestedCoachesExist?.length > 0 && show && notification) {
		return (
			<div className="suggested-coaches__notification">
				<div className="notification__title">
					Hey, {notification?.data?.name}!
				</div>
				<div className="notification__description">
					We already have some suggested Coaches for you within the "
					{notification?.data?.participant_program_name}" programme
				</div>
				<div className="notification__link">
					<Link to={link}>
						Choose a coach <img src={arrow} alt="" />
					</Link>
				</div>
			</div>
		);
	}

	return null;
};

export default SuggestedCoachesNotification;
