import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import "./Forms.scss";
import RadioFiveItems from "./FormSteps/RadioFiveItems";
import RadioItems from "./FormSteps/RadioItems";
import TextareaForm from "./FormSteps/TextareaForm";
import SelectForm from "./FormSteps/SelectForm";
import CheckboxForm from "./FormSteps/CheckboxForm";
import TextInputStep from "./FormSteps/TextInputStep";
import DropdownStep from "./FormSteps/DropdownStep";
import DateStep from "./FormSteps/DateStep";
import LegendPageStep from "./FormSteps/LegendPageStep";
import profile from "services/profile";
import routes from "constants/routes";
import { GET_QUESTIONNAIRE_DATA, SEND_QUESTIONNAIRE_DATA } from "constants/api";
import {
	showNotification,
	getInfoAboutCurrentUser,
} from "../../../../redux/actions";
import ResponseError from "helpers/ResponseError";
import questionnaireValidationSchema from "./ValidationSchemas/questionnaireValidationSchema";
import { fakeSubmitListener } from "components/pages/QuestionnaireForms/MockData/helpers/fakeSubmitListener";
import QuestionnaireStepper from "./QuestionnaireStepper.js/QuestionnaireStepper";

const booleanOptions = (lang) => [
	{
		label: lang === "it" ? "Si" : "Yes",
		value: "true",
	},
	{
		label: "No",
		value: "false",
	},
];

const scaleOptions = [
	{
		option: "1",
	},
	{
		option: "2",
	},
	{
		option: "3",
	},
	{
		option: "4",
	},
	{
		option: "5",
	},
];

const QuestionnaireForm = () => {
	const [blockSubmit, setBlockSubmit] = useState(false);
	const [steps, setSteps] = useState([]);
	const [formStep, setFormStep] = useState(steps[0]);
	const [initialValues, setInitialValues] = useState(false);
	const [stepDirection, setStepDirection] = useState("next");
	const [activeStep, setActiveStep] = useState(0);
	let isLastStep = activeStep === steps.length - 1;

	// used for displaying data from separate endpoints for some form steps
	const [countriesOptions, setCountriesOptions] = useState(null);
	const [languageOptions, setLanguageOptions] = useState(null);
	const [industryBackgroundOptions, setIndustryBackgroundOptions] =
		useState(null);
	const [functionalAreaOptions, setFunctionalAreaOptions] = useState(null);
	const [geographiesOptions, setGeographiesOptions] = useState(null);
	const [genderOptions, setGenderOptions] = useState(null);

	const dispatch = useDispatch();
	const history = useHistory();

	const accessToken = useSelector((state) => state.auth.authToken);
	const getCurrentUserInfo = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const { language } = useSelector((state) => state.questionnaireLanguage);

	const { QUESTIONNAIRE_COACH_MATCHING, DASHBOARD } = routes;

	// goToNextStep() and goToPreviousStep() functions are used in every form steps for checking conditions
	const goToNextStep = () => {
		setActiveStep(activeStep + 1);
		setFormStep(steps[activeStep + 1]);
	};

	const goToPreviousStep = () => {
		setActiveStep(activeStep - 1);
		setFormStep(steps[activeStep - 1]);
	};

	function _sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	const _renderStepContentForQuestionnaire = (
		item,
		values,
		goToNextStep,
		goToPreviousStep,
		stepDirection
	) => {
		switch (item.question_type) {
			case "text":
				return (
					<TextInputStep
						name={item.key}
						question={item.question}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						item={item}
						values={values}
						stepDirection={stepDirection}
					/>
				);
			case "textarea":
				return (
					<TextareaForm
						name={item.key}
						placeholder={
							language === "it"
								? "Inserisci la risposta..."
								: "Enter your answer..."
						}
						question={item.question}
						tooltip={item.question_tooltip}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						item={item}
						values={values}
						stepDirection={stepDirection}
					/>
				);
			case "dropdown":
				return (
					<DropdownStep
						options={
							item.key === "PQ-64"
								? item.question_options.filter(
										(option) => option.key !== "PQ-64-2"
								  )
								: item.question_options
						}
						question={item.question}
						name={item.key}
						rules={item.rules ? item.rules : ""}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						item={item}
						values={values}
						stepDirection={stepDirection}
					/>
				);
			case "country":
				return (
					<SelectForm
						options={countriesOptions}
						question={item.question}
						name={item.key}
						rules={item.rules ? item.rules : ""}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						item={item}
						values={values}
						stepDirection={stepDirection}
					/>
				);
			case "language":
				return (
					<DropdownStep
						options={languageOptions}
						question={item.question}
						name={item.key}
						rules={item.rules ? item.rules : ""}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						item={item}
						values={values}
						stepDirection={stepDirection}
					/>
				);
			case "geographic_area":
				return (
					<DropdownStep
						options={geographiesOptions}
						question={item.question}
						name={item.key}
						rules={item.rules ? item.rules : ""}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						values={values}
						item={item}
						stepDirection={stepDirection}
					/>
				);
			case "functional_area":
				return (
					<DropdownStep
						options={functionalAreaOptions}
						question={item.question}
						name={item.key}
						rules={item.rules ? item.rules : ""}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						values={values}
						item={item}
						stepDirection={stepDirection}
					/>
				);
			case "industry":
				return (
					<DropdownStep
						options={
							item.key === "PQ-169"
								? industryBackgroundOptions.filter(
										(item) => item.name !== "Other"
								  )
								: industryBackgroundOptions
						}
						question={item.question}
						name={item.key}
						rules={item.rules ? item.rules : ""}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						values={values}
						item={item}
						stepDirection={stepDirection}
					/>
				);
			case "boolean":
				return (
					<Field
						name={item.key}
						options={booleanOptions(language)}
						question={item.question}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						values={values}
						item={item}
						stepDirection={stepDirection}
						component={RadioItems}
					/>
				);
			case "scale":
				return (
					<Field
						name={item.key}
						min_scale={item.min_scale_text}
						max_scale={item.max_scale_text}
						options={scaleOptions}
						component={RadioFiveItems}
						question={item.question}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						values={values}
						item={item}
						stepDirection={stepDirection}
					/>
				);
			case "date":
				return <DateStep question={item.question} name={item.key} />;
			case "gender":
				return (
					<SelectForm
						options={genderOptions}
						question={item.question}
						name={item.key}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						values={values}
						item={item}
						stepDirection={stepDirection}
					/>
				);
			case "checkboxes":
				return (
					<Field
						values={values}
						name={item.key}
						options={item.question_options}
						component={CheckboxForm}
						question={item.question}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						item={item}
						stepDirection={stepDirection}
					/>
				);
			case "radio":
				return (
					<Field
						name={item.key}
						options={
							item.conditional_options?.length > 0
								? item.conditional_options
								: item.question_options
						}
						question={item.question}
						goToNextStep={goToNextStep}
						goToPreviousStep={goToPreviousStep}
						values={values}
						item={item}
						stepDirection={stepDirection}
						component={RadioItems}
					/>
				);
			case "legend_page":
				return <LegendPageStep />;
			default:
				break;
		}
	};

	const removeEmptyValues = (obj) => {
		let newObj = {};
		Object.keys(obj).forEach((prop) => {
			if (obj[prop] && obj[prop] !== "not answer") {
				newObj[prop] = obj[prop];
			}
		});
		return newObj;
	};

	const _submitForm = async (values, actions) => {
		if (isLastStep) setBlockSubmit(true);

		await _sleep(1000);
		let data = { answers: [] };

		if (getCurrentUserInfo?.role === "participant") {
			data.participant_program_id =
				getCurrentUserInfo?.last_participant_program_id;
		}

		for (const property in removeEmptyValues(values)) {
			const newValues = {};
			if (property === "CQ-36" || property === "PQ-167") {
				newValues["question_key"] = property;
				newValues["answer"] = moment(values[property]).format("DD-MM-Y");
				data.answers.push(newValues);
			} else {
				newValues["question_key"] = property;
				newValues["answer"] = values[property].toString();
				data.answers.push(newValues);
			}
		}

		axios({
			method: "post",
			url: SEND_QUESTIONNAIRE_DATA(getCurrentUserInfo?.survey?.survey_id),
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			accept: "application/json",
			data: data,
		})
			.then((response) => {
				if (response.status === 200) {
					if (getCurrentUserInfo.role === "participant") {
						dispatch(
							getInfoAboutCurrentUser(null, () =>
								history.push(QUESTIONNAIRE_COACH_MATCHING)
							)
						);
					}

					if (getCurrentUserInfo.role === "coach") {
						dispatch(
							getInfoAboutCurrentUser(null, () => history.push(DASHBOARD))
						);
					}
				}
			})
			.catch((error) => {
				const responseError = new ResponseError(error.response.data);

				dispatch(
					showNotification({
						title: responseError.getStatus(),
						text: responseError.getValidationErrorMessages(),
					})
				);

				setBlockSubmit(false);
			});
		actions?.setSubmitting(false);
	};

	const _handleSubmit = (values, actions) => {
		if (isLastStep) {
			_submitForm(values, actions);
		} else {
			setStepDirection("next");

			setActiveStep(activeStep + 1);
			setFormStep(steps[activeStep + 1]);

			actions.setTouched({});
			actions.setSubmitting(false);
		}
	};

	const _handleBack = (values) => {
		setStepDirection("back");

		values[formStep.key] = "";

		setActiveStep(activeStep - 1);
		setFormStep(steps[activeStep - 1]);

		window.scrollTo(0, 0);
	};

	// TODO for testing purposes on STAGE and LOCAL only
	// uses to pass mock data as SDA and Career answers
	useEffect(fakeSubmitListener(steps, _submitForm), [steps]);

	useEffect(() => {
		profile
			.getDefinedData(accessToken, language ? language : "en")
			.then((response) => {
				setCountriesOptions(response.countries);
				setLanguageOptions(response.languages);
				setIndustryBackgroundOptions(response.industryBackgrounds);
				setFunctionalAreaOptions(response.functionalAreas);
				setGeographiesOptions(response.geographies);
				setGenderOptions(response.genders);
			})
			.catch((error) => console.log(error));

		if (getCurrentUserInfo) {
			axios({
				method: "get",
				url: `${GET_QUESTIONNAIRE_DATA(
					getCurrentUserInfo?.survey?.survey_id
				)}?lang=${language ? language : "en"}`,
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
				accept: "application/json",
			})
				.then((response) => {
					const formData = response.data.data;

					// used to display the legend page step depending on the user's role in the SDA Bocconi survey
					const newItem = {
						key: "legend",
						question_type: "legend_page",
					};

					if (getCurrentUserInfo?.survey?.survey_id === 1) {
						if (getCurrentUserInfo?.role === "coach") {
							formData.splice(2, 0, newItem);
						}
						if (getCurrentUserInfo?.role === "participant") {
							const elementIndex = formData.findIndex(
								(item) => item.key === "PQ-68"
							);
							formData.splice(elementIndex + 1, 0, newItem);
						}
					}

					setSteps(formData);

					const initial = {};

					for (let i in formData) {
						let obj = formData[i];
						initial[obj.key] = "";
					}

					setInitialValues(initial);
					setFormStep(formData[0]);
				})
				.catch((error) => {
					const responseError = new ResponseError(error.response.data);

					dispatch(
						showNotification({
							title: responseError.getStatus(),
							text: responseError.getValidationErrorMessages(),
						})
					);
				});
		}
	}, [accessToken, getCurrentUserInfo]);

	return (
		<section className="feedback-form">
			<div className="feedback__container">
				<QuestionnaireStepper
					stepsLength={steps.length}
					activeStep={activeStep}
				/>
				{initialValues && formStep && (
					<Formik
						initialValues={initialValues}
						validationSchema={questionnaireValidationSchema(formStep)}
						onSubmit={_handleSubmit}
					>
						{({ isSubmitting, values, errors }) => {
							// console.log('values', values)
							return (
								<Form id="questionnaire">
									{formStep &&
										_renderStepContentForQuestionnaire(
											formStep,
											values,
											goToNextStep,
											goToPreviousStep,
											stepDirection
										)}
									<div className="form__buttons">
										{activeStep !== 0 && (
											<Button
												onClick={() => _handleBack(values)}
												variant="contained"
												className="button__back btn"
											>
												{language === "it" ? "Indietro" : "Back"}
											</Button>
										)}
										<div>
											<Button
												type="submit"
												variant="contained"
												color="primary"
												disabled={blockSubmit}
												className="button__submit btn"
												onClick={
													Object.keys(errors).length > 0
														? null
														: () => window.scrollTo(0, 0)
												}
											>
												{isLastStep
													? language === "it"
														? "Invia"
														: "Submit"
													: language === "it"
													? "Avanti"
													: "Next"}
											</Button>
											{isSubmitting && (
												<CircularProgress
													size={24}
													className="button__progress"
												/>
											)}
										</div>
									</div>
								</Form>
							);
						}}
					</Formik>
				)}
			</div>
		</section>
	);
};

export default QuestionnaireForm;
