import React from "react";
import "./Radio.scss";

import selectedRadioImage from "assets/vector/radio_selected.svg";

const RadioItem = ({
  selected,
  onChange,
  value,
  text,
  showValue = false,
  color = "#2a6bbf",
  backgroundColor = "rgb(46, 121, 218, 0.2)",
}) => {
  // props showValue used to display the value of the radio button in the middle of the circle (questions type 'range')
  return (
    <div
      className='radio-container'
      onClick={() => {
        onChange(value);
      }}
    >
      <div
        className={`outer-circle ${value !== selected && "unselected"}`}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <div
          className='inner-circle'
          style={{
            backgroundColor: value === selected ? color : "",
          }}
        >
          {value !== selected ? (
            showValue ? (
              value + 1
            ) : (
              ""
            )
          ) : (
            <img src={selectedRadioImage} alt='' />
          )}
        </div>
      </div>
      {text && <div className='radio-helper-text'>{text}</div>}
    </div>
  );
};

export default RadioItem;
