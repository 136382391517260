import React from "react";
import Select, { components } from 'react-select';

import { customSelectStyles } from "helpers/customSelectStyles";

import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";
import { useFormikContext } from 'formik';

const typeSelectStyles = Object.assign({}, customSelectStyles);

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowSvg />
        </components.DropdownIndicator>
    );
};

const CustomFormSelectParticipants = ({ field, options, placeholder, setProgramStatus, programStatus, form: { setFieldValue } }) => {

    const { submitForm } = useFormikContext();

    const onChangeSelect = (optionValue) => {
        setFieldValue(field.name, optionValue.value)
        setProgramStatus(optionValue.value)
        submitForm();
    };

    return (
        <Select
            getOptionLabel={options => options.label}
            getOptionValue={options => options.value}
            components={{ DropdownIndicator }}
            name={field.name}
            options={options}
            placeholder={placeholder}
            classNamePrefix={`row-select`}
            styles={typeSelectStyles}
            isSearchable={false}
            onChange={(optionValue) => onChangeSelect(optionValue)}
            value={options.filter(option => option.value === programStatus)}
        />
    )
}

export default CustomFormSelectParticipants;
