import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { GET_USER_NOTIFICATIONS } from "constants/api";
import ResponseError from "helpers/ResponseError";
import { showNotification } from "../../redux/actions";
import MissingFeedbackFormNotification from "./MissingFeedbackFormNotification/MissingFeedbackFormNotification";
import SuggestedCoachesNotification from "./suggestedCoachesNotification/SuggestedCoachesNotification";

import "./SideMenuNotifications.scss";
import { useLocation } from "react-router-dom";
import routes from "constants/routes";

const SideMenuNotifications = () => {
	const {
		QUESTIONNAIRE_COACH_MATCHING,
		QUESTIONNAIRE,
		QUESTIONNAIRE_FORM,
		FEEDBACK_PAGE,
		SIGN_IN,
	} = routes;
	const location = useLocation();
	const dispatch = useDispatch();
	const [feedbackFormNotification, setFeedbackFormNotification] =
		useState(null);
	const [suggestedCoachesNotification, setSuggestedCoachesNotification] =
		useState(null);
	const { authToken, userRole } = useSelector((state) => state.auth);
	const { role } =
		useSelector((state) => state.currentUserInfo.currentUserInfo) || {};
	const currentUserRole = userRole || role;

	const hideNotification =
		location?.pathname.includes(QUESTIONNAIRE_COACH_MATCHING) ||
		location?.pathname.includes(QUESTIONNAIRE) ||
		location?.pathname.includes(QUESTIONNAIRE_FORM) ||
		location?.pathname.includes(FEEDBACK_PAGE) ||
		location?.pathname.includes(SIGN_IN);

	const getNotifications = () => {
		axios({
			method: "get",
			url: GET_USER_NOTIFICATIONS(),
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
			accept: "application/json",
		})
			.then((response) => {
				const data = response.data.data;
				if (data?.notifications?.length > 0) {
					setFeedbackFormNotification(
						data.notifications.find(
							(notification) =>
								notification.type ===
								"not_completed_feedback_form_for_participant"
						)
					);
					setSuggestedCoachesNotification(
						data.notifications.find(
							(notification) => notification.type === "new_suggested_coaches"
						)
					);
				}
			})
			.catch((error) => {
				const responseError = new ResponseError(error.response.data);

				dispatch(
					showNotification({
						title: responseError.getStatus(),
						text: responseError.getValidationErrorMessages(),
					})
				);
			});
	};

	useEffect(() => {
		if (currentUserRole === "participant") {
			getNotifications();
		}
	}, [currentUserRole]);

	if (hideNotification) {
		return null;
	}

	return (
		<div className="side-menu-notifications">
			{suggestedCoachesNotification && (
				<SuggestedCoachesNotification
					notification={suggestedCoachesNotification}
				/>
			)}
			{feedbackFormNotification && (
				<MissingFeedbackFormNotification
					notification={feedbackFormNotification}
				/>
			)}
		</div>
	);
};

export default SideMenuNotifications;
