import React, { useEffect, useRef, useState } from 'react';
import uniqid from "uniqid";
import { calculatePlaceholdersQuantity } from '../../helpers/calculatePlaceholdersQuantity';

const mockSessionData = {
    completed_feedback_form_percent_of_sessions_total: 0,
    count_feedback_form: 0,
}

const Grade = ({ grades = [], question = {} }) => {
    const getStyleOption = (grade) => {
        switch (grade) {
            case grade >= 0 && grade < 1.5 && grade:
                return '--db_satisfaction_mark_3';
            case grade >= 1.5 && grade < 2 && grade:
                return '--db_satisfaction_mark_2';
            case grade >= 2 && grade < 2.5 && grade:
                return '--db_satisfaction_mark_1';
            case grade >= 2.5 && grade < 3 && grade:
                return '--db_satisfaction_mark_6';
            case grade >= 3 && grade < 3.5 && grade:
                return '--db_satisfaction_mark_5';
            case grade >= 3.5 && grade < 4 && grade:
                return '--db_satisfaction_mark_4';
            case grade >= 4 && grade < 4.5 && grade:
                return '--db_satisfaction_mark_9';
            case grade >= 4.5 && grade < 5 && grade:
                return '--db_satisfaction_mark_8';
            case 5:
                return '--db_satisfaction_mark_7';
            default:
                return null;
        }
    };

    const ref = useRef();
    const [width, setWidth] = useState(0);

    const [graphs, setGraphs] = useState(null);

    useEffect(() => {
        setWidth(ref.current.offsetWidth);
        // to handle page resize
        const getwidth = () => {
            setWidth(ref.current.offsetWidth);
        }
        window.addEventListener("resize", getwidth);
        // remove the event listener before the component gets unmounted
        return () => window.removeEventListener("resize", getwidth)
    }, []);

    useEffect(() => {
        const count = calculatePlaceholdersQuantity(width);
        const minCountOfGraphs = (count - grades.length);
        const newSessionsArray = [...grades];
        if (minCountOfGraphs > 0) {
            for (let i = 0; i < minCountOfGraphs; i++) {
                newSessionsArray.push(mockSessionData)
            }
        }
        setGraphs(newSessionsArray)
    }, [grades, width])

    return (
        <div className='marks-stat__grades' ref={ref}>
            {
                graphs?.map(session => {
                    const sessionQuestions = session.questions || [];
                    const findQuestionIndex = sessionQuestions.findIndex(sessionData => sessionData.question === question.question);

                    if (findQuestionIndex !== -1) {
                        const sessionGrade = sessionQuestions[findQuestionIndex].completed_percent_of_sessions_total;
                        const styles = sessionGrade ? { background: `var(${getStyleOption(sessionGrade)})` } : {};

                        return (
                            <div key={uniqid()}
                                style={styles}
                                className={`marks-stat__grade`}>
                                {sessionGrade}
                            </div>
                        )
                    } else {
                        return <div className={`marks-stat__grade`} key={uniqid()}></div>
                    }
                })
            }
        </div>
    );
};

export default Grade;