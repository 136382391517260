import {
	ACCEPT_SESSION_INVITATION,
	ACCEPT_SESSION_INVITATION_ERROR,
	ACCEPT_SESSION_INVITATION_SUCCESS,
	CLEAR_INDIVIDUAL_SESSION_INFO,
	DECLINE_SESSION_INVITATION,
	DECLINE_SESSION_INVITATION_ERROR,
	DECLINE_SESSION_INVITATION_SUCCESS,
	FETCH_SESSION_INFO,
	FETCH_SESSION_INFO_ERROR,
	FETCH_SESSION_INFO_SUCCESS,
	LATE_CANCEL_SESSION_INVITATION,
	LATE_CANCEL_SESSION_INVITATION_ERROR,
	LATE_CANCEL_SESSION_INVITATION_SUCCESS,
	ACCEPT_SESSION_BY_COACH_SUCCESS,
	ACCEPT_SESSION_BY_COACH_ERROR,
	ACCEPT_SESSION_BY_COACH,
} from "../constants";

const initialState = {
	acceptSessionInvitationProgress: false,
	acceptSessionInvitationSuccess: null,
	acceptSessionInvitationError: null,
	declineSessionInvitationProgress: false,
	declineSessionInvitationSuccess: null,
	declineSessionInvitationError: null,
	lateCancelInvitationProgress: false,
	lateCancelInvitationSuccess: null,
	lateCancelInvitationError: null,
	fetchSessionInfoProgress: false,
	acceptSessionByCoachProgress: false,
};

export default function sessionInvitationReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case ACCEPT_SESSION_INVITATION:
			return {
				...initialState,
				acceptSessionInvitationProgress: true,
			};
		case ACCEPT_SESSION_INVITATION_SUCCESS:
			return {
				...initialState,
				acceptSessionInvitationSuccess: payload,
			};
		case ACCEPT_SESSION_INVITATION_ERROR:
			return {
				...initialState,
				acceptSessionInvitationError: payload,
			};
		case DECLINE_SESSION_INVITATION:
			return {
				...initialState,
				declineSessionInvitationProgress: true,
			};
		case DECLINE_SESSION_INVITATION_SUCCESS:
			return {
				...initialState,
				declineSessionInvitationSuccess: payload,
			};
		case DECLINE_SESSION_INVITATION_ERROR:
			return {
				...initialState,
				declineSessionInvitationError: payload,
			};
		case LATE_CANCEL_SESSION_INVITATION:
			return {
				...initialState,
				lateCancelInvitationProgress: true,
			};
		case LATE_CANCEL_SESSION_INVITATION_SUCCESS:
			return {
				...initialState,
				lateCancelInvitationSuccess: payload,
			};
		case LATE_CANCEL_SESSION_INVITATION_ERROR:
			return {
				...initialState,
				lateCancelInvitationError: payload,
			};
		case FETCH_SESSION_INFO:
			return {
				...state,
				fetchSessionInfoProgress: true,
			};
		case FETCH_SESSION_INFO_SUCCESS:
			return {
				...state,
				fetchSessionInfoProgress: false,
			};
		case FETCH_SESSION_INFO_ERROR:
			return {
				...state,
				fetchSessionInfoProgress: false,
			};
		case ACCEPT_SESSION_BY_COACH:
			return {
				...state,
				acceptSessionByCoachProgress: true,
			};
		case ACCEPT_SESSION_BY_COACH_SUCCESS:
			return {
				...state,
				acceptSessionByCoachProgress: false,
			};
		case ACCEPT_SESSION_BY_COACH_ERROR:
			return {
				...state,
				acceptSessionByCoachProgress: false,
			};
		case CLEAR_INDIVIDUAL_SESSION_INFO:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
