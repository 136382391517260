import React from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import SessionsStats from "components/pages/Dashboard/SchoolAdmin/Charts/Ratings/Satisfaction/SessionsStats/SessionsStats";
import GradeStats from "components/pages/Dashboard/SchoolAdmin/Charts/Ratings/Satisfaction/GradesStats/GradeStats";
import { ReactComponent as EmptyRatings } from "assets/vector/dashboard/empty-ratings.svg";

const Satisfaction = ({ satisfaction = {} }) => {
    return (
        Object.keys(satisfaction).length > 0 ?
            <PerfectScrollbar className="users-table users-table--limit-scroll">
                <SessionsStats sessionStats={satisfaction} />
                <GradeStats sessionStats={satisfaction} />
            </PerfectScrollbar>
            :
            <div className="users-table__empty">
                <EmptyRatings className='icon' />
                Data is not available yet
            </div>
    );
};

export default Satisfaction;