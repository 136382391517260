import { fork, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { platformSettings } from "../constants";
import {
	getPlatformSettingsSuccess,
	getPlatformSettingsError,
} from "../actions/platformSettings";
import { GET_PLATFORM_SETTINGS } from "constants/api";
import { getToken } from "../sagas/helpers/helpers";

function* watchGetPlatformSettings() {
	yield takeLatest(
		platformSettings.GET_PLATFORM_SETTINGS,
		handleGetPlatformSettings
	);
}
function* handleGetPlatformSettings({ payload }) {
	try {
		const token = yield select(getToken);

		const currentThemeData = yield axios({
			method: "get",
			url: GET_PLATFORM_SETTINGS(payload),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		})
			.then((response) => response.data.data)
			.catch((error) => console.log(error));

		yield put(getPlatformSettingsSuccess(currentThemeData));
	} catch (e) {
		const errorMessage = e?.response?.message;
		console.log(errorMessage);

		yield put(getPlatformSettingsError(errorMessage));
	}
}

export default function* platformSettingsFunc() {
	yield fork(watchGetPlatformSettings);
}
