import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./QuestionnaireLanguageModal.scss";
import { languageSelection } from "helpers/languageSelection";
import { chooseQuestionnaireLanguage } from "../../../redux/actions";
import routes from "constants/routes";

const QuestionnaireLanguageModal = ({ closeModal }) => {
	const questionnaireLanguage = useSelector(
		(state) => state.questionnaireLanguage.language
	);
	const { QUESTIONNAIRE_FORM } = routes;

	const dispatch = useDispatch();
	const history = useHistory();

	return (
		<div className="questionnaire-languages__section">
			<div className="section-content__wrapper">
				<div className="section__title">Please Choose a Language first</div>
				<div className="section__description">
					You can choose a language just one time here. You will not be able to
					change it later
				</div>
				<div className="section__buttons">
					{languageSelection.map((language) => (
						<button
							key={language.id}
							className={
								questionnaireLanguage === language.value
									? "language-btn active"
									: "language-btn"
							}
							onClick={() => {
								dispatch(chooseQuestionnaireLanguage(language.value));
								localStorage.setItem("survey_language", language.value);
							}}
						>
							<img className="language__icon" src={language.icon} alt="" />
							{language.label}
						</button>
					))}
				</div>
				<div className="proceed-button__wrapper">
					<button
						className="proceed-btn"
						disabled={!questionnaireLanguage}
						onClick={() => {
							history.push(QUESTIONNAIRE_FORM);
							closeModal();
						}}
					>
						Proceed
					</button>
				</div>
			</div>
		</div>
	);
};

export default QuestionnaireLanguageModal;
