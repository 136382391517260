import moment from 'moment'

const checkIsOneDateIsBetweenTwoDates = (startDate, endDate, dateToCheck) => {

    // format - yyyy-MM-DD
    const start = moment(startDate).format('yyyy-MM-DD');
    const end = moment(endDate).format('yyyy-MM-DD');
    const date_to_check = moment(dateToCheck).format('yyyy-MM-DD');

    return (date_to_check > start && date_to_check < end)
}

export default checkIsOneDateIsBetweenTwoDates;
