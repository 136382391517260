import { fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { dashboardConstant } from "../constants";
import {
	getListUserSchoolProgramsSuccess,
	getListUserSchoolProgramsError,
	getSessionsCreditsSuccess,
	getSessionsCreditsError,
	getSessionsSuccess,
	getSessionsLoadSuccess,
	getSessionsError,
	getParticipantsSuccess,
	getParticipantsError,
	getAttendanceRateSuccess,
	getAttendanceRateError,
	getAverageMarkSuccess,
	getAverageMarkLoadSuccess,
	getAverageMarkError,
	showDashboarPage,
	showNotification,
	getSchoolAdminProgramsError,
	getSchoolOverviewGraphsSuccess,
	setCurrentSchoolProgram,
} from "../actions";
import {
	SCHOOL_ADMIN_PROGRAMS,
	GET_SESSIONS_DASHBOARD,
	GET_PARTICIPANTS_INFO_DASHBOARD,
	GET_ATTENDANCE_RATE_DIAGRAM_DASHBOARD,
	GET_GET_AVERAGE_MARK_DASHBOARD,
	GET_SESSIONS_CREDITS_DASHBOARD,
	GET_SCHOOL_OVERVIEW_GRAPHS,
} from "constants/api";
import { generalApiErrorHandler } from "../sagas/helpers/generalApiErrorHandler";
import { getNotificationMessage } from "../../helpers/notifications";
import { getToken } from "../sagas/helpers/helpers";
import ResponseError from "helpers/ResponseError";

function* watchGetListUserSchoolPrograms() {
	yield takeLatest(
		dashboardConstant.GET_LIST_USER_SCHOOL_PROGRAMS,
		handleGetListUserSchoolPrograms
	);
}

function* handleGetListUserSchoolPrograms({ params }) {
	try {
		const token = yield select(getToken);

		const listUserSchoolPrograms = yield axios({
			method: "get",
			url: SCHOOL_ADMIN_PROGRAMS,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
			params: { ...params },
		}).then((response) => response.data.data);

		if (listUserSchoolPrograms.programs.length > 0) {
			yield put(getListUserSchoolProgramsSuccess(listUserSchoolPrograms));
			yield put(setCurrentSchoolProgram(listUserSchoolPrograms.programs[0]));
			yield put(showDashboarPage(true));
		} else {
			yield put(showDashboarPage(false));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getListUserSchoolProgramsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchGetListUserSchoolProgramsOnScroll() {
	yield takeLatest(
		dashboardConstant.GET_LIST_USER_SCHOOL_PROGRAMS_ON_SCROLL,
		handleGetListUserSchoolProgramsOnScroll
	);
}

function* handleGetListUserSchoolProgramsOnScroll({ params }) {
	const { listUserSchoolPrograms } = yield select((state) => state.dashboard);

	try {
		const token = yield select(getToken);

		const newSchoolPrograms = yield axios({
			method: "get",
			url: SCHOOL_ADMIN_PROGRAMS,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
			params: { ...params },
		}).then((response) => response.data.data);

		if (newSchoolPrograms.programs.length > 0) {
			const newData = {
				...newSchoolPrograms,
			};

			if (params?.offset > 0) {
				newData.programs = [
					...listUserSchoolPrograms,
					...newSchoolPrograms.programs,
				];
			}

			yield put(getListUserSchoolProgramsSuccess(newData));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getListUserSchoolProgramsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchSessionsCredits() {
	yield takeLatest(
		dashboardConstant.GET_SESSIONS_CREDITS,
		handleSessionsCredits
	);
}

function* handleSessionsCredits({ payload }) {
	try {
		const token = yield select(getToken);

		const sessionsCreditsData = yield axios({
			method: "get",
			url: GET_SESSIONS_CREDITS_DASHBOARD(payload),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(getSessionsCreditsSuccess(sessionsCreditsData));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getSessionsCreditsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchSessions() {
	yield takeLatest(dashboardConstant.GET_SESSIONS, handleSessions);
}

function* handleSessions({ payload }) {
	try {
		const token = yield select(getToken);

		const currentUserData = yield axios({
			method: "get",
			url: GET_SESSIONS_DASHBOARD(payload),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(getSessionsSuccess(currentUserData));
		yield put(getSessionsLoadSuccess(false));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getSessionsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchParticipants() {
	yield takeLatest(dashboardConstant.GET_PARTICIPANTS, handleParticipants);
}

function* handleParticipants({ payload }) {
	try {
		const token = yield select(getToken);
		const participantsInfoData = yield axios({
			method: "get",
			url: GET_PARTICIPANTS_INFO_DASHBOARD(payload),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(getParticipantsSuccess(participantsInfoData));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getParticipantsError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchAttendance() {
	yield takeLatest(dashboardConstant.GET_ATTENDANCE, handleAttendance);
}

function* handleAttendance({ payload }) {
	try {
		const token = yield select(getToken);
		const currentUserData = yield axios({
			method: "get",
			url: GET_ATTENDANCE_RATE_DIAGRAM_DASHBOARD(payload),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(getAttendanceRateSuccess(currentUserData));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getAttendanceRateError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchAverageMark() {
	yield takeLatest(dashboardConstant.GET_AVERAGE_MARK, handleAverageMark);
}

function* handleAverageMark({ payload }) {
	try {
		const token = yield select(getToken);
		const currentUserData = yield axios({
			method: "get",
			url: GET_GET_AVERAGE_MARK_DASHBOARD(payload),
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data.data);

		yield put(getAverageMarkSuccess(currentUserData));
		yield put(getAverageMarkLoadSuccess(false));
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(getAverageMarkError(error.message));

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

function* watchSchoolOverview() {
	yield takeLatest(
		dashboardConstant.GET_SCHOOL_OVERVIEW_GRAPHS,
		handleSchoolOverview
	);
}

function* handleSchoolOverview() {
	try {
		const token = yield select(getToken);
		const data = yield axios({
			method: "get",
			url: GET_SCHOOL_OVERVIEW_GRAPHS,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			accept: "application/json",
		}).then((response) => response.data);

		if (data?.status === "Success") {
			const responseData = data.data;

			yield put(getSchoolOverviewGraphsSuccess(responseData));
		} else if (data?.status === "Error") {
			const err = new ResponseError(data);

			yield put(getSchoolAdminProgramsError(err.getValidationErrorMessages()));
		}
	} catch (error) {
		yield generalApiErrorHandler(error.message, function* () {
			console.log(error.message);

			yield put(
				showNotification(
					{
						title: "Error",
						text: getNotificationMessage(error.message),
					},
					true
				)
			);
		});
	}
}

export default function* DashboardSaga() {
	yield fork(watchGetListUserSchoolPrograms);
	yield fork(watchGetListUserSchoolProgramsOnScroll);
	yield fork(watchSessionsCredits);
	yield fork(watchSessions);
	yield fork(watchParticipants);
	yield fork(watchAttendance);
	yield fork(watchAverageMark);
	yield fork(watchSchoolOverview);
}
