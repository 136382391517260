import React from 'react';
import { useSelector } from 'react-redux';

const Title = ({ role }) => {
        
    const { survey } = useSelector(state => state.currentUserInfo.currentUserInfo);

    const duration = role === 'coach'
        ? '20'
        : survey?.survey_id === 2 ? '15' : '30';

    return (
        <div className="questionnaire__title">
            <h1 className="title_text">Welcome to the <span>Sw!tch</span> {survey?.survey_id === 2 ? 'Career' : ''}  Questionnaire</h1>
            <div className="subtitle_text">
                <span className="bold">This survey will take approximately {duration} minutes to complete </span>
            </div>
        </div>
    )
}

export default Title;