import React, { useState } from 'react';
import './Graph.scss';
import Tooltip from "components/pages/Dashboard/SchoolAdmin/Charts/Attendance/Tooltip/Tooltip";

const Graph = (props) => {
    const {
        isAdditional = false,
        completed = 0,
        completed_percent,
        not_attended = 0,
        not_attended_percent,
        planned = 0,
        planned_percent,
        sessions_total = 0
    } = props;

    const completedBarHeight = Math.round((completed / sessions_total) * 100);
    const plannedBarHeight = Math.round((planned / sessions_total) * 100);
    const notAttendedBarHeight = Math.round((not_attended / sessions_total) * 100);

    const defaultTooltip = {
        show: false,
        title: 'N/A',
        number: 0,
        percent: 0,
        position: {}
    };

    const [tooltip, setTooltip] = useState(defaultTooltip);

    const setTooltipData = ({ clientY = 0, clientX = 0 }, title, number, percent) => {
        setTooltip({ show: true, title, number, percent, position: { top: clientY - 90, left: clientX - 76 } });
    };

    const updateTooltipCords = ({ clientY = 0, clientX = 0 }) => {
        if (tooltip.show) {
            setTooltip({ ...tooltip, position: { top: clientY - 90, left: clientX - 76 } })
        }
    };

    const setTooltipOff = () => setTooltip(defaultTooltip);

    return (
        <>
            <div className='attendance-graph'>
                <div className="attendance-graph__scale">
                    {
                        plannedBarHeight > 0 && <div className="attendance-graph__line attendance-graph__line--confirmed"
                            style={{ height: `${plannedBarHeight}%` }}
                            onMouseOver={(e) => setTooltipData(e, 'Confirmed', planned, planned_percent)}
                            onMouseMove={updateTooltipCords}
                            onMouseOut={setTooltipOff}
                        />
                    }
                    {
                        notAttendedBarHeight > 0 && <div className="attendance-graph__line attendance-graph__line--not-attended"
                            style={{ height: `${notAttendedBarHeight}%` }}
                            onMouseOver={(e) => setTooltipData(e, 'Not Attended', not_attended, not_attended_percent)}
                            onMouseMove={updateTooltipCords}
                            onMouseOut={setTooltipOff}
                        />
                    }
                    {
                        completedBarHeight > 0 && <div className="attendance-graph__line"
                            style={{ height: `${completedBarHeight}%` }}
                            onMouseOver={(e) => setTooltipData(e, 'Completed', completed, completed_percent)}
                            onMouseMove={updateTooltipCords}
                            onMouseOut={setTooltipOff}
                        />
                    }
                </div>
                {isAdditional && <p className='attendance-graph__add-title'>Extra sessions</p>}
            </div>
            <Tooltip
                // title={tooltip.title}
                show={tooltip.show}
                // number={tooltip.number}
                // percent={tooltip.percent}
                position={tooltip.position}
                data={props}
            />
        </>
    );
};

export default Graph;