import React, { useState } from 'react';
import './ReportsTabs.scss';

const ReportsMobileTabs = ({ setClearFilters, setActiveTabContent }) => {
    const tabs = [
        {
            id: 1,
            label: 'Filters',
            name: 'filters'
        },
        {   id: 2,
            label: 'Sort by',
            name: 'sorting'
        }
    ];
    const [activeTab, setActiveTab] = useState('filters');

    const tabButtons = tabs.map((tab, index) => {
        return <button type="button"
            key={tab.id}
            name={tab.name}
            className={`reports-tabs__btn ${activeTab === tab.name ? 'reports-tabs__btn--active' : ''}`}
            onClick={() => {
                setActiveTab(tab.name);
                setActiveTabContent(tab.id);
                setClearFilters(true);
            }}
        >
            {tab.label}
        </button>
    }
    );


    return (
        <div className="reports-tabs">
            <div className="reports-tabs__btn-group">
                {tabButtons}
            </div>
        </div>
    );
};

export default ReportsMobileTabs;