export const updateGoogleAuthStatus = (currentUserInfo, googleAuth) => {
    return {
        ...currentUserInfo,
        google_account: googleAuth
    }
};

// hot fix of moment library which Kyiv timezone is differed from the backend (Kiev but should be Kyiv)
export const updateUserTimeZone = (userInfo = {}) => {
    if (userInfo.timezone === 'Europe/Kyiv') {
        return {...userInfo, timezone: 'Europe/Kiev'}
    }

    return userInfo;
};