import { calendarList } from "../constants";

const initialState = {
	calendarList: null,
	calendarExist: false,
	calendarListNull: true,
	calendarListErrorMessage: null,
};

export default function calendarListReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case calendarList.GET_LIST_CALENDAR_EVENTS_SUCCESS:
			return {
				...state,
				calendarList: payload,
				calendarListNull: true,
				calendarExist: true,
			};

		case calendarList.GET_LIST_CALENDAR_EVENTS_NULL:
			return {
				...state,
				calendarList: payload,
				calendarListNull: false,
			};

		case calendarList.GET_LIST_CALENDAR_EVENTS_ERROR:
			return {
				...state,
				calendarListErrorMessage: payload,
				calendarListNull: false,
			};

		case calendarList.CLEAR_CALENDAR_EVENTS_LIST:
			return {
				...state,
				calendarList: null,
				calendarExist: false,
			};

		default:
			return state;
	}
}
