import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';
import QuestionnaireMultiSelect from 'components/CustomFormSelect/QuestionnaireMultiSelect';

const MultiSelectStep = (props) => {
    const { question, options, name, item, values, goToNextStep, goToPreviousStep, stepDirection } = props;
    const { language } = useSelector(state => state.questionnaireLanguage);

    React.useEffect(() => {

        if (item?.condition) {
            let array = item.condition

            let check = array?.filter(item => {
                if (typeof values[item.key] === 'number') {
                    return values[item.key] === item.val && item
                }
                return values[item.key].includes(item.val) && item
            })

            if (!check?.length) {
                if (stepDirection === "next") {
                    goToNextStep()
                }
                if (stepDirection === "back") {
                    goToPreviousStep()
                }
            }
        }

    }, [item?.condition, goToNextStep, values, goToPreviousStep, stepDirection])

    return (
        <>
            <div className="form-question">{question}</div>
            <div className="select__container">
                <Field
                    name={name}
                    component={QuestionnaireMultiSelect}
                    options={options}
                    placeholder={language === 'it' ? 'Barra l’opzione di tua scelta' : 'Choose your option'}
                />
            </div>
            <ErrorMessage name={name} render={msg => <p className="error-message">{msg}</p>} />
        </>
    )
}

export default MultiSelectStep;