import {
	CLEAR_INDIVIDUAL_SESSION_INFO,
	CREATE_INDIVIDUAL_SESSION,
	CREATE_INDIVIDUAL_SESSION_ERROR,
	CREATE_INDIVIDUAL_SESSION_SUCCESS,
	EDIT_INDIVIDUAL_SESSION,
	EDIT_INDIVIDUAL_SESSION_ERROR,
	EDIT_INDIVIDUAL_SESSION_SUCCESS,
	GET_SESSION_TIME,
	GET_SESSION_TIME_SUCCESS,
	GET_SESSION_TIME_ERROR,
	GET_SESSION_FILTER,
	GET_SESSION_FILTER_SUCCESS,
	GET_SESSION_FILTER_ERROR,
	CLEAR_SESSION_FILTER,
	GET_COACH_ABSENCE_SCHEDULE,
	GET_COACH_ABSENCE_SCHEDULE_SUCCESS,
} from "../constants";

export const getSessionTime = (date, members) => {
	return {
		type: GET_SESSION_TIME,
		payload: { date, members },
	};
};

export const getSessionTimeSuccess = (successData) => {
	return {
		type: GET_SESSION_TIME_SUCCESS,
		payload: successData,
	};
};

export const getCoachAbsenceSchedule = (token, params) => {
	return {
		type: GET_COACH_ABSENCE_SCHEDULE,
		payload: token,
		params,
	};
};

export const getCoachAbsenceScheduleSuccess = (successData) => {
	return {
		type: GET_COACH_ABSENCE_SCHEDULE_SUCCESS,
		payload: successData,
	};
};

export const getSessionTimeError = (errorData) => {
	return {
		type: GET_SESSION_TIME_ERROR,
		payload: errorData,
	};
};

export const createSession = (requestData) => {
	return {
		type: CREATE_INDIVIDUAL_SESSION,
		payload: requestData,
	};
};

export const createSessionSuccess = (successData) => {
	return {
		type: CREATE_INDIVIDUAL_SESSION_SUCCESS,
		payload: successData.status,
	};
};

export const createSessionError = (errorData) => {
	return {
		type: CREATE_INDIVIDUAL_SESSION_ERROR,
		payload: errorData,
	};
};

export const clearSessionInfo = () => {
	return {
		type: CLEAR_INDIVIDUAL_SESSION_INFO,
	};
};

export const editSession = (sessionData) => {
	return {
		type: EDIT_INDIVIDUAL_SESSION,
		payload: sessionData,
	};
};

export const editSessionError = (errorData) => {
	return {
		type: EDIT_INDIVIDUAL_SESSION_ERROR,
		payload: errorData,
	};
};

export const editSessionSuccess = (successData) => {
	return {
		type: EDIT_INDIVIDUAL_SESSION_SUCCESS,
		payload: successData,
	};
};

export const getSessionFilter = (params) => {
	return {
		type: GET_SESSION_FILTER,
		payload: params,
	};
};

export const getSessionFilterSuccess = (successData) => {
	return {
		type: GET_SESSION_FILTER_SUCCESS,
		payload: successData,
	};
};

export const getSessionFilterError = (errorData) => {
	return {
		type: GET_SESSION_FILTER_ERROR,
		payload: errorData,
	};
};

export const clearSessionFilterError = () => {
	return {
		type: CLEAR_SESSION_FILTER,
	};
};
