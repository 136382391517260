export const GET_PROGRAMS_PROGRESS = 'GET_PROGRAMS_PROGRESS';
export const GET_PROGRAMS_PROGRESS_SUCCESS = 'GET_PROGRAMS_PROGRESS_SUCCESS';
export const GET_PROGRAMS_PROGRESS_ERROR = 'GET_PROGRAMS_PROGRESS_ERROR';

export const GET_PROGRAMS_ACTIVITIES = 'GET_PROGRAMS_ACTIVITIES';
export const GET_PROGRAMS_ACTIVITIES_SUCCESS = 'GET_PROGRAMS_ACTIVITIES_SUCCESS';
export const GET_PROGRAMS_ACTIVITIES_ERROR = 'GET_PROGRAMS_ACTIVITIES_ERROR';

export const GET_PROGRAMS_TODOS = 'GET_PROGRAMS_TODOS';
export const GET_PROGRAMS_TODOS_SUCCESS = 'GET_PROGRAMS_TODOS_SUCCESS';
export const GET_PROGRAMS_TODOS_ERROR = 'GET_PROGRAMS_TODOS_ERROR';
export const UPDATE_PROGRAMS_TODOS = 'UPDATE_PROGRAMS_TODOS';

export const GET_UPCOMING_SESSIONS = 'GET_UPCOMING_SESSIONS';
export const GET_UPCOMING_SESSIONS_SUCCESS = 'GET_UPCOMING_SESSIONS_SUCCESS';
export const GET_UPCOMING_SESSIONS_ERROR = 'GET_UPCOMING_SESSIONS_ERROR';