import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './MissingFeedbackFormNotification.scss';

const MissingFeedbackFormNotification = ({ notification }) => {

    const { show } = useSelector(state => state.missingFeedbackFormNotification)
    const link = notification?.data?.link?.split('.io')[1]

    if (show) {
        return (
            <div className='massing-feedback-from-notification'>
                <div className="notification__description">
                    {`How was the session "${notification.data.session_name}"?`}
                </div>
                <Link className='notification__link' to={link}>Submit your feedback</Link>
            </div>
        )
    }

    return null;

}

export default MissingFeedbackFormNotification;
