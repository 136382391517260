import React from 'react';
import { useHistory } from "react-router-dom";
import { ReactComponent as Arrow } from "assets/vector/arrow-back-to-programmes.svg";
import { useSelector } from "react-redux";
import PerfectMediaScroll from "components/perfectMediaScroll/PerfectMediaScroll";
import RequestSession from "components/pages/Programmes/RequestSession/RequestSession";
import {
    getUserProfileData,
    hasProgramSessions,
    hasSessionRequestDone,
    isSessionRequestAllowed
} from "helpers/userPrograms";
import { useMediaQuery } from "react-responsive";
import ProgrammeTopicsList from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/ProgrammeTopicsList/ProgrammeTopicsList";
import DesktopTopics from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/DesktopTopics/DesktopTopics";
import UserProfile from "components/pages/Programmes/SingleProgramme/UserProfile/UserProfile";

const ProgramContent = ({ programName, coachId, topicsList, handleSetActiveItem, activeListItem }) => {
    const currentProgram = useSelector(state => state.userPrograms.singleProgram) || {};
    const { id: programId, coach_id } = currentProgram;
    const { programs } = useSelector(state => state.userPrograms);
    const { role } = useSelector(state => state.currentUserInfo.currentUserInfo);
    const isRequestAllowed = isSessionRequestAllowed(programs, programId);
    const hasRequestedSession = hasSessionRequestDone(programs, programId);
    const requestButtonText = hasRequestedSession ? 'Request sent' : 'Request session';
    const hasCurrentProgramSessions = hasProgramSessions(currentProgram.topics);
    const isDesktop = useMediaQuery({ minWidth: 1194 });
    const userProfile = getUserProfileData(role, programId, programs, coach_id);
    const history = useHistory();

    const programmeTopicsList = topicsList.map((topics, index) => (
        <ProgrammeTopicsList
            key={index}
            handleSetActiveItem={handleSetActiveItem}
            activeListItem={activeListItem}
            coachId={coachId}
            topics={topics}
            isDeactivated={currentProgram?.is_deactivated}
        />
    ));

    return (
        <>
            <div className="single-programme__head">
                <div
                    onClick={history.goBack}
                    className="single-programme__go-back"
                >
                    <Arrow className="go-back-icon" />
                    {programName}
                </div>
                <UserProfile userInfo={{ programId, ...userProfile }} />
            </div>
            <div className="topics-list__outer-wrap">
                {
                    isDesktop && <DesktopTopics
                        isDeactivated={currentProgram?.is_deactivated}
                        topics={topicsList}
                        activeListItem={activeListItem}
                        coachId={coachId}
                    />
                }
                <PerfectMediaScroll mediaQuery={{ minWidth: 1194 }} className="topics-list__inner-wrap">
                    {programmeTopicsList}
                    {
                        role === 'participant'
                        &&
                        hasCurrentProgramSessions
                        &&
                        <RequestSession programId={programId}
                            isDisabled={!isRequestAllowed}
                            text={requestButtonText} />
                    }
                </PerfectMediaScroll>
            </div>
        </>
    );
};

export default ProgramContent;
