import React, { useEffect, useRef, useState } from "react";
import "./Coaches.scss";

import { Link } from "react-router-dom";
import Button from "components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import CustomMultiSelectParticipant from "components/CustomFormSelect/CustomMultiSelectParticipant";
import CustomCoachFormSelect from "components/CustomFormSelect/CustomCoachFormSelect";
import { Formik, Field, Form } from "formik";
import {
	getCoachesList,
	getNewCoachesItemsOnScroll,
	setClearCoachesFilters,
	updatePageTitle,
} from "../../../redux/actions";
import pageTitles from "constants/pageTitles";
import profile from "services/profile";
import PerfectScrollbar from "react-perfect-scrollbar";
import uniqid from "uniqid";
import search_is_nothing_found from "assets/vector/coaches/search-is-nothing-found.svg";
import defaultAva from "assets/vector/default-avatar.svg";
import { ReactComponent as CloseFilter } from "assets/vector/close-filter.svg";
import { ReactComponent as FilterToggleButton } from "assets/vector/filter-toggle-button.svg";
import { useObserver } from "hooks/useObserver";

export default function CoachesPage({ coachesData, defaultShowItems }) {
	const dispatch = useDispatch();
	const scrollSectionRef = useRef();
	const { COACHES_TITLE } = pageTitles;

	const [statusFilterToggle, setStatusFilterToggle] = useState(false);
	const [focusSearch, setFocusSearch] = useState(false);

	// offsetItems
	const userToken = useSelector((state) => state.auth.authToken);
	const coachesListCountByrefresh = useSelector(
		(state) => state.coachesList.coachesListNull
	);

	const { coachesListLoading } = useSelector((state) => state.coachesList);
	const lastElement = useRef();

	const [coachesSearchValue, setCoachesSearchValue] = useState("");
	const [offsetItems, setOffsetItems] = useState(defaultShowItems);
	const maxPages = coachesData && coachesData.total ? coachesData.total : 0;
	const [selectCoachesFiltersValue, setSelectCoachesFiltersValue] = useState({
		programs: [],
		languages: [],
		industry_background: [],
		geography: [],
		seniority_level: null,
		functional_areas: [],
		experience_years: null,
	});

	const newResultPrograms = selectCoachesFiltersValue.programs.map(function (
		ellement
	) {
		return ellement.id;
	});
	const newResultLanguagesList = selectCoachesFiltersValue.languages.map(
		function (ellement) {
			return ellement.id;
		}
	);
	const newResultIndustryBackground =
		selectCoachesFiltersValue.industry_background.map(function (ellement) {
			return ellement.id;
		});
	const newResultGeography = selectCoachesFiltersValue.geography.map(function (
		ellement
	) {
		return ellement.id;
	});
	const newResultSeniorityLevel = selectCoachesFiltersValue.seniority_level;
	const newResultFunctionalAreas =
		selectCoachesFiltersValue.functional_areas.map(function (ellement) {
			return ellement.id;
		});
	const newResultExperienceYears = selectCoachesFiltersValue.experience_years;

	const [programsOptions, setProgramsOptions] = useState(null);
	const [languageOptions, setLanguageOptions] = useState(null);
	const [geographiesOptions, setGeographiesOptions] = useState(null);
	const [industryBackgroundOptions, setIndustryBackgroundOptions] =
		useState(null);
	const [functionalAreaOptions, setFunctionalAreaOptions] = useState(null);
	const [seniorityLevelsOptions, setSeniorityLevelsOptions] = useState(null);
	const experienceYearsOptions = [
		{ id: 1, name: "1" },
		{ id: 2, name: "2" },
		{ id: 3, name: "3" },
		{ id: 4, name: "4" },
		{ id: 5, name: "5" },
		{ id: 6, name: "6" },
		{ id: 7, name: "7" },
		{ id: 8, name: "8" },
		{ id: 9, name: "9" },
		{ id: 10, name: "10" },
	];

	const [clearSelectFields, setClearSelectFields] = useState(false);

	function handleCoachesSearchChange(event) {
		setCoachesSearchValue(event.target.value);

		let getFormValue = {
			offset: 0,
			take: defaultShowItems,
			search: event.target.value,
			programs: newResultPrograms,
			languages: newResultLanguagesList,
			industry_background: newResultIndustryBackground,
			geography: newResultGeography,
			seniority_level: newResultSeniorityLevel,
			functional_areas: newResultFunctionalAreas,
			experience_years: newResultExperienceYears,
		};

		dispatch(getCoachesList(getFormValue));
	}

	const handleChangeSelect = (values) => {
		const newValuesPrograms = values.programs.map(function (ellement) {
			return ellement.id;
		});
		const newValuesLanguagesList = values.languages.map(function (ellement) {
			return ellement.id;
		});
		const newValuesIndustryBackground = values.industry_background.map(
			function (ellement) {
				return ellement.id;
			}
		);
		const newValuesGeography = values.geography.map(function (ellement) {
			return ellement.id;
		});
		const newValuesExperienceYears = values.experience_years;
		const newValuesSeniorityLevel = values.seniority_level;
		const newValuesFunctionalAreas = values.functional_areas.map(function (
			ellement
		) {
			return ellement.id;
		});

		let getFormValue = {
			offset: 0,
			take: defaultShowItems,
			search: coachesSearchValue,
			functional_areas: newValuesFunctionalAreas,
			languages: newValuesLanguagesList,
			industry_background: newValuesIndustryBackground,
			geography: newValuesGeography,
			programs: newValuesPrograms,
			seniority_level: newValuesSeniorityLevel,
			experience_years: newValuesExperienceYears,
		};

		dispatch(getCoachesList(getFormValue));
		setSelectCoachesFiltersValue(values);
	};

	const clearSearchField = () => {
		setCoachesSearchValue("");

		let getFormValue = {
			offset: 0,
			take: defaultShowItems,
			search: "",
			programs: newResultPrograms,
			languages: newResultLanguagesList,
			industry_background: newResultIndustryBackground,
			geography: newResultGeography,
			seniority_level: newResultSeniorityLevel,
			functional_areas: newResultFunctionalAreas,
			experience_years: newResultExperienceYears,
		};
		setOffsetItems(defaultShowItems);
		dispatch(getCoachesList(getFormValue));
	};

	const getDefinedData = () => {
		profile
			.getDefinedData(userToken)
			.then((response) => {
				setProgramsOptions(response.programs);
				setLanguageOptions(response.languages);
				setIndustryBackgroundOptions(response.industryBackgrounds);
				setGeographiesOptions(response.geographies);
				setFunctionalAreaOptions(response.functionalAreas);
				setSeniorityLevelsOptions(response.seniorityLevels);
			})
			.catch((error) => console.log(error));
	};

	useObserver(lastElement, offsetItems <= maxPages, coachesListLoading, () => {
		let getNewItems = {
			offset: offsetItems,
			take: defaultShowItems,
			search: coachesSearchValue,
			programs: newResultPrograms,
			languages: newResultLanguagesList,
			industry_background: newResultIndustryBackground,
			geography: newResultGeography,
			seniority_level: newResultSeniorityLevel,
			functional_areas: newResultFunctionalAreas,
			experience_years: newResultExperienceYears,
		};
		setOffsetItems(offsetItems + defaultShowItems);
		dispatch(getNewCoachesItemsOnScroll(getNewItems));
	});

	function scrollTopCoaches() {
		const currentScrollSectionRef = scrollSectionRef.current;
		if (currentScrollSectionRef) {
			currentScrollSectionRef.scrollTop = 0;
		}
	}

	const handleResetCoachesSearch = () => {
		clearSearchField();
		scrollTopCoaches();

		setClearSelectFields(true);

		let getFormValue = {
			offset: 0,
			take: defaultShowItems,
			search: coachesSearchValue,
			programs: [],
			languages: [],
			industry_background: [],
			geography: [],
			seniority_level: null,
			functional_areas: [],
			experience_years: null,
		};

		dispatch(setClearCoachesFilters(getFormValue));
		setOffsetItems(defaultShowItems);
		setSelectCoachesFiltersValue({
			programs: [],
			languages: [],
			industry_background: [],
			geography: [],
			seniority_level: null,
			functional_areas: [],
			experience_years: null,
		});
	};

	const checkClearItem = (value) => {
		if (value) {
			setOffsetItems(defaultShowItems);
		}
	};

	useEffect(() => {
		getDefinedData();
		dispatch(updatePageTitle(COACHES_TITLE));
	}, []);

	return (
		<Formik
			onReset={handleResetCoachesSearch}
			initialValues={{
				programs: [],
				languages: [],
				industry_background: [],
				geography: [],
				experience_years: null,
				seniority_level: null,
				functional_areas: [],
				search: "",
			}}
			onSubmit={(values, actions) => {
				handleChangeSelect(values, actions);
			}}
		>
			{({
				isClearable,
				handleReset,
				handleSubmit,
				handleChange,
				values,
				errors,
				touched,
				isSubmitting,
				dirty,
				setFieldValue,
				handleBlur,
				status,
				...props
			}) => {
				return (
					<Form>
						<section className="page-coaches">
							<div
								className={`coaches-filters ${
									statusFilterToggle ? "open-filter" : ""
								}`}
							>
								<div className="mobile-filter-header">
									<h3 className={`filter-title`}>Filters</h3>
									<div className="close-filter">
										<CloseFilter onClick={() => setStatusFilterToggle(false)} />
									</div>
								</div>
								<div className="filter-item coaching-programme">
									<p className={`filter-group-title`}>Coaching programme</p>

									<Field
										name="programs"
										options={programsOptions}
										defaultValue={values.programs}
										component={CustomMultiSelectParticipant}
										placeholder="All"
										customFunction={checkClearItem}
									/>
								</div>
								<div className="filter-item language">
									<p className={`filter-group-title`}>Language</p>

									<Field
										name="languages"
										options={languageOptions}
										defaultValue={values.languages}
										component={CustomMultiSelectParticipant}
										placeholder="All"
										customFunction={checkClearItem}
									/>
								</div>
								<div className="filter-item industry">
									<p className={`filter-group-title`}>Industry</p>
									<Field
										name="industry_background"
										options={industryBackgroundOptions}
										defaultValue={values.industry_background}
										component={CustomMultiSelectParticipant}
										placeholder="All"
										customFunction={checkClearItem}
									/>
								</div>
								<div className="filter-item geography">
									<p className={`filter-group-title`}>Geography</p>
									<Field
										name="geography"
										options={geographiesOptions}
										defaultValue={values.geography}
										component={CustomMultiSelectParticipant}
										placeholder="All"
										customFunction={checkClearItem}
									/>
								</div>
								<div className="filter-item experience-years">
									<p className={`filter-group-title`}>Years of experience</p>
									<Field
										name="experience_years"
										options={experienceYearsOptions}
										defaultValue={values.experience_years}
										component={CustomCoachFormSelect}
										placeholder="All"
										customFunction={checkClearItem}
										clearSelect={clearSelectFields}
										callbackClaerSelect={setClearSelectFields}
									/>
								</div>
								<div className="filter-item seniority-level">
									<p className={`filter-group-title`}>Seniority level</p>
									<Field
										name="seniority_level"
										options={seniorityLevelsOptions}
										defaultValue={values.seniority_level}
										component={CustomCoachFormSelect}
										placeholder="All"
										customFunction={checkClearItem}
										clearSelect={clearSelectFields}
										callbackClaerSelect={setClearSelectFields}
									/>
								</div>
								<div className="filter-item functional-area">
									<p className={`filter-group-title`}>Functional area</p>
									<Field
										name="functional_areas"
										options={functionalAreaOptions}
										defaultValue={values.functional_areas}
										component={CustomMultiSelectParticipant}
										placeholder="All"
										customFunction={checkClearItem}
									/>
								</div>
								<div className="clear-all-filters">
									<Button
										onClick={handleReset}
										className="clear-btn"
										type="reset"
										disabled={!isSubmitting}
									>
										Clear all filters
									</Button>
								</div>
							</div>
							<div className="coaches-list">
								<div className="coaches-filter-search">
									<h2>Coaches list</h2>
									<div className="search">
										<input
											name="coaches-search"
											onFocus={() => setFocusSearch(!focusSearch)}
											onBlur={() => setFocusSearch(!focusSearch)}
											onChange={handleCoachesSearchChange}
											type="text"
											value={coachesSearchValue}
											placeholder={`Key word for search...`}
										/>
										<div className={focusSearch ? "icon active" : "icon"}>
											<svg
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M2.875 8.875C2.875 5.56129 5.56129 2.875 8.875 2.875C12.1887 2.875 14.875 5.56129 14.875 8.875C14.875 12.1887 12.1887 14.875 8.875 14.875C5.56129 14.875 2.875 12.1887 2.875 8.875ZM8.875 0.625C4.31865 0.625 0.625 4.31865 0.625 8.875C0.625 13.4313 4.31865 17.125 8.875 17.125C10.7475 17.125 12.4744 16.5012 13.859 15.45L17.4545 19.0455L19.0455 17.4545L15.45 13.859C16.5012 12.4744 17.125 10.7475 17.125 8.875C17.125 4.31865 13.4313 0.625 8.875 0.625Z"
													fill="#929292"
												/>
											</svg>
										</div>
										<div className="filter-toggle-block">
											<FilterToggleButton
												className={`toggle-filter`}
												onClick={() => setStatusFilterToggle(true)}
											/>
										</div>
									</div>
								</div>
								{coachesData &&
								coachesListCountByrefresh &&
								coachesData.coaches.length > 0 ? (
									<PerfectScrollbar
										containerRef={(el) => (scrollSectionRef.current = el)}
										className="list"
									>
										{coachesData.coaches.map((element) => (
											<Link
												className="link__wrapper"
												to={`./coaches/${element.id}`}
												key={uniqid()}
											>
												<div className="item">
													<div className="coach-avatar">
														<img
															src={element.avatar ? element.avatar : defaultAva}
															alt=""
														/>
													</div>
													<div className="coach-short-info">
														<div
															className="name"
															to={`./coaches/${element.id}`}
														>
															{element.name}
														</div>
														<p className={`country`}>{element.location}</p>
														<ul className="coach-experience">
															{element.industry_background.map(function (el) {
																return <li key={uniqid()}>{el.name}</li>;
															})}
														</ul>
													</div>
													<div className="coach-full-info">
														<Link
															className="view-profile"
															to={`./coaches/${element.id}`}
														>
															View profile
														</Link>
													</div>
												</div>
											</Link>
										))}
										<div ref={lastElement}></div>
									</PerfectScrollbar>
								) : (
									<div className="empty-list">
										<img
											className={`search-is-nothing-found`}
											src={search_is_nothing_found}
											alt=""
										/>
										{coachesSearchValue !== "" && (
											<>
												<h2 className={`searched-value`}>
													Search request{" "}
													<span className={`text`}>“{coachesSearchValue}”</span>{" "}
													is nothing found
												</h2>

												<div
													className="clear-search-field"
													onClick={handleReset}
												>
													Back to all coaches list
												</div>
											</>
										)}
										<div ref={lastElement}></div>
									</div>
								)}
							</div>
						</section>
					</Form>
				);
			}}
		</Formik>
	);
}
