import React from 'react';
import './StarRating.scss';
import StarIcon from "components/icons/StarIcon";


const StarRating = ({rating, className = ''}) => {
    const classNames = `star-rating ${className}`
    const maxStars = 5;
    const renderRating = () => {
        let count = 0;
        let totalRating = [];

        while (count < maxStars) {
            if (count < rating) {
                totalRating.push(<StarIcon className="star-rating__icon" color="#ECAF2B" key={count}/>);
            } else {
                totalRating.push(<StarIcon className="star-rating__icon" key={count}/>);
            }
            count++;
        }

        return totalRating;
    }

    return (
        <div className={classNames}>
            {renderRating()}
        </div>
    );
};

export default StarRating;