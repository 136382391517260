import React from 'react';

import { useField, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';

const TextInputStep = ({ label, values, item, question, goToNextStep, goToPreviousStep, stepDirection, ...props }) => {

    const [field] = useField(props);

    const { name } = props;

    const { language } = useSelector(state => state.questionnaireLanguage);

    React.useEffect(() => {

        if (item?.condition) {
            let array = item.condition

            let check = array?.filter(item => {
                if (typeof values[item.key] === 'number') {
                    return values[item.key] === item.val && item
                }
                return values[item.key].includes(item.val) && item
            })

            if (!check?.length) {
                if (stepDirection === "next") {
                    goToNextStep()
                }
                if (stepDirection === "back") {
                    goToPreviousStep()
                }
            }
        }

    }, [item?.condition, goToNextStep, values, goToPreviousStep, stepDirection])

    return (
        <>
            <div className="text-input-step">
                <div className="form-question">{question}</div>
                <input className="" type="text" id="text" placeholder={language === 'it' ? 'Inserisci la risposta...' : 'Enter your answer...'} {...field} {...props} />
            </div>
            <ErrorMessage name={name} render={msg => <p className="error-message">{msg}</p>} />
        </>
    )
}

export default TextInputStep;