import axios from "axios";
import { GENERATE_COACH_INVOICE_ROUTE } from "constants/api";
import ResponseError from "helpers/ResponseError";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { openModal, showNotification } from "../../../../../redux/actions";
import { GENERATE_INVOICE_MODAL } from "../../../../../redux/constants/modal";
import "./GenerateInvoice.scss";

const GenerateInvoice = ({ isAllowedGeneration, changeActiveTab }) => {
	const dispatch = useDispatch();
	const { authToken } = useSelector((state) => state.auth);

	const generateInvoice = () => {
		axios({
			method: "post",
			url: GENERATE_COACH_INVOICE_ROUTE,
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
			accept: "application/json",
		})
			.then((response) => {
				if (response.data.status === "Success") {
					const modalData = {
						invoices: response.data.data,
						changeActiveTab: changeActiveTab,
					};

					dispatch(openModal(GENERATE_INVOICE_MODAL, modalData));
				}
			})
			.catch((error) => {
				const responseError = new ResponseError(error.response.data);

				dispatch(
					showNotification({
						title: responseError.getStatus(),
						text: responseError.getValidationErrorMessages(),
					})
				);
			});
	};

	return (
		<button
			className="generate-invoice"
			onClick={() => generateInvoice()}
			disabled={!isAllowedGeneration}
		>
			Generate billing report
		</button>
	);
};

export default GenerateInvoice;
