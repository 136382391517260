import React from 'react';

// forILTB
// import image1 from 'assets/images/Questionnaire/Frame_1247.png';
// import image2 from 'assets/images/Questionnaire/Group.png';

const LeftCardDescription = ({role}) => {

    if (role === "coach") {
        return (
            <p><span>The Perfect Match:</span> We use your answers to know you better and have the best possible coach-coachee matches.</p>
        )
    }
    return (
        <>
            <p><span>The Perfect Match:</span> We use your answers to get to know you and match you with the best possible coach.</p>
            <p>
                <span>The Perfect Coaching Programme:</span> Everyone has different aims and needs. Your responses empower your coach to tailor your coaching programme and its sessions to YOUR needs.
            </p>
        </>
    )
}

const Cards = ({ role }) => {

    return (
        <div className="questionnaire__cards">
            <div className="left-card card">
                {/* for ILTB */}
                {/* <div className="card__image">
                        <img src={image2} alt="" />
                    </div> */}
                <div className="card__title">Why Take This Questionnaire?</div>
                <div className="card__description">
                    <LeftCardDescription role={role}/>
                </div>
            </div>
            <div className="right-card card">
                {/* for ILTB */}
                {/* <div className="card__image">
                        <img src={image1} alt="" />
                    </div> */}
                <div className="card__title">Important:</div>
                <div className="card__description">
                    <p>While the survey is not timed, you must complete it in one sitting. Ensure you have a good internet connection and enough battery before starting.</p>
                    <p>If you have any questions, doubts or concerns contact: <a className="card__link" href="mailto:support@switchapp.io">support@switchapp.io</a></p>
                </div>
            </div>
        </div>
    )
}

export default Cards;