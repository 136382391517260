export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

//* MODAL TYPES
export const SESSION_EVENT_MODAL = `SESSION_EVENT_MODAL`;
export const DECLINE_MODAL = `DECLINE_MODAL`;
export const CREATE_SESSION_MODAL = `CREATE_SESSION_MODAL`;
export const CHOOSE_COACH_MODAL = 'CHOOSE_COACH_MODAL';
export const FIND_ANOTHER_COACH_MODAL = 'FIND_ANOTHER_COACH_MODAL';
export const GOOGLE_CANCEL_AUTH_MODAL = 'GOOGLE_CANCEL_AUTH_MODAL';
export const SESSION_REQUEST_MODAL = 'SESSION_REQUEST_MODAL';
export const CALENDAR_EVENT_MODAL = `CALENDAR_EVENT_MODAL`;
export const EDIT_SCHEDULE_MODAL = `EDIT_SCHEDULE_MODAL`;
export const CAREER_COACHING_QUESTIONNAIRE_MODAL = 'CAREER_COACHING_QUESTIONNAIRE_MODAL';
export const QUESTIONNAIRE_LANGUAGE_MODAL = 'QUESTIONNAIRE_LANGUAGE_MODAL';
export const GENERATE_INVOICE_MODAL = 'GENERATE_INVOICE_MODAL';
