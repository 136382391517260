export const italianDescription = (userRole) => {
    if (userRole === 'coach') {
        return `Molte delle seguenti domande riguardano gli ambiti in cui hai maturato interesse ed esperienza.
        Seleziona un ambito soltanto se hai una significativa esperienza di lavoro pertinente a esso o sei stato coach di molte persone operanti in quell’ambito.`
    }

    return `Le seguenti domande ti richiedono di valutare quanto una determinata affermazione è vera nel tuo caso.
    Se ti sembra che la domanda non sia applicabile alla tua situazione attuale,
    per rispondere fai riferimento alla tua esperienza (o esperienze) più significativa in proposito.
    Per esempio, se la domanda riguarda un contesto professionale, rispondi in relazione alla tua esperienza lavorativa più significativa in quel contesto,
    anche se si tratta di un’esperienza passata. Alcune domande prevedono l’opzione “Preferisco non rispondere”,
    tuttavia una risposta sincera a tutte le domande permette il migliore abbinamento possibile con il tuo coach,
    che potrà inoltre redigere una relazione più accurata sull’esperienza di coaching.`

}

export const englishDescription = (userRole) => {
    if (userRole === "coach") {
        return `Many of the following questions ask in which areas you have interest and expertise.
        Please select an area only if you have significant relevant work experience or if you have worked extensively with coachees in it.`
    }
    if (userRole === "participant") {
        return `The following questions ask you to rate how true a statement is for you.
        If a question seems not to apply to your current situation, consider the most significant experience(s) to which it can apply.
        For instance, if you receive a question related to a professional setting,
        answer while considering your most significant work experience even if it is in the past.
        While some questions have a "prefer not to answer" option, choosing to answer all questions truthfully ensures the best possible coaching match and the most accurate coaching report.`
    }
}