export const notifications = {
    emailRequired: `email is not provided`,
    emailFormatWrong: `Email address is incorrect`,
    passwordRequired: `password is not provided`,
    passwordFormatWrong: `Password: 8 chars min, upper, lower letter, digit and symbol: @$!%*#?&_`,
    confirmationMissmatch: `Passwords do not match`,
    tokenRequired: `token from email must be provided`,
    wrongRegisterLink: `means that system can't find program via that code and ID`,
    passwordConfirmationNeeded: `You are not allowed to join the programme`,
    wrongCredentials: `Password or email are incorrect`,
    access_denied: `You can't perform this action. Try again or contact us`,
    request_unauthorized: `Password or email are incorrect`,
    cantCreateNewUser: `means internal error - system can't create new user`,
    nameReuired: `name must be provided to register new user`,
    surnameRequired: `surname must be provided to register new user`,
    cantResetPassword: `something wrong with token or email`,
    internalError: `internal server error`,
    generalMessage: 'Unknown request error',
    wrongSchool: `You are not allowed to join the programme. Please, contact your administrator.`,
    emailAlreadyExists: `User with this email already registered. Please, go to the login page or reset your password.`,
    participantProgramNotFound: `No program found`
}

export const getNotificationMessage = (message) => {
    switch (message) {
        case 'request_unauthorized':
            return notifications.request_unauthorized;
        case 'emailRequired':
            return notifications.emailRequired;
        case 'emailFormatWrong':
            return notifications.emailFormatWrong;
        case 'wrongCredentials':
            return notifications.wrongCredentials;
        case 'generalMessage':
            return notifications.generalMessage;
        case 'emailAlreadyExists':
            return notifications.emailAlreadyExists;
        case 'passwordConfirmationNeeded':
            return notifications.passwordConfirmationNeeded;
        case 'wrongSchool':
            return notifications.wrongSchool;
        case 'participantProgramNotFound':
            return notifications.participantProgramNotFound;
        default:
            return message;
    }
}