const getTransformDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return (year + '-' + month + '-' + dt)
}

const getTransformTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    if (hours < 10) {
        hours = '0' + hours;
    }

    return (hours + ':' + minutes)
}

const getTransformTimeOff = (data) => {
    if (data?.length > 0) {
        const timeOff = data?.map(item => {
            return {
                start: `${getTransformDate(item.start_day)} ${getTransformTime(item.start_time)}`,
                end: `${getTransformDate(item.end_day)} ${getTransformTime(item.end_time)}`,
            }
        })

        return JSON.stringify(timeOff);
    }

    return '';
}

const transformValuesBeforeSending = (values, dailyBreak) => {
    const newValues = {
        break_days: values.break_days,
        break_days_time: JSON.stringify([
            {
                start: getTransformTime(values.break_days_start),
                end: getTransformTime(values.break_days_end)
            }
        ]),
        daily_break_time: "",
        time_off: getTransformTimeOff(values.time_off)
    }

    if (dailyBreak) {
        newValues.daily_break_time = JSON.stringify([
            {
                start: getTransformTime(values.daily_break_start),
                end: getTransformTime(values.daily_break_end),
            }
        ])
    }

    if (values.break_days.length === 0) {
        newValues.break_days = "";
        newValues.break_days_time = "";
    }

    return newValues;
}

export { transformValuesBeforeSending, getTransformDate };
