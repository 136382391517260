import Spinner from "components/Spinner/Spinner";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Scrollbar from "react-scrollbars-custom";
import { getProgramsProgress } from "../../../../../redux/actions";
import ProgramCard from "./ProgramCard/ProgramCard";
import "./ProgramsProgress.scss";

const ProgramsProgress = () => {
	const dispatch = useDispatch();
	const { programsProgress, programsProgressLoading } = useSelector(
		(state) => state.participantDashboard
	);

	useEffect(() => {
		dispatch(getProgramsProgress());
	}, []);

	return (
		<div className="programs-progress">
			<div className="programs-progress__title">Programme progress</div>
			<div className="programs-progress__programs-list">
				{programsProgressLoading ? (
					<Spinner size={50} />
				) : (
					<Scrollbar
						className="custom-scroll__wrapper"
						trackyelement={null}
						translateContentSizesToHolder
						disableTracksWidthCompensation
					>
						{programsProgress.length > 0 &&
							programsProgress?.map((program) => (
								<ProgramCard
									key={program?.id}
									id={program?.id}
									logoUrl={program?.logo_url}
									name={program?.name}
									topicsCount={program.topics_count}
									sessions={program.sessions}
									coach={program.coach}
								/>
							))}
					</Scrollbar>
				)}
			</div>
		</div>
	);
};

export default ProgramsProgress;
