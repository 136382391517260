import React from 'react';

const CheckIcon = ({color = 'white', width = 32, height = 32, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 0C7.15207 0 0 7.15207 0 16C0 24.8479 7.15207 32 16 32C24.8479 32 32 24.8479 32 16C32 7.15207 24.8479 0 16 0ZM23.7419 12.682L14.2673 22.1198C13.9724 22.4147 13.6037 22.5622 13.235 22.5622C12.8664 22.5622 12.4608 22.4147 12.2028 22.1198L7.52074 17.4747C6.93088 16.8848 6.93088 15.9631 7.52074 15.3733C8.1106 14.7834 9.03226 14.7834 9.62212 15.3733L13.235 18.9862L21.6406 10.5806C22.2304 9.99078 23.1521 9.99078 23.7419 10.5806C24.2949 11.1705 24.2949 12.129 23.7419 12.682Z" fill={color}/>
        </svg>
    );
};

export default CheckIcon;