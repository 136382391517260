import React from 'react';
import fallbackAvatar from "assets/vector/default-avatar.svg";
import RemoveRoundIcon from "components/icons/RemoveRoundIcon";
import {calculateTimeWithZone} from "components/pages/CalendarPage/helpers/timeTransforms";
import {transformDateAndTime} from "helpers/createSession";

const Participant = ({participant, removeParticipant, handleRemoveParticipant, toggleParticipantInfo, hoverUser, sessionTime = {}}) => {
    const {id, avatar, name, invite_status, timezone} = participant;
    const hasDeclinedSession = invite_status === 'declined';
    const transformedSelectedDate = transformDateAndTime(sessionTime.date, sessionTime.time, sessionTime.timezone);
    const {userHours, userUTC} = calculateTimeWithZone(transformedSelectedDate, {timezone}, {timezone: sessionTime.timezone});

    const isNightTime = () => {
        const nightStart = 22.00;
        const nightEnd = 7.00;
        const userTime = +userHours?.replace(':', '.');

        return userTime >= nightStart || userTime <= nightEnd;
    };


    return (
        <li className="invited-participants__user"
            onMouseEnter={() => toggleParticipantInfo(participant.id)}
            onMouseLeave={() => toggleParticipantInfo()}
        >
            <img src={avatar ? avatar : fallbackAvatar}
                 alt={`user ${name} avatar`}
                 className={`invited-participants__user-img ${hasDeclinedSession ? 'is-declined' : ''}`}/>
            <p className='invited-participants__user-name'>{name}</p>
            {
                hasDeclinedSession && (
                    <p className='invited-participants__user-status'>
                        Declined
                    </p>
                )
            }
            <p className="invited-participants__user-zone">
                UTC{userUTC} <span className={`hours ${isNightTime() ? 'alert' : ''}`}>{userHours}</span>
            </p>

            {
                removeParticipant && (
                    <RemoveRoundIcon className="invited-participants__remove"
                                     color={hoverUser === id ? 'white' : '#929292'}
                                     background={hoverUser === id ? '#2E79DA' : 'white'}
                                     onClick={() => handleRemoveParticipant(id)}
                    />
                )
            }
        </li>
    );
};

export default Participant;