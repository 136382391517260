import { programParticipantsList } from "../constants";

const initialState = {
	programParticipantsList: [],
	programParticipantsExist: false,
	programParticipantsLoading: false,
	programParticipantsListNull: true,
	programParticipantsListErrorMessage: null,
};

export default function programParticipantsListReducer(
	state = initialState,
	action
) {
	const { type, payload } = action;

	switch (type) {
		case programParticipantsList.GET_LIST_PROGRAM_PARTICIPANTS:
			return {
				...state,
				programParticipantsLoading: true,
			};
		case programParticipantsList.GET_LIST_PROGRAM_PARTICIPANTS_SUCCESS:
			return {
				...state,
				programParticipantsList: payload,
				programParticipantsListNull: false,
				programParticipantsExist: true,
				programParticipantsLoading: false,
			};

		case programParticipantsList.GET_LIST_PROGRAM_PARTICIPANTS_NULL:
			return {
				...state,
				programParticipantsList: null,
				programParticipantsListNull: true,
				programParticipantsLoading: false,
			};

		case programParticipantsList.GET_LIST_PROGRAM_PARTICIPANTS_ERROR:
			return {
				...state,
				programParticipantsListErrorMessage: payload,
				programParticipantsListNull: true,
				programParticipantsLoading: false,
			};

		case programParticipantsList.CLEAR_PROGRAM_PARTICIPANTS_FILTERS:
			return {
				...state,
				programParticipantsList: null,
				programParticipantsExist: false,
				programParticipantsLoading: false,
			};

		default:
			return state;
	}
}
