export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SET_NOTIFICATION_CONTENT = 'SET_NOTIFICATION_CONTENT';
export const CLEAR_NOTIFICATION_CONTENT = 'CLEAR_NOTIFICATION_CONTENT';

export const dashboardNotification = {
    DASHBOARD_NOTIFICATION_OPEN: "DASHBOARD_NOTIFICATION_OPEN",
    DASHBOARD_NOTIFICATION_STATUS: "DASHBOARD_NOTIFICATION_STATUS",

    GET_NOTIFICATIONS : 'GET_NOTIFICATIONS',
    GET_NOTIFICATIONS_ON_SCROLL : 'GET_NOTIFICATIONS_ON_SCROLL',
    GET_NOTIFICATIONS_SUCCESS : 'GET_NOTIFICATIONS_SUCCESS',
    GET_NOTIFICATIONS_ERROR : 'GET_NOTIFICATIONS_ERROR',
    GET_NOTIFICATIONS_EMPTY : 'GET_NOTIFICATIONS_EMPTY',

    SET_REMOVE_NOTIFICATIONS : 'SET_REMOVE_NOTIFICATIONS',
    SET_NOTIFICATIONS_READ_ALL: 'SET_NOTIFICATIONS_READ_ALL',
    GET_NOTIFICATIONS_UNREAD_COUNT: 'GET_NOTIFICATIONS_UNREAD_COUNT',
    SET_NOTIFICATIONS_COUNT: 'SET_NOTIFICATIONS_COUNT',
    LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
    REMOVE_ITEM_NOTIFICATION: 'REMOVE_ITEM_NOTIFICATION',
    CLEAR_ALL_NOTIFICATION: 'CLEAR_ALL_NOTIFICATION',
    CLEAR_ON_LOGOUT_ALL_DATA_NOTIFICATIONS: 'CLEAR_ON_LOGOUT_ALL_DATA_NOTIFICATIONS',

    SEND_READ_NOTIFICATIONS: 'SEND_READ_NOTIFICATIONS',
    SET_TOTAL_NOTIFICATIONS: 'SET_TOTAL_NOTIFICATIONS',

    SEND_ACCEPT_NOTIFICATIONS: 'SEND_ACCEPT_NOTIFICATIONS',
    SEND_DECLINE_NOTIFICATIONS: 'SEND_DECLINE_NOTIFICATIONS',


};




