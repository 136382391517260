import React from 'react';
import {ReactComponent as Image} from "assets/vector/programmes/cancel-session.svg";
import Button from "components/button/Button";

const DefaultModal = ({closeModal, modalData}) => {
    return (
        <div className="decline-modal">
            <Image className="decline-modal__img"/>
            <h2 className="decline-modal__title">
                {modalData?.title}
            </h2>
            {
                modalData?.description && (
                    <p className="decline-modal__text">
                        {modalData.description}
                    </p>
                )
            }
            <div className="decline-modal__actions">
                <Button type="button"
                        className="button button--orange"
                        onClick={closeModal}
                >
                    OK
                </Button>
            </div>
        </div>
    );
};

export default DefaultModal;