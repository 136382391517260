export default function getStyles(styles = {}) {
    return [
        { '--background': styles.gen_background },
        { '--loader-color': styles.gen_loader_color },
        { '--active_menu': styles.sidebar_active_menu },
        { '--reports-sorting_bg': styles.reports_table_header },
        { '--session_late_canceled': styles.session_late_canceled },
        { '--session_confirmed': styles.session_confirmed },
        { '--session_pending': styles.session_pending },
        { '--opened_activity': styles.opened_activity },
        { '--completed_activity': styles.completed_activity },
        { '--db_active': styles.db_active },
        { '--db_average_mark': styles.db_average_mark },
        { '--db_booked_session': styles.db_booked_session },
        { '--db_btn_bg_color': styles.db_btn_bg_color },
        { '--db_completed_session': styles.db_completed_session },
        { '--db_confirmed_session': styles.db_confirmed_session },
        { '--db_assigned_session': styles.db_assigned_session },
        { '--db_not_assigned_session': styles.db_not_assigned_session },
        { '--db_not_attended_session': styles.db_not_attended_session },
        { '--db_session_credits_purchased': styles.db_session_credits_purchased },
        { '--db_session_credits_used': styles.db_session_credits_used },
        { '--db_session_credits_available': styles.db_session_credits_available },
        { '--db_session_credits_booked': styles.db_session_credits_in_progress },
        { '--db_deactivated': styles.db_deactivated },
        { '--db_disengaged': styles.db_disengaged },
        { '--db_attendance_rate_late_cancelled': styles.db_attendance_rate_late_cancelled },
        { '--db_attendance_rate_planed': styles.db_attendance_rate_planed },
        { '--db_attendance_rate_completed': styles.db_attendance_rate_completed },
        { '--db_session_frequency': styles.db_session_frequency },
        { '--db_satisfaction_bad': styles.db_satisfaction_bad },
        { '--db_satisfaction_good': styles.db_satisfaction_good },
        { '--db_satisfaction_level': styles.db_satisfaction_level },
        { '--db_satisfaction_neutral': styles.db_satisfaction_neutral },
        { '--db_total': styles.db_total },
        { '--gen_button_color': styles.gen_buttons },
        { '--gen_font_color': styles.gen_font_color },
        { '--db_satisfaction_mark_1': styles.db_satisfaction_by_session_average_mark_1 },
        { '--db_satisfaction_mark_2': styles.db_satisfaction_by_session_average_mark_2 },
        { '--db_satisfaction_mark_3': styles.db_satisfaction_by_session_average_mark_3 },
        { '--db_satisfaction_mark_4': styles.db_satisfaction_by_session_average_mark_4 },
        { '--db_satisfaction_mark_5': styles.db_satisfaction_by_session_average_mark_5 },
        { '--db_satisfaction_mark_6': styles.db_satisfaction_by_session_average_mark_6 },
        { '--db_satisfaction_mark_7': styles.db_satisfaction_by_session_average_mark_7 },
        { '--db_satisfaction_mark_8': styles.db_satisfaction_by_session_average_mark_8 },
        { '--db_satisfaction_mark_9': styles.db_satisfaction_by_session_average_mark_9 },
    ];
}