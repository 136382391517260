import React from 'react';

const DecisionIcon = ({color = '#2E79DA', width = 40, height = 40, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9691_16998)">
                <path d="M15.3125 15.3125H8.20312V12.971V12.9688L8.20258 12.9692C8.20078 11.9577 7.00445 11.4227 6.25 12.0975L0.46875 16.7186C-0.15625 17.1874 -0.15625 18.1249 0.46875 18.5937L6.25 23.2149C7.00445 23.8897 8.20078 23.3549 8.2025 22.3433L8.20312 22.3438V22.3415V20H15.3125C16.607 20 17.6562 21.0493 17.6562 22.3438V38.8281C17.6562 39.4753 18.1809 40 18.8281 40H21.1719C21.8191 40 22.3438 39.4753 22.3438 38.8281V22.3438C22.3438 18.4605 19.1958 15.3125 15.3125 15.3125Z" fill="#2E79DA"/>
                <path d="M39.5312 23.7501L33.75 19.1288C32.9955 18.454 31.7992 18.9889 31.7975 20.0004L31.7969 20V20.0023V22.3438H24.6875V27.0313H31.7969V29.3728V29.375L31.7974 29.3746C31.7991 30.3861 32.9955 30.921 33.7499 30.2463L39.5312 25.6252C40.1562 25.1564 40.1562 24.2189 39.5312 23.7501Z" fill={color}/>
                <path d="M25.5586 6.25L20.9375 0.46875C20.4688 -0.15625 19.5312 -0.15625 19.0624 0.46875L14.4413 6.25C13.7661 7.005 14.302 8.20305 15.3147 8.20305H17.6562V13.3016C19.5069 13.7828 21.1215 14.8096 22.3437 16.2087V8.20312H24.6851C25.698 8.20312 26.2338 7.005 25.5586 6.25Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_9691_16998">
                    <rect width="40" height="40" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default DecisionIcon;
