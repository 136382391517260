import React from "react";
import Select, { components } from 'react-select';
import DownArrowIcon from "components/icons/DownArrowIcon";
import { customSelectStyles as selectStyles } from "helpers/customSelectStyles";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <DownArrowIcon />
        </components.DropdownIndicator>
    );
};

const CustomCategoryEditSelect = ({ name, category, setCategory, options, error, className = 'custom-select', placeholder }) => {
    const classNameWithError = `${className}--error`;

    if (error) {
        className = `${className} ${classNameWithError}`;
    }

    const onChangeSelect = (optionValue) => {
        setCategory(optionValue);
    };

    return (
        <>
            <Select
                components={{ DropdownIndicator }}
                name={name}
                options={options}
                className={className}
                placeholder={placeholder}
                classNamePrefix="category-editor__select"
                styles={selectStyles}
                value={category}
                isSearchable={false}
                isClearable={true}
                maxMenuHeight={285}
                onChange={(optionValue) => onChangeSelect(optionValue)}
            />
            {
                error && <div className='category-editor__error'>{error}</div>
            }
        </>
    )
};

export default CustomCategoryEditSelect;