import {
	CLEAR_REPORTS_DATA,
	GET_EVALUATION_REPORTS,
	GET_EVALUATION_REPORTS_SUCCESS,
	GET_EVALUATION_REPORTS_EMPTY,
	GET_FEEDBACK_REPORTS,
	GET_FEEDBACK_REPORTS_SUCCESS,
	GET_FEEDBACK_REPORTS_EMPTY,
	GET_REPORTS_ERROR,
	GET_ENGAGEMENT_REPORTS,
	GET_ENGAGEMENT_REPORTS_SUCCESS,
	GET_ENGAGEMENT_REPORTS_EMPTY,
	GET_BILLING_OVERVIEW_REPORTS,
	GET_BILLING_OVERVIEW_REPORTS_EMPTY,
	GET_BILLING_OVERVIEW_REPORTS_SUCCESS,
	ADD_SELECTED_REPORTS,
	ADD_SELECTED_FILTER_OPTIONS,
	SET_SORTING_OPTION_AND_ORDER,
	ADD_SEARCH_QUERY,
	GET_REPORT,
	GET_REPORT_ERROR,
	GET_REPORT_SUCCESS,
	CLEAR_REPORT_DATA,
	GET_COACH_INVOICES,
	GET_COACH_INVOICES_SUCCESS,
	GET_COACH_INVOICES_EMPTY,
} from "../constants/reports";

// EVALUATION reports
export const getEvaluationReports = (requestData, params) => {
	return {
		type: GET_EVALUATION_REPORTS,
		payload: requestData,
		params,
	};
};

export const getEvaluationReportsSuccess = (successData) => {
	return {
		type: GET_EVALUATION_REPORTS_SUCCESS,
		payload: successData,
	};
};

export const getEvaluationReportsEmpty = (emptyData) => {
	return {
		type: GET_EVALUATION_REPORTS_EMPTY,
		payload: emptyData,
	};
};

// FEEDBACK reports
export const getFeedbackReports = (requestData, params) => {
	return {
		type: GET_FEEDBACK_REPORTS,
		payload: requestData,
		params,
	};
};

export const getFeedbackReportsSuccess = (successData) => {
	return {
		type: GET_FEEDBACK_REPORTS_SUCCESS,
		payload: successData,
	};
};

export const getFeedbackReportsEmpty = (emptyData) => {
	return {
		type: GET_FEEDBACK_REPORTS_EMPTY,
		payload: emptyData,
	};
};

// BILLING OVERVIEW reports
export const getBillingOverviewReports = (requestData, params) => {
	return {
		type: GET_BILLING_OVERVIEW_REPORTS,
		payload: requestData,
		params,
	};
};

export const getBillingOverviewSuccess = (successData) => {
	return {
		type: GET_BILLING_OVERVIEW_REPORTS_SUCCESS,
		payload: successData,
	};
};

export const getBillingOverviewEmpty = (emptyData) => {
	return {
		type: GET_BILLING_OVERVIEW_REPORTS_EMPTY,
		payload: emptyData,
	};
};

// coach invoices
export const getCoachInvoices = (requestData, params) => {
	return {
		type: GET_COACH_INVOICES,
		payload: requestData,
		params,
	};
};

export const getCoachInvoicesSuccess = (successData) => {
	return {
		type: GET_COACH_INVOICES_SUCCESS,
		payload: successData,
	};
};

export const getCoachInvoicesEmpty = (emptyData) => {
	return {
		type: GET_COACH_INVOICES_EMPTY,
		payload: emptyData,
	};
};

// ENGAGEMENT reports
export const getEngagementReports = (requestData, params) => {
	return {
		type: GET_ENGAGEMENT_REPORTS,
		payload: requestData,
		params,
	};
};

export const getEngagementReportsSuccess = (successData) => {
	return {
		type: GET_ENGAGEMENT_REPORTS_SUCCESS,
		payload: successData,
	};
};

export const getEngagementReportsEmpty = (emptyData) => {
	return {
		type: GET_ENGAGEMENT_REPORTS_EMPTY,
		payload: emptyData,
	};
};

export const getReportsError = (errorData) => {
	return {
		type: GET_REPORTS_ERROR,
		payload: errorData,
	};
};

export const getReport = (reportId) => {
	return {
		type: GET_REPORT,
		payload: reportId,
	};
};

export const clearReportData = () => {
	return {
		type: CLEAR_REPORT_DATA,
	};
};

export const getReportError = (errorData) => {
	return {
		type: GET_REPORT_ERROR,
		payload: errorData,
	};
};

export const getReportSuccess = (successData) => {
	return {
		type: GET_REPORT_SUCCESS,
		payload: successData,
	};
};

export const clearReportsData = () => {
	return {
		type: CLEAR_REPORTS_DATA,
	};
};

export const addSelectedReports = (selectedReports) => {
	return {
		type: ADD_SELECTED_REPORTS,
		payload: selectedReports,
	};
};

export const addSearchQuery = (searchQuery) => {
	return {
		type: ADD_SEARCH_QUERY,
		payload: searchQuery,
	};
};

export const addSelectedFilterOptions = (selectedFilterOptions) => {
	return {
		type: ADD_SELECTED_FILTER_OPTIONS,
		payload: selectedFilterOptions,
	};
};

export const setReportsSortingOptionAndOrder = (sortingOptionAndOrder) => {
	return {
		type: SET_SORTING_OPTION_AND_ORDER,
		payload: sortingOptionAndOrder,
	};
};
