import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TotalCredits from "../Charts/TotalCredits/TotalCredits";
import {
	numberOfSessionsChartData,
	participantsSatisfactionChartData,
} from "../helpers/pieChartsData";
import PieChartComponent from "../PieChartComponent/PieChartComponent";
import { useMediaQuery } from "react-responsive";
import SelectProgramm from "components/icons/SelectProgramm";
import "./SchoolOverview.scss";
import Spinner from "components/Spinner/Spinner";
import { getSchoolOverviewGraphs } from "../../../../../redux/actions";

const SchoolOverview = () => {
	const dispatch = useDispatch();
	const [hideCharts, setHideCharts] = useState();
	const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

	const schoolOverviewData = useSelector(
		(state) => state.dashboard.schoolOverviewData
	);
	const schoolOverviewLoading = useSelector(
		(state) => state.dashboard.schoolOverviewLoading
	);

	useEffect(() => {
		setHideCharts(isMobile);
	}, [isMobile]);

	useEffect(() => {
		if (!schoolOverviewData) {
			dispatch(getSchoolOverviewGraphs());
		}
	}, [schoolOverviewData]);

	return (
		<div className="school-overview">
			<h2 className="section__title">Institution overview</h2>
			{!schoolOverviewLoading ? (
				<>
					<TotalCredits credits={schoolOverviewData?.total_credits} />
					{!hideCharts && schoolOverviewData && (
						<div className="pie-charts__section">
							<PieChartComponent
								title="Number of sessions"
								chartTitle={`${schoolOverviewData?.number_of_sessions?.completed_percent}%`}
								chartSubtitle={
									<>
										Used
										<br />
										sessions
									</>
								}
								pieChartData={numberOfSessionsChartData(
									schoolOverviewData
								).reverse()}
								statisticData={numberOfSessionsChartData(schoolOverviewData)}
							/>
							<PieChartComponent
								title="Participants satisfaction"
								chartTitle={`${schoolOverviewData?.participant_satisfaction?.amazing_percent}%`}
								chartSubtitle="Satisfied"
								pieChartData={participantsSatisfactionChartData(
									schoolOverviewData
								).reverse()}
								statisticData={participantsSatisfactionChartData(
									schoolOverviewData
								)}
							/>
						</div>
					)}
					{isMobile && (
						<div
							className="show-charts__button"
							onClick={() => setHideCharts((prev) => !prev)}
						>
							<span>Show school overview</span>
							<SelectProgramm
								color="#2E79DA"
								className={`dropdown-arrow ${!hideCharts && "rotate-arrow"}`}
							/>
						</div>
					)}
				</>
			) : (
				<div className="school-overview__spinner">
					<Spinner size={72} />
				</div>
			)}
		</div>
	);
};

export default SchoolOverview;
