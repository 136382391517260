import React, { useEffect, useRef } from "react";
import Tabs from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/Tabs/Tabs";
import "./TopicsContent.scss";
import CalendarIcon from "components/icons/CalendarIcon";
import LockedContent from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/LockedContent/LockedContent";
import CompleteAction from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/TopicsContent/CompleteAction/CompleteAction";
import Scrollbar from "react-scrollbars-custom";
import { checkIfDecision } from "components/pages/Programmes/helpers/helpers";

const TopicsContent = ({
  name,
  type,
  activityId,
  typeName,
  topicNum,
  coachId,
  num,
  isBlocked,
  isDeactivated,
  ...otherProps
}) => {
  const { completed, due_date, is_disabled } = otherProps;
  const scrollEl = useRef();

  useEffect(() => () => scrollEl?.current?.scrollToTop());

  return (
    <section className={`topics-content activityId-${activityId}`}>
      {/*we use Scrollbar lib instead of PerfectScroll to fix an issue with inner scroll in Select component*/}
      <Scrollbar ref={scrollEl}>
        {(isBlocked || isDeactivated || is_disabled) &&
        !checkIfDecision(type) ? (
          <LockedContent
            isDeactivated={isDeactivated}
            name={name}
            coachId={coachId}
            num={num}
            typeName={typeName}
            topicNum={topicNum}
          />
        ) : (
          <>
            <div className='topics-content__head'>
              <span className='topics-content__num'>
                {topicNum ? `${topicNum}.` : null}
                {num ? `${num}. ` : null}
                {typeName}
              </span>
              {type !== "module" && type !== "decision" && (
                <CompleteAction
                  completed={completed}
                  activityType={type}
                  activityId={activityId}
                  activityInfo={otherProps}
                />
              )}
            </div>
            <h2 className='topics-content__title'>{name}</h2>
            {due_date && (
              <time className='topics-content__date'>
                <CalendarIcon
                  color={"#929292"}
                  className='topics-content__date-icon'
                />
                {due_date}
              </time>
            )}
            <Tabs
              type={type}
              name={name}
              activityId={activityId}
              {...otherProps}
            />
          </>
        )}
      </Scrollbar>
    </section>
  );
};

export default TopicsContent;
