export const getParticipantInitValues = (userInfo) => {
    return {
        avatar: userInfo.avatar,
        cvFile: userInfo.cv_url || '',
        firstName: userInfo.name || '',
        lastName: userInfo.surname || '',
        location: userInfo.location || '',
        seniority_level: userInfo.seniority_level ? userInfo.seniority_level.id : null,
        summary: userInfo.summary || '',
        goal: userInfo.goal || '',
        functionalArea: userInfo.functional_areas,
        industryBackground: userInfo.industry_background,
        languages: userInfo.languages,
        languagesLevel: userInfo.languages.level,
        work_experience: userInfo.work_experience?.map(element => {
            return {
                company: element.company,
                position: element.position,
                responsibilities: element.responsibilities,
                date_from: new Date(element.date_from),
                date_to: new Date(element.date_to === "Invalid date" || !element.date_to ? new Date() : element.date_to),
            }
        }) || [],
    }
}