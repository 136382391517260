import React from 'react';

import './DownloadReport.scss';

import image from 'assets/vector/questionnaire/Download_report.svg';
import DownloadReportButton from 'components/DownloadReportButton/DownloadReportButton';

const DownloadReport = () => {

    return (
        <div className="download-report">
            <div className="download__section">
                <h1 className="title">Well Done!</h1>
                <div className="description">We evaluate your answers and generate a report. You can download it and see your results</div>
                <DownloadReportButton />
            </div>
            <div className="image__section">
                <img src={image} alt="" />
            </div>
        </div>
    )
}

export default DownloadReport;