import React from 'react';
import { Link } from 'react-router-dom';
import { getProgramIcon } from "components/pages/Programmes/helpers/getProgramIcon";
import './ActivityItem.scss';

const ActivityItem = ({ type, name, activityId, program, isBlocked = false, completed = false }) => {
    return (
        <Link to={`/programmes/single-program/${program.id}?_activityId=${activityId}`} className='activity-item'>
            <div className="activity-item__icon">
                {getProgramIcon(type, isBlocked, completed)}
            </div>
            <div className="activity-item__content">
                <div className="activity-item__type">{program.name}</div>
                <div className="activity-item__name">{name}</div>
            </div>
            <div className="activity-item__actions">
                <div className="activity-item__complete-btn">Complete activity</div>
            </div>
        </Link>
    )
}

export default ActivityItem;
