import React from 'react';
import Checkbox from 'components/UI/Checkbox/Checkbox';

import './registrationCheckbox.scss';

const RegistrationCheckbox = ({ name, field, form }) => {
    const fieldName = name || field.name;

    const { setFieldValue, errors, values, setFieldTouched, touched } = form;

    const toggleChecked = (name, value, field) => {
        if (field.value.includes(value)) {
            setFieldTouched(name, true);
            setFieldValue(name, '');
        } else {
            setFieldValue(name, value);
        }
    };

    return (
        <>
            <div
                className={`registration-checkbox__wrapper ${touched[field.name] && errors[field.name] ? 'error' : ''
                    }`}
            >
                <Checkbox
                    field={field}
                    name={fieldName}
                    label={
                        <>
                            I agree to the processing of my personal data according to the
                            platform's{' '}
                            <a href='https://switchapp.io/privacy-policy/'>Privacy Policy</a>{' '}
                            and accept its{' '}
                            <a href='https://switchapp.io/terms-and-conditions/'>
                                Terms and Conditions
                            </a>
                            .
                        </>
                    }
                    value='checked'
                    values={values}
                    onChange={toggleChecked}
                />
            </div>
            {
                touched[field.name] && errors[field.name] &&
                <div className='default-auth__error'>{errors[field.name]}</div>
            }
        </>
    );
};

export default RegistrationCheckbox;
