export const sdaCareerAnswers = {
    'PQ-3': "PQ-3-1",
    'PQ-4': "PQ-4-5",
    'PQ-5': "",
    'PQ-6': "true",
    'PQ-7': "",
    'PQ-8': "",
    'PQ-9': "",
    'PQ-10': "jhvhbj,nlkm",
    'PQ-11': "true",
    'PQ-12': "gvnb bmbj",
    'PQ-15': "",
    'PQ-16': "",
    'PQ-20': "hjvgvghvg",
    'PQ-21': "",
    'PQ-22': "",
    'PQ-23': "",
    'PQ-24': "",
    'PQ-25': "2",
    'PQ-26': "",
    'PQ-27': "",
    'PQ-28': "",
    'PQ-29': "2",
    'PQ-30': ['PQ-30-1'],
    'PQ-31': "PQ-31-2",
    'PQ-32': "65",
    'PQ-33': "8",
    'PQ-34': "7",
    'PQ-35': [3],
    'PQ-36': "PQ-36-2",
    'PQ-37': "",
    'PQ-38': "",
    'PQ-39': "",
    'PQ-40': "PQ-40-2",
    'PQ-46': "",
    'PQ-47': "",
    'PQ-48': "jkbhjg",
    'PQ-50': ['PQ-50-2'],
    'PQ-52': "8",
    'PQ-53': "PQ-53-4",
    'PQ-54': "PQ-54-1",
    'PQ-55': "hjvvg",
    'PQ-56': "",
    'PQ-58': "",
    'PQ-60': "",
    'PQ-61': "",
    'PQ-62': "",
    'PQ-64': "PQ-64-3",
    'PQ-65': "2",
    'PQ-66': "hbjhhbjbh",
    'PQ-67': "PQ-67-2",
    'PQ-68': "2",
    'PQ-69': "2",
    'PQ-72': "2",
    'PQ-73': "",
    'PQ-74': "2",
    'PQ-75': "2",
    'PQ-76': "2",
    'PQ-77': "2",
    'PQ-78': "2",
    'PQ-79': "2",
    'PQ-80': "2",
    'PQ-81': "2",
    'PQ-82': "2",
    'PQ-83': "2",
    'PQ-84': "2",
    'PQ-85': "2",
    'PQ-86': "3",
    'PQ-87': "2",
    'PQ-88': "2",
    'PQ-89': "2",
    'PQ-90': "2",
    'PQ-91': "2",
    'PQ-92': "2",
    'PQ-93': "2",
    'PQ-94': "2",
    'PQ-95': "2",
    'PQ-96': "2",
    'PQ-97': "2",
    'PQ-98': "2",
    'PQ-99': "2",
    'PQ-100': "2",
    'PQ-101': "2",
    'PQ-102': "2",
    'PQ-103': "2",
    'PQ-104': "2",
    'PQ-105': "2",
    'PQ-106': "2",
    'PQ-107': "2",
    'PQ-108': "2",
    'PQ-109': "2",
    'PQ-110': "2",
    'PQ-111': "2",
    'PQ-112': "2",
    'PQ-113': "2",
    'PQ-114': "2",
    'PQ-115': "2",
    'PQ-116': "2",
    'PQ-117': "2",
    'PQ-118': "2",
    'PQ-119': "2",
    'PQ-120': "2",
    'PQ-121': "2",
    'PQ-122': "2",
    'PQ-123': "2",
    'PQ-124': "2",
    'PQ-125': "3",
    'PQ-126': "2",
    'PQ-127': "2",
    'PQ-128': "2",
    'PQ-129': "2",
    'PQ-130': "2",
    'PQ-131': "2",
    'PQ-132': "2",
    'PQ-133': "2",
    'PQ-134': "2",
    'PQ-135': "2",
    'PQ-136': "2",
    'PQ-137': "2",
    'PQ-138': "2",
    'PQ-139': "2",
    'PQ-140': "2",
    'PQ-141': "2",
    'PQ-142': "2",
    'PQ-143': "2",
    'PQ-144': "2",
    'PQ-145': "2",
    'PQ-146': "2",
    'PQ-147': "2",
    'PQ-148': "2",
    'PQ-149': "2",
    'PQ-150': "2",
    'PQ-151': "2",
    'PQ-152': "2",
    'PQ-153': "2",
    'PQ-154': "2",
    'PQ-155': "2",
    'PQ-156': "2",
    'PQ-157': "2",
    'PQ-158': "2",
    'PQ-159': "2",
    'PQ-160': "2",
    'PQ-161': "",
    'PQ-163': "",
    'PQ-164': "",
    'PQ-165': "",
    'PQ-166': "2",
    'PQ-167': new Date(),
    'PQ-168': "",
    'PQ-169': "",
    'legend': "",
};

export const careerAnswers = {
    'PC-1': "PC-1-2",
    'PC-2': "PC-2-6",
    'PC-3': "PC-3-1",
    'PC-4': "true",
    'PC-5': "",
    'PC-6': "yuyiyypyyy",
    'PC-7': "",
    'PC-8': "fsdsdfsdf",
    'PC-9': "true",
    'PC-10': "sdfsdfsfd",
    'PC-12': "PC-12-2",
    'PC-13': "",
    'PC-14': "",
    'PC-15': "",
    'PC-16': "sdfsfdsd",
    'PC-17': "",
    'PC-18': "",
    'PC-19': "",
    'PC-20': "",
    'PC-21': "1",
    'PC-22': "",
    'PC-23': "",
    'PC-24': "",
    'PC-25': "1",
    'PC-26': "PC-26-2",
    'PC-27': "",
    'PC-28': "",
    'PC-29': "",
    'PC-30': "",
    'PC-31': "",
    'PC-32': "PC-32-2",
    'PC-33': "",
    'PC-34': "",
    'PC-35': "sdfsfdsfd",
    'PC-36': "",
    'PC-37': "6"
}

export const coachAnswers = {
    'CQ-3': ['CQ-3-6'],
    'CQ-4': [7],
    'CQ-5': [7],
    'CQ-6': [21],
    'CQ-7': "",
    'CQ-8': "",
    'CQ-9': "",
    'CQ-10': "",
    'CQ-11': "",
    'CQ-12': "",
    'CQ-13': "",
    'CQ-14': "",
    'CQ-15': [65],
    'CQ-16': "7",
    'CQ-17': "7",
    'CQ-18': "CQ-18-2",
    'CQ-19': "pipka",
    'CQ-20': ['CQ-20-2'],
    'CQ-21': "CQ-21-2",
    'CQ-22': "stepan",
    'CQ-23': ['CQ-23-2'],
    'CQ-24': "red alert",
    'CQ-25': "true",
    'CQ-26': "5",
    'CQ-27': "21",
    'CQ-28': "1",
    'CQ-29': "",
    'CQ-30': "",
    'CQ-31': "",
    'CQ-32': "",
    'CQ-33': "",
    'CQ-34': "river",
    'CQ-35': "1",
    'CQ-36': new Date(),
    'legend': ""
}