import React, { useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import './BillingTable.scss';

const BillingTable = ({ data }) => {

    const [detailsVisible, setDetailsVisible] = useState(false);

    function handleDetailsClick() {
        setDetailsVisible(!detailsVisible)
    }

    return (
        <div className='billing-table' key={data.period}>
            <div className="billing-table__head">
                <div className='head-col'>{data.period}</div>
                <div className='head-col right'>
                    <span>{data.number_of_sessions}</span>
                    <span className='details' onClick={handleDetailsClick}>Details</span>
                </div>
            </div>
            {detailsVisible &&
                <div className="table__wrapper">
                    <PerfectScrollbar className='billing-table__details'>
                        <div className="table__row head">
                            <div className="table__col number">#</div>
                            <div className="table__col">Session name</div>
                            <div className="table__col">Session status</div>
                            <div className="table__col">Billing status</div>
                            <div className="table__col">Scheduled</div>
                            <div className="table__col">Completed at</div>
                            <div className="table__col">Price</div>
                            <div className="table__col">School</div>
                            <div className="table__col">Program</div>
                        </div>
                        {
                            data?.sessions.map((session, index) =>
                                <div className="table__row" key={session.id}>
                                    <div className="table__col number">{index + 1}</div>
                                    <div className="table__col">{session.name}</div>
                                    <div className="table__col">
                                        <div className="status">{session.status}</div>
                                    </div>
                                    <div className="table__col">
                                        <div className="status">{session.billing_status}</div>
                                    </div>
                                    <div className="table__col">{session.scheduled}</div>
                                    <div className="table__col">{session.completed_at}</div>
                                    <div className="table__col">{session.price}</div>
                                    <div className="table__col">{session.school}</div>
                                    <div className="table__col">{session.program}</div>
                                </div>
                            )
                        }
                    </PerfectScrollbar>
                </div>
            }
        </div>
    )
}

export default BillingTable;
