import React from 'react';
import {CSSTransition} from 'react-transition-group';

const Notification = ({show, children, ...props}) => {
    const nodeRef = React.useRef(null);

    return (
        <CSSTransition in={show}
                       timeout={500}
                       unmountOnExit
                       nodeRef={nodeRef}
                       classNames='default-auth__notification-'
                       {...props}>
            <div ref={nodeRef} className="default-auth__notification">{children}</div>
        </CSSTransition>
    )
};

export default Notification;