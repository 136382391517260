export const chatConstant = {
    SET_IS_CHAT_OPEN: 'SET_IS_CHAT_OPEN',
    SET_CHAT_USER: 'SET_CHAT_USER',

    SEND_MESSAGE_TO_USER: 'SEND_MESSAGE_TO_USER',
    SEND_MESSAGE_TO_USER_SUCCESS: 'SEND_MESSAGE_TO_USER_SUCCESS',
    SEND_MESSAGE_TO_USER_ERROR: 'SEND_MESSAGE_TO_USER_ERROR',

    CHAT_WITH_USER: 'CHAT_WITH_USER',

    GET_LIST_OF_ROOMS: 'GET_LIST_OF_ROOMS',
    SUCCESS_ROOMS: 'SUCCESS_ROOMS',
    ERROR_ROOMS: 'ERROR_ROOMS',

    GET_LIST_OF_USERS: 'GET_LIST_OF_USERS',
    SUCCESS_USERS: 'SUCCESS_USERS',
    EMPTY_USERS: 'EMPTY_USERS',
    ERROR_USERS: 'ERROR_USERS',

    CHAT_SEARCH_ROOM: 'CHAT_SEARCH_ROOM',
    GET_MESSAGES_FROM_USERS: 'GET_MESSAGES_FROM_USERS',
    GET_EMPTY_MESSAGES_FROM_USERS: 'GET_EMPTY_MESSAGES_FROM_USERS',
    GET_MESSAGES_FROM_USERS_SUCCESS: 'GET_MESSAGES_FROM_USERS_SUCCESS',
    GET_MESSAGES_FROM_USERS_ERROR: 'GET_MESSAGES_FROM_USERS_ERROR',
    CLEAR_USER_MESSAGES: 'CLEAR_USER_MESSAGES',
    GET_MESSAGES_FROM_USER_ON_SCROLL: 'GET_MESSAGES_FROM_USER_ON_SCROLL',
    GET_TOTAL_COUNT_MESSAGES: 'GET_TOTAL_COUNT_MESSAGES',


    CHAT_READED: 'CHAT_READED',
    GET_NEW_CHAT_MESSAGE: 'GET_NEW_CHAT_MESSAGE',
    GET_MESSAGES_SPECIFIED_USER: 'GET_MESSAGES_SPECIFIED_USER',

    CLEAR_CHAT_DATA: 'CLEAR_CHAT_DATA',
}
