import * as Yup from 'yup';
import { getTransformDate } from './dateTransform';

const convertDateToSeconds = (date) => {
    const newDate = new Date(date);

    return newDate.getTime() / 1000
}

const editScheduleValidationSchema = () => {
    return Yup.object().shape({
        break_days: Yup.array().of(Yup.number()),
        break_days_start: Yup.date(),
        break_days_end: Yup.date()
            .when('break_days_start', (break_days_start, schema) => {
                return schema.test({
                    test: break_days_end => convertDateToSeconds(break_days_end) - convertDateToSeconds(break_days_start) > 841,
                    message: "The end time must be at least 15 minutes later than the start time"
                })
            })
            .required('Date must be the in format: 12:00 AM'),
        daily_break_start: Yup.date(),
        daily_break_end: Yup.date()
            .when('daily_break_start', (daily_break_start, schema) => {
                return schema.test({
                    test: daily_break_end => convertDateToSeconds(daily_break_end) - convertDateToSeconds(daily_break_start) > 841,
                    message: "The end time must be at least 15 minutes later than the start time"
                })
            }),
        time_off: Yup.array().of(
            Yup.object().shape({
                start_day: Yup.date(),
                end_day: Yup.date().min(Yup.ref('start_day'), 'The end day must be later than the start day'),
                start_time: Yup.date(),
                end_time: Yup.date()
                    .test({
                        test: function (value, context) {
                            if (getTransformDate(context.parent.start_day) === getTransformDate(context.parent.end_day)) {
                                return convertDateToSeconds(value) - convertDateToSeconds(context.parent.start_time) > 841
                            }
                            return true;
                        },
                        message: "The end time must be at least 15 minutes later than the start time",
                    })
            })
        )
    })
}

export default editScheduleValidationSchema;
