import React from 'react';
import Credits from "components/pages/Dashboard/SchoolAdmin/Charts/Credits/Credits";

const TotalCredits = ({ credits }) => {
    return (
        <div className='credit-graph credit-graph--total'>
            <div className="title">Total Credits</div>
            <Credits credits={credits} />
            <ul className='credit-graph__stats'>
                <li className='credit-graph__stat credit-graph__stat--used'><span className='accent'>{credits?.used}</span> Used</li>
                <li className='credit-graph__stat credit-graph__stat--in-progress'><span className='accent'>{credits?.in_progress}</span> In progress</li>
                <li className='credit-graph__stat'><span className='accent'>{credits?.available}</span> Available</li>
            </ul>
        </div>
    );
};

export default TotalCredits;