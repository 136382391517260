import React from 'react';
import './EventLink.scss';
import { ReactComponent as LinkIcon } from 'assets/vector/link.svg';
import { ReactComponent as GoogleLink } from 'assets/vector/google.svg';

const EventLink = ({ link = '', name = '' }) => {
  if (!link) return null;

  return name ?
    <a href={link} target="_blank" className="event-link event-link--google">
      <GoogleLink className="event-info__icon"/>
      {name}
    </a>
    :
    <a href={link} target="_blank" className="event-link">
      <LinkIcon className="event-info__icon"/>
      {link}
    </a>;
};

export default EventLink;
