import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Session from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/Session/Session";
import FeedbackLink from "components/pages/Programmes/SingleProgramme/FeedbackLink/FeedbackLink";
import EditIcon from "components/icons/EditIcon";
import TextEditor from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/TextEditor/TextEditor";
import CategoryDescriptionEditor from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/CategoryDescriptionEditor/CategoryDescriptionEditor";
import { createCategoryDescription } from "../../../../../../redux/actions";
import CategoryDescriptions from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/CategoryDescriptions/CategoryDescriptions";
import DecisionBox from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/DecisionBox/DecisionBox";

const DetailsTab = (props) => {
	const [userName, setUserName] = React.useState(null);
	const [userAvatar, setUserAvatar] = React.useState(null);
	const [evaluatedUserRole, setEvaluatedUserRole] = React.useState(null);

	const {
		type,
		name,
		activityId,
		description,
		duration,
		session,
		details,
		topicId,
		feedback_form,
		is_form_filled,
		categorized_descriptions,
	} = props;

	const dispatch = useDispatch();
	const { staticData } = useSelector((state) => state.auth);
	const { id: programId } =
		useSelector((state) => state.userPrograms.singleProgram) || {};
	const currentUserRole = useSelector(
		(state) => state.currentUserInfo.currentUserInfo.role
	);
	const { coach_avatar, coach_name } =
		useSelector((state) => state.userPrograms.programs).find(
			(program) => program.id === programId
		) || {};
	const { participant } =
		useSelector((state) => state.userPrograms.programs).find(
			(program) => program.id === programId
		) || {};
	let participant_avatar;
	let participant_name;
	const maxCategories = 3;

	function createMarkup(content) {
		return { __html: content };
	}

	if (participant) {
		participant_avatar = participant.avatar;
		participant_name = participant.name;
	}

	const [isDescriptionEditing, setIsEditingDescription] = useState(false);
	const [isCategoryCreating, setIsCategoryCreating] = useState(false);
	const isCoachRole = currentUserRole === "coach";
	const isCategoryEditable =
		type === "session" || type === "session_and_evaluation";

	React.useEffect(() => {
		if (currentUserRole === "coach") {
			setUserName(participant_name);
			setUserAvatar(participant_avatar);
			setEvaluatedUserRole("Participant");
		}

		if (currentUserRole === "participant") {
			setUserName(coach_name);
			setUserAvatar(coach_avatar);
			setEvaluatedUserRole("Coach");
		}
	}, [
		currentUserRole,
		programId,
		participant_avatar,
		participant_name,
		coach_avatar,
		coach_name,
	]);

	const showTextEditor = () => {
		setIsEditingDescription(true);
	};

	const hideTextEditor = () => {
		setIsEditingDescription(false);
	};

	const showCategoryEditor = () => {
		setIsCategoryCreating(true);
	};

	const hideCategoryEditor = () => {
		setIsCategoryCreating(false);
	};

	const renderTabContent = () => {
		switch (type) {
			case "session":
			case "session_and_evaluation":
				return (
					<Session
						session={session}
						duration={duration}
						type={type}
						activityName={name}
						topicId={topicId}
						filledFormReport={is_form_filled}
						programId={programId}
						userName={userName}
						userAvatar={userAvatar}
						evaluatedUserRole={evaluatedUserRole}
						feedbackForm={feedback_form}
						activityId={activityId}
					/>
				);
			// case 'files':
			//    return <AttachedFile files={files}/>;
			case "form":
				return (
					<FeedbackLink
						filledFormReport={is_form_filled}
						sessionCompletedStatus={true}
						programId={programId}
						activityId={activityId}
						userName={userName}
						userAvatar={userAvatar}
						evaluatedUserRole={evaluatedUserRole}
						feedbackForm={feedback_form}
					/>
				);
			case "decisionForm":
			case "decision":
				return <DecisionBox {...props} />;
			default:
				return null;
		}
	};

	const addCategoryDescription = (itemId, categoryId, categoryDescription) => {
		dispatch(
			createCategoryDescription(
				activityId,
				categoryId,
				categoryDescription,
				categorized_descriptions
			)
		);
	};

	const removeDescription = (itemId) => {
		const categoriesWithoutRemoved = categorized_descriptions?.filter(
			(category) => category.itemId !== itemId
		);

		if (categoriesWithoutRemoved.length !== categorized_descriptions?.length) {
			dispatch(
				createCategoryDescription(
					activityId,
					null,
					null,
					categoriesWithoutRemoved
				)
			);
		}
	};

	const editDescription = (itemId, categoryId, categoryNewDescription) => {
		let originalDescription = null;

		const categoriesWithEdited = categorized_descriptions?.map((category) => {
			if (category.itemId === itemId) {
				originalDescription = category.description;

				category.description = categoryNewDescription;
				category.category_id = categoryId;
			}

			return category;
		});

		if (categoryNewDescription !== originalDescription) {
			dispatch(
				createCategoryDescription(activityId, null, null, categoriesWithEdited)
			);
		}
	};

	return (
		<div className={`tabs__content ${type === "decision" ? "decision" : ""}`}>
			<h4 className="tabs__title">
				Description
				{!isDescriptionEditing && isCoachRole && (
					<EditIcon
						className="tabs__description-edit"
						onClick={showTextEditor}
					/>
				)}
			</h4>
			{isDescriptionEditing && type !== "module" ? (
				<TextEditor
					originalText={details}
					type={type}
					programId={programId}
					topicId={topicId}
					activityId={activityId}
					hideTextEditor={hideTextEditor}
				/>
			) : (
				<div
					className="tabs__details"
					dangerouslySetInnerHTML={createMarkup(details)}
				/>
			)}
			{isDescriptionEditing && type === "module" ? (
				<TextEditor
					originalText={description}
					type={type}
					programId={programId}
					s
					topicId={topicId}
					activityId={activityId}
					hideTextEditor={hideTextEditor}
				/>
			) : (
				<div
					className="tabs__details"
					dangerouslySetInnerHTML={createMarkup(description)}
				/>
			)}

			<CategoryDescriptions
				descriptions={categorized_descriptions}
				removeDescription={removeDescription}
				editDescription={editDescription}
				categories={staticData.activityCategories}
			/>

			{isCategoryEditable &&
				isCoachRole &&
				!isCategoryCreating &&
				categorized_descriptions.length < maxCategories && (
					<button
						className="tabs__category-edit"
						type="button"
						onClick={showCategoryEditor}
					>
						+ Add topic
					</button>
				)}
			{isCategoryCreating && (
				<CategoryDescriptionEditor
					selectLabel={"Topic"}
					selectPlaceholder={"Choose topic..."}
					options={staticData.activityCategories}
					hideCategoryEditor={hideCategoryEditor}
					textLabel={"Description"}
					handleSubmit={addCategoryDescription}
				/>
			)}
			{renderTabContent()}
		</div>
	);
};

export default DetailsTab;
