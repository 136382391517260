import React, { useEffect, useState } from "react";
// for ILTB
import { ReactComponent as SessionIcon } from "assets/vector/programmes/create-session-icon.svg";
// import {ReactComponent as SessionIcon} from "assets/vector/programmes/create-session-icon-switch.svg";
import EventSessionForm from "components/forms/EventSession/EventSessionForm";
import "./EventSessionModal.scss";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import Button from "components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { getSessionTime } from "../../../redux/actions/createSession";
import { getUsersIds } from "components/modal/helpers/helpers";

const EventSessionModal = ({ closeModal }) => {
	const dispatch = useDispatch();
	const { singleProgram } = useSelector((state) => state.userPrograms);
	const additionalRequestData = useSelector((state) => state.modal.modalData);
	const { activityName = "", editedSession } = additionalRequestData;
	const [time, setTime] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [sessionStatus, setSessionStatus] = useState("");
	const [groupName, setGroupName] = useState(
		editedSession?.name || activityName
	);
	const [sessionLink, setSessionLink] = useState("");
	const [sessionDetails, setSessionDetails] = useState("");
	const [sessionType, setSessionType] = useState("individual");
	const [isDatePickerVisible, setDatePickerVisible] = useState(false);
	const { timezone } = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const { role } = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const isParticipant = role === "participant";
	const todayWithTimeZone = new Date(
		moment.tz(timezone).format("YYYY/MM/DD HH:mm:ss")
	);
	// YYYY/MM/DD format used to display new Date correctly in Safari

	const modifiers = { highlighted: todayWithTimeZone };

	if (isParticipant) modifiers["disabled"] = { before: todayWithTimeZone };

	const showDatePicker = () => setDatePickerVisible(true);
	const saveDate = () => setDatePickerVisible(false);
	const closeDatePicker = () => {
		setSelectedDate(null);
		setDatePickerVisible(false);
	};

	const selectDate = (day, modifiers = {}) => {
		if (modifiers.disabled) {
			return;
		}

		setSelectedDate(modifiers.selected ? undefined : day);
	};

	useEffect(() => {
		if (editedSession) {
			if (
				editedSession.session_type &&
				editedSession.session_type !== sessionLink
			) {
				setSessionType(editedSession.session_type);
			}

			if (editedSession.link) {
				setSessionLink(editedSession.link);
			}

			if (editedSession.details) {
				setSessionDetails(editedSession.details);
			}

			if (editedSession.date_start) {
				const timeZoneTime = moment(editedSession.date_start)
					.format("LT")
					.replace(" ", ":");
				const fullDate = moment(editedSession.date_start).toString();

				setSelectedDate(fullDate);
				setTime(timeZoneTime);
			}
		}
	}, [editedSession]);

	useEffect(() => {
		if (selectedDate && singleProgram) {
			const chosenDate = moment(selectedDate).format("YYYY-MM-DD");
			const members = editedSession
				? getUsersIds([...editedSession.coaches, ...editedSession.participants])
				: [singleProgram.participant_id, singleProgram.coach_id];

			dispatch(getSessionTime(chosenDate, members));
		}
	}, [selectedDate, singleProgram]);

	return (
		<div className="create-event">
			{isDatePickerVisible ? (
				<>
					<div className="create-event__head">
						<SessionIcon className="create-event__icon" />
						<h3 className="create-event__title">Choose date</h3>
					</div>
					<DayPicker
						className="create-event__date-picker"
						mode="single"
						numberOfMonths={2}
						weekStartsOn={1}
						modifiers={modifiers}
						selected={selectedDate}
						onSelect={selectDate}
					/>
					<div className="create-event__actions">
						{/* for ILTB */}
						{/* <Button type="button"
                                        onClick={saveDate}
                                        className="button button--orange create-event__save">
                                    Save
                                </Button> */}
						<Button
							type="button"
							onClick={saveDate}
							className="button button--blue create-event__save"
						>
							Save
						</Button>
						<Button
							type="button"
							className="button button--orange-invert create-event__cancel"
							onClick={closeDatePicker}
						>
							Cancel
						</Button>
					</div>
				</>
			) : (
				<>
					<div className="create-event__head">
						<SessionIcon className="create-event__icon" />
						<h3 className="create-event__title">Create Session or Event</h3>
					</div>
					<EventSessionForm
						closeModal={closeModal}
						showDatePicker={showDatePicker}
						selectedDate={selectedDate}
						time={time}
						setTime={setTime}
						groupName={groupName}
						setGroupName={setGroupName}
						sessionLink={sessionLink}
						setSessionLink={setSessionLink}
						sessionDetails={sessionDetails}
						setSessionDetails={setSessionDetails}
						sessionType={sessionType}
						sessionStatus={sessionStatus}
						setSessionStatus={setSessionStatus}
						coachID={singleProgram.coach_id}
					/>
				</>
			)}
		</div>
	);
};

export default EventSessionModal;
