import React from 'react';

const CrossRoundIcon = ({color = '#CDCBCB', width = 16, height = 16, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16 8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114 0 8 0Z" fill={color}/>
            <path d="M10.946 10.0022C11.2066 10.263 11.2066 10.6842 10.946 10.945C10.8159 11.075 10.6453 11.1403 10.4745 11.1403C10.3039 11.1403 10.1332 11.075 10.0032 10.945L7.99991 8.94156L5.99661 10.945C5.86661 11.075 5.69595 11.1403 5.5253 11.1403C5.35452 11.1403 5.18387 11.075 5.05386 10.945C4.79324 10.6842 4.79324 10.263 5.05386 10.0022L7.05728 7.99893L5.05386 5.99564C4.79324 5.73489 4.79324 5.31363 5.05386 5.05289C5.31461 4.79227 5.73587 4.79227 5.99661 5.05289L7.99991 7.05631L10.0032 5.05289C10.2639 4.79227 10.6852 4.79227 10.946 5.05289C11.2066 5.31363 11.2066 5.73489 10.946 5.99564L8.94253 7.99893L10.946 10.0022Z" fill="#FAFAFA"/>
        </svg>
    );
};

export default CrossRoundIcon;