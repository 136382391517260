import React, { useState } from "react";
import Select, { components } from 'react-select';
import FilterIcon from "components/icons/FilterIcon";
import { useMediaQuery } from 'react-responsive';
import CreateSession from "components/createSession/createSession";
import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";
import EditSchedule from 'components/editSchedule/editSchedule';
import { customSelectStyles } from 'helpers/customSelectStyles';


let initProgram;
let initType;
let initMember;

const CalendarFilterData = ({ calendarFilters, calendarGoogleMembers, onFilterChange, onFilterGoogleChange }) => {

    const isMobileFilters = useMediaQuery({ query: `(max-width: 920px)` });
    const isCreateSession = useMediaQuery({ query: `(max-width: 768px)` });
    const [isActiveFilters, setActiveFilters] = useState(false);

    const toggleClass = () => { setActiveFilters(!isActiveFilters); };

    const optionsMapperProgram = item => {
        return item.map(({ id: id, name: value, name: label }) => ({
            id,
            value,
            label
        }));
    };

    const optionsMapperType = (item3) => {
        return item3.map((elem) => ({
            value: elem.key,
            label: elem.name
        }));
    };

    const optionsMapperMembers = item2 => {
        return item2.map(({ id, full_name: value, full_name: label }) => ({
            id,
            value,
            label
        }));
    };

    let programmeOptions = [
        { id: 0, value: "all", label: "All" },
        ...optionsMapperProgram(calendarFilters.school_programs)
    ]

    let typeOptions = [
        { value: "all", label: "All" },
        ...optionsMapperType(calendarFilters.types)
    ]

    let usersOptions = [
        { id: 0, value: "all", label: "Sessions with all users" },
        ...optionsMapperMembers(calendarFilters.members)
    ]

    let googleMembersOptions = [
        { id: 0, value: "addition_member", label: "Check others' schedule" },
        ...optionsMapperMembers(calendarGoogleMembers)
    ]

    const [activeFilter, setActiveFilter] = useState('')

    const handleFilterChange = (e, filterOption) => {
        setActiveFilter("")
        switch (filterOption) {
            case "programs":
                initProgram = e.id !== 0 ? [e.id] : []
                break;
            case "type":
                if (e.value === "individual") {
                    initType = 'individual';
                } else if (e.value === "group") {
                    initType = 'group';
                } else {
                    initType = '';
                }
                break;
            case "members":
                initMember = e.id !== 0 ? [e.id] : [];
                break;

            default: break;
        }
        onFilterChange(initProgram, initType, initMember)
    }

    const handleGoogleFilterChange = (e) => {
        let value = e.id !== 0 ? [e.id] : [];
        onFilterGoogleChange(value)
    }

    const FilterHeaders = () => {
        return (
            <div className="mobile__filters-headers">
                <div className="mobile__filters-title">Filters</div>
                <div className="mobile__filters-close" onClick={toggleClass}></div>
            </div>
        )
    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowSvg />
            </components.DropdownIndicator>

        );
    };

    return (
        <div className={`filter-wrapper ${isMobileFilters ? 'mobile' : ''}`}>
            <div className="mobile__filters" onClick={toggleClass} >
                <FilterIcon className={`icon`} />
                Filters
            </div>
            <div className={`filter_form ${isActiveFilters ? 'active' : ''}`}>
                {isMobileFilters ? <FilterHeaders /> : null}
                <form>
                    <div className={`${activeFilter === 'programs' ? 'filter_item active' : 'filter_item'}`}>
                        <Select
                            options={programmeOptions}
                            defaultValue={[{ value: 'all', label: 'All programs' }]}
                            clearable={false}
                            styles={customSelectStyles}
                            components={{
                                DropdownIndicator,
                                IndicatorSeparator: () => null
                            }}
                            onChange={(e) => handleFilterChange(e, "programs")}
                        />
                    </div>
                    <div className={`${activeFilter === 'type' ? 'filter_item active' : 'filter_item'}`}>
                        <Select
                            options={typeOptions}
                            defaultValue={[{ value: 'all', label: 'All  sessions' }]}
                            clearable={false}
                            styles={customSelectStyles}
                            components={{
                                DropdownIndicator,
                                IndicatorSeparator: () => null
                            }}
                            onChange={(e) => handleFilterChange(e, "type")}
                        />
                    </div>
                    <div className={`${activeFilter === 'members' ? 'filter_item active' : 'filter_item'}`}>
                        <Select
                            options={usersOptions}
                            defaultValue={[{ id: 0, value: 'all', label: 'Sessions with all users' }]}
                            clearable={false}
                            styles={customSelectStyles}
                            components={{
                                DropdownIndicator,
                                IndicatorSeparator: () => null
                            }}
                            onChange={(e) => handleFilterChange(e, "members")}
                        />
                    </div>
                    {
                        window.localStorage.getItem('user_role') !== 'school_admin' &&
                        <>
                            <div className={`${activeFilter === 'googleMember' ? 'filter_item active' : 'filter_item'}`}>
                                <Select
                                    options={googleMembersOptions}
                                    defaultValue={[{ id: 0, value: "addition_member", label: "Check others' schedule" }]}
                                    clearable={false}
                                    styles={customSelectStyles}
                                    components={{
                                        DropdownIndicator,
                                        IndicatorSeparator: () => null
                                    }}
                                    onChange={(e) => handleGoogleFilterChange(e, "googleMember")}
                                />
                            </div>
                        </>
                    }
                </form>
                {isCreateSession
                    ?
                    <div className='calendar-event__buttons'>
                        <EditSchedule />
                        <CreateSession />
                    </div>
                    :
                    null
                }
            </div>
        </div>
    );
};

export default CalendarFilterData;