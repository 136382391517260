import React from 'react';

const CrossIcon = ({color = '#929292', width = 20, height = 20, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.69341 18.5971L7.2745 18.016L1.98395 12.7255L13.8877 0.821781C14.9834 -0.273927 16.7599 -0.273927 17.8556 0.821781L19.1782 2.14442C20.2739 3.24012 20.2739 5.01662 19.1782 6.11232L6.69341 18.5971ZM5.94537 19.3322C5.44002 19.7617 4.79648 20 4.12837 20H0.467622C0.209362 20 0 19.7906 0 19.5324V15.8716C0 15.2035 0.238293 14.56 0.667813 14.0546L5.94537 19.3322Z" fill={color}/>
        </svg>
    );
};

export default CrossIcon;