import React, {useEffect, useState} from 'react';
import CreatePasswordForm from "components/forms/CreatePassword/CreatePasswordForm";
import Notification from "components/auth/notification/Notification";
import {useSelector} from "react-redux";

const CreatePassword = () => {
    const {createPasswordError, createPasswordInProgress} = useSelector(state => state.setPassword);
    const [isErrorNotification, setErrorNotification] = useState(false);

    useEffect(() => {
        let timedNotification;

        if (createPasswordError && !createPasswordInProgress) {
            timedNotification = setTimeout(() => setErrorNotification(false), 5000);
            setErrorNotification(true);

        } else if (createPasswordInProgress) {
            clearTimeout(timedNotification);
            setErrorNotification(false);
        }
    }, [createPasswordError, createPasswordInProgress]);

    return (
        <>
            <h1 className="default-auth__title">
                <Notification show={isErrorNotification}>{createPasswordError}</Notification>
                Create Password
            </h1>
            <CreatePasswordForm/>
        </>
    );
};

export default CreatePassword;