import React from 'react';

const StatusMessage = ({show = false, success = false, children}) => {
    return (
        show && (
            <div className="status-messages">
                <p className={`alert ${success ? 'alert-success' : 'alert-error'}`}>
                    {children}
                </p>
            </div>
        )
    );
};

export default StatusMessage;