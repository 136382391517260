import React from 'react';
import {Ripple}  from 'react-spinners-css';

import './spinner.scss';

const Spinner = ({size = 100}) => {

    const spinnerProps = {
        color: `var(--loader-color)`,
        size
    };

    return (
        <div className='spinner__wrapper'>
            <Ripple  {...spinnerProps} />
            <span>Loading.. </span>
        </div>
    )
}

export default Spinner