export const personalInfo = {
    GET_INFO_ABOUT_CURRENT_USER : "GET_INFO_ABOUT_CURRENT_USER",
    GET_THROTTLED_INFO_ABOUT_CURRENT_USER : "GET_THROTTLED_INFO_ABOUT_CURRENT_USER",
    GET_INFO_ABOUT_CURRENT_USER_SUCCESS : "GET_INFO_ABOUT_CURRENT_USER_SUCCESS",
    GET_INFO_ABOUT_CURRENT_USER_ERROR : "GET_INFO_ABOUT_CURRENT_USER_ERROR",
    CLEAR_DATA_ABOUT_CURRENT_USER : "CLEAR_DATA_ABOUT_CURRENT_USER",
    UPDATE_GOOGLE_AUTH_STATUS: "UPDATE_GOOGLE_AUTH_STATUS",
}


