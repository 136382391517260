import React from 'react';
// for ILTB
// import {ReactComponent as Decor1} from "assets/vector/empty-pages/empty-page-decor-1.svg";
// import {ReactComponent as Decor2} from "assets/vector/empty-pages/empty-page-abstract-2.svg";

const EmptyProgrammes = ({children}) => {
    return (
        <div className="page-programmes__empty">
            {/* for ITLB */}
            {/* <Decor1 className="decor-1"/> */}
            {/* <Decor2 className="decor-2"/> */}
            <div className="page-programmes__empty-info">
                {children}
            </div>
        </div>
    );
};

export default EmptyProgrammes;