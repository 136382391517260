import React from "react";
import { openModal } from "../../redux/actions/modal";
import { EDIT_SCHEDULE_MODAL } from "../../redux/constants/modal";
import { useDispatch, useSelector } from "react-redux";

import "./editSchedule.scss";

const EditSchedule = () => {
	const dispatch = useDispatch();
	const { role } =
		useSelector((state) => state.currentUserInfo.currentUserInfo) || {};

	return role === "coach" ? (
		<button
			className={`button edit-schedule`}
			onClick={() => dispatch(openModal(EDIT_SCHEDULE_MODAL))}
		>
			Schedule Availability
		</button>
	) : null;
};

export default EditSchedule;
