import React from 'react';
import InvoicesFilters from './InvoicesFilters/InvoicesFilters';
import InvoicesTable from './InvoicesTable/InvoicesTable';
import './Invoices.scss';

const Invoices = ({ lastElement, setOffsetReports, invoices }) => {
    return (
        <div className='invoices'>
            <InvoicesFilters setOffsetReports={setOffsetReports} />
            <InvoicesTable setOffsetReports={setOffsetReports} invoices={invoices?.invoices} />
            <div ref={lastElement} />
        </div>
    )
}

export default Invoices;
