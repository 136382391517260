import React from 'react';

const GoalsIcon = ({color = '#486EA0', width = 32, height = 32, ...props}) => {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip09)">
                <path d="M31.8803 30.5197L24.8803 17.853C24.6936 17.5197 24.4003 17.3597 23.987 17.333C23.6136 17.333 23.2803 17.5463 23.107 17.8797L21.9736 20.093L27.2803 29.533C27.707 30.2797 27.7736 31.1863 27.4803 31.9997H31.0003C31.3603 31.9997 31.6803 31.813 31.867 31.5063C32.0403 31.1997 32.0403 30.8263 31.8803 30.5197Z" fill={color}/>
                <path d="M25.5334 30.5071L19.1468 19.1338L16.6001 21.2938C16.0668 21.7471 15.3734 22.0005 14.6668 22.0005C13.9201 22.0005 13.2134 21.7338 12.6668 21.2405C12.1201 21.7338 11.4134 22.0005 10.6668 22.0005C9.96011 22.0005 9.26678 21.7471 8.72011 21.2805L6.29344 19.2271L0.120107 30.5205C-0.0532259 30.8271 -0.0398925 31.2005 0.133441 31.5071C0.320108 31.8138 0.653441 32.0005 1.00011 32.0005H24.6668C25.0268 32.0005 25.3468 31.8138 25.5334 31.5071C25.7068 31.2005 25.7068 30.8138 25.5334 30.5071Z" fill={color}/>
                <path d="M20.3332 6.66667H12.6665C12.1145 6.66667 11.6665 6.21867 11.6665 5.66667V1C11.6665 0.448 12.1145 0 12.6665 0H20.3332C20.7238 0 21.0785 0.226667 21.2412 0.582667C21.4052 0.937333 21.3465 1.35467 21.0918 1.652L19.6505 3.33333L21.0932 5.016C21.3478 5.31333 21.4052 5.73067 21.2425 6.08533C21.0785 6.44 20.7238 6.66667 20.3332 6.66667Z" fill={color}/>
                <path d="M12.6665 10.6667C12.1145 10.6667 11.6665 10.2187 11.6665 9.66667V1C11.6665 0.448 12.1145 0 12.6665 0C13.2185 0 13.6665 0.448 13.6665 1V9.66667C13.6665 10.2187 13.2185 10.6667 12.6665 10.6667Z" fill={color}/>
                <path d="M18.1466 17.36L15.3066 19.76C15.1199 19.92 14.8933 20 14.6666 20C14.4133 20 14.1599 19.9067 13.9599 19.7067L12.6666 18.4134L11.3733 19.7067C10.9999 20.08 10.4133 20.0934 10.0266 19.76L7.2666 17.44L8.25327 15.6534L11.7866 9.18669C11.9599 8.86669 12.2933 8.66669 12.6666 8.66669C12.9999 8.62669 13.3599 8.85335 13.5333 9.17335L17.1466 15.5867L18.1466 17.36Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip09">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default GoalsIcon;