import React from 'react';
import {css} from "@emotion/react";
import {PulseLoader} from "react-spinners";
import './Button.scss';

const Button = ({children, loading = false, spinner= {}, ...props}) => {
        const override = css`
          position: absolute;
        `;
        const spinnerProps = {
            ...{
                color: 'white',
                size: 8,
                margin: 3
            },
            ...spinner
        }

        return (
            <button {...props}>
                <PulseLoader loading={loading} css={override} {...spinnerProps}/>
                <span style={{visibility: loading ? 'hidden' : 'visible', display: 'flex', alignItems: 'center'}}>{children}</span>
            </button>
        );
    }
;

export default Button;