import React, { useEffect, useState } from "react";
import { ReactComponent as Image } from "assets/vector/programmes/cancel-session.svg";
import Button from "components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import Notification from "components/auth/notification/Notification";
import TextareaAutosize from "react-autosize-textarea";
import { sessionRequestClearData } from "../../../redux/actions";

const RequestSessionModal = ({ closeModal, modalData }) => {
	const dispatch = useDispatch();
	const { sessionRequestStatus } = useSelector(
		(state) => state.userPrograms.singleProgram
	);
	const [text, setText] = useState("");

	const handleTextInput = (e) => {
		setText(e.target.value);
	};

	const handleSubmit = () => {
		modalData.confirmAction(text);
	};

	const handleCloseModal = () => {
		closeModal();
		dispatch(sessionRequestClearData());
	};

	useEffect(() => {
		if (sessionRequestStatus?.success) {
			closeModal();
			dispatch(sessionRequestClearData());
		}
	}, [sessionRequestStatus?.success]);

	return (
		<div className="decline-modal decline-modal--request">
			<Image className="decline-modal__img" />
			<h2 className="decline-modal__title">{modalData.title}</h2>
			{modalData.description && (
				<p className="decline-modal__text">{modalData.description}</p>
			)}
			<TextareaAutosize
				value={text}
				className="text-editor__area"
				onChange={handleTextInput}
			/>
			<div className="decline-modal__actions">
				<Button
					type="button"
					loading={!!sessionRequestStatus?.progress}
					className="button button--orange"
					onClick={handleSubmit}
				>
					Confirm
				</Button>
				<Button
					type="button"
					onClick={handleCloseModal}
					className="button button--orange-invert"
				>
					Cancel
				</Button>
			</div>
			<Notification
				style={{ position: "static" }}
				show={!!sessionRequestStatus?.error}
			>
				{sessionRequestStatus?.error}
			</Notification>
		</div>
	);
};

export default RequestSessionModal;
