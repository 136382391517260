import React from 'react'

const DatePickerIcon = ({ color }) => {
    return (
        <svg className='icon' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 5C3.67157 5 3 5.67157 3 6.5V20C3 20.8284 3.67157 21.5 4.5 21.5H19.5C20.3284 21.5 21 20.8284 21 20V6.5C21 5.67157 20.3284 5 19.5 5H4.5ZM1.5 6.5C1.5 4.84315 2.84315 3.5 4.5 3.5H19.5C21.1569 3.5 22.5 4.84315 22.5 6.5V20C22.5 21.6569 21.1569 23 19.5 23H4.5C2.84315 23 1.5 21.6569 1.5 20V6.5Z" fill={color} />
            <path d="M13.875 12.5C14.4963 12.5 15 11.9963 15 11.375C15 10.7537 14.4963 10.25 13.875 10.25C13.2537 10.25 12.75 10.7537 12.75 11.375C12.75 11.9963 13.2537 12.5 13.875 12.5Z" fill={color} />
            <path d="M17.625 12.5C18.2463 12.5 18.75 11.9963 18.75 11.375C18.75 10.7537 18.2463 10.25 17.625 10.25C17.0037 10.25 16.5 10.7537 16.5 11.375C16.5 11.9963 17.0037 12.5 17.625 12.5Z" fill={color} />
            <path d="M13.875 16.25C14.4963 16.25 15 15.7463 15 15.125C15 14.5037 14.4963 14 13.875 14C13.2537 14 12.75 14.5037 12.75 15.125C12.75 15.7463 13.2537 16.25 13.875 16.25Z" fill={color} />
            <path d="M17.625 16.25C18.2463 16.25 18.75 15.7463 18.75 15.125C18.75 14.5037 18.2463 14 17.625 14C17.0037 14 16.5 14.5037 16.5 15.125C16.5 15.7463 17.0037 16.25 17.625 16.25Z" fill={color} />
            <path d="M6.375 16.25C6.99632 16.25 7.5 15.7463 7.5 15.125C7.5 14.5037 6.99632 14 6.375 14C5.75368 14 5.25 14.5037 5.25 15.125C5.25 15.7463 5.75368 16.25 6.375 16.25Z" fill={color} />
            <path d="M10.125 16.25C10.7463 16.25 11.25 15.7463 11.25 15.125C11.25 14.5037 10.7463 14 10.125 14C9.50368 14 9 14.5037 9 15.125C9 15.7463 9.50368 16.25 10.125 16.25Z" fill={color} />
            <path d="M6.375 20C6.99632 20 7.5 19.4963 7.5 18.875C7.5 18.2537 6.99632 17.75 6.375 17.75C5.75368 17.75 5.25 18.2537 5.25 18.875C5.25 19.4963 5.75368 20 6.375 20Z" fill={color} />
            <path d="M10.125 20C10.7463 20 11.25 19.4963 11.25 18.875C11.25 18.2537 10.7463 17.75 10.125 17.75C9.50368 17.75 9 18.2537 9 18.875C9 19.4963 9.50368 20 10.125 20Z" fill={color} />
            <path d="M13.875 20C14.4963 20 15 19.4963 15 18.875C15 18.2537 14.4963 17.75 13.875 17.75C13.2537 17.75 12.75 18.2537 12.75 18.875C12.75 19.4963 13.2537 20 13.875 20Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M6 2C6.41421 2 6.75 2.33579 6.75 2.75V4.25C6.75 4.66421 6.41421 5 6 5C5.58579 5 5.25 4.66421 5.25 4.25V2.75C5.25 2.33579 5.58579 2 6 2ZM18 2C18.4142 2 18.75 2.33579 18.75 2.75V4.25C18.75 4.66421 18.4142 5 18 5C17.5858 5 17.25 4.66421 17.25 4.25V2.75C17.25 2.33579 17.5858 2 18 2Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.25 7.25H21.75V8.75H2.25V7.25Z" fill={color} />
        </svg>
    )
}

export default DatePickerIcon