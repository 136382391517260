import React from 'react';
import './Attendance.scss';
import Rate from "components/pages/Dashboard/SchoolAdmin/Charts/Attendance/Rate/Rate";
import Info from "components/pages/Dashboard/SchoolAdmin/Charts/Attendance/Info/Info";
import { useMediaQuery } from "react-responsive";

const Attendance = ({ sessions = [], additional = {} }) => {
    const isTabletOrLarger = useMediaQuery({ minWidth: 920 });
    const additionalSession = additional.sessions_total > 0 ? [{ ...additional, isAdditional: true }] : [];
    const unitedSessions = [...sessions, ...additionalSession];

    return (
        <section className='attend'>
            <div className="attend__head">
                <h4 className='attend__title'>Attendance Rate</h4>
                <Info show={isTabletOrLarger} />
            </div>
            <Rate sessions={unitedSessions} />
            <Info show={!isTabletOrLarger} />
        </section>
    );
};

export default Attendance;