import React from 'react'
import { PieChart } from 'react-minimal-pie-chart'

import './PieChartComponent.scss';

const PieChartComponent = ({ title, chartTitle, chartSubtitle, pieChartData, statisticData, frequency = false, total = false }) => {

    return (
        <div className="chart-item">
            <h3 className={`chart-item-title`} >{title}</h3>
            <div className="chart-wrap-chart">
                <div className={`pie-chart-data ${frequency && chartTitle > 0 ? "frequency" : ""}`}>
                    <div className="title-chart">{chartTitle}</div>
                    <div className="subtitle-chart">{chartSubtitle}</div>
                </div>
                <PieChart
                    data={pieChartData.sort((a, b) => b.value - a.value)}
                    lineWidth={30}
                    rounded
                    startAngle={-90}
                    style={{ width: 120 }}
                    animate
                    lengthAngle={-360}
                />
            </div>
            <div className={`chart-info-block ${frequency ? 'frequency' : ''}`}>
                {
                    statisticData.length > 3
                        ?
                        <div className='columns'>
                            <ul className="pie-chart-statistic">
                                {statisticData.slice(0, 3).map((item, index) =>
                                    <li key={index} className="statistic-item">
                                        {item.className && <div className={`color ${item.className}`}></div>}
                                        {item.statisticValue !== undefined && <div className="value">{item.statisticValue}</div>}
                                        <div className={`status ${frequency ? 'frequency' : ''}`}>{item.status ? item.status : item.title}</div>
                                    </li>)}
                            </ul>
                            <ul className="pie-chart-statistic">
                                {statisticData.slice(-2).map((item, index) =>
                                    <li key={index} className="statistic-item">
                                        {item.className && <div className={`color ${item.className}`}></div>}
                                        {item.statisticValue !== undefined && <div className="value">{item.statisticValue}</div>}
                                        <div className={`status ${frequency ? 'frequency' : ''}`}>{item.status ? item.status : item.title}</div>
                                    </li>)}
                            </ul>
                        </div>
                        :
                        <ul className="pie-chart-statistic">
                            {statisticData.map((item, index) =>
                                <li key={index} className="statistic-item">
                                    {item.className && <div className={`color ${item.className}`}></div>}
                                    {item.statisticValue !== undefined && <div className="value">{item.statisticValue}</div>}
                                    <div className={`status ${frequency ? 'frequency' : ''}`}>{item.status ? item.status : item.title}</div>
                                </li>)}
                        </ul>
                }

            </div>
            {total && <div className="chart-footer">{total}</div>}
        </div>
    )
}

export default PieChartComponent