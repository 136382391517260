import { call, put } from "redux-saga/effects";
import axios from "axios";
import {
	loginUserRequest,
	registerForProgramError,
	registerForProgramSuccess,
	showNotification,
} from "../../actions";
import api from "constants/api";
import { saveUserCredentials } from "../../sagas/helpers/saveUserCredentials";
import ResponseError from "helpers/ResponseError";
import { getNotificationMessage } from "../../../helpers/notifications";

export function* handleNewRegister(requestData) {
	const { REGISTER_FOR_PROGRAM_URL } = api;
	const { code: programCode, id: programId } = requestData;

	const { data } = yield call(() =>
		axios
			.post(
				`${REGISTER_FOR_PROGRAM_URL}/${programCode}/${programId}`,
				requestData
			)
			.then((data) => data)
			.catch((error) => error.response)
	);

	if (data?.status === "Success") {
		const registerData = data.data;

		yield put(registerForProgramSuccess(registerData.participant_program));

		yield put(
			loginUserRequest(
				{
					email: requestData?.email,
					password: requestData?.password,
				},
				requestData.callback
			)
		);

		yield saveUserCredentials(registerData);
	} else if (data?.status === "Error") {
		const err = new ResponseError(data);

		yield put(registerForProgramError(err.getValidationErrorMessages()));

		yield put(
			showNotification(
				{
					title: err.getMessage(),
					text: getNotificationMessage(err.getValidationErrorMessages()),
				},
				true
			)
		);
	}
}
