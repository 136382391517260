import * as Yup from 'yup';

export default function feedbackValidationSchema(item) {
    if (item.type === "multi_select") {
        if (item.required.includes("1")) {
            return Yup.object().shape({
                [item.id]: Yup.array().required("At list one option is required")
            })
        }
        return Yup.object().shape({
            [item.id]: Yup.array()
        })
    }

    if (item.type === "text") {
        return Yup.object().shape({
            [item.id]: Yup.string().required('Field is required').trim().max(1000, "This field must not be greater than 1000 characters")
        })
    }

    if (item.required.includes("1")) {
        return Yup.object().shape({
            [item.id]: Yup.string().required('Field is required').trim()
        })
    }

    return Yup.object().shape({
        [item.id]: Yup.string().trim()
    })
}
