import './addStyles';
import removeStyles from "services/platformSettings/removeStyles";
import getStyles from "services/platformSettings/addStyles";

const CustomPlatformSettings = () => {
    const platformSetting = JSON.parse(window.localStorage.getItem('platformSettings'));

    if (platformSetting && localStorage.getItem('school_id')) {
        const styles = getStyles(platformSetting);

        styles.forEach(styles => {
            const key = Object.keys(styles)[0];
            const value = Object.values(styles)[0];

            document.documentElement.style.setProperty(key, `${value}`);
        });
    } else {
        removeStyles.forEach(style => document.documentElement.style.removeProperty(style));
    }
}

export default CustomPlatformSettings;