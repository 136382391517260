import React from "react";
import {Switch, Route} from "react-router-dom";
import routes from "constants/routes";
import {LastLocationProvider} from "react-router-last-location";
import {privateRoutes as mainRoutes} from "./routes";
import NotFound from "components/pages/NotFound/NotFound";
import Auth from "components/pages/Auth/Auth";
import withLoggedLayout from "components/HOC/withLoggedLayout";
import {PrivateRoute} from "../PrivateRoute/PrivateRoute";
import {useAppInit} from "../../hooks/useAppInit";
import InitLoader from "components/auth/initLoader/initLoader";

const {SIGN_IN, REGISTER_PROGRAMS, CREATE_PASSWORD, RESET_PASSWORD} = routes;

export default function Root() {
    const isAppInited = useAppInit();

    return (
        isAppInited ?
            <LastLocationProvider>
                <Switch>
                    {
                        mainRoutes.map(route => <PrivateRoute key={route.path}
                                                              path={route.path}
                                                              exact={route.exact}
                                                              component={route.component}/>
                        )
                    }
                    <Route path={[SIGN_IN, REGISTER_PROGRAMS, CREATE_PASSWORD, RESET_PASSWORD]} component={Auth}/>
                    <PrivateRoute path="*" component={withLoggedLayout(NotFound)}/>
                </Switch>
            </LastLocationProvider>
            :
            <InitLoader/>
    );
};

