import React from "react";

import { Radio, RadioGroup, FormControlLabel, Checkbox } from "@mui/material";

// import vector1 from 'assets/images/FeedbackForm/Vector_72.svg';
// import vector2 from 'assets/images/FeedbackForm/Vector_75.svg';
import { ErrorMessage } from "formik";

const CheckboxIcon = () => {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 3C1 1.89543 1.89543 1 3 1H23C24.1046 1 25 1.89543 25 3V23C25 24.1046 24.1046 25 23 25H3C1.89543 25 1 24.1046 1 23V3Z"
				fill="white"
			/>
			<path
				d="M3 1.5H23V0.5H3V1.5ZM24.5 3V23H25.5V3H24.5ZM23 24.5H3V25.5H23V24.5ZM1.5 23V3H0.5V23H1.5ZM3 24.5C2.17157 24.5 1.5 23.8284 1.5 23H0.5C0.5 24.3807 1.61929 25.5 3 25.5V24.5ZM24.5 23C24.5 23.8284 23.8284 24.5 23 24.5V25.5C24.3807 25.5 25.5 24.3807 25.5 23H24.5ZM23 1.5C23.8284 1.5 24.5 2.17157 24.5 3H25.5C25.5 1.61929 24.3807 0.5 23 0.5V1.5ZM3 0.5C1.61929 0.5 0.5 1.61929 0.5 3H1.5C1.5 2.17157 2.17157 1.5 3 1.5V0.5Z"
				fill="#CDCBCB"
			/>
		</svg>
	);
};

const CheckboxCheckedIcon = () => {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 3C1 1.89543 1.89543 1 3 1H23C24.1046 1 25 1.89543 25 3V23C25 24.1046 24.1046 25 23 25H3C1.89543 25 1 24.1046 1 23V3Z"
				fill="white"
			/>
			<rect x="6" y="6" width="14" height="14" rx="1" fill="#2E79DA" />
			<path
				d="M3 1.5H23V0.5H3V1.5ZM24.5 3V23H25.5V3H24.5ZM23 24.5H3V25.5H23V24.5ZM1.5 23V3H0.5V23H1.5ZM3 24.5C2.17157 24.5 1.5 23.8284 1.5 23H0.5C0.5 24.3807 1.61929 25.5 3 25.5V24.5ZM24.5 23C24.5 23.8284 23.8284 24.5 23 24.5V25.5C24.3807 25.5 25.5 24.3807 25.5 23H24.5ZM23 1.5C23.8284 1.5 24.5 2.17157 24.5 3H25.5C25.5 1.61929 24.3807 0.5 23 0.5V1.5ZM3 0.5C1.61929 0.5 0.5 1.61929 0.5 3H1.5C1.5 2.17157 2.17157 1.5 3 1.5V0.5Z"
				fill="#CDCBCB"
			/>
		</svg>
	);
};

const RadioFiveItems = ({
	field,
	name,
	options,
	min_scale,
	max_scale,
	children,
	question,
	item,
	values,
	goToNextStep,
	goToPreviousStep,
	stepDirection,
	form,
	...props
}) => {
	const fieldName = name || field.name;
	const { setFieldValue } = form;
	// used to change the default colors for the checked radio buttons when navigating between shape steps
	const _addClasses = (item) => {
		switch (item) {
			case 0:
				return "fifth";
			case 1:
				return "fourth";
			case 2:
				return "third";
			case 3:
				return "second";
			case 4:
				return "first";
			default:
				return "";
		}
	};

	const renderOptions = (options) => {
		const colors = [
			"#3D8B82",
			"#4296E5",
			"#ECAF2B",
			"#E75B32",
			"#9F3C47",
		].reverse();
		const backgroundColors = [
			"rgb(61, 139, 130, 0.2)",
			"rgb(66, 150, 229, 0.2)",
			"rgb(236, 175, 43, 0.2)",
			"rgb(231, 91, 50, 0.2)",
			"rgb(159, 60, 71, 0.2)",
		].reverse();
		const checkedBackgroundColors = [
			"rgb(61, 139, 130)",
			"rgb(66, 150, 229)",
			"rgb(236, 175, 43)",
			"rgb(231, 91, 50)",
			"rgb(159, 60, 71)",
		].reverse();

		const changeColorAfterChecked = (index) => {
			const radioButtons = document.querySelectorAll(
				".MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-1.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary"
			);
			for (let i = 0; i < radioButtons.length; i++) {
				if (i === index) {
					radioButtons[i].style.backgroundColor = checkedBackgroundColors[i];
				} else {
					radioButtons[i].style.backgroundColor = backgroundColors[i];
				}
			}
		};

		return options.map((option, index) => (
			<FormControlLabel
				style={{
					width: "64px",
					height: "64px",
				}}
				key={option.option}
				value={option.option}
				control={
					<Radio
						className={_addClasses(index)}
						onClick={() => changeColorAfterChecked(index)}
						checkedIcon={
							<svg
								width="20"
								height="18"
								viewBox="0 0 20 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16.6274 1.30797C17.2175 0.75259 18.2241 -0.184623 19.1266 0.301339C21.4523 1.5509 18.5018 4.46673 17.5646 5.64692C16.072 7.55606 14.51 9.39583 12.9827 11.2703C11.8025 12.6934 10.7612 14.2555 9.4768 15.6439C8.74786 16.4423 7.49824 17.9002 6.28333 17.8307C5.2767 17.796 4.40891 17.0671 3.74939 16.3729C2.18737 14.7415 0.069957 12.1728 0.000533835 9.77766C-0.0341778 7.93795 1.63198 8.49333 2.63862 9.25699C4.02708 10.333 5.10314 11.652 6.35276 12.867C6.90814 11.8256 7.81064 10.9231 8.64372 10.0901C10.1016 8.59741 11.4207 7.0701 12.8438 5.54279C14.1282 4.15433 15.2737 2.62702 16.6274 1.30797Z"
									fill="white"
								/>
							</svg>
						}
						style={{
							color: colors[index],
							backgroundColor: backgroundColors[index],
							width: "64px",
							height: "64px",
						}}
					/>
				}
			/>
		));
	};

	React.useEffect(() => {
		if (item?.condition) {
			let array = item.condition;

			let check = array?.filter((item) => {
				if (typeof values[item.key] === "number") {
					return values[item.key] === item.val && item;
				}
				return values[item.key].includes(item.val) && item;
			});

			if (!check?.length) {
				if (stepDirection === "next") {
					goToNextStep();
				}
				if (stepDirection === "back") {
					goToPreviousStep();
				}
			}
		}
	}, [item?.condition, goToNextStep, values, goToPreviousStep, stepDirection]);

	return (
		<>
			<div className="form-question">{question}</div>
			<div
				className="radio__buttons five__buttons"
				role="group"
				aria-labelledby="my-radio-group"
			>
				{/* <img className="vector vector_1" src={vector1} alt="" />
                <img className="vector vector_2" src={vector2} alt="" />
                <img className="vector vector_3" src={vector1} alt="" />
                <img className="vector vector_4" src={vector2} alt="" /> */}
				<div className="criteria min mobile">
					{min_scale ? min_scale : options[0].option}
				</div>
				<React.Fragment>
					<RadioGroup {...field} {...props} name={fieldName}>
						{options ? renderOptions(options) : children}
					</RadioGroup>
				</React.Fragment>
				<div className="criteria max mobile">
					{max_scale ? max_scale : options[options.length - 1].option}
				</div>
			</div>
			<div className="radio__criterias">
				<div className="criteria min">
					{min_scale ? min_scale : options[0].option}
				</div>
				<div className="criteria max">
					{max_scale ? max_scale : options[options.length - 1].option}
				</div>
			</div>
			{(fieldName === "PQ-72" ||
				fieldName === "PQ-76" ||
				fieldName === "PQ-78" ||
				fieldName === "PQ-88" ||
				fieldName === "PQ-89" ||
				fieldName === "PQ-90" ||
				fieldName === "PQ-93" ||
				fieldName === "PQ-116") && (
				<div className="not-answer__wrapper">
					<FormControlLabel
						className={"not-answer"}
						value={"not answer"}
						label={"Prefer not to answer"}
						onChange={(event) => {
							if (values[fieldName].includes("not answer")) {
								setFieldValue(fieldName, "");
							} else {
								setFieldValue(fieldName, event.target.value);
							}
						}}
						checked={values[fieldName].includes("not answer")}
						control={
							<Checkbox
								name={fieldName}
								icon={<CheckboxIcon />}
								checkedIcon={<CheckboxCheckedIcon />}
							/>
						}
					/>
				</div>
			)}
			<ErrorMessage
				name={fieldName}
				render={(msg) => <p className="error-message">{msg}</p>}
			/>
		</>
	);
};

export default RadioFiveItems;
