import React from 'react';
import {Field} from "formik";
import CustomFormSelect from "components/CustomFormSelect/CustomMyProfileSelect";

const SeniorityLevel = ({userInfo, getSeniorityLevels}) => {
    return (
        <div className="section-info-block seniority-level">
            <h3 className={`section-title`}>Seniority Level</h3>
            <div className={`profile-row seniority-level-list`}>
                <label htmlFor={'seniority_level'} className="profile-row__label">Seniority</label>
                <Field
                    name="seniority_level"
                    options={getSeniorityLevels}
                    placeholder="Choose your option..."
                    component={CustomFormSelect}
                    defaultValue={userInfo.seniority_level_id}
                />
            </div>
        </div>
    );
};

export default SeniorityLevel;