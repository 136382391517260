import {useSelector} from "react-redux";


const useRequestStatus = ([
                              stateSelector = '',
                              progress = 'requestProgress',
                              success = 'requestSuccess',
                              error = 'requestError'
                          ] = []) => {

    const statusSelector = useSelector(state => state[stateSelector]);

    if (statusSelector) {
        return {
            requestProgress: statusSelector[progress],
            requestError: statusSelector[error],
            requestSuccess: statusSelector[success]
        }
    }

    return {};
}

export default useRequestStatus;