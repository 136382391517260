import {
    careerAnswers,
    coachAnswers,
    sdaCareerAnswers
} from "components/pages/QuestionnaireForms/MockData/sdaCareerAnswers";

export const fakeSubmitListener = (steps, submitForm) => () => {
    if (process.env.REACT_APP_API_BASE_URL === 'https://api.staging.switchapp.io/api') {
        let fakeAnswers = {};

        if (steps.length > 0 && steps[0].key?.match('PQ')) {
            fakeAnswers = {...sdaCareerAnswers};
        }

        if (steps.length > 0 && steps[0].key?.match('PC')) {
            fakeAnswers = {...careerAnswers};
        }

        if (steps.length > 0 && steps[0].key?.match('CQ')) {
            fakeAnswers = {...coachAnswers};
        }

        const keysListener = e => {
            if (e.shiftKey && e.key === 'F12') {
                submitForm(fakeAnswers);
            }
        };

        window.addEventListener('keydown', keysListener);

        return () => window.removeEventListener('keydown', keysListener);
    }
}