import React from 'react';
import {useMediaQuery} from "react-responsive";
import PerfectScrollbar from "react-perfect-scrollbar";

const PerfectMediaScroll = ({children, mediaQuery = {}, ...props}) => {
    const isMediaQuery = useMediaQuery(mediaQuery);

    return (
        isMediaQuery ?
            <PerfectScrollbar {...props}>
                {children}
            </PerfectScrollbar>
            :
            <div {...props}>
                {children}
            </div>
    );
};

export default PerfectMediaScroll;