export const transformActivityCategories = (staticData) => {
    if (staticData) {
        const transformedCategories = staticData.activityCategories?.map(category => {
            return {
                ...category,
                label: category.name
            };
        });

        return {
            ...staticData,
            activityCategories: transformedCategories
        };
    }

    return staticData;
};